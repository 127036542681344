
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const EBD = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EBD.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const LTBlockDeviceMappings = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LTBlockDeviceMappings.png")';
    let LabelName = data.Param[3][1] + "GB";
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EBS = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EBS.png")';
    let LabelName = data.Param[3][1] + "GB";
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EBSA = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EBSA.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export { EBS, EBSA, EBD, LTBlockDeviceMappings };

