import { Handle, Position } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'
import React, { useState, useEffect } from 'react';
import GenericBoxNode from '../GenericBoxNode';

const dragHandleStyle = {
    display: 'inline-block',
    width: 300,
    height: 30,
    backgroundColor: 'teal',
    marginLeft: 5,
    borderRadius: 1,
};


const SecurityGroup = ({ id, data, selected }) => {
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    const [isMouseNear, setIsMouseNear] = useState(false);
    let Opacity = data.Opacity;
    return (
        <div
            onMouseEnter={() => setIsMouseNear(true)}
            onMouseLeave={() => setIsMouseNear(false)}
        >
            <GenericBoxNode
                id={id}
                data={data}
                selected={selected}
                HasHandle={true}
            >
                <div style={{
                    position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
                    borderRadius: "15px 15px 0 0", border: data.EnableBorder ? data.Border : 'none', opacity: Opacity
                }}>
                    <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: 10 }}>
                        Security Group</div>
                    <div style={{ borderRadius: "10%" }}>
                    </div>
                </div >
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />

                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />
            </GenericBoxNode>
        </div>
    );
};

const SGERule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SGERule.png")';
    let LabelName = " " + data.Param[3][3] + " " + data.Param[7][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const SGIRule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SGIRule.png")';
    let LabelName = " " + data.Param[3][3] + " " + data.Param[7][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { SecurityGroup, SGERule, SGIRule }




