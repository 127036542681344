import { Handle, Position, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const AZRT = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZRT.svg")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected} />
    );
};

const AZInternet = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZInternet.svg")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected} />
    );
};

const AZNATGateway = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZNATGateway.svg")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected} />
    );
};

const EgressOnlyIG = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EgressOnlyIG.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected} />
    );
};
const VPCEndpointG = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'VPCEndpointG.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected} />
    );
};
const VPCEndpointI = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'VPCEndpointI.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected} />
    );
};

export { AZRT, AZInternet, AZNATGateway };

