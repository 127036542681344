import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const EBBus = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'EBBus.png")';

    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};

const EBTarget = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'EBTarget.png")';

    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};

const EBRule = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'EBRule.png")';

    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};


export { EBRule, EBTarget, EBBus };

