import { Handle, Position } from "reactflow";
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'
import { useState } from 'react';

const EFS = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EFS.png")';
    let Label = data.Label;
    const [isMouseNear, setIsMouseNear] = useState(false);
    let Opacity = data.Opacity;
    return (
        <div onMouseEnter={() => setIsMouseNear(true)} onMouseLeave={() => setIsMouseNear(false)}  >
            <div style={{
                boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%", opacity: Opacity
            }}>
                <div className="circleNode" style={{
                    backgroundImage: URLIcon,
                    filter: selected ? "saturate(500%)" : "none", borderRadius: data.ShowLabel ? "3px 3px 0 0 " : "3px"
                }} ></div>
                {data.ShowLabel && (<div className="BarNameFontSmaller"
                    style={{
                        width: "40px", background: selected ? "#C0C0E0" : "#FFFFFF", height: "auto",
                        borderRadius: data.ShowLabelName ? "0" : "0 0 3px 3px"
                    }}>
                    {Label} {data.Param[1][1]}
                </div>
                )}
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />

                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />
            </div>
        </div>
    );
};

const EFSPolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EFSPolicy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EFSRC = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EFSRC.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const EFSAP = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EFSAP.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};




export { EFS, EFSPolicy, EFSRC, EFSAP };

