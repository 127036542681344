import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const SQS = ({ data, selected, }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SQS.png")';
    let LabelName = data.Param[1][1];

    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const SQSDLQ = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SQSDLQ.png")';
    let LabelName = data.Param[1][1];

    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { SQS, SQSDLQ };

