import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const SSMParameter = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SSMParameter.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const EC2Connect = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EC2Connect.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};


export { SSMParameter, EC2Connect };

