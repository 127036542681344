import { Constraints } from '../NodesData';
import '../VPC.css';
import GenericNode from '../GenericNode'

const KMSKey = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KMSKey.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const KMSKeyD = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KMSKeyD.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { KMSKey, KMSKeyD };

