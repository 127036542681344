import { Constraints } from '../NodesData';
import '../VPC.css';
import GenericNode from '../GenericNode'

const SecretsmanagerSecret = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SecretsmanagerSecret.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const SecretsmanagerSVersion = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SecretsmanagerSVersion.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export { SecretsmanagerSVersion, SecretsmanagerSecret };

