import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const EKSNodeGroup = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EKSNodeGroup.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EKSCluster = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EKSCluster.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const EKSfargateProfile = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EKSfargateProfile.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EKSAddon = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EKSAddon.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export { EKSNodeGroup, EKSCluster, EKSfargateProfile, EKSAddon };

