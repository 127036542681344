import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'
const Methods = ["GET", "POST", "PUT", "DELETE", "HEAD", "OPTIONS", "ANY"]

const APIGRest = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGRest.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const APIGRestLambda = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGRestLambda.png")';
    let Get = data.Param[3][2][1][1] != 0 ? "GET " : "";
    let Post = data.Param[3][2][2][1] != 0 ? "POST " : "";
    let Put = data.Param[3][2][3][1] != 0 ? "PUT " : "";
    let Delete = data.Param[3][2][4][1] != 0 ? "DELETE " : "";
    let Head = data.Param[3][2][5][1] != 0 ? "HEAD " : "";
    let Options = data.Param[3][2][6][1] != 0 ? "OPTIONS " : "";
    let Any = data.Param[3][2][7][1] != 0 ? "ANY" : "";
    let LabelName = " (" + Get + Post + Put + Delete + Head + Options + Any + ")"
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const APIGAccount = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGAccount.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGResource = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGResource.png")';
    let Label = data.Label
    let Resource = data.Param[4][1]
    Label = Label.replace("$", Resource);
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} Label={Label} />
    );
};

const APIGMethod = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGMethod.png")';
    let Label = data.Label
    let Method = data.Param[4][1]
    let MethodName = Methods[Method];
    data.Method = MethodName;
    Label = Label.replace("$", MethodName);

    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} Label={Label} />
    );
};
const APIGMethodResp = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGMethodResp.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const APIGIntegration = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGIntegration.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGIntegrationResp = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGIntegrationResp.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGStage = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGStage.png")';
    let LabelName = " (" + data.Param[4][1] + ")";

    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGDeployment = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGDeployment.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGUsagePlan = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGUsagePlan.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGMSettings = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGMSettings.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGAuthorizer = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGAuthorizer.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const APIGDomainName = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGDomainName.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export {
    APIGRest, APIGRestLambda, APIGResource, APIGMethod, APIGIntegration, APIGMethodResp, APIGIntegrationResp,
    APIGStage, APIGDeployment, APIGUsagePlan, APIGMSettings, APIGAuthorizer, APIGDomainName, APIGAccount,
};

