import React, { useState } from 'react';
import { useModal } from './ModalMain.js';
import { SearchNodesTarget, SearchNodesSource, DiscoveryTerraformNetwork, FindNodeHead, UpdateRoute53BlueGreen } from './Functions';

function ListBoxFunc(GlobalEdges, GlobalNodes, StageID) {
    let ListBox = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageID], "SBoxN");
    ListBox = ListBox.concat(SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageID], "VPCN"));
    return ListBox;
}
const Action = ({ GlobalNodes, NodeID, onCopyStage, GlobalEdges }) => {
    const NodeType = GlobalNodes[NodeID].type;
    const [loadingLogs, setLoadingLogs] = useState(false);
    let CurrentLog;
    try {
        CurrentLog = GlobalNodes[NodeID].data.Historic[0] || "";
    } catch (error) {
        CurrentLog = "";
    }
    const [logs, setLogs] = useState(CurrentLog);
    const [GreenPercent, setGreenPercent] = useState(GlobalNodes[NodeID].data.GreenPercent || 0);
    const { activeModal } = useModal();
    /*if (NodeType === "CodePipelineN") {
        GlobalNodes[NodeID].data.StateMachine = "Start";
    }
    if (NodeType === "CodePipelineStageN") {
        GlobalNodes[NodeID].data.StateMachine = "Idle";
    }*/
    if (NodeType === "CodePipelineN" || NodeType === "CodePipelineStageN") {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Janeiro é 0!
        const day = String(now.getUTCDate()).padStart(2, '0');
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');
        const utcDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        const Stage = GlobalNodes[NodeID].data.Param[3][1].toLowerCase();
        const Percent = parseInt(GlobalNodes[NodeID].data.Param[4][1]) || 10;
        const BluePercent = 100 - GreenPercent;
        const IsTest = Stage.toLowerCase().includes("test");
        const IsIdentityProvider = parseInt(GlobalNodes[NodeID].data.Param[5][1]) === 1;
        let HasVersion = true, Error = false;
        console.log("IsIdentityProvider", IsIdentityProvider, GlobalNodes[NodeID].data.Param[5][1])
        const [CPHeadID, Level] = FindNodeHead(GlobalEdges, GlobalNodes, "CodePipelineN", "CodePipelineStageN", NodeID);
        console.log("CPHeadID, Level", CPHeadID, Level)
        let CopyButton = false, StartTerraformApply, DBSync = false, StartTest = false, StartPipeline = false, FinalApproval = false, TestApproval = false,
            BlueGreen = false, StateMachine, ListBox = [];
        let BoxSourceID, BoxTargetID, PrevStageID = 0, Rollback = "";
        if (NodeType === "CodePipelineStageN" && !["dev", "test"].includes(Stage)) {
            Rollback = ": Rollback";
        }
        if (NodeType === "CodePipelineN") {
            try {
                StateMachine = GlobalNodes[NodeID].data.StateMachine;
            } catch (error) {
                StateMachine = "Start";
            }
            StartPipeline = StateMachine === "Start";
        } else {//CodePipelineStageN
            try {
                StateMachine = GlobalNodes[NodeID].data.StateMachine;
            } catch (error) {
                StateMachine = "Idle";
            }
            CopyButton = StateMachine === "Copy";
            StartTerraformApply = StateMachine === "StartTerraformApply";
            DBSync = StateMachine === "DBSync";
            StartTest = StateMachine === "StartTest";
            TestApproval = StateMachine === "TestApproval";
            BlueGreen = StateMachine === "BlueGreen";
            FinalApproval = StateMachine === "FinalApproval";
            console.log("CodePipelineStageN", Level, DBSync)
            console.log("StateMachine", StateMachine, GlobalNodes[NodeID].data.Param[3][1], CopyButton, FinalApproval)
            /*if (StateMachine === "Copy" && CPHeadID !== 0) {
                console.log("PAssu aqui");
                if (!(GlobalNodes[NodeID].data.Param[3][1].toLowerCase().includes("dev"))) {
                    //if (GlobalNodes[CPHeadID].data.StateMachine !== "Start") { CopyButton = true; }
                    CopyButton = true;
                }
            }
            if (StateMachine === "Copy" && CPHeadID !== 0) {
                console.log("Processing");
                if (GlobalNodes[CPHeadID].data.StateMachine !== "Start") { StateMachine = "FinalApproval"; }
            }*/
        }
        //console.log("CopyButton", CopyButton)
        console.log("StateMachine", StateMachine, GlobalNodes[NodeID].data.Param[3][1])

        if (activeModal !== 'modalAction') return null;
        //console.log("NodeID", NodeID);

        async function HandleStartPipeline() {
            // Função para iniciar o pipeline e buscar os logs
            let TerraformNetwork;
            let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
            console.log("ListStage", ListStage, GlobalNodes[NodeID].type);
            StateMachine = "Test";
            GlobalNodes[NodeID].data.StateMachine = StateMachine;
            GlobalNodes[NodeID].data.Historic[0] = utcDateTime + " (UTC) : StartPipeline";
            setLogs(GlobalNodes[NodeID].data.Historic[0]);
            if (ListStage.length > 0) {
                let StageDevID = parseInt(ListStage[0]);
                console.log("StageDevID", StageDevID);
                //let ListBox = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageDevID], "SBoxN");
                let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, StageDevID)
                console.log("ListBox", ListBox);
                if (ListBox.length > 0) {
                    let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[StageDevID], "CodePipelineStageN");
                    if (ListStage.length > 0) {
                        let StageTestID = parseInt(ListStage[0]);
                        GlobalNodes[StageTestID].data.StateMachine = "Copy";
                    }
                    //TerraformNetwork = DiscoveryTerraformNetwork(GlobalEdges, GlobalNodes, parseInt(ListBox[0]));
                }
            }
        }

        async function HandleCopyStage() {
            //let ListBox = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "SBoxN");
            let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID)
            if (ListBox.length > 0) {
                BoxTargetID = parseInt(ListBox[0]);
                const ListPreviousStage = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
                if (ListPreviousStage.length > 0) {
                    PrevStageID = parseInt(ListPreviousStage[0]);
                    //ListBox = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[PrevStageID], "SBoxN");
                    ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, PrevStageID)
                    if (ListBox.length > 0) {
                        BoxSourceID = parseInt(ListBox[0]);
                    }
                }
                let TerraformNetwork;
                console.log("HandleCopyStage", BoxSourceID, BoxTargetID, PrevStageID);
                const BoxTargetName = GlobalNodes[BoxTargetID].data.Param[1][1];
                console.log("IsTest", IsTest, ListBox)
                Error = onCopyStage([BoxSourceID, BoxTargetID, IsTest, "Copy"]);
                console.log("hasversion act", HasVersion)
                if (Error) {
                    GlobalNodes[NodeID].data.StateMachine = "Idle";
                    GlobalNodes[CPHeadID].data.StateMachine = "Start";
                } else {
                    TerraformNetwork = DiscoveryTerraformNetwork(GlobalEdges, GlobalNodes, parseInt(ListBox[0]));
                    console.log("TerraformNetwork", TerraformNetwork);
                    let TerraformFileNameList = [];
                    for (let i = 1; i < TerraformNetwork[0].length; i++) {
                        TerraformFileNameList.push(GlobalNodes[parseInt(TerraformNetwork[0][i])].data.Param[1][1]);
                    }
                    console.log("TerraformFileNameList", TerraformFileNameList, BoxTargetName, IsTest)
                    GlobalNodes[NodeID].data.StateMachine = "StartTerraformApply";
                }
            }
        }
        async function HandleStartTerraformApply() {
            console.log("HandleStartTerraformApply")
            GlobalNodes[NodeID].data.StateMachine = "DBSync";
        }
        async function HandleDBSync() {
            console.log("DBSync")
            GlobalNodes[NodeID].data.StateMachine = "StartTest";
        }
        async function HandleStartTest() {
            console.log("HandleStartTest")
            GlobalNodes[NodeID].data.StateMachine = "TestApproval";
        }
        async function HandleTestApproved() {
            TestPass(true);
        }
        async function HandleTestFail() {
            TestPass(false);
        }
        async function HandleShiftToGreen() {
            console.log("HandleShiftToGreen")
            let NewGreenPercent = GlobalNodes[NodeID].data.GreenPercent;
            NewGreenPercent += Percent;
            setGreenPercent(NewGreenPercent);
            console.log("GreenPercent", GreenPercent, Percent)
            GlobalNodes[NodeID].data.GreenPercent = NewGreenPercent;
            let R53ZoneID = GlobalNodes[NodeID].data.R53ZoneID;
            console.log("R53ZoneID", R53ZoneID)
            let R53ZoneName = GlobalNodes[R53ZoneID].data.Param[2][1];
            while (true) {
                let ListR53 = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[R53ZoneID], "R53ZoneN");
                if (ListR53.length !== 0) {
                    R53ZoneID = parseInt(ListR53[0]);
                    R53ZoneName += "." + GlobalNodes[R53ZoneID].data.Param[2][1];
                } else {
                    break;
                }
            }
            UpdateRoute53BlueGreen(R53ZoneName, NewGreenPercent);
            if (NewGreenPercent >= 100) {
                GlobalNodes[NodeID].data.GreenPercent = 0;
                GlobalNodes[NodeID].data.StateMachine = "FinalApproval";
            }
        }

        async function HandleRollback() {
            FinalPass(false);
        }
        async function HandleStageApproved() {
            FinalPass(true);
        }
        async function HandleStageFail() {
            FinalPass(false);
        }
        function TestPass(IsApproved) {
            //let ListBox = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "SBoxN");
            let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID)
            if (ListBox.length > 0) {
                BoxTargetID = parseInt(ListBox[0]);
                let NextStageIntID = 0;
                let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
                if (ListStage.length > 0) {
                    //array.unshift(newElement);
                    NextStageIntID = parseInt(ListStage[0]);
                    GlobalNodes[NodeID].data.StateMachine = "Idle";
                    GlobalNodes[NodeID].data.Historic[Level] = utcDateTime + " (UTC) : StartPipeline";
                    //setLogs(GlobalNodes[NodeID].data.Historic[Level]);
                }
                console.log("NextStageIntID", NextStageIntID, Level)
                if (CPHeadID !== 0) {
                    if (IsTest) {
                        GlobalNodes[CPHeadID].data.StateMachine = "Start";
                        GlobalNodes[NodeID].data.StateMachine = "Idle";
                        if (IsApproved) {
                            //GlobalNodes[NodeID].data.StateMachine = "BlueGreen";
                            if (NextStageIntID !== 0) {
                                let NextStage = GlobalNodes[NextStageIntID].data.Param[3][1];
                                GlobalNodes[NextStageIntID].data.StateMachine = "Copy";
                            }
                        }
                    } else {
                        console.log("Prod aqui")
                        if (IsApproved) {
                            let ListR53 = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "R53ZoneN");
                            console.log("ListR53", ListR53)
                            if (ListR53.length === 0) {
                                GlobalNodes[NodeID].data.StateMachine = "FinalApproval";
                            } else {
                                GlobalNodes[NodeID].data.StateMachine = "BlueGreen";
                                const R53ID = parseInt(ListR53[0])
                                GlobalNodes[NodeID].data.R53ZoneID = R53ID;
                            }
                        } else {
                            console.log("delete green here")
                            onCopyStage([BoxTargetID, BoxTargetID, IsTest, "DelGreen"]);
                            GlobalNodes[NodeID].data.StateMachine = "Copy";
                        }
                    }
                }
            }
        }

        function FinalPass(IsApproved) {
            //let ListBox = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "SBoxN");
            let ListBox = ListBoxFunc(GlobalEdges, GlobalNodes, NodeID)
            if (ListBox.length > 0) {
                BoxTargetID = parseInt(ListBox[0]);
                let NextStageIntID = 0;
                let ListStage = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[NodeID], "CodePipelineStageN");
                if (ListStage.length > 0) {
                    //array.unshift(newElement);
                    NextStageIntID = parseInt(ListStage[0]);
                    GlobalNodes[NodeID].data.StateMachine = "Idle";
                    GlobalNodes[NodeID].data.Historic[Level] = utcDateTime + " (UTC) : StartPipeline";
                    //setLogs(GlobalNodes[NodeID].data.Historic[Level]);
                }
                console.log("NextStageIntID", NextStageIntID, Level)
                if (CPHeadID !== 0) {
                    if (NextStageIntID !== 0) {
                        let NextStage = GlobalNodes[NextStageIntID].data.Param[3][1];
                        GlobalNodes[NextStageIntID].data.StateMachine = "Copy";
                        GlobalNodes[NextStageIntID].data.GreenPercent = 0;
                    }
                    if (Stage !== "test") {
                        if (IsApproved && Stage !== "test") {//Delete Blue e promove Green para Blue
                            onCopyStage([BoxTargetID, BoxTargetID, IsTest, "DelBlue"]);
                            GlobalNodes[NodeID].data.StateMachine = "Idle";
                        } else {// Delete Green
                            console.log("delete green here")
                            onCopyStage([BoxTargetID, BoxTargetID, IsTest, "DelGreen"]);
                            GlobalNodes[NodeID].data.StateMachine = "Copy";
                            GlobalNodes[NodeID].data.GreenPercent = 0;
                        }
                    }
                }
            }
        }

        return (
            <div style={{ backgroundColor: 'white' }}>
                <div className="custom-node__header">
                    <h2 className="custom-drag-handle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        Action
                    </h2>
                    {StartPipeline && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStartPipeline()} style={{ marginRight: '10px' }}>
                                Start Pipeline
                            </button>
                        </div>
                    )}
                    {CopyButton && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleCopyStage()} style={{ marginRight: '10px' }}>
                                Copy Version Box
                            </button>
                        </div>
                    )}
                    {StartTerraformApply && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStartTerraformApply()} style={{ marginRight: '10px' }}>
                                Start Terraform Apply
                            </button>
                        </div>
                    )}
                    {DBSync && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleDBSync()} style={{ marginRight: '10px' }}>
                                Data Copy
                            </button>
                        </div>
                    )}
                    {StartTest && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStartTest()} style={{ marginRight: '10px' }}>
                                Start Test
                            </button>
                        </div>
                    )}
                    {TestApproval && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleTestApproved()} style={{ marginRight: '10px' }}>
                                Test Approved
                            </button>
                            <button id="focus" onClick={() => HandleTestFail()} style={{ marginRight: '10px' }}>
                                Test Fail
                            </button>
                        </div>
                    )}
                    {BlueGreen && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <br />
                            <h1>Blue {BluePercent}% Green {GreenPercent}%</h1>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <button id="focus" onClick={() => HandleShiftToGreen()} style={{ marginRight: '10px' }}>
                                    Shift {Percent}% to Green
                                </button>
                                <button id="focus" onClick={() => HandleRollback()} style={{ marginRight: '10px' }}>
                                    Rollback
                                </button>
                            </div>
                        </div>

                    )}
                    {FinalApproval && (
                        <div className="Variable" style={{ fontSize: '10px', display: 'flex', justifyContent: 'center' }}>
                            <br />
                            <button id="focus" onClick={() => HandleStageApproved()} style={{ marginRight: '10px' }}>
                                Stage Approved
                            </button>
                            <button id="focus" onClick={() => HandleStageFail()} style={{ marginRight: '10px' }}>
                                Stage Fail{Rollback}
                            </button>
                        </div>
                    )}
                </div>
                {loadingLogs && <div>Loading logs...</div>}
                {logs && (
                    <div className="pipeline-logs" style={{ marginTop: '10px', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                        <h3>Pipeline Logs</h3>
                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            {logs}
                        </pre>
                    </div>
                )}
            </div>
        );
    };
}

export { Action };
