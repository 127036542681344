import { Handle, Position } from "reactflow";
import { CalculateScale } from '../Functions';
import { Constraints, GNodes } from '../NodesData';
import GenericNode from '../GenericNode';
import { NodeResizer } from '@reactflow/node-resizer';
const MinWidth = 120;
const MinHeight = 120;
const MaxWidth = 360;

const KCluster = ({ id, data, selected }) => {
    try {
        let Width = GNodes[parseInt(id)].style.width;
        let scaleFactor = CalculateScale(Width, MinWidth, MaxWidth) * 1.3;

        const baseStyles = {
            img: { width: 80, height: 80, margin: "0px 11px" },
            text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" },
            providerImg: { width: 60, height: 60, margin: "0px 11px" },
            title: { fontSize: 32 }
        }
        const scaledStyles = {
            img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor * 3, height: baseStyles.img.height * scaleFactor },
            text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
            providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
            title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }
        }

        return (
            <>
                <NodeResizer style={{ borderRadius: "20px" }} color="#ff0000" isVisible={selected} minWidth={140} minHeight={90} />
                <div style={{
                    position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
                    borderRadius: "15px 15px 0 0"
                }}>
                    <div className="custom-drag-handle" style={{ ...scaledStyles.title, textAlign: "center" }}>
                        Kubernetes Cluster  {data.Param[1][1]}
                    </div>

                    <div style={{ borderRadius: "10%" }}>
                    </div>
                </div>
                <Handle
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />

            </>
        );
    } catch (error) {
        //pass
    }
};

const KNamespace = ({ id, data, selected }) => {
    try {
        let Width = GNodes[parseInt(id)].style.width;
        let scaleFactor = CalculateScale(Width, MinWidth, MaxWidth) * 1.3;

        const baseStyles = {
            img: { width: 80, height: 80, margin: "0px 11px" },
            text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" },
            providerImg: { width: 60, height: 60, margin: "0px 11px" },
            title: { fontSize: 32 }
        }
        const scaledStyles = {
            img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor * 3, height: baseStyles.img.height * scaleFactor },
            text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
            providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
            title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }
        }

        return (
            <>
                <NodeResizer style={{ borderRadius: "20px" }} color="#ff0000" isVisible={selected} minWidth={140} minHeight={90} />
                <div style={{
                    position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
                    borderRadius: "15px 15px 0 0"
                }}>
                    <div className="custom-drag-handle" style={{ ...scaledStyles.title, textAlign: "center" }}>
                        Namespace  {data.Param[1][1]}
                    </div>

                    <div style={{ borderRadius: "10%" }}>
                    </div>
                </div>
            </>
        );
    } catch (error) {
        //pass
    }
};

const KPod = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KPod.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KLivenessProbe = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KLivenessProbe.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KDNSConfig = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KDNSConfig.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KAffinit = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KAffinit.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KDeployment = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KDeployment.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KPodAutoscaler = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KPodAutoscaler.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KPodVolume = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KPodVolume.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KSA = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KSA.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KIngress = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KIngress.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KIngressPath = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KIngressPath.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KIngressClass = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KIngressClass.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KConfigMap = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KConfigMap.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KSecret = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KSecret.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KQuota = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KQuota.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KServiceNodePort = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KServiceNodePort.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KRole = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KRole.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KTaint = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KTaint.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KContainer = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KContainer.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KPVolume = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KPVolume.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const KPVolumeC = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'KPVolumeC.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};

export {
    KCluster, KNamespace, KPod, KDeployment, KPodAutoscaler, KLivenessProbe, KDNSConfig, KAffinit, KPodVolume, KSA,
    KIngress, KIngressPath, KIngressClass, KConfigMap, KSecret, KQuota, KServiceNodePort, KRole, KTaint, KContainer,
    KPVolume, KPVolumeC,
};

