import React, { useState, useEffect } from 'react';
import { Handle, Position, NodeToolbar } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import 'reactflow/dist/style.css';
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode';


const AZPublicIP = ({ data, selected }) => {
  const URLIcon = 'url("' + Constraints.IconsURL + 'AZPublicIP.svg")';
  let Label = data.Label;
  return (
    <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
      Source={data.Source} Target={data.Target} Selected={selected} ShowLabelName={data.ShowLabelName} />
  );
};

const AZVNET = ({ data, selected }) => {
  const URLIcon = Constraints.IconsURL + 'AZVNET.svg';
  const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
  let VNETName = data.Param[1][1];
  let CIDR = data.Param[3][1];
  let FontSize = data.Param[13][1];
  var [scaleFactor, setScaleFactor] = useState(1);  // Valor inicial é 1 (100%)
  useEffect(() => {
    let newScale;
    switch (FontSize) {
      case 1:
        newScale = .6;
        break;
      case 2:
        newScale = .3;
        break;
      default:
        newScale = 1;
    }
    setScaleFactor(newScale);
  }, [FontSize]);
  const baseStyles = {
    img: { width: 80, height: 80, margin: "0px 11px" },
    text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" },
    providerImg: { width: 60, height: 60, margin: "0px 11px" },
    title: { fontSize: 32 }  // Defini um tamanho base de 24px para o título.
  }
  const scaledStyles = {
    img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor, height: baseStyles.img.height * scaleFactor },
    text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
    providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
    title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }
  }
  return (
    <>
      <NodeResizer style={{ borderRadius: "20px" }} color="#ff0000" isVisible={selected} minWidth={20} minHeight={20} />
      <div style={{
        position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
        borderRadius: "15px 15px 0 0"
      }}>
        <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <img src={URLIcon} alt="Icon" style={scaledStyles.img} />
          <div style={scaledStyles.title}>{VNETName} {CIDR}</div>
        </div>

        <div style={{ borderRadius: "10%" }}>
        </div>
        <div style={{ borderRadius: "10%" }}>
        </div>

      </div>
      <Handle type="source" position="right" style={{
        background: 'transparent', backgroundImage: `url(${URLArrow})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', border: 'none', afterBorderColor: 'none', width: '15px', height: '15px', top: '22px',
        transform: 'translateX(0px)', imageRendering: 'crisp-edges', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
      }} isConnectable={true} />
    </>
  );
};

const AZSBox = ({ data, selected }) => {

  return (
    <>
      <NodeResizer style={{ borderRadius: "20px" }} color="#ff0000" isVisible={selected} minWidth={20} minHeight={20} />
      <div style={{
        position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
        borderRadius: "15px 15px 0 0"
      }}>
        <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          Serverless Box  {"(" + data.Param[1][1] + ")"}
        </div>
        <div style={{ borderRadius: "10%" }}>
        </div>
      </div>
    </>
  );
};

const AZAZ = ({ id, data, selected }) => {
  let AZ = data.Param[2]?.[2] || "NOAZ";
  if (AZ !== "Regional") { AZ = "AZ-" + AZ; }
  return (
    <>
      <NodeResizer style={{ padding: 50 }} color="#ff0000" isVisible={data.Select} minWidth={20} minHeight={20} />
      <div className="custom-drag-handle"> &nbsp;&nbsp; {AZ} </div>
      <div style={{ padding: 10 }}>
      </div>
    </>
  );
};
export { AZVNET, AZAZ, AZSBox, AZPublicIP };




