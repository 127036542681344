import { useState, useEffect, useRef } from 'react';
import { Constraints, GNodes, GEdges, ListNodeWithCost } from '../NodesData';
import { SearchNodesSource, FindRegionAndCloud, GetCostFromCostExporerResponse, FindNodesChieldID } from '../Functions';
import { Handle, Position } from "reactflow";

const sizeToScale = { "0": 0.15, "1": 0.25, "2": .4, "3": .8, };
const AWS = require('aws-sdk');

const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return {
        startDate: startDate.toISOString().split('T')[0],  // formato 'yyyy-mm-dd'
        endDate: endDate.toISOString().split('T')[0]       // formato 'yyyy-mm-dd'
    };
}

const Cost = ({ data, selected, id }) => {
    const [Costs, setCosts] = useState([""]);
    const NodeID = parseInt(id);
    const size = data.Param[2][1].toString();
    const scale = sizeToScale[size] || 1;
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    let SourceList = SearchNodesSource(GEdges, GNodes, GNodes[NodeID], "all");
    let SourceName = "";
    let SourceID = 0;
    if (SourceList.length > 0) {
        SourceID = parseInt(SourceList[0]);
    } else {
        SourceID = parseInt(GNodes[NodeID].parentNode);
    }
    SourceName = (GNodes[SourceID] && GNodes[SourceID].data && GNodes[SourceID].data.Param && GNodes[SourceID].data.Param[1] && GNodes[SourceID].data.Param[1][1]) ? GNodes[SourceID].data.Param[1][1] : "";
    useEffect(() => {
        if (!SourceName) {
            setCosts(["Connect a node on input."]);
            console.log("Connect a node on input");
            return;
        }
        console.log("SourceName", SourceName, SourceID);

        // Esta função faz a chamada e atualiza o estado
        const fetchCosts = () => {
            const SourceType = GNodes[SourceID].type;
            let Key = "Name";
            let SourceList = [[SourceName, ""]];
            if (["VPCN", "SubnetN", "SBoxN", "RegionN"].includes(SourceType)) {
                SourceList = [];
                let ListNodes = FindNodesChieldID(GNodes, SourceID);
                for (let i = 0; i < ListNodes.length; i++) {
                    let NodeID = ListNodes[i];
                    let NodeType = GNodes[NodeID].type;
                    if (ListNodeWithCost.includes(NodeType)) {
                        let NodeName = GNodes[NodeID].data.Param[1][1];
                        SourceList.push([NodeName, NodeType]);
                    }
                }
            }
            const [Cloud, Region] = FindRegionAndCloud(GNodes, SourceID);
            const CostRead = GNodes[parseInt(Cloud)].data.Param[8][1];
            //console.log("CostRead", Key, CostRead);
            let Resp = ["0"];
            if (CostRead == "") {
                Resp = ["No data available."]
            } else {
                let CostJson = 0;
                for (let i = 0; i < SourceList.length; i++) {
                    CostJson += GetCostFromCostExporerResponse(CostRead[1], Key, SourceList[i][0]);
                    console.log("SourceName", SourceList[i][0], SourceList[i][1], CostJson);
                }
                console.log("CostJson", CostJson)
                let CMCformattedCost = 0;
                if (CostJson !== null) {
                    CMCformattedCost = CostJson.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                }
                let HourlyCost = .14;
                let MonthyCost = HourlyCost * 720;
                let SMCformattedCost = MonthyCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                let SHCformattedCost = HourlyCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                const CMC = GNodes[NodeID].data.Param[6][1];
                const SMC = GNodes[NodeID].data.Param[7][1];
                const SHC = GNodes[NodeID].data.Param[8][1];
                Resp = [];
                if (CMC || SMC || SHC) {
                    if (CMC) { Resp.push(`CMC: ${CMCformattedCost}`); }
                    if (SMC) { Resp.push(`SMC: ${SMCformattedCost}`); }
                    if (SHC) { Resp.push(`SHC: ${SHCformattedCost}`); }
                } else { Resp.push("Select any type of cost") }

            }
            //console.log("Resp", Resp)
            setCosts(Resp);
        }
        fetchCosts();

    }, [SourceName, selected]);
    const handlerStyle = {
        pointerEvents: scale < 1 ? 'none' : 'auto',
        background: 'transparent',
        backgroundImage: `url(${URLArrow})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        border: 'none',
        afterBorderColor: 'none',
        width: '8px',
        height: '8px',
        top: '7px',
        transform: 'translateX(2px)',
        imageRendering: 'crisp-edges',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    };
    const baseFontSize = 14; // Exemplo de tamanho de fonte padrão em pixels
    const reducedFontSize = baseFontSize * 0.7; // Reduz o tamanho da fonte em 30%
    const styles = {
        reportLine: {
            padding: '5px 10px',
            border: selected ? '1px solid #ccc' : '1px solid #0',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: `${reducedFontSize}px`, // Aplica o tamanho de fonte reduzido
        },
        oddLine: {
            backgroundColor: selected ? '#d0d0d0' : '#f7f7f7'
        },
        evenLine: {
            backgroundColor: selected ? '#C0C0c0' : '#e0e0e0'
        }
    };
    const [isMouseNear, setIsMouseNear] = useState(false);
    return (
        <div
            onMouseEnter={() => setIsMouseNear(true)}
            onMouseLeave={() => setIsMouseNear(false)}
        >
            <div style={{ position: 'relative', zIndex: 1000 }}>
                {Costs.map((line, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.reportLine,
                            ...(index % 2 === 0 ? styles.evenLine : styles.oddLine)
                        }}
                    >
                        {line}
                    </div>
                ))}
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />

                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />
            </div>
        </div>
    );

}
export { Cost };

