import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'





const AZKCluster = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZKCluster.svg")';
    let Label = data.Label + " " + data.Param[1][1]
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};

const AZKNodePool = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZKNodePool.svg")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};



export { AZKCluster, AZKNodePool };

