import { useState, useCallback, useEffect } from 'react';
import './index.css';
import { Save, ProcessLoad, Descompact } from './Functions';
import { APIDB, Stage, CloudManVersion } from "./Config.js"
import { Constraints, GlobalToken, Code, GEdges, GlobalCognitoSub, GlobalUserName } from './NodesData';
import Template from './Template'; // Importa o componente Template
import { MarkerType } from 'reactflow';


const ListCloudProvider = ["Standard", "AWS", "Azure", "Kubernets"];
var Hidden = false;
var ShowCloudProvider = [];
for (let i = 0; i < ListCloudProvider.length; i++) {
    ShowCloudProvider[i] = false;
}
const ListCategory = [["Standard"], ["Network", "Computing", "Containers", "Storage", "Database", "Application & Integration", "Security, Identity & Compliance",
    "Management & Governace", "Developer Tools", "Machine Learning"], ["Network", "Computing", "Storage"], ["Node"]];
var ShowCategory = [];
for (let i = 0; i < ListCloudProvider.length; i++) {
    //const IndexCloud = 0;
    ShowCategory[i] = [];
    for (let j = 0; j < ListCategory[i].length; j++) {
        ShowCategory[i][j] = false;
    }
}


const SubMenuCtrlOpen = { transform: "rotate(270deg)", cursor: "pointer" };
const SubMenuCtrlClose = { cursor: "pointer" };

var FileNameInit = sessionStorage.getItem("FileName");
export default ({ setNodes, setEdges, nodes, edges, sharedNodeTypeSearch, GlobalIsSaved, Saved, CognitoRegion, CognitoDomain, CognitoClient,
}) => {
    function ajustarString(str, tamanho) {
        try {
            if (str.length > tamanho) {
                return str.substring(0, tamanho);
            }
            else if (str.length < tamanho) {
                return str.padEnd(tamanho, ' ');
            }
        } catch (error) {
            return ""
        }
        return str;
    }
    const IsDev = Stage == "DevLocal";
    const UserName = ajustarString(GlobalUserName, 18);
    const URLIcons = Constraints.IconsURL;
    var CategoryByProvider = [Constraints.Standard, Constraints.AWS, Constraints.Azure, Constraints.Kubernets];
    //console.log("CategoryByProvider", CategoryByProvider)
    const SubMenuCtrl = URLIcons + "MenuOpenTransp.png";
    const HideEdges = useCallback(() => {
        if (Hidden == true) {
            Hidden = false;
        } else {
            Hidden = true;
        }
        HideNodes();
        setEdges((eds) =>
            eds.map((edge) => {
                if (Hidden == false) {
                    edge.hidden = false;
                } else {
                    edge.hidden = true;
                }
                return edge
            })
        );
    }, [setEdges]);

    const HideNodes = useCallback(() => {
        const ListKeyType = Constraints.KeyNodes;
        setNodes((nds) =>
            nds.map((node) => {
                if (ListKeyType.includes(node.type) == false) {
                    if (Hidden == false) {
                        if (node.type != "NullN") {
                            node.hidden = false;
                        } else {
                            node.hidden = true;
                        }
                    } else {
                        node.hidden = true;
                    }
                }
                return node;
            })
        );
    }, [setNodes]);

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    let ExtensionType = [".png", ".png", ".svg", ".png"]
    var imagePaths = [];
    var NodesType = [];
    var NodesName = [];
    var NodesTypeNameList = [];
    var SearchList = [];
    for (let IndexCloud = 0; IndexCloud < ListCloudProvider.length; IndexCloud++) {
        imagePaths[IndexCloud] = [];
        NodesType[IndexCloud] = [];
        NodesName[IndexCloud] = [];
        for (let i = 0; i < ListCategory[IndexCloud].length; i++) {
            let category = ListCategory[IndexCloud][i];
            imagePaths[IndexCloud][category] = [];
            NodesType[IndexCloud][category] = [];
            NodesName[IndexCloud][category] = [];
            let SelectNodes = CategoryByProvider[IndexCloud][category];
            for (let j = 0; j < SelectNodes.length; j++) {
                let Files = SelectNodes[j][0].slice(0, -1) + ExtensionType[IndexCloud];
                if (Files == "AZCloud.svg") { Files = "AZCloud.png"; }
                if (Files == "AZResourceGroup.svg") { Files = "AZResourceGroup.png"; }
                if (Files == "AZRegion.svg") { Files = "AZRegion.png"; }
                let PushIcon = URLIcons + Files;
                let PushName = SelectNodes[j][1];
                let PushType = SelectNodes[j][0].slice(0, -1);
                imagePaths[IndexCloud][category].push(PushIcon);
                NodesName[IndexCloud][category].push(PushName);
                NodesType[IndexCloud][category].push(PushType);
                NodesTypeNameList.push([PushType, PushName, PushIcon]);
            }
        }
    }

    ShowCategory[0][0] = true;
    const [showCaption, setShowCaption] = useState(false);
    const [timerB, setTimerB] = useState(null);
    const [timer2, setTimer2] = useState(null);

    const [text, setText] = useState("");
    const [hoverImageId, setHoverImageId] = useState(null);

    const handleMouseEnter = (nodeType, nodeName) => {
        clearTimeout(timer2);
        setHoverImageId(nodeType);
        setTimerB(
            setTimeout(() => {
                setShowCaption(true);
                setText(nodeName);
            }, 500)
        );
    };

    const handleMouseLeave = () => {
        clearTimeout(timerB);
        setShowCaption(false);
        setHoverImageId("");
    };

    const ResetHandler = () => {
        sessionStorage.setItem("ReloadNodes", []);
        sessionStorage.setItem("ReloadEdges", []);
        sessionStorage.setItem("FileName", "");
        document.location.reload(true);
    }
    const LoadHandler = () => {
        let FileName = document.getElementById("Login").value;
        sessionStorage.setItem("FileName", FileName);
        console.log("FileName", FileName)
        var raw = JSON.stringify([5, FileName]);// 0  = Insere código bruto na DB. Pos 1 = Nome do Arquivo 
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
        console.log("APIDB", APIDB)
        fetch(APIDB, requestOptions)
            //fetch("https://9sdu2merc4.execute-api.us-east-1.amazonaws.com/main/InfoDB-dev", requestOptions)
            .then(response => response.text())
            .then(result => {
                let Resp = JSON.parse(result).body;
                if (Resp == false) {
                    alert("User not found!");
                    //console.log("User not found")
                } else {
                    //console.log("User found")
                    var raw = JSON.stringify([3, "Nodes", FileName]);// 0  = Insere código bruto na DB. Pos 1 = Nome do Arquivo 
                    var myHeaders = new Headers();
                    var NewNodes = [];
                    var NewEdges = [];
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
                    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
                    fetch(APIDB, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            let Array = ProcessLoad(result);
                            NewEdges = Array[0];
                            NewNodes = Array[1];
                            sessionStorage.setItem("ReloadNodes", JSON.stringify(NewNodes));
                            sessionStorage.setItem("ReloadEdges", JSON.stringify(NewEdges));
                            document.location.reload(true);
                        }
                        )
                        .catch(error => {
                            console.log('error', error)
                        })
                }
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    const SaveHandler = () => {
        try {
            Save(edges, nodes)
            Saved(true);
        } catch (error) {
            //pass
        }
    }
    const LogoutHandler = () => {
        Save(edges, nodes, Stage, GlobalCognitoSub, GlobalToken)
        Saved(true);
        sessionStorage.setItem("Code" + Code, null);
        var logoutUrl = `https://${CognitoDomain}.auth.${CognitoRegion}.amazoncognito.com/logout?client_id=${CognitoClient}&logout_uri=`
            + encodeURIComponent("https://cloudman.pro") + `&redirect_uri=`
            + encodeURIComponent("https://cloudman.pro/CloudMan.html");
        window.location.href = logoutUrl;
    }
    const [Show, setShow] = useState(ShowCategory);
    const OpenCloseCategory = (e, Index, IndexCloud) => {
        setShow((prevShow) =>
            prevShow.map((ItemCloud, IdCloud) =>
                ItemCloud.map((Item, Idx) => {
                    if (Idx === Index && IdCloud === IndexCloud) {
                        return !Item; // Inverta o valor
                    }
                    return Item; // Retorne o valor inalterado
                })
            )
        );
    };
    const [ShowCloud, setShowCloud] = useState(ShowCloudProvider);
    const OpenCloseCloud = (e, Index) => {
        setShowCloud((nds) =>
            nds.map((Item, Idx) => {
                if (Idx == Index) {
                    Item = !Item;
                }
                return Item;
            })
        )
        console.log("Index ShowCloud", Index, ShowCloud);
    }
    const [SearchListReact, setSearchListReact] = useState([]);
    const [searchText, setSearchText] = useState('');
    const handleKeyUp = (event) => {
        var inputValue = event.target.value
        setSearchText(inputValue);  // atualize o estado aqui
        if (inputValue !== null) {
            try {
                inputValue = inputValue.toUpperCase();
            } catch (error) {
                inputValue = "";
            }
            setSearchText(inputValue);
            if (inputValue == "") { SearchList = []; }
            else {
                console.log("searchText", inputValue);
                for (let i = 0; i < NodesTypeNameList.length; i++) {
                    if (NodesTypeNameList[i][1].toUpperCase().includes(inputValue)) {
                        SearchList.push(NodesTypeNameList[i]);
                        if (SearchList.length == 12) { console.log("mais que 12 itens"); break; }
                    }
                }
                if (SearchList.length == 12) {
                    let SearchListAux = [];
                    for (let i = 0; i < NodesTypeNameList.length; i++) {
                        if (NodesTypeNameList[i][1].toUpperCase().startsWith(inputValue)) {
                            SearchListAux.push(NodesTypeNameList[i]);
                            if (SearchListAux.length == 12) { break; }
                        }
                    }
                    SearchListAux = SearchListAux.concat(SearchList);
                    SearchListAux = [...new Set(SearchListAux)];
                    SearchListAux.length = 12;
                    SearchList = SearchListAux;
                }
                setSearchListReact(SearchList);
                console.log("Text", inputValue, SearchList)
            };
        };
    }
    useEffect(() => {
        const newValue = sharedNodeTypeSearch;
        setSearchText(newValue); // Atualiza o valor de searchText

        // Simula um evento keyUp
        handleKeyUp({
            target: { value: newValue }
        });
    }, [sharedNodeTypeSearch]);

    const [isExpanded, setIsExpanded] = useState(true);
    const [Direction, setDirection] = useState("<<<")

    const handleDoubleClick = () => {
        setIsExpanded(!isExpanded);
        if (isExpanded) { setDirection(">>>") } else { setDirection("<<<") }
    }

    const sidebarStyle = {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: isExpanded ? 0 : "-175px",  // Se não estiver expandido, esconda 188px do sidebar
        width: "208px",
        height: "100%",
        background: "softblue",
        borderRight: "1px solid black",
        boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
        overflow: "auto",
        direction: 'rtl',
        userSelect: "none",
        zIndex: 100,
        transition: 'left 0.2s ease-in-out',
    };
    const imageStyle = {
        width: '40px',
        height: '40px',
        margin: '2px',
        border: '1px solid black',
        display: 'inline-block',
        position: 'relative',
        transition: 'transform 0.33s ease', // Alterado para transform
        borderRadius: "7%",
        // Adicionar um cursor de ponteiro para indicar que é interativo
        cursor: 'pointer',
        zIndex: 29,
    };
    const ToolTipStyle = {
        position: 'absolute',
        left: '0',
        backgroundColor: 'yellow',
        padding: '5px',
        borderRadius: '5%',
        zIndex: 31,
        overflow: 'hidden',
        fontSize: '10px',
    }

    // Estilo para aplicar quando o mouse está sobre a imagem
    const hoverStyle = {
        transform: 'scale(1.45)', // Aumenta o tamanho em 30%
        zIndex: 30,
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    const TemplateHandler = () => {
        setIsModalOpen(!isModalOpen);
        console.log("Template")
    };
    return (
        <>
            <Template isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
            <aside style={sidebarStyle} >
                <div style={{ direction: 'ltr' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <h2 style={{ margin: 0, marginLeft: '4px', flexShrink: 0 }}>
                                {UserName}
                            </h2>
                            {/* Wrapper adicional para controle de espaçamento */}
                            <div style={{ position: 'relative', right: '-10px' }}>
                                <div onClick={handleDoubleClick} style={{
                                    backgroundColor: '#4750Fd',
                                    padding: '3px 5px',
                                    textAlign: 'center',
                                    color: 'white',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    flexShrink: 0
                                }}>
                                    {Direction}
                                </div>
                            </div>
                        </div>
                        <div class="inputContainer">
                            {IsDev && <input spellCheck="false" type="text" id="Login" defaultValue={FileNameInit} />}
                        </div>
                    </div>
                    <div className="buttonContainer">
                        <div className="HideEdge">
                            <button onClick={LogoutHandler}>Logout</button>
                        </div>
                        <div className="HideEdge">
                            <button
                                onClick={SaveHandler}
                                style={{
                                    backgroundColor: GlobalIsSaved ? '#3a6aff' : 'orange', // Altera a cor de fundo condicionalmente
                                    // Manter os outros estilos como estavam
                                }}
                            >
                                &nbsp;Save
                            </button>
                        </div>

                    </div>
                    {IsDev && <div className="buttonContainer">
                        <div className="HideEdge">
                            <button onClick={LoadHandler}>&nbsp;Load&nbsp;</button>
                        </div>
                        <div className="HideEdge">
                            <button onClick={ResetHandler}>Clear</button>
                        </div>
                    </div>}
                    <div className="buttonContainer">
                        <div className="Variable">
                            <button onClick={TemplateHandler} id="Template" onDragStart={(event) =>
                                onDragStart(
                                    event,
                                    document.getElementById("Template").id + 'N'
                                )
                            }
                                draggable>&nbsp;Template&nbsp;</button>
                        </div>
                    </div>
                    <div class="inputContainer">
                        <h2 style={{ margin: 0, }}> Search:</h2>
                        <input spellcheck="false" autocomplete="off" type="input" id="Search" value={searchText}
                            onChange={event => setSearchText(event.target.value)} onKeyUp={handleKeyUp} />
                    </div>
                    {/* Show Searched resources*/}

                    <div style={{
                        display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '70%', height: '70%',
                        gridTemplateRows: `repeat(${Math.ceil(SearchList.length / 4)}, 1fr)`, gridGap: '3px',
                    }}>
                        {SearchListReact.map((Item, index) => (
                            <div key={index} style={{
                                position: "relative", boxShadow: '1px 0px 2px 0px rgba(0,0,0,0.60)', borderRadius: "7%"
                            }}>
                                <div
                                    onMouseEnter={() => handleMouseEnter(Item[0], Item[1])}
                                    onMouseLeave={handleMouseLeave}
                                    style={{ position: "relative" }}
                                >
                                    <img src={Item[2]} alt="" style={imageStyle} id={Item[0]}
                                        onDragStart={(event) => onDragStart(event, document.getElementById(Item[0]).id + "N")}
                                        draggable />
                                    {hoverImageId === Item[0] && showCaption &&
                                        <div style={ToolTipStyle}>
                                            {text}
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {ListCloudProvider.map((CloudProvider, IndexCloud) => (
                        <div>
                            {IndexCloud !== 0 && (
                                <h1>
                                    {CloudProvider + " "}
                                    {!ShowCloud[IndexCloud] && <img src={SubMenuCtrl} alt="im" width="15" height="15" style={SubMenuCtrlOpen} onClick={(e) => OpenCloseCloud(e.target.value, IndexCloud)} />}
                                    {ShowCloud[IndexCloud] && <img src={SubMenuCtrl} alt="" width="15" height="15" style={SubMenuCtrlClose} onClick={(e) => OpenCloseCloud(e.target.value, IndexCloud)} />}
                                </h1>
                            )}

                            {/* Show Regular resources*/}
                            {ListCategory[IndexCloud].map((Category, Index) => (
                                <>
                                    {(ShowCloud[IndexCloud] || IndexCloud == 0) && (
                                        <div>
                                            <h2>
                                                {Category + " "}
                                                {!Show[IndexCloud][Index] && <img src={SubMenuCtrl} alt="im" width="15" height="15" style={SubMenuCtrlOpen} onClick={(e) => OpenCloseCategory(e.target.value, Index, IndexCloud)} />}
                                                {Show[IndexCloud][Index] && <img src={SubMenuCtrl} alt="" width="15" height="15" style={SubMenuCtrlClose} onClick={(e) => OpenCloseCategory(e.target.value, Index, IndexCloud)} />}
                                            </h2>
                                            {Show[IndexCloud][Index] && (
                                                <div style={{
                                                    display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '70%', height: '70%',
                                                    gridTemplateRows: `repeat(${Math.ceil(imagePaths[IndexCloud][Category].length / 4)}, 1fr)`, gridGap: '3px',
                                                }}>
                                                    {imagePaths[IndexCloud][Category].map((image, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                position: 'relative',
                                                                boxShadow: '1px 0px 2px 0px rgba(0,0,0,0.60)',
                                                                borderRadius: '7%',
                                                            }}
                                                            onMouseEnter={() =>
                                                                handleMouseEnter(NodesType[IndexCloud][Category][index], NodesName[IndexCloud][Category][index])
                                                            }
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <img
                                                                src={image}
                                                                alt=""
                                                                style={hoverImageId === NodesType[IndexCloud][Category][index] ? { ...imageStyle, ...hoverStyle } : imageStyle}
                                                                id={NodesType[IndexCloud][Category][index]}
                                                                onDragStart={(event) =>
                                                                    onDragStart(
                                                                        event,
                                                                        document.getElementById(NodesType[IndexCloud][Category][index]).id + 'N'
                                                                    )
                                                                }
                                                                draggable
                                                            />
                                                            {hoverImageId === NodesType[IndexCloud][Category][index] && showCaption && (
                                                                <div style={ToolTipStyle}>
                                                                    {text}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                    ))}

                    {Stage} version {CloudManVersion}

                </div>

                <br></br>
                <br></br>
                <br></br>
            </aside >
        </>
    );
};