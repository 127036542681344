import React, { useState } from 'react';
import styled from 'styled-components';
import { DownloadTemplateScripts } from "./Config.js";

// Componentes e dados importados
// Importando Constraints da sua aplicação
import { Constraints, ListTemplates } from './NodesData';

// Estilização dos componentes
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.isOpen ? '0' : '-100%')};
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #4a90e2, #8a3ab9); 
  overflow-x: hidden;
  transition: 0.5s;
`;

const ModalContent = styled.div`
  position: relative;
  top: 5%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-left: 7%;
  margin-right: 12%;
  color: white;
`;

const ImageMosaic = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 30px;
  padding: 20px;
  margin: 0 auto;
  max-width: 78vw;
  margin-left: 12%;
  margin-right: 15%;
  @media (max-width: 1200px) {
    max-width: 100vw;
  }
`;



const ImageDescription = styled.p`
  font-size: 0.4em;
  text-align: justify;
  text-indent: 10px; // Ajuste o valor conforme necessário
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'Roboto', sans-serif;
  margin-top: 0; // Reduzir o espaço acima da descrição
  margin-bottom: 0; // Remover espaço abaixo da descrição
`;
const ImageTitleContainer = styled.div`
  height: 40px; // Altura fixa para o container do título
  overflow: hidden; // Esconde o conteúdo que exceder a altura
  display: flex;
  flex-direction: column-reverse; // Inverte a ordem dos elementos para a rolagem ser para cima
`;

const ImageTitle = styled.h3`
  margin-top: 0;
  font-size: .8em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.05em; // Altura da linha para controlar o espaçamento entre as linhas
`;

const MosaicImageContainer = styled.div`
  position: relative;
  transition: transform 0.3s ease-in-out;
  cursor: zoom-in;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #3a6aff;
    opacity: 0;
    z-index: -1;
    border-radius: 2%; 
    transition: opacity 0.5s ease-in-out;
  }

  &:hover {
    width: 100%;
    height: auto;
    object-fit: contain;
    box-sizing: border-box; /* Garante que a borda seja aplicada externamente */
    border: 1px solid #99F; /* Aplica uma borda sólida de 1px na cor #99F */
    border-radius: 2%; /* Arredonda as bordas da imagem em 5% */
    transform: scale(2.5);
    z-index: 10;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Adiciona sombra ao redor da imagem */

    &:before {
      opacity: 1;
    }

    ${ImageTitle}, ${ImageDescription} {
      z-index: 1;
      position: relative;
    }
}

`;

const MosaicImage = styled.img`
  width: 100%;
  height: auto;
  border: 1px solid #99F;
  border-radius: 1%; 
  transform: translateX(-1px);
`;

const CloseButton = styled.button`
  // Estilos para o botão de fechar
`;


// Componente principal
const Template = ({ isModalOpen, closeModal }) => {

  const [zoomedImageIndex, setZoomedImageIndex] = useState(null);
  const [translateStyle, setTranslateStyle] = useState({});
  const URLTemplates = Constraints.TemplatesURL;


  const calculateAndApplyTranslate = (index) => {
    const element = document.querySelector(`[data-index="${index}"]`);
    if (element) {
      const rect = element.getBoundingClientRect();
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const deltaX = (centerX - (rect.left + rect.width / 2)) * 0.2;
      const deltaY = (centerY - (rect.top + rect.height / 2)) * 0.2;
      setTranslateStyle({ transform: `translate(${deltaX}px, ${deltaY}px) scale(2.5)` });
    }
  };

  const handleImageMouseEnter = (index) => {
    setZoomedImageIndex(index);
    calculateAndApplyTranslate(index);
  };

  const handleImageMouseLeave = () => {
    setZoomedImageIndex(null);
    setTranslateStyle({});
  };

  const handleImageClick = (TemplateFileName) => {
    console.log(TemplateFileName);
    sessionStorage.setItem("TemplateFileName", TemplateFileName);
    closeModal();
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalContent style={{ overflowY: 'scroll' }}>
        <h1>
          <a href={DownloadTemplateScripts} download>Download scripts used in these templates</a>
        </h1>
        <h1>Select a template by clicking on the image</h1>
        <ImageMosaic>
          {ListTemplates.map((image, index) => (
            <MosaicImageContainer
              key={index}
              data-index={index}
              onMouseEnter={() => handleImageMouseEnter(index)}
              onMouseLeave={handleImageMouseLeave}
              onClick={() => handleImageClick(image[0])}
              style={zoomedImageIndex === index ? translateStyle : {}}
            >
              <ImageTitleContainer>
                <ImageTitle>{image[1]}</ImageTitle>
              </ImageTitleContainer>
              <MosaicImage src={URLTemplates + image[0] + ".png"} alt={`Imagem ${index + 1}`} />
              {zoomedImageIndex === index && <ImageDescription>{image[2]}</ImageDescription>}
            </MosaicImageContainer>
          ))}
        </ImageMosaic>
      </ModalContent>
    </Modal>
  );
};

export default Template;
