import { Handle, Position, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import GenericNode from '../GenericNode'
import { Constraints } from '../NodesData';


const ASG = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ASG.png")';
    let LabelName = " Max=" + data.Param[3][1] + " Min=" + data.Param[4][1] + " Des=" + data.Param[5][1]
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const ASPolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ASPolicy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ASLHook = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ASLHook.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const AppASPolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AppASPolicy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export { ASG, ASPolicy, ASLHook, AppASPolicy };