import { Constraints } from '../NodesData';
import '../VPC.css';
import GenericNode from '../GenericNode'

const S3 = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3ACL = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3ACL.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3Grant = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3Grant.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3CORSRule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3CORSRule.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3LCR = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3LCR.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3Logging = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3Logging.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const S3T = ({ data, selected }) => {
    var URLIcon = 'url("' + Constraints.IconsURL + 'S3T.png")';
    let Label = "S3 Transition";
    if ((data.Param[2][2] == "Expiration Current Version")
        || (data.Param[2][2] == "Expiration Non Current Version")) {
        URLIcon = 'url("' + Constraints.IconsURL + 'S3Expiration.png")';
        Label = "Data Expiration";
    } else {
        if (data.Param[5][2] == "GLACIER") {
            URLIcon = 'url("' + Constraints.IconsURL + 'S3TGlacier.png")';
            Label = "Transition Glacier";
        }
        if (data.Param[5][2] == "STANDARD_IA") {
            URLIcon = 'url("' + Constraints.IconsURL + 'S3TIA.png")';
            Label = "Transition Standard IA";
        }
        if (data.Param[5][2] == "ONEZONE_IA") {
            URLIcon = 'url("' + Constraints.IconsURL + 'S3TOZ.png")';
            Label = "Transition One Zone IA";
        }
        if (data.Param[5][2] == "INTELLIGENT_TIERING") {
            URLIcon = 'url("' + Constraints.IconsURL + 'S3TIT.png")';
            Label = "Transition Intelligent Tiering";
        }
        if (data.Param[5][2] == "DEEP_ARCHIVE") {
            URLIcon = 'url("' + Constraints.IconsURL + 'S3TDA.png")';
            Label = "Transition Deep Archive";
        }
        if (data.Param[5][2] == "GLACIER_IR") {
            URLIcon = 'url("' + Constraints.IconsURL + 'S3TGIR.png")';
            Label = "Transition Glacier Instant Retrieve";
        }
    }
    Label += " " + data.Param[4][1] + " days";
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} Label={Label} />
    );
};

const S3OLC = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3OLC.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const S3RC = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3RC.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3RCR = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3RCR.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3WebCRRule = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3WebCRRule.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3WebC = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3WebC.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3NotFilter = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3NotFilter.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3Object = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3Object.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    )
};
const BucketPublicAccessBlock = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'BucketPublicAccessBlock.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const S3OwnershipControls = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'S3OwnershipControls.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export {
    S3, S3ACL, S3Grant, S3CORSRule, S3T, S3LCR, S3Logging, S3OLC, S3RC, S3WebC, S3WebCRRule, S3RCR, S3NotFilter, S3Object,
    BucketPublicAccessBlock, S3OwnershipControls
};

