import React, { useState, useEffect } from 'react';
import { Constraints } from '../NodesData';
import { NodeResizer } from '@reactflow/node-resizer';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import { Handle, Position, NodeToolbar } from 'reactflow';
import { API, APIDBName } from ".././Config.js";
import styled from 'styled-components';


const dragHandleStyle = {
    display: 'inline-block',
    width: 300,
    height: 30,
    backgroundColor: 'teal',
    marginLeft: 5,
    borderRadius: 1,
};

var ListRegions = [];
var raw = JSON.stringify([1, "ListStandard", "ListAWSRegions"]);// 1  = Read ListStandard 
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
/*fetch(API + APIDBName, requestOptions)
    .then(response => response.text())
    .then(result => {
        ListRegions = JSON.parse(result).body;
        console.log("ListReg", ListRegions);
    })*/

const StyledResizer = styled(NodeResizer)`
    &::after {
      content: "";
      position: absolute;
      top: -50px;
      right: -50px;
      bottom: -50px;
      left: -50px;
    }
  `;

const AZRegion = ({ id, data, selected }) => {
    const URLIcon = Constraints.IconsURL + 'RegionGlobe.png';
    let FontSize = data.Param[4][1];
    var [scaleFactor, setScaleFactor] = useState(1);  // Valor inicial é 1 (100%)
    useEffect(() => {
        let newScale;
        switch (FontSize) {
            case 1:
                newScale = .6;
                break;
            case 2:
                newScale = .3;
                break;
            default:
                newScale = 1;
        }
        setScaleFactor(newScale);
    }, [FontSize]);
    const baseStyles = {
        img: { width: 80, height: 80, margin: "0px 11px" },
        text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" },
        providerImg: { width: 60, height: 60, margin: "0px 11px" },
        title: { fontSize: 32 }  // Defini um tamanho base de 24px para o título.
    }
    const scaledStyles = {
        img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor, height: baseStyles.img.height * scaleFactor },
        text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
        providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
        title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }
    }
    return (
        <>
            <StyledResizer style={{ borderRadius: "20px" }} color="#ff0000" isVisible={selected} minWidth={20} minHeight={20} />
            <div style={{
                position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
                borderRadius: "15px 15px 0 0"
            }}>
                <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img src={URLIcon} alt="Icon" style={scaledStyles.img} />
                    <div style={scaledStyles.text}></div>
                    <div style={scaledStyles.text}> </div>
                    <div style={scaledStyles.title}>Location : {data.Param[2][2]}</div>
                </div>
                <div style={{ borderRadius: "10%" }}>
                </div>
                <div style={{ borderRadius: "10%" }}>
                </div>
            </div>
            <Handle type="source" position="right" style={{ top: 15, background: '#555' }} isConnectable={true} />
        </>
    );
};
const AZResourceGroup = ({ id, data, selected }) => {
    const URLIcon = Constraints.IconsURL + 'AZResourceGroup.png';
    let FontSize = data.Param[4][1];
    var [scaleFactor, setScaleFactor] = useState(1);  // Valor inicial é 1 (100%)

    useEffect(() => {
        let newScale;
        switch (FontSize) {
            case 1:
                newScale = .6;
                break;
            case 2:
                newScale = .3;
                break;
            default:
                newScale = 1;
        }
        setScaleFactor(newScale);
    }, [FontSize]);

    const baseStyles = {
        img: { width: 80, height: 80, margin: "0px 11px" },
        text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" },
        providerImg: { width: 60, height: 60, margin: "0px 11px" },
        title: { fontSize: 32 }  // Defini um tamanho base de 24px para o título.
    }
    const scaledStyles = {
        img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor, height: baseStyles.img.height * scaleFactor },
        text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
        providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
        title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }
    }
    return (
        <>
            <StyledResizer style={{ borderRadius: "20px" }} color="#ff0000" isVisible={selected} minWidth={20} minHeight={20} />
            <div style={{
                position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
                borderRadius: "15px 15px 0 0"
            }}>
                <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img src={URLIcon} alt="Icon" style={scaledStyles.img} />
                    <div style={scaledStyles.text}></div>
                    <div style={scaledStyles.text}> </div>
                    <div style={scaledStyles.title}>Resource Group : {data.Param[1][1]}</div>
                </div>
                <div style={{ borderRadius: "10%" }}>
                </div>
                <div style={{ borderRadius: "10%" }}>
                </div>
            </div>
            <Handle type="source" position="right" style={{ top: 15, background: '#555' }} isConnectable={true} />

        </>
    );
};


export { AZRegion, ListRegions, AZResourceGroup };




