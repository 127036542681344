import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'



const Info = (e, data, draggable, zIndex) => {
    console.log("Aqui")
    data.Mode = "Info";
    draggable = false;
    zIndex = 20;
}

const Close = (e, data, draggable, zIndex) => {
    console.log("Aqui")
    data.Mode = "Regular";
    draggable = true;
    zIndex = 10;
}

const NAqui = () => {
    console.log("NAqui")
    return (
        <>
        </>
    )
}




const AZmanagedDisk = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZmanagedDisk.svg")';
    let Label = data.Label + " " + data.Param[4][2].replace("_", "") + " " + data.Param[12][1] + "GB"
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};


export { AZmanagedDisk };

