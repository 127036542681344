import React, { useContext, useState, useEffect, useRef } from 'react';
import {
    ResourceLabelAdjust, fetchAndProcessMetrics, MakeCWParams, getCurrentMonthDates, ReadCloud, FindRegionAndCloud,
    adjustTimestamps,
} from './Functions'
import { Constraints, ResourceLookUp, ListaStandard, ListDocAWS, GEdges, GNodes, GlobalToken, setNodes } from './NodesData';
import { APIAWSReader } from "./Config.js"
import ContextBridge from './ContextBridge.js';
import FeedBack from './FeedBack.js';


const GlobalWidth = "98%"
var VPCList = [];
var VPCID = "";
var StatusBacketName = "";
var StatusDomainName = "";
var DictResourcesVPC = { "aws_subnet": [], "aws_security_group": [], "aws_internet_gateway": [] }
var ListNodeSource;
var ListNodeTarget;

function SetHide(Index, ResourceParam) {

    for (let i = 1; i < ResourceParam.length; i++) {
        //console.log("rESOURCEpaRAM antes SET HIDE", i, Index, ResourceParam)
        if (i == Index) {
            ResourceParam[i][4] = false;
        } else {
            ResourceParam[i][4] = true;
        }
        //console.log("rESOURCEpaRAM PÓS SET HIDE", ResourceParam)
    }
    return ResourceParam
}

const ChangeBlock = (e, ResourceName, Index, ResourceParam) => {
    let ID = GetLastIndexPartValue(Index);
    //console.log("ChangeBlock Variables", ResourceName, Index, ID, ResourceParam);
    if (ResourceParam[ID][5] == true) {
        ResourceParam[ID][5] = false;
    } else {
        ResourceParam[ID][5] = true;
    }
}

const ChangeVar = (e, ResourceName, Index, Value, ResourceParam, Type, MirrorFlag, ResourceParamMirror, BoxChecked, BoxUnchecked, SubMenuCtrl,
    Alarm = false, Forced = false) => {
    console.log("ChangeVar", Type, e, Index, Value, Forced)
    let Continue = true;
    if (Alarm) {
        if (window.confirm("Are you sure you want to change the region? Changing the region will cause inconsistencies if any Terraform state is being executed in this region")) {
            //pass
        } else {
            Continue = false;
        }
    }
    if (Continue) {
        let ID = GetLastIndexPartValue(Index);
        var Y = "";
        if (Type != "Subform") {
            if (Type == "CheckBox") {
                if (ResourceParam[ID][1] == true) {
                    document.getElementById(Index).src = BoxUnchecked;
                    var X = false;
                } else {
                    document.getElementById(Index).src = BoxChecked;
                    var X = true;
                }
            } else {
                if (Type == "ListOptionsMultiple") {
                    var selectElement = document.getElementById(Index);
                    var Y = [];
                    for (var i = 0; i < selectElement.length; i++) {
                        if (selectElement.options[i].selected) {
                            Y.push(selectElement.options[i].value);
                        }
                    }
                    var selectElement = document.getElementById(Index);
                    var X = [];
                    for (var i = 0; i < selectElement.options.length; i++) {
                        if (selectElement.options[i].selected) {
                            X.push(i);
                        }
                    }
                    //console.log("Multiple ***********", X, Y)
                } else {
                    if ((Type == "ListOptionsSubform") || (Type == "ListOptions")) {

                        if (Forced) {
                            var X = Value;
                        } else {
                            var X = document.getElementById(Index).selectedIndex;
                        }
                        var Y = document.getElementById(Index).value;

                    } else {
                        var X = document.getElementById(Index).value;
                        X = X.trim();
                    }
                    //console.log("X e Y", X, Y);
                }
            }
            if (Type == "Filter") {
                X = document.getElementById(Index).value;
                let IndexList = Index.split(".");
                let IndexMain = parseInt(IndexList[0]);
                let IndexFilter = parseInt(IndexList[1]);
                ResourceParam[IndexMain][2][IndexFilter][1] = [X];
                if (MirrorFlag) {
                    ResourceParamMirror[IndexMain][2][IndexFilter][1] = [X];
                }
            }
            var YY = Index.split('.');
            Index = parseInt(YY.slice(-1)[0]);
            if (Type == "ListOptionsSubform") {
                ResourceParam = ResourceParam[Index];
                ResourceParam[1] = X;
                ResourceParam[3] = Y;
                if (MirrorFlag) {
                    ResourceParamMirror[1] = X;
                }
            } else {
                if (Type == "CheckBox") {
                    ResourceParam[Index][1] = !ResourceParam[Index][1];
                    if (MirrorFlag) {
                        try {
                            ResourceParamMirror[Index][2][0] = X;
                        } catch (error) {
                            //pass 
                        }
                    }
                } else {
                    if (Type == "Filter") {
                        //
                    } else {
                        ResourceParam[Index][1] = X;
                        ResourceParam[Index][2] = Y;
                        if (MirrorFlag) {
                            try {
                                ResourceParamMirror[Index][1] = X;
                                ResourceParamMirror[Index][2] = Y;
                            } catch (error) {
                                //pass 
                            }
                        }
                    }
                }
            }
            //console.log("ResourceParam Final", ResourceParam, Index);
        }
    }
}

function GetLastIndexPartValue(Index) {
    var YY = Index.split('.');
    //console.log("YY", YY, YY.length);
    let ID = 1;
    YY = YY[YY.length - 1];
    ID = parseInt(YY);
    //console.log("ID", ID);
    return ID
}

const ShowComment = (Prop) => {
    const { Item } = Prop;
    let ID = GetLastIndexPartValue(Item.Index);
    let Value = Item.ResourceParam[ID][1];
    let IsImage = false;
    let ValueB = "";
    let ValueA = Value;
    let Icon = "";
    if (Value.includes("$$")) {
        IsImage = true;
        Value = Value.split("$$")
        Icon = Constraints.IconsURL + Value[1] + ".png"
        ValueA = Value[0] + " "
        ValueB = " " + Value[2]
    }
    return (
        <>
            <h2>{ValueA}
                {IsImage && <img src={Icon} alt="Descrição da imagem" style={{ height: "25px", width: "25px;", borderRadius: "4px" }}>

                </img>} {ValueB}</h2>
        </>
    );
}

const ShowString = (Prop) => {
    const { Item } = Prop;
    let ID = GetLastIndexPartValue(Item.Index);
    const Value = Item.ResourceParam[ID][1];
    let Label = ResourceLabelAdjust(Item.ResourceName);
    const Lista = Item.Lista;
    const Hide = Item.ResourceParam[ID][4];
    let LabelSufix = "";
    let Range = Lista[ID - 1]["Range"];
    if (Range !== undefined) {
        LabelSufix += "Range:" + Range[0] + "-" + Range[1] + " ";
    }
    let Comment = Lista[ID - 1]["Comment"];
    if (Comment !== undefined) {
        LabelSufix += Comment;
    }
    if (LabelSufix !== "") { LabelSufix = "(" + LabelSufix.trim() + ")"; Label += LabelSufix; }
    //Exception
    const NodeID = Item.NodeID;
    if (!Hide) {
        return (
            <>
                <h3>{Label}</h3>
                <input
                    spellcheck="false"
                    onChange={(e) => ChangeVar(e.target.value, Item.ResourceName, Item.Index, Value, Item.ResourceParam,
                        "String", Item.MirrorFlag, Item.ResourceParamMirror, Item.BoxChecked, Item.BoxUnchecked, Item.SubMenuCtrl)}
                    type="input"
                    id={Item.Index}
                    defaultValue={Value}
                    style={{ width: GlobalWidth }}
                />
            </>
        );
    } else {
        return (
            <>
            </>)
    }
}

const ShowTextArea = (Prop) => {
    const { Item } = Prop;
    let ID = GetLastIndexPartValue(Item.Index);
    const Value = Item.ResourceParam[ID][1];
    //console.log("TextAreaID", Value, Item.ResourceParam);
    let Label = ResourceLabelAdjust(Item.ResourceName);
    const Hide = Item.ResourceParam[ID][4];
    let OpenBlock = Item.ResourceParam[ID][5];
    if (OpenBlock == null) {
        Item.ResourceParam[ID][5] = false;
        Item.ResourceParam[ID][4] = false;
        //console.log(" ShowTextArea Init OpenBlock");
    }
    let ImageID = Item.Index + "IMG"
    //console.log("ResourceParam ShowTextArea", Item.SubMenuCtrl);
    var Lista = Item.Lista;
    let LabelSufix = "";
    let Comment = Lista[ID - 1]["Comment"];
    const Open = Lista[ID - 1]["SubType"];
    if (Open == "Open") { OpenBlock = true; }
    //console.log("Comment", Comment)
    if (Comment !== undefined) {
        LabelSufix += Comment;
    }
    if (LabelSufix !== "") { LabelSufix = "(" + LabelSufix.trim() + ")"; Label += LabelSufix; }
    if (!Hide) {
        if (OpenBlock) {
            return (
                <>
                    <h3>{Label}{" "}
                        <img id={ImageID} style={{ cursor: "pointer" }} src={Item.SubMenuCtrl}
                            alt="Imagem rotacionada" width="12" height="12"
                            onClick={(e) => ChangeBlock(e.target.value, Item.ResourceName, ImageID, Item.ResourceParam)} /></h3>
                    <textarea style={{ width: GlobalWidth }} spellcheck="false" id={Item.Index} name={Item.ResourceParam[ID][1]} rows="10" cols="35"
                        onChange={(e) => ChangeVar(e.target.value, Item.ResourceName, Item.Index, Value, Item.ResourceParam, "String",
                            Item.MirrorFlag, Item.ResourceParamMirror, Item.BoxChecked, Item.BoxUnchecked, Item.SubMenuCtrl)} >{Value}
                    </textarea>
                </>
            );
        } else {
            return (
                <>
                    <h3>{Label}{" "}
                        <img id={Item.Index}
                            style={{ transform: "rotate(270deg)", cursor: "pointer" }}
                            src={Item.SubMenuCtrl}
                            alt="Imagem rotacionada"
                            width="12"
                            height="12"
                            onClick={(e) => ChangeBlock(e.target.value, Item.ResourceName, Item.Index, Item.ResourceParam)} /></h3>
                </>
            )
        }
    } else {
        return (
            <>
            </>)
    }
}

const ShowFilter = (Prop) => {
    const { Item } = Prop;
    let ID = GetLastIndexPartValue(Item.Index);
    //console.log("ShowFilter", Item.ResourceName, Item.Index, Item.Options);
    const Value = Item.ResourceParam[ID][1];
    let Default = Item.ResourceParam[ID][2]
    //console.log("ID", ID, Value, Default);
    const Hide = Item.ResourceParam[ID][4];
    if (!Hide) {
        return (
            <>
                <h2>{Item.ResourceName}</h2>
                {Item.Options.map((Option, Index) =>
                    <>
                        <h3>{Option[0]}</h3>
                        <input style={{ width: GlobalWidth }} onChange={(e) => ChangeVar(e.target.value, Item.ResourceName, Item.Index + "." + Index, Value, Item.ResourceParam,
                            "Filter", Item.MirrorFlag, Item.ResourceParamMirror, Item.BoxChecked, Item.BoxUnchecked, Item.SubMenuCtrl)}
                            type="input" id={Item.Index + "." + Index} defaultValue={Default[Index][1][0]} />
                    </>
                )}
            </>
        );
    } else {
        return (
            <>
            </>)
    }
}

const ShowCheckBox = (Prop) => {
    const BoxStyle = { position: "absolute", left: 5, width: 16, height: 16, cursor: 'pointer', };
    const { Item } = Prop;
    //console.log("ShowCheckBox", Item.ResourceName, Item.Value, Item.Index);
    let Label = ResourceLabelAdjust(Item.ResourceName);
    let ID = GetLastIndexPartValue(Item.Index);
    const Hide = Item.ResourceParam[ID][4];
    const Lista = Item.Lista;
    let LabelSufix = "";
    try {
        const Lista = Item.Lista;
        let Comment = Lista[ID - 1]["Comment"];
        if (Comment !== undefined) {
            LabelSufix += Comment;
        }
        if (LabelSufix !== "") { LabelSufix = "(" + LabelSufix.trim() + ")"; Label += LabelSufix; }
    } catch (error) {
        //pass
    }
    if (!Hide) {
        return (
            <>
                <div style={{ position: 'relative' }}>  {/* Wrapper div */}
                    {Item.Value ?
                        <h3>
                            <div>
                                <img
                                    id={Item.Index}
                                    src={Item.BoxChecked}
                                    style={{ ...BoxStyle, transform: "translateY(-4px)" }}
                                    onClick={(e) => ChangeVar(e.target.value, Item.ResourceName, Item.Index, true, Item.ResourceParam, "CheckBox",
                                        Item.MirrorFlag, Item.ResourceParamMirror, Item.BoxChecked, Item.BoxUnchecked, Item.SubMenuCtrl)}
                                />
                            </div>
                            <div style={{ marginLeft: 25 }}>
                                {Label}
                            </div>
                        </h3>
                        :
                        <h3>
                            <div>
                                <img id={Item.Index} src={Item.BoxUnchecked} style={{ ...BoxStyle, transform: "translateY(-4px)" }}
                                    onClick={(e) => ChangeVar(e.target.value, Item.ResourceName, Item.Index, false, Item.ResourceParam, "CheckBox",
                                        Item.MirrorFlag, Item.ResourceParamMirror, Item.BoxChecked, Item.BoxUnchecked, Item.SubMenuCtrl)} />
                            </div>
                            <div style={{ marginLeft: 25 }}>
                                {Label}
                            </div>
                        </h3>
                    }
                </div>
            </>
        );
    } else {
        return (
            <>
            </>)
    }
}


const ShowListOptionsSubform = (Prop) => {
    const { Item } = Prop;
    //const ListNodeSource = Prop.ListNodeSource;
    //const ListNodeTarget = Prop.ListNodeTarget;
    const NodeID = Item.NodeID;
    let ID = GetLastIndexPartValue(Item.Index);
    let ContinueSubform = true;
    let ShowOptions = true;
    let InputNode = Item.InputNode[0];
    let OutputNode = Item.OutputNode;
    let ForceOption;
    let ForcedOpt = -1;
    let RemoveOption;
    let RemoveOpt = [];
    //console.log("InputNode", InputNode)
    useEffect(() => {
        if (ForcedOpt !== -1) {
            console.log("useEffect", ForcedOpt)
            ChangeVar(Item.Options[ForcedOpt], Item.ResourceName, Item.Index, ForcedOpt,
                Item.ResourceParam, "ListOptionsSubform", Item.MirrorFlag, Item.ResourceParamMirror, "", "", "", false, true);
        }
    }, [ForcedOpt, Item, ChangeVar]);
    if (Item.Type === "ListInputSelectSubform") {
        ShowOptions = false;
        let ListNodes = Item.ResourceParam[ID][5];
        for (let i = 0; i < ListNodes.length; i++) {
            if (InputNode == ListNodes[i]) {
                Item.ResourceParam[ID][1] = i;
                Item.ResourceParam[ID][3] = InputNode;
                break;
            }
        }
        if (InputNode == "Null") {
            ContinueSubform = false;
            return (
                <>
                    <h2>Connect a node on Input</h2>
                </>
            )
        }
    }
    if (Item.Type === "ListOptionsSubform") {
        try {
            // Tentar carregar o valor de Item.ForceOption
            ForceOption = Item.ForceOption;
            // Verificar se ForceOption é definido e possui a estrutura esperada
            if (ForceOption && Array.isArray(ForceOption)) {
                // Iterar sobre todos os elementos no array ForceOption
                for (const entry of ForceOption) {
                    const nodeKey = Object.keys(entry)[0]; // Pega a chave, que é o Node
                    // Verificar se OutputNode é igual à chave e se o valor na posição 0 é "Output"
                    if (OutputNode == nodeKey && entry[nodeKey][0] == "Output") {
                        // Atribuir o valor de entry[nodeKey][1] a ForcedOpt
                        ForcedOpt = entry[nodeKey][1];
                        break; // Parar a iteração após encontrar a correspondência
                    }
                }
            }
        } catch (error) {
            ForceOption = undefined;
        }
        try {
            let Opt = Item.Options;
            RemoveOption = Item.RemoveOption;
            // Verificar se RemoveOption é definido e possui a estrutura esperada
            if (RemoveOption && Array.isArray(RemoveOption)) {
                // Iterar sobre todos os elementos no array RemoveOption
                for (const entry of RemoveOption) {
                    const nodeKey = Object.keys(entry)[0]; // Pega a chave, que é o Node
                    // Verificar se OutputNode é igual à chave e se o valor na posição 0 é "Output"
                    if (OutputNode == nodeKey && entry[nodeKey][0] == "Output") {
                        // Atribuir o valor de entry[nodeKey][1] a List
                        const List = entry[nodeKey][1];
                        // Adicionar "#|" na frente de cada opção em Opt de acordo com os índices em List
                        for (const index of List) {
                            if (index >= 0 && index < Opt.length) {
                                Opt[index] = "#|" + Opt[index];
                            }
                        }
                        break; // Parar a iteração após encontrar a correspondência
                    }
                }
            }
            Item.Options = Opt;

        } catch (error) {
            RemoveOption = undefined;
        }
    }
    //console.log("RemoveOption", RemoveOption, RemoveOpt, Item.Options)
    if (Item.Type === "ListOutputSelectSubform") {
        ShowOptions = false;
        let ListNodes = Item.ResourceParam[ID][5];
        for (let i = 0; i < ListNodes.length; i++) {
            if (Item.OutputNode == ListNodes[i]) {
                Item.ResourceParam[ID][1] = i;
                Item.ResourceParam[ID][3] = Item.OutputNode;
                break;
            }
        }
        if (Item.OutputNode == "Null") {
            ContinueSubform = false;
            return (
                <>
                    <h2>Connect a node on Output</h2>
                </>
            )
        }
    }
    //console.log("Item.OutputNode", Item.OutputNode)
    if (ContinueSubform) {
        const Hide = Item.ResourceParam[ID][4];
        let X = Item.ResourceParam[ID][3];
        if (X == "") {  //Iniciliza a variável Options
            Item.ResourceParam[ID][3] = Item.Options[0];
        }
        var SelectID = parseInt(Item.ResourceParam[parseInt(ID)][1]);
        var ResourceParam = Item.ResourceParam[parseInt(ID)][2];
        const InitialOptions = Item.Options[SelectID];
        const Lista = Item.Lista;
        //console.log("Lista", Lista)
        let Label = ResourceLabelAdjust(Item.ResourceName);
        ResourceParam = SetHide(SelectID + 1, ResourceParam);
        if (!Hide) {
            return (
                <>
                    <h3>{Label}</h3>
                    {ShowOptions && (
                        <select
                            id={Item.Index}
                            onChange={(e) => ChangeVar(e.target.value, Item.ResourceName, Item.Index, Item.Value,
                                Item.ResourceParam, "ListOptionsSubform", Item.MirrorFlag, Item.ResourceParamMirror)}
                            value={ForcedOpt !== -1 ? Item.Options[ForcedOpt] : (Item.Options[SelectID] || '')}
                        >
                            {ForcedOpt !== -1 ? (
                                <option key={ForcedOpt} value={Item.Options[ForcedOpt]}>
                                    {Item.Options[ForcedOpt]}
                                </option>
                            ) : (
                                Item.Options
                                    .filter((Option, Index) => !RemoveOpt.includes(Index)) // Filtrar opções baseadas em RemoveOpt
                                    .map((Option, Index) =>
                                        !Option.startsWith("#|") && (
                                            <option
                                                key={Index}
                                                value={Option}
                                                selected={Index === SelectID}
                                            >
                                                {Index === SelectID ? InitialOptions : Option}
                                            </option>
                                        )
                                    )
                            )}
                        </select>
                    )}
                    <ProcessItems Lista={Lista} Options={true} Level={2} ResourceParam={ResourceParam}
                        MirrorFlag={Item.MirrorFlag} ResourceParamMirror={Item.ResourceParamMirror} BoxChecked={Item.BoxChecked}
                        BoxUnchecked={Item.BoxUnchecked} InputNode={InputNode} OutputNode={OutputNode}
                        SubMenuCtrl={Item.SubMenuCtrl} NodeID={NodeID} ListNodeSource={ListNodeSource}
                        ListNodeTarget={ListNodeTarget}
                    />
                </>
            );
        } else {
            return (
                <>
                </>)
        }
    }
}

const ShowListOptions = (Prop) => {
    const { Item } = Prop;
    let ID = GetLastIndexPartValue(Item.Index);
    var SelectID = parseInt(Item.ResourceParam[parseInt(ID)][1]);
    let Label = ResourceLabelAdjust(Item.ResourceName);
    let X = Item.ResourceParam[ID][2];
    let previouslySelected = X;
    if (X == "") {  //Iniciliza a variável Options
        Item.ResourceParam[ID][2] = Item.Options[0];
        previouslySelected = [];
    }
    var RegionCondition = Item.ResourceParam[ID][6];
    const Alarm = Item.ResourceParam[ID][6] == true;
    var RegionCondition = false;
    if (RegionCondition === undefined) {
        RegionCondition = false;
    }
    let ListOptionsType = "ListOptions";
    if (Item.isMultiple) {
        ListOptionsType = "ListOptionsMultiple";
        if (typeof Item.ResourceParam[ID][2] === "string") {
            Item.ResourceParam[ID][2] = [Item.ResourceParam[ID][2]]
        }
    }
    const Hide = Item.ResourceParam[ID][4];
    let LabelSufix = "";
    try {
        const Lista = Item.Lista;
        let Comment = Lista[ID - 1]["Comment"];
        if (Comment !== undefined) {
            LabelSufix += Comment;
        }
        if (LabelSufix !== "") { LabelSufix = "(" + LabelSufix.trim() + ")"; Label += LabelSufix; }
    } catch (error) {
        //pass
    }

    if (!Hide) {
        return (
            <>
                <h3>{Label}</h3>
                <>
                    {RegionCondition === false && (
                        <select
                            multiple={Item.isMultiple}
                            id={Item.Index}
                            value={previouslySelected} // Defina o valor selecionado aqui
                            onChange={(e) => ChangeVar(e.target.value, Item.ResourceName, Item.Index, Item.Value, Item.ResourceParam, ListOptionsType,
                                Item.MirrorFlag, Item.ResourceParamMirror, Item.BoxChecked, Item.BoxUnchecked, Item.SubMenuCtrl, Alarm)}
                        >
                            {Item.Options.map((Option, Index) => (
                                <option key={Index} value={Option}>
                                    {Option}
                                </option>
                            ))}
                        </select>
                    )}
                    {RegionCondition === true && <h3>{Item.ResourceParam[ID][2]}</h3>}
                </>
            </>
        );

    } else {
        return (
            <>
            </>)
    }
};
function darkenColor(hexColor, level) {
    if (level < 1 || level > 5) {
        return hexColor; // Se o nível estiver fora do intervalo, retorne a cor original
    }
    // Converte o código hexadecimal para RGB
    let r = parseInt(hexColor.substring(1, 3), 16);
    let g = parseInt(hexColor.substring(3, 5), 16);
    let b = parseInt(hexColor.substring(5, 7), 16);
    // Subtrai o valor de escurecimento
    let subtractValue = 20 * level;
    r = Math.max(0, r - subtractValue);
    g = Math.max(0, g - subtractValue);
    b = Math.max(0, b - subtractValue);
    // Reconverte os valores RGB para hexadecimal
    r = r.toString(16).padStart(2, '0');
    g = g.toString(16).padStart(2, '0');
    b = b.toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
}
const ShowSubform = (Prop) => {
    const { Item } = Prop;
    //const ListNodeSource = Item.ListNodeSource;
    //const ListNodeTarget = Item.ListNodeTarget;
    //console.log("ShowSubform ListNodeTarget ", ListNodeTarget)
    const NodeID = Item.NodeID;
    let ID = GetLastIndexPartValue(Item.Index);
    var Level = Item.Level;
    var Lista = Item.Lista;
    var ResourceParam = "";
    const Hide = Item.ResourceParam[ID][4];
    let InputNode = Item.InputNode;
    //console.log("Input Node Subform", InputNode)
    let OutputNode = Item.OutputNode;
    var OpenBlock = true;
    if (Level == 1) {
        ResourceParam = Item.ResourceParam[ID][2];
    }
    if (Level > 1) {
        ResourceParam = Item.ResourceParam[ID][2];
    }
    const greaterThanChar = ">";
    const greaterThanString = greaterThanChar.repeat(Level);
    let BlockName = ""
    if (Item.Type === "Block") {
        BlockName = greaterThanString + ResourceLabelAdjust(Item.ResourceName) + "Block:";
        OpenBlock = Item.ResourceParam[ID][5];
    }
    if (Item.Type === "Section") {
        BlockName = greaterThanString + ResourceLabelAdjust(Item.ResourceName) + "Section:";
        OpenBlock = Item.ResourceParam[ID][5];
    }
    Level += 1;
    const BackgroundColor = darkenColor('#66ffff', Level);
    let LabelSufix = "";
    try {
        let Comment = Lista[ID - 1]["Comment"];
        if (Comment !== undefined) {
            LabelSufix += Comment;
        }
        //console.log("Comment", Comment, BlockName)
    } catch (error) {
        //pass
    }
    if (LabelSufix !== "") { LabelSufix = "(" + LabelSufix.trim() + ")" }
    if (!Hide) {
        if (OpenBlock) {
            return (
                <div style={{ backgroundColor: BackgroundColor, paddingLeft: '3px' }}>
                    {(Item.Type === 'Block' || Item.Type === 'Section') &&
                        <h2>
                            {BlockName}{" "}{LabelSufix}{" "}
                            <img id={Item.Index} style={{ cursor: "pointer" }} src={Item.SubMenuCtrl} alt="Imagem rotacionada"
                                width="20" height="20" onClick={(e) => ChangeBlock(e.target.value, Item.ResourceName, Item.Index, Item.ResourceParam)} />
                        </h2>}
                    <ProcessItems Lista={Item.Lista} Options={true} Level={Level} ResourceParam={ResourceParam}
                        MirrorFlag={Item.MirrorFlag} ResourceParamMirror={Item.ResourceParamMirror}
                        BoxChecked={Item.BoxChecked} BoxUnchecked={Item.BoxUnchecked} NodeID={NodeID}
                        SubMenuCtrl={Item.SubMenuCtrl} InputNode={InputNode} OutputNode={OutputNode}
                        ListNodeSource={ListNodeSource} ListNodeTarget={ListNodeTarget} />
                </div>
            );
        } else {
            return (
                <>
                    {(Item.Type === 'Block' || Item.Type === 'Section') &&
                        <h2>
                            {BlockName}{" "}
                            <img id={Item.Index}
                                src={Item.SubMenuCtrl}
                                alt="Imagem rotacionada"
                                width="20"
                                height="20"
                                style={{ transform: "rotate(270deg)", cursor: "pointer" }}
                                onClick={(e) => ChangeBlock(e.target.value, Item.ResourceName, Item.Index, Item.ResourceParam)} />
                        </h2>}
                </>
            )
        }
    } else {
        return (
            <>
            </>)
    }
}
const ShowJSONOptions = (Prop) => {
    const { Item } = Prop;
    let ID = GetLastIndexPartValue(Item.Index);
    let InputNodeType = Item.InputNode;
    let JSON = ListaStandard[ResourceLookUp["GraphN"]]["GeneralParam"]["ListMetrics"];
    let InitVar = Item.ResourceParam[ID][1];
    if (InitVar == "") {
        InitVar = {};
        let NameSpaceObj = JSON[InputNodeType];
        let NameSpace = Object.keys(NameSpaceObj)[0];
        console.log("NameSpaceObj", NameSpace, NameSpaceObj)
        InitVar.Metric = Object.keys(JSON[InputNodeType][NameSpace])[0];
        InitVar.Dimensions = ""//JSON[InitVar.Metric]?.Dimensions?.Name[0] ?? NameSpaceObj[NameSpace][InitVar.Metric].Dimensions[0];
        InitVar.ValidStatistics = JSON[InitVar.Metric]?.ValidStatistics?.[0] ?? "";
        InitVar.Units = JSON[InitVar.Metric]?.Units?.[0] ?? "";
        InitVar.NameSpace = NameSpace;
        //InitVar.Dimensions = NameSpaceObj[NameSpace][InitVar.Metric].Dimensions[0];
        //InitVar.ValidStatistics = NameSpaceObj[NameSpace][InitVar.Metric].ValidStatistics[0];
        //InitVar.Units = NameSpaceObj[NameSpace][InitVar.Metric].Units[0];
        console.log("Dimensions", InitVar.ValidStatistics, ":", InitVar.Units, ":", InitVar.NameSpace)
    }
    if (InputNodeType === undefined) { InputNodeType = "LambdaN" }
    let NameSpaceObj = JSON[InputNodeType] || "";
    let NameSpace = Object.keys(NameSpaceObj)[0] || InitVar.NameSpace;
    JSON = JSON[InputNodeType][NameSpace] || "";
    const [data] = useState(JSON);
    const getDefaultValues = (selectedKey) => {
        const defaultVals = {};
        const selectedData = data[selectedKey];
        if (selectedData) {
            for (let [key, value] of Object.entries(selectedData)) {
                if (Array.isArray(value) && value.length > 0) {
                    defaultVals[key] = value[0];
                }
            }
        }
        return defaultVals;
    }
    const [selectedKey, setSelectedKey] = useState(InitVar.Metric || Object.keys(data)[0]);
    const initialSelectedValues = {
        ...getDefaultValues(selectedKey),
        MetricDescription: InitVar.MetricDescription,
        NameSpace: InitVar.NameSpace,
        ValidStatistics: InitVar.ValidStatistics,
        Dimensions: InitVar.Dimensions
    };
    const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

    useEffect(() => { //SelectedKey
        if (selectedKey !== InitVar.Metric) {
            setSelectedValues({
                ...getDefaultValues(selectedKey),
                MetricDescription: InitVar.MetricDescription,
                NameSpace: NameSpace,
                ValidStatistics: InitVar.ValidStatistics,
                Dimensions: InitVar.Dimensions
            });
            let ValidStatistics = JSON[InitVar.Metric]?.ValidStatistics?.[0] ?? ""
            let Units = JSON[InitVar.Metric]?.Units?.[0] ?? "";
            let Values = { "InputNodeType": InputNodeType, "NameSpace": NameSpace, "Metric": selectedKey, ValidStatistics, Units };
            if (JSON[InitVar.Metric]?.Dimensions) {
                Values.Dimensions = ""//JSON[InitVar.Metric].Dimensions[0];
            }
            Item.ResourceParam[ID][1] = Values;
        }
    }, [selectedKey]);

    useEffect(() => {
        let Values = { "InputNodeType": InputNodeType, "NameSpace": NameSpace, "Metric": selectedKey, ...selectedValues };
        if (JSON[InitVar.Metric]?.Dimensions) {
            Values.Dimensions = JSON[InitVar.Metric].Dimensions[0];
        }
        Item.ResourceParam[ID][1] = Values;
    }, [selectedValues, NameSpace]);

    useEffect(() => {
        if (selectedKey !== InitVar.Metric) {
            setSelectedValues(getDefaultValues(selectedKey));
        }
    }, [selectedKey]);

    const handleDropdownChange = (key) => (event) => {
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [key]: event.target.value
        }));
    };
    //console.log("selectedKey", selectedKey)
    return (
        <div style={{ padding: '0%' }}>
            <h3>Metric Selection</h3>
            <select
                value={selectedKey}
                onChange={(e) => setSelectedKey(e.target.value)}
                style={{ margin: '0px 0' }}>
                {Object.keys(data).map((key) => (
                    <option key={key} value={key}>
                        {key}
                    </option>
                ))}
            </select>
            {Object.entries(data[selectedKey] || {}).map(([key, value]) => {

                if (Array.isArray(value) && value.length > 0 && key !== "Dimensions") {
                    return (
                        <div key={key}>
                            <h3>{key}</h3>
                            {value.length === 1 ? (
                                <div style={{ margin: '0px 0' }}>
                                    <h2>{value[0]}</h2>
                                </div>
                            ) : (
                                <select
                                    value={selectedValues[key]} // Esta é a linha importante
                                    onChange={handleDropdownChange(key)}
                                    style={{ margin: '0px 0' }}
                                >
                                    {value.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    );
                }
                return null;
            })}

            <hr />
        </div>
    );
}
const ShowMultiLevelOptions = (Prop) => {
    const { Item } = Prop;
    let Label = ResourceLabelAdjust(Item.ResourceName);
    const ID = GetLastIndexPartValue(Item.Index);
    const NodeID = Item.NodeID;
    const NodeType = GNodes[NodeID].type;
    const initialValue = Item.ResourceParam[ID][1];
    const RegionID = parseInt(FindRegionAndCloud(GNodes, NodeID)[1]);
    const RegionName = GNodes[RegionID].data.Param[2][2];
    const JSON = ListaStandard[ResourceLookUp[NodeType]]["GeneralParam"]["MultiLevelList"][RegionName];
    const NitroBased = Constraints["Nitro"];
    //console.log("JSON ListEC2", JSON[0])
    //console.log("InitVar", initialValue)
    const initialPrefix = initialValue ? initialValue.split('.')[0] : null;
    const initialSufix = initialValue ? initialValue.split('.')[1] : null;
    const [selectedPrefix, setSelectedPrefix] = useState(initialPrefix);
    const [selectedSufix, setSelectedSufix] = useState(initialSufix);
    useEffect(() => {
        setSelectedPrefix(initialPrefix);
        setSelectedSufix(initialSufix);
    }, [initialPrefix, initialSufix]);

    const handlePrefixChange = (event) => {
        const newPrefix = event.target.value;
        const firstSufix = JSON[1][newPrefix][0][0]; // Obtendo o primeiro sufixo da lista
        setSelectedPrefix(newPrefix);
        setSelectedSufix(firstSufix);
        const combinedValue = `${newPrefix}.${firstSufix}`;
        //console.log("Saving to database:", combinedValue);
        Item.ResourceParam[ID][1] = combinedValue;
    };

    const handleSufixChange = (event) => {
        const newSufix = event.target.value;
        setSelectedSufix(newSufix);
        const combinedValue = `${selectedPrefix}.${newSufix}`;
        //console.log("Saving to database:", combinedValue);
        Item.ResourceParam[ID][1] = combinedValue;
    };
    let Hypervisor = "";
    if (["EC2N", "LaunchTemplateN"].includes(NodeType)) {
        if (NitroBased.includes(selectedPrefix)) { Hypervisor = "Nitro" } else { Hypervisor = "Xen" }
    }
    const Hide = Item.ResourceParam[ID][4];
    if (!Hide) {
        return (
            <>
                <h3>{Label}</h3>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <select
                        multiple={Item.isMultiple}
                        id={Item.Index}
                        onChange={handlePrefixChange}
                        value={selectedPrefix}
                    >
                        {JSON[0].map((Option) => (
                            <option value={Option}>{Option}</option>
                        ))}
                    </select>
                    {selectedPrefix && JSON[1][selectedPrefix] && (
                        <select value={selectedSufix} onChange={handleSufixChange}>
                            {JSON[1][selectedPrefix].map((sufixData) => (
                                <option value={sufixData[0]}>{sufixData[0]}</option>
                            ))}
                        </select>
                    )}
                    <h2>{Hypervisor}</h2>
                </div>
            </>
        );
    }
}

const HandleChange = (NodeID, field, setNodesState) => {
    setNodesState(prevState => {
        const newNodes = { ...prevState };

        if (field === 'IC') {
            newNodes[NodeID].data.LifeCycle[field] = newNodes[NodeID].data.LifeCycle[field].length === 0 ? ["*"] : [];
        } else {
            newNodes[NodeID].data.LifeCycle[field] = !newNodes[NodeID].data.LifeCycle[field];
        }

        return newNodes;
    });
}

const ShowLifeCycle = (Prop) => {
    const [nodesState, setNodesState] = useState(GNodes);
    const BoxStyle = { position: "absolute", left: 5, width: 16, height: 16, cursor: 'pointer' };
    const NodeID = Prop.NodeID;
    const BoxChecked = Constraints.IconsURL + "BoxChecked.png";
    const BoxUnchecked = Constraints.IconsURL + "BoxUnchecked.png";
    const NodeType = nodesState[NodeID].type;
    const NodeIndex = ResourceLookUp[NodeType];
    const LifeCycle = ListaStandard[NodeIndex].Node.data.LifeCycle;
    const HasLifeCycle = ListaStandard[NodeIndex].GeneralParam.HasLifeCycle;
    if (HasLifeCycle) {
        const CreateBeforeDestroy = nodesState[NodeID].data.LifeCycle.CBD;
        const IgnoreChanges = nodesState[NodeID].data.LifeCycle.IC.length !== 0;
        const PreventDestroy = nodesState[NodeID].data.LifeCycle.PD; // prevent Destroy
        //console.log("Item", LifeCycle);

        const getCheckboxSrc = (condition) => condition ? BoxChecked : BoxUnchecked;

        return (
            <>
                <h2>Life Cycle</h2>
                <div style={{ position: 'relative' }}>  {/* Wrapper div */}
                    <h3>
                        <div>
                            <img src={getCheckboxSrc(CreateBeforeDestroy)} style={{ ...BoxStyle, transform: "translateY(-4px)" }}
                                onClick={() => HandleChange(NodeID, 'CBD', setNodesState)} />
                        </div>
                        <div style={{ marginLeft: 25 }}>  Create Before Destroy  </div>
                    </h3>
                    <h3>
                        <div>
                            <img src={getCheckboxSrc(IgnoreChanges)} style={{ ...BoxStyle, transform: "translateY(-4px)" }}
                                onClick={() => HandleChange(NodeID, 'IC', setNodesState)} />
                        </div>
                        <div style={{ marginLeft: 25 }}>  Ignore Changes  </div>
                    </h3>
                    <h3>
                        <div>
                            <img src={getCheckboxSrc(PreventDestroy)} style={{ ...BoxStyle, transform: "translateY(-4px)" }}
                                onClick={() => HandleChange(NodeID, 'PD', setNodesState)} />
                        </div>
                        <div style={{ marginLeft: 25 }}>  Prevent Destroy  </div>
                    </h3>
                </div>
            </>
        );
    }
}




const ProcessItems = (Prop) => {
    const Lista = Prop.Lista;
    const Options = Prop.Options;
    const Level = Prop.Level;
    const ResourceParam = Prop.ResourceParam;
    const MirrorFlag = Prop.MirrorFlag;
    const ResourceParamMirror = Prop.ResourceParamMirror;
    const InputNode = Prop.InputNode;
    const OutputNode = Prop.OutputNode;
    const SubMenuCtrl = Prop.SubMenuCtrl;
    const BoxChecked = Prop.BoxChecked;
    const BoxUnchecked = Prop.BoxUnchecked;
    const NodeID = Prop.NodeID;
    //console.log("Prop.ListNodeTarget", Prop.ListNodeTarget)
    //let ListNodeTarget = (Prop.ListNodeTarget !== undefined) ? Prop.ListNodeTarget : [];
    //let ListNodeSource = (Prop.ListNodeSource !== undefined) ? Prop.ListNodeSource : [];
    //console.log("ListNodeTarget", ListNodeTarget)

    for (let i = 0; i < Lista.length; i++) {
        try {
            let read = ResourceParam[i + 1]
            //console.log("Lista", i, Lista[i], ResourceParam[i])
        } catch (error) {
            //console.log("Gerou erro")
        }
    }
    return (
        <>

            {Lista.map((Item, Index) =>
                (Item.Required === Options && !Item.Index.includes(".") || Item.Index.includes(".")) && (
                    (Item.CondInput === undefined || Item.CondInput.some(cond => ListNodeSource.includes(cond))) &&
                    (Item.NotCondInput === undefined || !Item.NotCondInput.some(notCond => ListNodeSource.includes(notCond))) &&
                    (Item.CondOutput === undefined || Item.CondOutput.some(cond => ListNodeTarget.includes(cond))) &&
                    (Item.NotCondOutput === undefined || !Item.NotCondOutput.some(notCond => ListNodeTarget.includes(notCond)))
                ) &&//Compara se Flag Item[2] (True - Required) (False - Optional)
                <div>
                    {
                        Item.Type === 'Comment' &&
                        <>
                            <ShowComment Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "General", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'String' &&
                        <>
                            <ShowString Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "General", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista, "NodeID": NodeID,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'ListString' &&
                        <>
                            <ShowString Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "General", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'IPAddress' &&
                        <>
                            <ShowString Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "IPAddress", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'CIDR' &&
                        <>
                            <ShowString Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "CIDR", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'IPv6CIDR' &&
                        <>
                            <ShowString Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "IPv6CIDR", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'Number' &&
                        <>
                            <ShowString Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "Number", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        (Item.Type === 'TextArea' || Item.Type === 'TextAreaTags') &&
                        <>
                            <ShowTextArea Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "Number", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                                "SubMenuCtrl": SubMenuCtrl,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'JSON' &&
                        <>
                            <ShowTextArea Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "Number", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                                "SubMenuCtrl": SubMenuCtrl,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'Policy' &&
                        <>
                            <ShowTextArea Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName,
                                "Type": "Number", "ResourceParam": ResourceParam, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Lista": Lista,
                                "SubMenuCtrl": SubMenuCtrl,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'ListOptionsSubform' &&
                        <>
                            <ShowListOptionsSubform Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "ResourceParam": ResourceParam, "Options": Item.Options,
                                "Lista": Item.Extension, "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror, "BoxChecked": BoxChecked,
                                "BoxUnchecked": BoxUnchecked, "SubMenuCtrl": SubMenuCtrl, "InputNode": InputNode, "OutputNode": OutputNode,
                                "NodeID": NodeID, "ListNodeSource": ListNodeSource, "ListNodeTarget": ListNodeTarget, "ForceOption": Item.ForceOption,
                                "Type": "ListOptionsSubform", "RemoveOption": Item.RemoveOption
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'Subform' &&
                        <>
                            <ShowSubform Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Lista": Item.Extension, "MirrorFlag": MirrorFlag, Level: Level, "ResourceParamMirror": ResourceParamMirror,
                                "Type": "Subform", "SubMenuCtrl": SubMenuCtrl, "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked,
                                "InputNode": InputNode, "OutputNode": OutputNode, "NodeID": NodeID, "ListNodeSource": ListNodeSource, "ListNodeTarget": ListNodeTarget,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'Block' &&
                        <>
                            <ShowSubform Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Lista": Item.Extension, "MirrorFlag": MirrorFlag, Level: Level, "ResourceParamMirror": ResourceParamMirror, "Type": "Block",
                                "SubMenuCtrl": SubMenuCtrl, "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked, "InputNode": InputNode,
                                "OutputNode": OutputNode, "NodeID": NodeID, "ListNodeSource": ListNodeSource, "ListNodeTarget": ListNodeTarget,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'Section' &&
                        <>
                            <ShowSubform Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Lista": Item.Extension, "MirrorFlag": MirrorFlag, Level: Level, "ResourceParamMirror": ResourceParamMirror, "Type": "Section",
                                "SubMenuCtrl": SubMenuCtrl, "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked, "InputNode": InputNode,
                                "OutputNode": OutputNode, "NodeID": NodeID, "ListNodeSource": ListNodeSource, "ListNodeTarget": ListNodeTarget,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'ListInputSelectSubform' &&
                        <>
                            <ShowListOptionsSubform Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "ResourceParam": ResourceParam,
                                "Options": Item.Options, "Lista": Item.Extension, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Type": "ListInputSelectSubform",
                                "InputNode": InputNode, "OutputNode": OutputNode, "SubMenuCtrl": SubMenuCtrl,
                                "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked, "NodeID": NodeID, "ListNodeSource": ListNodeSource, "ListNodeTarget": ListNodeTarget,
                            }} />
                        </>
                    }
                    {

                        Item.Type === 'ListOutputSelectSubform' &&
                        <>
                            <ShowListOptionsSubform Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "ResourceParam": ResourceParam,
                                "Options": Item.Options, "Lista": Item.Extension, "MirrorFlag": MirrorFlag,
                                "ResourceParamMirror": ResourceParamMirror, "Type": "ListOutputSelectSubform",
                                "InputNode": InputNode, "OutputNode": OutputNode, "SubMenuCtrl": SubMenuCtrl,
                                "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked, "NodeID": NodeID, "ListNodeSource": ListNodeSource, "ListNodeTarget": ListNodeTarget,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'RegionList' &&
                        <>

                            <ShowListOptions Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Options": Item.Options, "OptionsIndex": 0, "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror,
                                "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked, "isMultiple": false
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'OptionsBoolean' &&
                        <>
                            <ShowCheckBox Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[(Index + 1)][1], "ResourceParam": ResourceParam,
                                "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror, "BoxChecked": BoxChecked,
                                "BoxUnchecked": BoxUnchecked, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'ListOptions' &&
                        <>
                            <ShowListOptions Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Options": Item.Options, "OptionsIndex": "", "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror,
                                "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked, "isMultiple": false, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'ListOptionsMultiple' &&
                        <>
                            <ShowListOptions Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Options": Item.Options, "OptionsIndex": "", "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror,
                                "BoxChecked": BoxChecked, "BoxUnchecked": BoxUnchecked, "isMultiple": true, "Lista": Lista,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'Filter' &&
                        <>
                            <ShowFilter Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Options": Item.Options, "OptionsIndex": "", "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'JSONOptions' &&
                        <>
                            <ShowJSONOptions Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Options": Item.Options, "OptionsIndex": "", "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror,
                                "Lista": Lista, "InputNode": InputNode, "NodeID": NodeID,
                            }} />
                        </>
                    }
                    {
                        Item.Type === 'MultiLevelOptions' &&
                        <>
                            <ShowMultiLevelOptions Item={{
                                "Index": Item.Index, "ResourceName": Item.ResourceName, "Value": ResourceParam[1][1], "ResourceParam": ResourceParam,
                                "Options": Item.Options, "OptionsIndex": "", "MirrorFlag": MirrorFlag, "ResourceParamMirror": ResourceParamMirror,
                                "Lista": Lista, "NodeID": NodeID,
                            }} />
                        </>
                    }

                </div>
            )}
        </>
    )
}
const ListRequired = [true, false]
const Config = ({ Node, GlobalNodes }) => {
    var data = Node.data;
    const NodeID = parseInt(data.id);
    var [IP, setIP] = useState(false);
    var [ARN, setARN] = useState(false);
    var [ID, setID] = useState(false);
    var [URL, setURL] = useState(false);
    var [ImportVPC, setImportVPC] = useState(false);
    var [isButtonDisabled, setButtonDisabled] = useState(true);
    var [Remount, setRemount] = useState(true);
    //const [Resp, setResp] = useState(null);
    const [Cloud, Region] = FindRegionAndCloud(GNodes, NodeID);
    const CloudID = parseInt(Cloud);
    let Cost = "";
    try {
        Cost = GNodes[CloudID].data.Param[8][1];
    } catch (error) {
        //pass
    }
    //console.log("Cost Config", Cost)
    const [LastRead, setLastRead] = useState(Cost[0]);
    const { setImportedData } = useContext(ContextBridge);
    // Efeito para atualizar o contexto quando o estado Resp mudar
    //useEffect(() => {
    //    setImportedData(Resp);
    //}, [Resp, setImportedData]);
    const BoxStyleB = { display: "inline-block", left: 5, width: 16, height: 16, cursor: 'pointer', };
    const BoxChecked = Constraints.IconsURL + "BoxChecked.png";
    const BoxUnchecked = Constraints.IconsURL + "BoxUnchecked.png";
    const SubMenuCtrl = Constraints.IconsURL + "MenuOpenTransp.png";
    const TerraformIcon = Constraints.IconsURL + "Terraform.png";
    const IterCon = Constraints.IconsURL + "InterConnectionMapIcon.png";
    const AWSIcon = Constraints.IconsURL + "AWS.png";
    let IsNode = data.IsNode;
    //bloco em que nodes possuem algum buttom
    let IsVPC = data.nodeAction.type == "VPCN";
    let IsS3 = data.nodeAction.type == "S3N";
    let IsGraph = data.nodeAction.type == "GraphN";
    let IsCogUPoolDomain = false;//data.nodeAction.type == "CogUPoolDomainN";
    let IsCost = data.nodeAction.type == "CostN";
    //console.log("NodeType", data.nodeAction.type)
    let ReadOnlyCredentials = "";//data.ReadOnlyCredentials;
    //let ListNodeSource = data.ListSourceType;
    //let ListNodeTarget = data.ListTargetType;
    ListNodeSource = data.ListSourceType;
    ListNodeTarget = data.ListTargetType;
    let InputNode = data.NodeTypeSource;
    if (typeof InputNode === 'string') {
        InputNode = [InputNode];
    }
    //console.log("************InputNode**************", InputNode)
    try {
        var VPCRegion = data.nodeAction.data.Param[2][1];
    } catch (error) {
        VPCRegion = "";
    }
    if (data.FirstRender) {
        data.FirstRender = false;
        StatusBacketName = "";
    }
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const handleFeedback = (Message) => {
        setFeedbackMessage(Message);
        setShowFeedback(true);
    };
    const handleCloseFeedback = () => {
        setShowFeedback(false);
    };
    setTimeout(handleCloseFeedback, 7000);
    useEffect(() => {
        // Set the timeout
        const timer = setTimeout(handleCloseFeedback, 7000);

        // Clear the timeout when the component unmounts
        return () => clearTimeout(timer);
    }, []);

    async function HandleCostFetch() {
        const Period = getCurrentMonthDates();
        let Key = "Name";
        if (InputNode[0] == "TerraformN") { Key = "Cloudman_State_Tag"; }
        const Value = GNodes[NodeID].data.Param[1][1];
        let [Result, Cost] = await ReadCloud(GNodes, "GetCurrentCosts", NodeID, { "Key": Key, "Value": Value, "StartDate": Period.startDate, "EndDate": Period.endDate });
        if (Result) {
            GNodes[CloudID].data.Param[8][1] = Cost;
            setLastRead(Cost[0]);
        } else {
            setLastRead("Access denied. \n Check if your read-onlycredentials allow access to AWS Cost Explorer.");
        }
    }

    async function HandleStaticCostFetch() {
        //pass
    }

    async function HandleGraphApply() {
        console.log("ParamMetrics");
        const EnableVar1 = GNodes[NodeID].data.Param[3][2][1][2][2][2][2][1];
        const EnableVar2 = GNodes[NodeID].data.Param[3][2][1][2][3][2][2][1];
        const EnableVar3 = GNodes[NodeID].data.Param[3][2][1][2][4][2][2][1];
        const EnableVar4 = GNodes[NodeID].data.Param[3][2][1][2][5][2][2][1];

        if (EnableVar1 || EnableVar2 || EnableVar3 || EnableVar4) {
            const Resp = await fetchAndProcessMetrics(GEdges, GNodes, NodeID, GlobalToken, setNodes);
            console.log("Resp", Resp)
            if (Resp !== undefined) {
                handleFeedback("IAM Role ARN invalid format.");
            }
        } else {
            handleFeedback("At least one variable must be enabled.");
        }
    }

    async function HandleCheckBucketName() {
        let BucketName = data.nodeAction.data.Param[1][1];
        var raw = JSON.stringify([0, BucketName]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
        let response = await fetch(APIAWSReader, requestOptions);
        let Respo = await response.json();
        StatusBacketName = Respo.body;
        setRemount(!Remount);
        //console.log("Check Bucket Name", StatusBacketName, BucketName);
    }
    async function HandleCheckDomainName() {
        let DomainRegion = data.nodeAction.data.Param[5][1];
        let DomainName = data.nodeAction.data.Param[2][1];
        let domainURL = `https://${DomainName}.auth.${DomainRegion}.amazoncognito.com`;
        console.log("Checking domain URL", domainURL);
        try {
            let response = await fetch(domainURL, { method: 'HEAD' });
            if (response.ok) {
                console.log("Domain exists.");
                StatusDomainName = true;
            } else {
                console.log("Domain does not exist.");
                StatusDomainName = false;
            }
        } catch (error) {
            console.log("Error checking domain:", error);
            StatusDomainName = false;
        }
        setRemount(!Remount);
        // console.log("Check Domain Name", StatusDomainName, DomainName);
    }

    async function ListVPC(ResourceVPC, Resource = "") {
        setButtonDisabled(true);
        //console.log("ListVPC&&&&&&&&&&&&&&&&&&&&&&")
        var raw = JSON.stringify([1, VPCRegion, ResourceVPC, ReadOnlyCredentials]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
        let response = await fetch(APIAWSReader, requestOptions);
        let Respo = await response.json();
        Respo = Respo.body;
        //console.log("ListVPC&&&&&&&&&&&&&&&&&&&&&&", Resp);
        if (Respo == "Access Error") {
            VPCList = [Respo];
        } else {
            VPCList = [];
            for (let i = 0; i < Respo.length; i++) {
                VPCList.push(Respo[i].ID);
            }
        }
        //console.log("VPCList", VPCList);
        setImportVPC(true);
        return VPCList;
    }
    async function handleResourcesReading(VPCIDSelected) {
        VPCID = VPCIDSelected;
        var raw = JSON.stringify([2, VPCRegion, VPCIDSelected, ReadOnlyCredentials]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
        let response = await fetch(APIAWSReader, requestOptions);
        let Respo = await response.json();
        DictResourcesVPC = Respo.body[0];
        let ListResourceRelationship = Respo.body[1];
        sessionStorage.setItem("ListResourceRelationship", JSON.stringify(ListResourceRelationship));
        //console.log("GetItem", sessionStorage.getItem("ListResourceRelationship"));
        //console.log("DictResourcesVPC&&&&&&&&&&&&&&&&&&&&&&", DictResourcesVPC, ListResourceRelationship);
        setButtonDisabled(false);
    }
    async function HandleProceedImport() {
        var ListImportedTerraform = [];
        /*var ListResource = ["aws_vpc"];
        var ListResourceID = [VPCID];
        for (let Key in DictResourcesVPC) {
            for (let i = 0; i < DictResourcesVPC[Key].length; i++) {
                ListResource.push(Key);
                ListResourceID.push(DictResourcesVPC[Key][i])
            }
        }
        //console.log("HandleProceedImport Resource", ListResource, ListResourceID);
        const dataObjects = ListResource.map((resource, index) => {
            return JSON.stringify([0, VPCRegion, resource, ListResourceID[index]]);
        });
        //console.log("dataObjects", dataObjects)
        const promises = dataObjects.map(data => {
            return fetch(APILambdaTerraform, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: data })
        });
        try {
            const responses = await Promise.all(promises);
            const data = await Promise.all(responses.map(response => response.json()));

            for (let i = 0; i < data.length; i++) {
                let ResourceImported = data[i].body[0].resources[0];
                let STDOUT = data[i].body[3];
                ListImportedTerraform.push(ResourceImported);
                //console.log("List resp", JSON.stringify(ResourceImported), JSON.stringify(STDOUT));
            }
        } catch (error) {
            //console.error(`Erro na requisição: ${error.message}`);
            let ResourceImported = data;
            //console.log("erro", ResourceImported);
        }
        //console.log("Passou aqui")
        var raw = JSON.stringify([0, ListImportedTerraform, VPCRegion, ListResourceID]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
        var response = await fetch(API + "TerraformToCloudManConvert", requestOptions);
        var RespTemp = await response.json();
        RespTemp = RespTemp.body;
        RespTemp.unshift(data.id);
        let ListResourceRelationship = sessionStorage.getItem("ListResourceRelationship");
        //console.log("ListResourceRelationship", ListResourceRelationship);
        ListResourceRelationship = JSON.parse(ListResourceRelationship);
        let MsgToApp = [RespTemp, ListResourceRelationship];
        //console.log("List Param Subnet", MsgToApp);
        //setResp(MsgToApp); */
    }

    const splitLabel = (label) => {
        if (!label) return ["", ""];
        const parts = label.split("|").map(part => part.trim());
        return [parts[0], parts[1] || ""];
    };

    const [selectedLabel, setSelectedLabel] = useState("");
    const [suffix, setSuffix] = useState("");

    useEffect(() => {
        const [newSelectedLabel, newSuffix] = splitLabel(data.label);
        setSelectedLabel(newSelectedLabel);
        setSuffix(newSuffix);
    }, [data]);

    const updateLabel = (newSelectedLabel, newSuffix, Options) => {
        //console.log("newSelectedLabel", newSelectedLabel, newSuffix)
        if (newSuffix.includes('|')) {
            alert("The character '|' is reserved and cannot be used.");
            return; // Termina a função aqui para não atualizar o label
        }
        setSelectedLabel(newSelectedLabel);
        setSuffix(newSuffix);
        let newLabel = `${newSelectedLabel}`;
        if (Options) {
            newLabel = `${newSelectedLabel} | ${newSuffix}`;
            data.setLabel(newLabel);
        };
    }
    let NodeAction = ""
    try {
        NodeAction = data.nodeAction.data.Param;
        if (NodeAction === undefined) { NodeAction = "" }
    } catch (error) {
        NodeAction = ""
    }
    const [dataJSON, setDataJSON] = useState(NodeAction);
    const prevDataRef = useRef(NodeAction); // Ref para armazenar a cópia anterior

    useEffect(() => {
        if (JSON.stringify(prevDataRef.current) !== JSON.stringify(NodeAction)) {
            //console.log("Os dados foram alterados!");
            setDataJSON({ ...NodeAction }); // Força uma re-renderização atualizando o estado com o mesmo objeto
        }
        prevDataRef.current = NodeAction; // Atualize a ref com o estado atual de data
    }, [NodeAction]);


    if (IsNode == "Node") {
        let FlagOpenMenu = true;
        if (ImportVPC) { FlagOpenMenu = false; }
        let Message = "";
        let MessageIcon = "";
        let IsImage = false;
        //Bloco de controle de ListInputSelectSubform
        if (data.nodeAction.type == "CFOriginN") {
            if (InputNode[0] != "S3N") {
                InputNode[0] = "NotS3N";
            }
        }
        let ListSGType = ["SecurityGroupN", "AZSecurityGroupN"];
        if ((ListSGType.includes(InputNode[0])) && (ListSGType.includes(data.nodeAction.type))) {
            FlagOpenMenu = false;
            Message = "Use the Security Group head to configure.";
        }
        let ListSubnetType = ["AZSubnetN"];
        if ((ListSubnetType.includes(InputNode[0])) && (ListSubnetType.includes(data.nodeAction.type))) {
            FlagOpenMenu = false;
            Message = "Use the Subnet head to configure.";
        }
        if ((InputNode[0] == "ASGN") && (data.nodeAction.type == "EC2N")) {
            FlagOpenMenu = false;
            Message = "Configure EC2 in Launch Template.";
            MessageIcon = Constraints.IconsURL + "LaunchTemplate.png";
            IsImage = true;
        }
        if (FlagOpenMenu) {
            var OutputNode = "Null";
            if (data.NodeTypeTarget != "Null") {
                //console.log("NodeXXXX Target :", data.NodeTypeTarget);
                //console.log("Target Event List :", DictResourceEvent[data.NodeTypeTarget]);
                OutputNode = data.NodeTypeTarget;
            } else {//console.log("Output Open")
            }
            if (data.nodeAction.type == "AZN") {
                GlobalNodes[parseInt(data.id)].data.Param[1][1] = GlobalNodes[parseInt(data.id)].data.Param[2][2];
            }
            if ((data.nodeAction.type != "SecurityGroupBoxN")) { //Just open ConfigNode if it doesnt AZ type
                var Lista = [];
                var ListaB = [];
                var ResourceName = "";
                var MirrorFlag = false;
                try {
                    var NodeParam = GlobalNodes[parseInt(data.id)].data.Param;//NodeAction //Dentro de NodeAction tem Param do node clickado
                } catch (error) {
                    //console.error("Ocorreu um erro: ", error);
                    return (
                        <></>)
                }
                var DataNodeMirror = data.nodeAction.data.ParamMirror;
                if (typeof DataNodeMirror != "undefined") {
                    MirrorFlag = true;
                }
                //console.log("ListaStandard", ListaStandard);
                //console.log("DataNode", NodeParam);
                //console.log("ndsX", data.id, MirrorFlag, data.nodeAction.data.ParamMirror);
                for (let i = 0; i < ListaStandard.length; i++) {
                    if (data.nodeAction.type === ListaStandard[i].ResourceType) {
                        ResourceName = ListaStandard[i].ResourceName;
                        Lista = ListaStandard[i].ListParam;
                        //console.log("Listas", Lista);
                    }
                }
                for (let i = 0; i < NodeParam.length; i++) {
                    for (let j = 0; j < Lista.length; j++) {
                        ListaB.push([false, false]);
                        if (NodeParam[i][0] === Lista[j][0]) {
                            ListaB[j][0] = true;
                            ListaB[j][1] = NodeParam[i][1];
                        }
                    }
                }
                //Documentation
                let Sufix = NodeParam[0].split("$")[0]
                Sufix = Sufix.replace("aws_", "");
                let TerraformURL = "";
                if (Sufix.toUpperCase() == "NA") { TerraformURL = null } else {
                    if (Sufix == "required_providers") {
                        TerraformURL = "https://registry.terraform.io/namespaces/hashicorp"
                    } else { TerraformURL = Constraints.TerraformDocLink + Sufix; }
                }
                let AWSURL = null
                try {
                    AWSURL = ListDocAWS[NodeParam[0]][0];
                } catch (error) {
                    //pass
                }

                let EnableTerraformImg = true;
                let EnableAWSImg = true;
                if (AWSURL == null) { EnableAWSImg = false; }
                if (TerraformURL == null) { EnableTerraformImg = false; }

                //Lista Output Terraform
                //Lista Output Terraform
                let EnableIP = false;
                let EnableARN = false;
                let EnableURL = false;
                let EnableID = false;
                try {
                    let ListOutput = ListDocAWS[NodeParam[0]][1];
                    //console.log("ListOutput", ListOutput);
                    EnableIP = ListOutput["IP"] != "";
                    EnableARN = ListOutput["ARN"] != "";
                    EnableID = ListOutput["ID"] != "";
                    EnableURL = (ListOutput["URL"] != "") && ((ListOutput["URL"] != null));
                } catch (error) {
                    //pass
                }
                let EnableOutputs = EnableIP || EnableID || EnableARN || EnableURL;
                //Incializa IP,ARN,ID
                let LastElement = NodeParam[NodeParam.length - 1];
                if (LastElement[0] == "Output") {
                    //console.log("tem Output", LastElement, IP, ARN, ID);
                    for (let i = 0; i < LastElement[1].length; i++) {
                        if ((LastElement[1][i].includes("IP")) && (IP == false)) { IP = true; }
                        if ((LastElement[1][i].includes("ARN")) && (ARN == false)) { ARN = true; }
                        if ((LastElement[1][i].includes("ID")) && (ID == false)) { ID = true; }
                        if ((LastElement[1][i].includes("URL")) && (URL == false)) { URL = true; }
                    }
                }

                function UpdateOutput(Type, Remove) {
                    let LastElement = NodeParam[NodeParam.length - 1];
                    let Sufix = ListDocAWS[NodeParam[0]][1][Type];
                    if (Remove == false) {
                        if (LastElement[0] != "Output") {
                            NodeParam.push(["Output", [[Type, Sufix]]]);
                        } else {
                            //console.log("last Element", LastElement)
                            LastElement[1].push([Type, Sufix]);
                            LastElement[1] = Array.from(new Set(LastElement[1]));
                        }
                    } else {
                        //console.log("removendo IP", LastElement);
                        for (let i = 0; i < LastElement[1].length; i++) {
                            if (LastElement[1][i][0] == Type) {
                                LastElement[1].splice(i, 1);
                            }
                        }
                    }
                }
                function toggleIP() {
                    setIP(!IP);
                    UpdateOutput("IP", IP);
                }
                function toggleARN() {
                    setARN(!ARN);
                    UpdateOutput("ARN", ARN);
                }
                function toggleID() {
                    setID(!ID);
                    UpdateOutput("ID", ID);
                }
                function toggleURL() {
                    setURL(!URL);
                    UpdateOutput("URL", URL);
                }

                const customStyles = {
                    boxSizing: "border-box",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column"
                };

                const customContentStyles = {
                    flex: 1,
                    overflowY: "auto"
                };

                return (
                    <>
                        <div className="react-flow__node-custom" style={customStyles}>
                            <div style={customContentStyles}>
                                <div className="custom-node__header">
                                    <FeedBack show={showFeedback} message={feedbackMessage} onClose={handleCloseFeedback} />
                                    {ListRequired.map((Options) =>
                                        //ListaRequired serve para percorrer todos os elementos Required (True e False)
                                        //para comparar com o Item[2] da Lista, que é o Flag que indica se é Required (true) or not (false)
                                        <>
                                            <div>
                                                {Options === true && <h2>Required:</h2>}
                                                {Options === false && <h2>Optional:</h2>}
                                                <ProcessItems Lista={Lista} Options={Options} Level={1} ResourceParam={NodeParam}
                                                    MirrorFlag={MirrorFlag} ResourceParamMirror={DataNodeMirror}
                                                    onChange={data.onChange} InputNode={InputNode} OutputNode={OutputNode}
                                                    BoxChecked={BoxChecked} BoxUnchecked={BoxUnchecked}
                                                    SubMenuCtrl={SubMenuCtrl} NodeID={NodeID} ListNodeSource={ListNodeSource}
                                                    ListNodeTarget={ListNodeTarget} />
                                            </div>

                                        </>
                                    )}
                                    <ShowLifeCycle NodeID={NodeID} />
                                    <div>
                                        <br></br>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {EnableOutputs && <strong style={{ marginRight: "10px" }}>Outputs:</strong>}
                                            {EnableIP && <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                                <strong>IP</strong>
                                                <img id="OutputIP" src={IP ? BoxChecked : BoxUnchecked} style={{ ...BoxStyleB, marginLeft: "5px" }} onClick={toggleIP} />
                                            </div>}
                                            {EnableARN && <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                                <strong>ARN</strong>
                                                <img id="OutputARN" src={ARN ? BoxChecked : BoxUnchecked} style={{ ...BoxStyleB, marginLeft: "5px" }} onClick={toggleARN} />
                                            </div>}
                                            {EnableID && <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                                                <strong>ID</strong>
                                                <img id="OutputID" src={ID ? BoxChecked : BoxUnchecked} style={{ ...BoxStyleB, marginLeft: "5px" }} onClick={toggleID} />
                                            </div>}
                                            {EnableURL && <div style={{ display: "flex", alignItems: "center" }}>
                                                <strong>URL</strong>
                                                <img id="OutputURL" src={URL ? BoxChecked : BoxUnchecked} style={{ ...BoxStyleB, marginLeft: "5px" }} onClick={toggleURL} />
                                            </div>}
                                        </div>
                                    </div>
                                    {IsVPC &&
                                        <div className="HideEdge">
                                            <br></br>
                                            <button id="focus" onClick={() => ListVPC("aws_vpc")}>Import</button>
                                        </div>
                                    }
                                    {IsS3 &&
                                        <div className="Variable" style={{ fontSize: '10px' }}>
                                            <br></br>
                                            <button id="focus" onClick={() => HandleCheckBucketName()} style={{ marginRight: '10px' }}>Check Bucket Name</button>
                                            <strong>Status Name:</strong> {StatusBacketName}
                                        </div>
                                    }
                                    {IsGraph &&
                                        <div className="Variable" style={{ fontSize: '10px' }}>
                                            <br></br>
                                            <button id="focus" onClick={() => HandleGraphApply()} style={{ marginRight: '10px' }}>Apply</button>
                                        </div>
                                    }
                                    {IsCogUPoolDomain &&
                                        <div className="Variable" style={{ fontSize: '10px' }}>
                                            <br></br>
                                            <button id="focus" onClick={() => HandleCheckDomainName()} style={{ marginRight: '10px' }}>Check Domain Name</button>
                                            <strong>Status Name:</strong> {StatusDomainName}
                                        </div>
                                    }
                                    {IsCost &&
                                        <>
                                            <div className="Variable" style={{ fontSize: '10px' }}>

                                                <button id="focus" onClick={() => HandleCostFetch()} style={{ marginRight: '10px' }}>Update Current Costs</button>
                                                <h3>Last Update: {LastRead}</h3>
                                                <h3>* AWS charges $0.01 for each Cost Explorer API query</h3>
                                            </div>
                                            <div className="Variable" style={{ fontSize: '10px' }}>

                                                <button
                                                    id="focus"
                                                    onClick={() => HandleStaticCostFetch()}
                                                    style={{
                                                        marginRight: '10px',
                                                        width: '400px !important'
                                                    }}
                                                >
                                                    Update Static Costs
                                                </button>

                                                <h3>Last Update: {LastRead}</h3>

                                            </div>
                                            <div className="Variable" style={{ fontSize: '10px' }}>

                                                <h3>To obtain the cost readings, it is necessary to enable the tag "Name"</h3>
                                                <button id="focus" onClick={() => window.open('https://us-east-1.console.aws.amazon.com/billing/home?region=us-east-1#/tags', '_blank')}
                                                    style={{ marginRight: '10px' }}> Enable Tags  </button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )



            }
        } else {
            if (ImportVPC) {
                return (
                    <>
                        <div className="react-flow__node-custom">
                            <div className="custom-node__header">
                                <h2 className="custom-drag-handle" >Importing VPC</h2>
                            </div>
                            <div className="custom-node__header">
                                <select id="import" disabled={!isButtonDisabled} onChange={(event) => handleResourcesReading(event.target.value)}>
                                    <option value="">Select one VPC ID to Import</option>
                                    {VPCList.map((vpc) => (
                                        <option key={vpc} value={vpc}>
                                            {vpc}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>

                                {Object.entries(DictResourcesVPC).map(([key, value], index) => (
                                    <p key={index}>{key} : {value.length}</p>
                                ))}
                            </div>

                            <button disabled={isButtonDisabled} onClick={() => HandleProceedImport()}>Confirm Import</button>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className="react-flow__node-custom">
                            <div className="custom-node__header">
                                <h2 className="custom-drag-handle">{Message}
                                    {IsImage && <img src={MessageIcon} alt="Descrição da imagem" style={{ height: "25px", width: "25px;", borderRadius: "4px" }}>
                                    </img>}</h2>
                            </div>
                        </div>
                    </>
                )
            }
        }
    } else {
        if (IsNode == "Edge") {//Configura edge
            let Edge = data.nodeAction;
            //console.log("eh uma edge", data.labelAttr.length, data.labelAttr, Edge);

            if (data.labelAttr.length < 2) {
                let Prefix = "";
                if (data.labelAttr.length == 1) {
                    //console.log("Prefix", Prefix, data.labelAttr, data.labelAttr[0][1])
                    Prefix = data.labelAttr[0].split("?")[0]
                    //if (Prefix.length !== 1) {Prefix = ""; } else {Prefix = Prefix[0]}
                    //console.log("Prefix", Prefix)
                }
                return (
                    <>
                        <div className="react-flow__node-custom">
                            <div className="custom-node__header">
                                <h2 className="custom-drag-handle">Enter Edge Label</h2>
                                <input
                                    type="text"
                                    placeholder="Enter Label"
                                    defaultValue={data.label}
                                    onChange={(e) => updateLabel(Prefix, e.target.value, false)}
                                />
                            </div>
                        </div>
                    </>
                )
            } else {
                //console.log("List attr", data.labelAttr);
                return (
                    <div className="react-flow__node-custom">
                        <div className="custom-node__header">
                            <h2 className="custom-drag-handle">Option | Edge Label</h2>
                            <select
                                id={"Select"}
                                value={selectedLabel}
                                onChange={(e) => updateLabel(e.target.value, suffix, true)}
                            >
                                {data.labelAttr.map((Option, Index) =>
                                    <option key={Index} value={Option}>{Option}</option>
                                )}
                            </select>
                            <input
                                type="text"
                                placeholder="Enter Label (Optional)"
                                value={suffix}
                                onChange={(e) => updateLabel(selectedLabel, e.target.value)}
                            />
                        </div>
                    </div>
                )
            }

        } else {
            return (
                <>
                </>
            )
        }
    }

}
export { Config };