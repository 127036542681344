

import { Constraints, GNodes, GEdges } from './NodesData';
import { useState, useCallback, useEffect } from 'react';

const Modal = ({ x, y, width, height, isOpen, children, NodeType, DictTarget, onSetsharedNodeTypeSearch, DictSource }) => {
    const [Connection, setConnection] = useState('Output Connections');
    const [Switch, setSwitch] = useState('Switch to Input Nodes');
    const [ListNodes, setListNodes] = useState([]);

    useEffect(() => {
        if (isOpen) {
            setSwitch('Switch to Input Nodes');
            setConnection('Output Connections');
            setListNodes(DictTarget[NodeType] || []);
        }
    }, [isOpen, DictTarget, NodeType]);

    const SwitchInOut = useCallback(() => {
        if (Switch === 'Switch to Input Nodes') {
            setSwitch('Switch to Output Nodes');
            setListNodes(DictSource[NodeType] || []);
            setConnection("Input Connections");
        } else {
            setSwitch('Switch to Input Nodes');
            setListNodes(DictTarget[NodeType] || []);
            setConnection("Output Connections");
        }
    }, [Switch, DictSource, DictTarget, NodeType]);

    if (!isOpen) return null;

    const modalStyle = {
        position: 'fixed', // Para definir a posição X, Y
        left: '80%', // Posição X centralizada
        top: '50%', // Posição Y centralizada
        transform: 'translate(-50%, -50%)', // Para centralizar o modal
        padding: '20px', // Acolchoamento interno
        backgroundColor: '#ffffff', // Cor de fundo branca
        borderRadius: '20px', // Borda arredondada
        boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)', // Sombra suave
        overflowY: 'auto', // Para adicionar rolagem vertical
        maxHeight: '70%', // Altura máxima
        border: '1px solid #e0e0e0', // Borda sutil
    };

    let Resource = NodeType.slice(0, -1);
    let ImgNode = Constraints.IconsURL + Resource + '.png';

    const handleOnClick = (Node) => {
        onSetsharedNodeTypeSearch(LookUpNamesByNodeType[Node]);
    };

    let LookUpNamesByNodeType = Constraints['NodeNames'];

    return (
        <div style={modalStyle}>
            {children}
            <br></br>
            <div className="HideEdge">
                <button id="Switch" onClick={SwitchInOut}>
                    {Switch}
                </button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>{Connection}</h2>
                <img
                    src={ImgNode}
                    alt="Descrição da imagem"
                    style={{ cursor: 'pointer', height: "40px", width: "40px", borderRadius: "3px", marginLeft: "8px", position: "relative", top: "3px" }}
                />
            </div>
            {ListNodes.map((Node, Index) => (
                <div style={{ display: 'flex', alignItems: 'center' }} key={Index}>
                    <img
                        src={Constraints.IconsURL + Node.slice(0, -1) + '.png'}
                        onClick={() => handleOnClick(Node)}
                        alt="Descrição da imagem"
                        style={{
                            cursor: 'pointer', height: "40px", width: "40px", borderRadius: "3px", marginLeft: "8px", marginRight: "8px", position: "relative", top: "3px"// ... estilos omitidos para brevidade
                        }}
                    />
                    <h3>{LookUpNamesByNodeType[Node]}</h3>
                </div>
            ))}
        </div>
    );
};

export default Modal;
