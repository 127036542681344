import React, { useState } from 'react';
import { Constraints, ListRegions } from '../NodesData';
import { NodeResizer } from '@reactflow/node-resizer';
import { Handle, Position } from 'reactflow';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import styled from 'styled-components';
import GenericBoxNode from '../GenericBoxNode';


const dragHandleStyle = {
    display: 'inline-block',
    width: 300,
    height: 30,
    backgroundColor: 'teal',
    marginLeft: 5,
    borderRadius: 1,
};


const StyledResizer = styled(NodeResizer)`
    &::after {
      content: "";
      position: absolute;
      top: -50px;
      right: -50px;
      bottom: -50px;
      left: -50px;
    }
  `;

const Region = ({ id, data, selected }) => {
    const URLIcon = Constraints.IconsURL + 'RegionGlobe.png';
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    const [isMouseNear, setIsMouseNear] = useState(false);
    let Cost = "";
    let ShowCost;
    try {
        ShowCost = data.ShowCost;
        Cost = "$" + data.Cost;
    } catch (error) {
        ShowCost = false;
    }
    if (Cost === undefined || ShowCost === false) { Cost = ""; }
    return (
        <div
            onMouseEnter={() => setIsMouseNear(true)}
            onMouseLeave={() => setIsMouseNear(false)}
        >
            <GenericBoxNode
                id={id}
                data={data}
                selected={selected}
                HasHandle={false}
                borderWidth={6}
            >
                <div style={{
                    position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
                    borderRadius: "15px 15px 0 0"
                }}>
                    <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img src={URLIcon} alt="Icon" style={{ width: 60, height: 60, margin: "3px 11px" }} />
                        <div style={{ whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" }}> Region : {data.Param[2][2]}</div>
                        {ShowCost && <div style={{ marginLeft: "5px" }}>{Cost}</div>}
                    </div>
                    <div style={{ borderRadius: "10%" }}>
                    </div>
                </div>
                <Handle
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />
            </GenericBoxNode>
        </div>
    );
};

export { Region, ListRegions };




