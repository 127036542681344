import React, { useState, useContext, useEffect } from 'react';
import '././VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import { Constraints, GlobalToken, GlobalCognitoSub, TypeAZ, TypeVPC, GEdges, GNodes, Stage } from './NodesData';
import {
    CheckIdenticalName, FindBackendStorage, AdjustSufixName, updateTerraformDependencies, FindRegionAndCloud, ListTerraformDomain, TestDuplicateEdges,
    DiscoveryTerraformNetwork, Save,
} from './Functions';
import { APIAWSTerraformCode, APIAzureTerraformCode } from "./Config.js";
const TypeCloudRegion = ["RegionN", "AZRegionN", "CloudN", "AZCloudN"];
const TypeCloud = ["CloudN", "AZCloudN"];
const TypeTerraform = ["TerraformN", "TerraformBackendS3N"]

const Compile = ({ TFID = 0, GlobalNodes, GlobalEdges, onIconClick }) => {
    let InitialWarning = [];
    let InitialError = [];
    let InitialNameError = [];
    console.log("TFID", TFID)
    let IsTerraform = TypeTerraform.includes(GlobalNodes[TFID].type);
    try {
        InitialWarning = GlobalNodes[TFID].data.Compile.WarningList || [];
        InitialError = GlobalNodes[TFID].data.Compile.ErrorList || [];
        InitialNameError = GlobalNodes[TFID].data.Compile.NameError || [];
    } catch (error) {
        console.log("Erro em leitura das listas")
    }
    let WarningArray = InitialWarning;
    let ErrorArray = InitialError;
    let NameError = InitialNameError;
    let Success = InitialError.length === 0 && InitialWarning.length === 0 && InitialNameError.length === 0;
    let Fail = InitialError.length !== 0 || InitialNameError.length !== 0;
    let Pass = InitialWarning.length !== 0 && !Fail;
    const [loading, setLoading] = useState(false);
    console.log("ErrorArray", ErrorArray, WarningArray);
    console.log("TFID", TFID)
    let [ListStates, BackendID, HasLoop] = DiscoveryTerraformNetwork(GlobalEdges, GlobalNodes, parseInt(TFID));
    //if (BackendID === 0) { BackendID = TFID }
    console.log("BackendID", BackendID)
    let [StorageID, DBID, RepoID, BuildID, BackendErrorMSG] = FindBackendStorage(GlobalEdges, GlobalNodes, BackendID);
    console.log("StorageID", StorageID)
    if (BackendID === 0) { StorageID = 0; }
    const BackendError = [BackendID, BackendErrorMSG]
    if (HasLoop) {
        console.log("hasLoop")
        return (
            <div style={{ backgroundColor: 'white' }}>
                <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                    <h2>Found Terraform nodes network loop</h2>
                </div>
            </div>
        )
    }
    if (TFID != 0 && IsTerraform && !HasLoop) {
        const handleIconClick = (ID) => {
            //console.log('click icon', ID);
            onIconClick(ID);  // Aqui estamos chamando a função passada pelo componente Filho, que por sua vez foi passada pelo componente Avô.
        };
        let ListTF = [];
        let ListPositionTFSatete = 0;
        let CountListTF = 0;
        let ListTFState = [];
        let NewNodes = [];
        let NewNode = [];
        let NewNodeToOldNode = {};
        let OldNodeToNewNode = {};
        let NewEdges = [];
        let NewEdge = [];
        let ListNodesID = [];
        var TerraformNodeName = "";
        //try {
        for (let i = 1; i < GlobalNodes.length; i++) {
            if (TypeTerraform.includes(GlobalNodes[i].type)) {
                let ListTerraform = ListTerraformDomain(GlobalEdges, GlobalNodes, GlobalNodes[i]);
                console.log("ListTerraformDomain", ListTerraform, i)
                for (let j = 1; j < ListTerraform.length; j++) {
                    let NodeID = parseInt(ListTerraform[j]);
                    GlobalNodes[NodeID].data.Terraform = i.toString();
                }
            }
        }
        //Monta a lista de Terraform Nodes
        /*for (let i = 1; i < GlobalNodes.length; i++) {
            if (GlobalNodes[i].type === "TerraformN") {
                let TFFather = parseInt(GlobalNodes[i].parentNode);
                //console.log("TFFather", TFFather)
                let List = FindNodesChieldID(GlobalNodes, TFFather);
                ListTF.push([i, List]);
                if (i == TFID) { ListPositionTFSatete = CountListTF }
                CountListTF += 1;
            }
        }
        //console.log("ListTF", ListTF)
        //console.log("ListPositionTFSatete", ListPositionTFSatete)
        //Crai a lista de nodes do TF corrente: ListNodesID
        let UsedNodesList = FindAllFathers(GlobalNodes, TFID);
        try {
            ListTFState = ListTF[ListPositionTFSatete][1];
        } catch (error) {
            //pass
        }
        UsedNodesList = UsedNodesList.concat(ListTFState);
        for (let i = 0; i < UsedNodesList.length; i++) {
            ListNodesID.push(UsedNodesList[i]);
        }
        //verifica outros TF nodes que são source ou target do TF currente, e inclui os nodes na lista UsedNodesList
        for (let i = 0; i < GlobalEdges.length; i++) {
            let Source = parseInt(GlobalEdges[i]["source"]);
            let Target = parseInt(GlobalEdges[i]["target"]);
            if (ListTF[ListPositionTFSatete][1].includes(Target) || (ListTF[ListPositionTFSatete][1].includes(Source))) {
                //console.log("Source Target", Source, Target)
                for (let j = 0; j < ListTF.length; j++) {
                    if ((ListTF[j][1].includes(Source)) || (ListTF[j][1].includes(Target))) {
                        let CurrentTFID = ListTF[j][0];
                        if (CurrentTFID != TFID) {
                            UsedNodesList = UsedNodesList.concat(ListTF[j][1]);
                            let ListFather = FindAllFathers(GlobalNodes, CurrentTFID);
                            UsedNodesList = UsedNodesList.concat(ListFather);
                        }
                    }
                }
            }
        }
        //********* insere TODOS os nodes ******************
        for (let i = 1; i < GlobalNodes.length; i++) {
            if (["NullN"].includes(GlobalNodes[i].type) == false)
                UsedNodesList[i] = parseInt(GlobalNodes[i].id);
        }
        //Convert UsedNodesList para Int
        //for (let i = 0; i < UsedNodesList.length; i++) {
        //    UsedNodesList[i] = parseInt(UsedNodesList[i]);
        //}
        // Cria lista dos novos nodes para enviar ao backend
        UsedNodesList = [...new Set(UsedNodesList)];
        for (let i = 0; i < UsedNodesList.length; i++) {
            NewNode = JSON.parse(JSON.stringify(GlobalNodes[UsedNodesList[i]]));
            NewNode.id = i.toString();
            NewNodeToOldNode[i] = UsedNodesList[i];
            OldNodeToNewNode[UsedNodesList[i]] = i;
            NewNodes.push(NewNode);
        }
        // Atualiza o campo parentNode dos nodes
        for (let i = 0; i < NewNodes.length; i++) {
            //console.log("NewNodes[i].parentNode)", NewNodes[i].parentNode);
            try {
                NewNodes[i].parentNode = OldNodeToNewNode[parseInt(NewNodes[i].parentNode)].toString();
            } catch (error) { //pass }
                NewNodes[i].parentNode = "-1";
                //console.log("Erro em Atualiza o campo parentNode", i);
            }
        }
        //Atualiza os id de ListNodesID
        for (let i = 0; i < ListNodesID.length; i++) {
            ListNodesID[i] = OldNodeToNewNode[ListNodesID[i]].toString();
        }
        //Cria a lista das novas edges para enviar ao backend
        for (let i = 0; i < GlobalEdges.length; i++) {
            if (UsedNodesList.includes(parseInt(GlobalEdges[i].source))) {
                NewEdge = JSON.parse(JSON.stringify(GlobalEdges[i]));
                let Source = parseInt(NewEdge.source);
                NewEdge.source = OldNodeToNewNode[Source].toString();
                let Target = parseInt(NewEdge.target);
                NewEdge.target = OldNodeToNewNode[Target].toString();
                NewEdges.push(NewEdge);
            }
        }
        //} catch (error) {
        //pass
        //} */
        ListNodesID = ListTerraformDomain(GlobalEdges, GlobalNodes, GlobalNodes[TFID]);
        console.log("ListNodesID", ListNodesID)
        let CloudRegionList = [];
        //console.log("GlobalNodes", GlobalNodes, ListNodesID)
        for (let i = 0; i < ListNodesID.length; i++) {
            //Seleciona Cloud e Region Nodes
            let NodeType = GlobalNodes[ListNodesID[i]].type;
            //console.log("GlobalNodes", NodeType);
            if (TypeCloudRegion.includes(NodeType)) { CloudRegionList.push(ListNodesID[i]); }
        }
        //Testa se Terraform é para apenas VPC network, e se sim remove outros nodes
        const IsVPCNetwork = GlobalNodes[TFID].data.Param[2][1] == 1;
        const IsVPCNonNetwork = GlobalNodes[TFID].data.Param[2][1] == 2;
        if (IsVPCNetwork || IsVPCNonNetwork) {
            let NewList = [];
            for (let i = 0; i < ListNodesID.length; i++) {
                if (Constraints.NetworkResources.includes(GlobalNodes[ListNodesID[i]].type)) {
                    if (IsVPCNetwork) { NewList.push(ListNodesID[i]); }
                } else {
                    if (IsVPCNonNetwork) { NewList.push(ListNodesID[i]); }
                }
            }
            ListNodesID = [...new Set(NewList)];
        }
        //console.log("ListNodesID A", ListNodesID);
        //Remove nodes de Terraform State abaixo do State corrente
        let ListTFStateChild = [];
        for (let i = 0; i < ListNodesID.length; i++) {
            //Seleciona Cloud e Region Nodes
            if ((TypeTerraform.includes(GlobalNodes[ListNodesID[i]].type)) && (ListNodesID[i] != TFID)) {
                ListTFStateChild = ListTFStateChild.concat(ListTerraformDomain(GlobalEdges, GlobalNodes, GlobalNodes[ListNodesID[i]]));
                //console.log("TFStateChild", ListTFStateChild);
            }
        }
        ListNodesID = ListNodesID.filter(elemento => !ListTFStateChild.includes(elemento));
        ListNodesID = ListNodesID.concat(CloudRegionList);
        ListNodesID = [...new Set(ListNodesID)];
        //console.log("NewNodes", NewNodes);
        //console.log("Old Nodes", GlobalNodes);
        //console.log("NewEdges", NewEdges);
        //console.log("ListNodesID", ListNodesID);
        let FileName = sessionStorage.getItem("FileName");
        //var TFFilter = GlobalNodes[TFID].data.Param[2][1];
        //console.log("TFFilter", TFFilter, ListNodesID);
        //console.log("ListNodesID **********************************************", ListNodesID);
        //Filtra os elementos da mesma cloud

        const GlobalNodesLocal = AdjustSufixName(GlobalNodes, TFID);
        let GlobalEdgesLocal = JSON.parse(JSON.stringify(GlobalEdges));
        updateTerraformDependencies(TFID, GlobalEdges, GlobalNodesLocal, GlobalEdgesLocal); //Adiciona eddges entre todos os ancestrais para TFID
        console.log("OldEdges", GlobalEdges)
        console.log('NewEdges', GlobalEdgesLocal)
        /*let GlobalNodesLocal = JSON.parse(JSON.stringify(GlobalNodes));
        let [CloudTF, Region] = FindRegionAndCloud(GlobalNodesLocal, TFID);
        for (let i = 1; i < GlobalNodesLocal.length; i++) {
            let [Cloud, Region] = FindRegionAndCloud(GlobalNodesLocal, i);
            //console.log("CloudTF", CloudTF, Cloud)
            let NodeType = GlobalNodesLocal[i].type;
            if (Cloud !== CloudTF && !(TypeCloud.includes(NodeType))) {
                GlobalNodesLocal[i].data.Param = "";
                GlobalNodesLocal[i].type = "Discard"
            }
            if (NodeType === "GraphN") {
                GlobalNodesLocal[i].data.Param = ""
                GlobalNodesLocal[i].type = "Discard"
            }
            if (NodeType === "SBoxN" || TypeVPC.includes(NodeType)) {
                console.log("NodeType", NodeType)
                let Sufix = "";
                let ParentID = parseInt(GlobalNodesLocal[i].parentNode);
                if (!(GlobalNodesLocal[ParentID].type === "SBoxN" && ["dev", "test"].includes(GlobalNodesLocal[ParentID].data.Param[4][1]).toString())) {
                    if (NodeType === "SBoxN") {
                        Sufix = GlobalNodesLocal[i].data.Param[4][1];
                    } else {
                        Sufix = GlobalNodesLocal[i].data.Param[13][1];
                    }
                    if (Sufix !== "") {
                        ListBoxesSufix.push([i, Sufix])
                    }
                }
            }
        }
        const ExcludedList = ["GraphN", "Discard", "CodePipelineStageN"];
        for (let i = 0; i < ListBoxesSufix.length; i++) {
            let BoxID = ListBoxesSufix[i][0];
            let Level = FindFiliationLevel(GlobalNodesLocal, BoxID);
            console.log("Level", Level, GlobalNodesLocal[BoxID].type)
            let Sufix = ListBoxesSufix[i][1];
            console.log("GlobalNodesLocal", GlobalNodesLocal)
            let ListNodesSufix = FindNodesChieldID(GlobalNodesLocal, BoxID.toString());
            console.log("ListNodes", ListNodesSufix)
            for (let j = 0; j < ListNodesSufix.length; j++) {
                let Index = parseInt(ListNodesSufix[j]);
                let NodeTypeInsideBox = GlobalNodesLocal[Index].type;
                console.log("Index", Index, NodeTypeInsideBox)
                if (!TypeAZ.includes(NodeTypeInsideBox) && !(ExcludedList.includes(NodeTypeInsideBox))) {
                    GlobalNodesLocal[Index].data.Param[1][1] = GlobalNodesLocal[Index].data.Param[1][1] + "-" + Sufix;
                }
            }
        }*/
        TerraformNodeName = GlobalNodesLocal[TFID].data.Param[1][1];
        console.log("GlobalNodesLocal bbb", GlobalNodesLocal, GlobalNodes)
        var myHeaders = new Headers();
        let WarningIcon = Constraints.IconsURL + "Warning.png";
        let ErrorIcon = Constraints.IconsURL + "Error.png";
        //NewEdges = GlobalEdges;  //Alterar isso quando for removido os nodes deletados (null)
        NewEdges = TestDuplicateEdges(GlobalEdges);
        const CompileExec = () => {
            console.log("Passou aqui B");
            setLoading(true);
            let newErrors = [];
            let newWarnings = [];
            console.log("*********************compile*******************************")
            var raw = JSON.stringify([TerraformNodeName, ListNodesID, FileName, GlobalEdgesLocal, GlobalNodesLocal, StorageID, DBID, RepoID, BuildID]);
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
            var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
            let [Cloud, Region] = FindRegionAndCloud(GlobalNodes, TFID);
            let LambdaResource = "GenTerraformCode";
            let APITerraformCode = APIAWSTerraformCode;
            console.log("Cloud", Region, Cloud, GlobalNodes[parseInt(Cloud)])
            if (GlobalNodes[parseInt(Cloud)].type === "AZCloudN") {
                APITerraformCode = APIAzureTerraformCode;
            }
            let TFFatherID = parseInt(GlobalNodes[TFID].parentNode);
            let TFFatherType = GlobalNodes[TFFatherID].type;
            if (TFFatherType === "KNamespaceN") {
                LambdaResource = "GenKubernetesTerraformCode";
                console.log("GenKubernetesTerraformCode")
            }
            console.log("APITerraformCode", APITerraformCode)
            fetch(APITerraformCode, requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log("Tests");
                    let IconInput = "";
                    let IconOuput = "";
                    let Resp = JSON.parse(result).body;
                    console.log("Compilation Resp", Resp);
                    let ListOuputOpenNodes = Resp[1];
                    //for (let i = 0; i < ListOuputOpenNodes.length; i++) {
                    //    ListError[i] = [NewNodeToOldNode[ListOuputOpenNodes[i][0]], ListOuputOpenNodes[i][1]];
                    //}
                    let ListInputOpenNodes = Resp[2];
                    //console.log("ListInputOpenNodes", JSON.parse(JSON.stringify(ListInputOpenNodes)))
                    //for (let i = 0; i < ListInputOpenNodes.length; i++) {
                    //    ListError[i] = [NewNodeToOldNode[ListInputOpenNodes[i][0]], ListInputOpenNodes[i][1]];
                    //}
                    let ListError = Resp[3];
                    //console.log("ListError", JSON.parse(JSON.stringify(ListError)))
                    //for (let i = 0; i < ListError.length; i++) {
                    //    ListError[i] = [NewNodeToOldNode[ListError[i][0]], ListError[i][1]];
                    //console.log("ListError[i]", ListError[i]);
                    //}
                    let ListWarning = Resp[4];
                    //console.log("ListWarning 1", ListWarning)
                    //for (let i = 0; i < ListWarning.length; i++) {
                    //    ListWarning[i] = [NewNodeToOldNode[ListWarning[i][0]], ListWarning[i][1]];

                    //}

                    if (GlobalNodes.length > 0) {
                        for (let i = 0; i < ListOuputOpenNodes.length; i++) {
                            let ID = ListOuputOpenNodes[i];
                            IconOuput = Constraints.IconsURL + GlobalNodes[ID[0]].type.slice(0, -1) + ".png"
                            newWarnings.push([ID[0], ID[1], IconOuput]);
                        }
                        for (let i = 0; i < ListInputOpenNodes.length; i++) {
                            let ID = ListInputOpenNodes[i];
                            IconInput = Constraints.IconsURL + GlobalNodes[ID[0]].type.slice(0, -1) + ".png"
                            newWarnings.push([ID[0], ID[1], IconInput]);
                        }
                        //console.log("ListWarning", ListWarning)
                        for (let i = 0; i < ListWarning.length; i++) {
                            let ID = ListWarning[i][0];
                            //console.log("ID warning pre", ID)
                            //ID = AdjustID(ID, GlobalNodes);
                            //console.log("ID warning pos", ID)
                            let Msg = ListWarning[i][1];
                            let IconInput = Constraints.IconsURL + GlobalNodes[ID].type.slice(0, -1) + ".png";
                            newWarnings.push([ID, Msg, IconInput]);
                        }
                        WarningArray = newWarnings;
                        //console.log("ListError", ListError)
                        for (let i = 0; i < ListError.length; i++) {
                            let Msg = ListError[i][1];
                            let ID = ListError[i][0];
                            IconInput = Constraints.IconsURL + GlobalNodes[ID].type.slice(0, -1) + ".png";
                            newErrors.push([ID, Msg, IconInput]);
                        }
                        ErrorArray = newErrors
                        //if (BackendErrorMSG !== "") { ErrorArray = ErrorArray.concat(BackendError) }
                        NameError = CheckIdenticalName(GlobalNodesLocal, Constraints);
                        console.log("Passou aqui A")
                    }
                    //Success = ((ErrorArray.length == 0) && (WarningArray.length == 0));
                    //Fail = ErrorArray.length > 0;
                    //Pass = !Fail && !Success;
                    let HaveSuccess = (ErrorArray.length === 0) && (WarningArray.length === 0) && (NameError.length === 0);
                    console.log("HaveSuccess var", HaveSuccess)
                    console.log("ErrorArray", ErrorArray, WarningArray, WarningArray.length)
                    Success = HaveSuccess;
                    console.log("Success", Success);
                    if (HaveSuccess) {
                        GlobalNodes[TFID].data.Compile.WarningList = [];
                        GlobalNodes[TFID].data.Compile.ErrorList = [];
                        GlobalNodes[TFID].data.Compile.NameError = [];
                        console.log("HaveSuccess")
                    } else {
                        GlobalNodes[TFID].data.Compile.WarningList = WarningArray;
                        GlobalNodes[TFID].data.Compile.ErrorList = ErrorArray;
                        GlobalNodes[TFID].data.Compile.NameError = NameError;
                        console.log("No HaveSuccess")
                    }
                    Fail = ErrorArray.length > 0;
                    Pass = !Fail && !Success;
                    NameError = CheckIdenticalName(GlobalNodesLocal, Constraints);
                    console.log("Passou aqui B")
                    WarningIcon = Constraints.IconsURL + "Warning.png";
                    //console.log("NameError", NameError)
                    ErrorIcon = Constraints.IconsURL + "Error.png";
                    //setData(Resp);
                    setLoading(false);
                    //console.log("newWarnings", newWarnings)
                    GlobalNodes[TFID].data.CodeGenerated = Resp[0];
                    if (Stage !== "DevLocal") {
                        Save(GEdges, GNodes)
                    }
                    //console.log("WarningArray length", WarningArray, WarningArray.length)
                })
                .catch(error => {
                    console.error("Error during fetch:", error);
                });
        };

        if (loading) {
            return <div style={{ backgroundColor: 'white' }}>Compiling...</div>;
        } else {
            return (
                <div style={{ backgroundColor: 'white' }}>
                    <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <button onClick={CompileExec} style={{ marginTop: '6px' }}>New Compile</button>
                        </div>

                        <h2>Result:</h2>
                        {NameError.map((ID) =>
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', }}>
                                    <img src={ID[2]} alt="Descrição da imagem" onClick={(e) => handleIconClick((ID[0]).toString())}
                                        style={{
                                            height: "60px", width: "60px", borderRadius: "2px", verticalAlign: "middle", marginRight: '8px',
                                            border: "1px solid black", cursor: "pointer !important", boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                                        }}></img>

                                    <img src={ID[2]} alt="Descrição da imagem" onClick={(e) => handleIconClick(ID[1])}
                                        style={{
                                            height: "60px", width: "60px", borderRadius: "2px", verticalAlign: "middle", marginRight: '8px',
                                            border: "1px solid black", cursor: "pointer !important", boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                                        }}></img>
                                    <div style={{ marginLeft: '8px' }}>
                                        <img src={ErrorIcon} alt="Descrição da imagem" style={{ height: "25px", width: "25px", verticalAlign: "middle" }}></img>
                                        <strong> Error: </strong>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>{ID[3]} </span>
                                    </div>
                                </div>
                            </>

                        )}
                        {WarningArray.map((ID) =>
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={ID[2]} alt="Descrição da imagem" onClick={() => handleIconClick(ID[0])}
                                        style={{
                                            height: "60px", width: "60px;", verticalAlign: "middle", cursor: "pointer !important", border: "1px solid black",
                                            boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                                        }}></img>
                                    <div style={{ marginLeft: '8px' }}>
                                        <img src={WarningIcon} alt="Descrição da imagem" style={{ height: "25px", width: "25px;", verticalAlign: "middle" }}></img>
                                        <strong> Warning: </strong>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>
                                            {GlobalNodes?.[ID[0]]?.data?.Param?.[1]?.[1]} &nbsp; {ID[1]}
                                        </span>
                                    </div>
                                </div >

                                <br></br>
                            </>
                        )}
                        {ErrorArray.map((ID) =>
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={ID[2]} alt="Descrição da imagem" onClick={() => handleIconClick(ID[0])}
                                        style={{
                                            height: "60px", width: "60px;", verticalAlign: "middle", cursor: "pointer !important", border: "1px solid black",
                                            boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                                        }}></img>
                                    <div style={{ marginLeft: '8px' }}>
                                        <img src={ErrorIcon} alt="Descrição da imagem" style={{ height: "25px", width: "25px;", verticalAlign: "middle" }}></img>
                                        <strong> Error: </strong>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>
                                            {GlobalNodes?.[ID[0]]?.data?.Param?.[1]?.[1]} &nbsp; {ID[1]}
                                        </span>
                                    </div>
                                </div >
                                <br></br>
                            </>
                        )}
                        {Success && <h3> Success! 0 Errors and 0 warnings.</h3>}
                        {Fail && <h3> Fail! With {ErrorArray.length + NameError.length} error(s). </h3>}
                        {Pass && <h3> Pass with {WarningArray.length} warning(s). </h3>}        </div>
                </div >
            );
        }
    }
}
export { Compile };


