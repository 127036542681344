import React, { useState, useEffect } from 'react';
import { Handle, NodeToolbar } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import FileSaver from 'file-saver';
import { Constraints, GNodes, GEdges } from '../NodesData';
import GenericNode from '../GenericNode';
import { MaskString } from '../Functions';
import { API } from "../Config.js"

var ListOuputOpenNodes = [];
var ListInputOpenNodes = [];
var ListError = [];
var ListWarning = [];
var GlobalNodes = GNodes;
var GlobalEdges = GEdges;
var Code = "No code"


const AZCloud = ({ id, data, selected }) => {
    const URLIcon = Constraints.IconsURL + 'Cloud.png';
    const ProviderURLIcon = Constraints.IconsURL + 'AZCloud.png';
    let Name = data.Param[1][1];
    let FontSize = data.Param[2][1];
    var [scaleFactor, setScaleFactor] = useState(1);  // Valor inicial é 1 (100%)
    let Account = MaskString(data.Param[3][1]);

    useEffect(() => {
        let newScale;
        switch (FontSize) {
            case 1:
                newScale = .6;
                break;
            case 2:
                newScale = .3;
                break;
            default:
                newScale = 1;
        }
        setScaleFactor(newScale);
    }, [FontSize]);

    const baseStyles = {
        img: { width: 80, height: 80, margin: "0px 11px" },
        text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" },
        providerImg: { width: 60, height: 60, margin: "0px 11px" },
        title: { fontSize: 32 }  // Defini um tamanho base de 24px para o título.
    }

    const scaledStyles = {
        img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor, height: baseStyles.img.height * scaleFactor },
        text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
        providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
        title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }

    }

    return (
        <>
            <NodeResizer style={{ borderRadius: "20px" }} color="#ff0000" isVisible={selected} minWidth={20} minHeight={20} />
            <div style={{
                position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)",
                backgroundColor: selected ? "#A2A2A2" : "#f2f2f2", borderRadius: "15px 15px 0 0"
            }}>
                <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img src={URLIcon} alt="Icon" style={scaledStyles.img} />
                    <div style={scaledStyles.text}></div>
                    <img src={ProviderURLIcon} alt="Icon" style={scaledStyles.providerImg} />
                    <div style={scaledStyles.text}> </div>
                    <div style={scaledStyles.title}>&nbsp;Name:&nbsp;{Name}&nbsp;&nbsp;&nbsp;Account:&nbsp;{Account}</div>
                </div>
                <div style={{ borderRadius: "10%" }}>
                </div>
                <div style={{ borderRadius: "10%" }}>
                </div>
            </div>
        </>
    );
};





export { AZCloud };




