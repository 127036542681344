import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const RDS = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'RDS.png")';
    let LabelName = data.Param[1][1];
    let Label = data.Label;
    if (data.MultiAZ == true) {
        URLIcon = 'url("' + Constraints.IconsURL + 'RDSMultiAZ.png")';
        Label = "RDS MultiAZ";
    }
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} Label={Label} />
    );
};

const RDSReplica = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'RDSReplica.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const MariaDB = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'MariaDB.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const MySQL = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'MySQL.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const PostGres = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'PostGres.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const SQLServer = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SQLServer.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const Oracle = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'Oracle.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const RDSFinalSnapShot = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'RDSFinalSnapShot.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const RDSSnapShot = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'RDSSnapShot.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};
const RDSSnapShotCopy = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'RDSSnapShotCopy.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};


export { RDS, RDSReplica, MariaDB, MySQL, PostGres, SQLServer, Oracle, RDSFinalSnapShot, RDSSnapShot, RDSSnapShotCopy };

