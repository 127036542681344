import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const Lambda = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'Lambda.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const LambdaURL = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LambdaURL.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const LambdaAlias = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LambdaAlias.png")';
    let version = data.Param[5][1];
    let pattern = /^(\$LATEST|[0-9]+)$/;
    if (version.match(pattern) === null) { version = "$LATEST" }
    let LabelName = data.Param[1][1] + " Vr:" + version;
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const LambdaLayer = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LambdaLayer.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    )
};

const LambdaESM = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LambdaESM.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const LambdaCSC = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LambdaCSC.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { Lambda, LambdaLayer, LambdaESM, LambdaCSC, LambdaAlias, LambdaURL };

