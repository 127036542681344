import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { Handle, NodeResizer, Position } from 'reactflow';
import styles from './index.module.css';
import { Constraints } from '../../../NodesData';

const fontFamilies = [
  'Arial',
  'Times New Roman',
  'Courier New',
  'Verdana',
  'Georgia',
  'Comic Sans MS',
];

const Editor = ({ fontSize, fontStyle, data }) => {
  const [value, setValue] = useState(data.TextValue || '');

  useEffect(() => {
    data.TextValue = value; // atualiza o valor no objeto data
  }, [value]);

  const auto_height = (elem) => {
    elem.style.height = '1px';
    elem.style.height = `${elem.scrollHeight}px`;
  };

  return (
    <textarea
      className={'nodrag ' + styles.textarea}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Text here"
      style={{
        fontSize,
        fontFamily: fontStyle,
        backgroundColor: 'transparent',
        border: 'none',
        resize: 'none',
        overflow: 'hidden'
      }}
      onInput={(e) => auto_height(e.target)}
      spellCheck="false"
      autoCorrect="off"
    />
  );
};

const NodeText = ({ data, selected }) => {
  const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
  const divRef = useRef(null);
  //console.log("data.FontSize", data.FontSize, data.FontStyle,)
  const [fontSize, setFontSize] = useState(data.FontSize || '16px');
  const [fontStyle, setFontStyle] = useState(data.FontStyle || 'Arial');



  useEffect(() => {
    data.FontSize = fontSize; // atualiza o fontSize no objeto data
    data.FontStyle = fontStyle; // atualiza o fontStyle no objeto data
  }, [fontSize, fontStyle]);

  const handleFontSizeChange = (event) => {
    setFontSize(event.target.value);
  };

  const handleFontStyleChange = (event) => {
    setFontStyle(event.target.value);
  };

  const style = selected ? { border: '2px solid lightblue' } : {};
  const className = selected ? styles.node : '';

  return (
    <div ref={divRef} style={style} className={className}>
      <NodeResizer color="lightblue" isVisible={selected} minWidth={5} minHeight={5} />
      {selected && (
        <Grid container spacing={0} direction="row" style={{ position: 'absolute', top: -30, fontSize: 5, zIndex: 1, flexWrap: 'nowrap' }}>
          <Grid item xs={4} className="grid-box">
            <label htmlFor="fontSize">Font Size:</label>
            <select id="fontSize" value={fontSize} onChange={handleFontSizeChange} style={{ width: '100%' }}>
              <option value="12px">12px</option>
              <option value="16px">16px</option>
              <option value="20px">20px</option>
              <option value="30px">30px</option>
              <option value="95px">95px</option>
            </select>
          </Grid>
          <Grid item xs={4} className="grid-box">
            <label htmlFor="fontStyle">Font Style:</label>
            <select id="fontStyle" value={fontStyle} onChange={handleFontStyleChange} style={{ width: '100%' }}>
              {fontFamilies.map((family, index) => (
                <option key={index} value={family}>
                  {family}
                </option>
              ))}
            </select>
          </Grid>
          <Grid item xs={4} className="grid-box">
            <div style={{ textAlign: 'center' }}>
              <h1>Drag Here</h1>
            </div>
          </Grid>
        </Grid>
      )}
      <Editor fontSize={fontSize} fontStyle={fontStyle} data={data} />
      {selected && (
        <Handle
          type="source"
          position="right"
          style={{
            background: 'transparent',
            backgroundImage: `url(${URLArrow})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            border: 'none',
            afterBorderColor: 'none',
            width: '8px',
            height: '8px',
            top: '7px',
            transform: 'translateX(-2px)',
            imageRendering: 'crisp-edges',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          }}
          isConnectable={true}
        />
      )}
    </div>
  );
};

export default NodeText;

