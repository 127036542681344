import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import { useState, useRef, useEffect } from "react";
import '../VPC.css';
import GenericNode from '../GenericNode'

const DynamoDB = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'DynamoDB.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const DynamoDBItem = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'DynamoDBItem.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const DTReplica = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'DTReplica.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { DynamoDB, DTReplica, DynamoDBItem };

