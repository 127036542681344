

import { SearchNameNodeType, GetNodeId } from './Functions';
var ImportedData = [];

function ImportTerraform(ImportedDataX, GlobalNodes, ResourceToNodeLookUp, NodeData) {
    ImportedData = ImportedDataX;
    let ListConnectEdge = [];
    let ImportedNodes = [];
    if (ImportedData != null) {
        console.log("Data in Context has been updated!", ImportedData);
        let ListResourceRelationship = ImportedData[1];
        for (let i = 0; i < ListResourceRelationship.length; i++) {
            let Rel = ListResourceRelationship[i];
            console.log("ListResourceRelationship[i]", Rel);
            if (Rel.includes("~")) {
                console.log("included")
                ListResourceRelationship[i] = Rel.split("~");
            }
        }
        console.log("ListResourceRelationship", ListResourceRelationship)
        ImportedData = ImportedData[0];
        //Split os recursos abaixo nad lista SplitResourceListParam
        let SplitResourceListName = ["aws_network_interface", "aws_security_group", "aws_vpc_security_group_ingress_rule",
            "aws_vpc_security_group_egress_rule"]
        let SplitResourceListParam = [];
        let SplitResourceListPosition = [];
        for (let i = 2; i < ImportedData.length; i++) {
            let ResourceName = ImportedData[i][1][0];
            console.log("ResourceName", ResourceName);
            if (SplitResourceListName.includes(ResourceName)) {
                console.log("ResourceName Splited", ResourceName);
                SplitResourceListParam.push(ImportedData[i]);
                SplitResourceListPosition.push(i);
            }
        }
        console.log("SplitResourceListParam!", SplitResourceListParam, SplitResourceListPosition);
        for (let i = SplitResourceListPosition.length - 1; i > -1; i--) { //Remove os recursos separados da lista ImportedData
            console.log("SplitResourceListPosition!", SplitResourceListPosition[i], i);
            ImportedData.splice(SplitResourceListPosition[i], 1);
            console.log("ImportedData", ImportedData)
        }
        console.log("new ImportedData!", JSON.parse(JSON.stringify(ImportedData)));
        console.log("new SplitResourceListParam!", JSON.parse(JSON.stringify(SplitResourceListParam)));

        let VPCID = parseInt(ImportedData[0]);
        let ListAZ = [];
        let EdgeConnections = [];
        let ListResourceID = [];
        for (let i = 1; i < 9; i++) {
            let AZID = VPCID + i;
            if (GlobalNodes[AZID].type == "AZN") {
                ListAZ.push(GlobalNodes[AZID].data.Param[0])
            } else { break; }
        }
        console.log("ListVPCChield", GlobalNodes[VPCID].data.ListChieldType)
        GlobalNodes[VPCID].data.Param = JSON.parse(JSON.stringify(ImportedData[1][1]));
        GlobalNodes[VPCID].data.ResourceID = ImportedData[1][0];
        //nodes[VPCID] = GlobalNodes[VPCID];
        let Count = 0;
        let i = 2;
        while (i < ImportedData.length) {
            console.log("ImpotedNodes", i, JSON.parse(JSON.stringify(ImportedNodes)));
            let ListNewNodes = [];
            let TerraformResource = ImportedData[i][1][0];
            let NodeType = ResourceToNodeLookUp[TerraformResource];
            let Resource = NodeType.slice(0, -1);
            let ResourceID = ImportedData[i][0];
            let NewNode = JSON.parse(JSON.stringify(NodeData[Resource]));
            let NewParam = JSON.parse(JSON.stringify(ImportedData[i][1]));
            console.log("NewParam", NewParam);
            NewNode.data.Param = NewParam;
            NewNode.data.ResourceID = ResourceID;
            let Connection = ImportedData[i][2];
            if ((Connection != null) && (Connection !== "")) { EdgeConnections.push([ImportedData[i][0], Connection]) }
            console.log("id", id);
            if (NodeType == "SubnetN") {
                let SubnetAZ = NewNode.data.Param[3][1];
                let lastChar = SubnetAZ.charAt(SubnetAZ.length - 1);
                let Index = ListAZ.indexOf(lastChar);
                console.log("Index", i, Index, lastChar);
                let IDParent = (VPCID + Index + 1).toString();
                var GetID = GetNodeId(); var id = GetID[0];
                NewNode = { id, parentNode: IDParent, ...NewNode }
                ListNewNodes.push(NewNode);
            }
            if (GlobalNodes[VPCID].data.ListChieldType.includes(NodeType)) {
                console.log("New Node", NodeType)
                let IDParent = VPCID.toString();
                let Position = { "x": Count * 60, "y": 80 }
                Count += 1;
                NewNode.position = Position;
                var GetID = GetNodeId(); var id = GetID[0];
                NewNode = { id, parentNode: IDParent, ...NewNode }
                ListNewNodes.push(NewNode);
            }
            if (NodeType == "ASGN") {
                console.log("Processa ASG", ResourceID, ListResourceRelationship);
                //Achar o LT associado: -> LT deve ser criado antes de ASG
                let ResourceLTID = ""
                let LTID = 0;
                for (let j = 0; j < ListResourceRelationship.length; j++) {
                    if (ResourceID == ListResourceRelationship[j][0]) {
                        ResourceLTID = ListResourceRelationship[j][1];
                        if (ResourceLTID.slice(0, 3) == "lt-") {
                            console.log("ImportedNodes", ImportedNodes);
                            LTID = FindNodeIDFromResourceID(ResourceLTID, ImportedNodes);
                            break;
                        }
                    }
                }
                console.log("LTID", LTID);
                if (LTID != 0) {
                    //Identificar o SG ID np LT. 
                    let LTImportedID = FindImportedID(LTID, ImportedNodes);
                    console.log("nodes[LTID]", typeof LTID, ImportedNodes);
                    console.log("LTImportedID", LTImportedID);
                    let ResourceSGID = ImportedNodes[LTImportedID].data.Param[7][1][0];
                    //Identificar as subnets do ASG
                    let ListSubnetID = NewParam[2][1];
                    console.log("ListSGID", ResourceSGID, ListSubnetID);
                    //Criar o node SGN em cada subnet com uma EC2 
                    let OldSG = "";
                    let ListEC2 = [];
                    for (let j = 0; j < ListSubnetID.length; j++) {
                        let SubnetID = FindNodeIDFromResourceID(ListSubnetID[j], ImportedNodes);
                        let SGID = CreateImportedSG(ResourceSGID, SubnetID, NodeData, SplitResourceListParam, ListNewNodes, ListResourceID, j === 0);
                        console.log("Criou SG", SGID, j);
                        //let EC2ID = NodeGeneral(SGID, "EC2", nodes, NodeData, "").toString();
                        let EC2Node = CreateNewNode("EC2", NodeData, SGID, ListNewNodes);
                        EC2Node.data.AS = true;
                        let EC2ID = EC2Node.id;
                        ListEC2.push(EC2ID);
                        if (OldSG != "") { //Conectar Cada SG da cada subnet em daisy chain
                            ListConnectEdge.push([OldSG, SGID]);
                            OldSG = SGID;
                        }
                    }
                    for (let j = 0; j < ListEC2.length; j++) { //Conectar cada EC2 no ASG (edges)
                        ListConnectEdge.push([NewNode.id, ListEC2[j]]);
                    }
                }
            }
            if (NodeType == "ALBListenerN") {
                console.log("Processa ALBListener", ResourceID, ImportedNodes);
                console.log("NewNode", NewNode.type, NewNode)
                //Identifica o ARN do target group em 5.1.1.1.1
                let ResourceTGID = NewNode.data.Param[5][2][1][2][1][2][1][2][1][2];
                let TGID = FindNodeIDFromResourceID(ResourceTGID, ImportedNodes);
                ListConnectEdge.push([NewNode.id, TGID]);
                console.log("NewNode.id, TGID", NewNode.id, TGID, ResourceTGID);
                //Identifica o ARN do LB
                let ResourceLBID = NewNode.data.Param[2][2];
                let LBID = FindNodeIDFromResourceID(ResourceLBID, ImportedNodes);
                ListConnectEdge.push([LBID, TGID]);
                console.log("LBID, TGID", LBID, TGID, ResourceLBID);
            }
            if (NodeType == "ALoadBalancerN") {
                //Identificar o SG associado ao LB
                let ResourceSGID = NewNode.data.Param[4][1][0];
                console.log("NewNode.data.Param[4][1]", NewNode.data.Param[4][1]);
                let ListParamSG = FindResourceInSplitList(ResourceSGID, SplitResourceListParam);
                console.log("ListParamSG", ListParamSG);
                //Cria as SGRules
                TransferSplitListToImportedData(ResourceSGID, SplitResourceListParam, ImportedData)
                let SGName = ListParamSG[1][1];
                if (SGName != "") { NewNode.data.Param[22][1] = SGName; }
            }
            let ListSGChield = ["EC2N", "RDSN"];
            if (ListSGChield.includes(NodeType)) {
                let ResourceSubnetID = NewNode.data.Param[2][2];//Identificar a Subnet
                let SubnetID = FindNodeIDFromResourceID(ResourceSubnetID, ImportedNodes);
                NewNode.data.Param[2][2] = SubnetID;
                let ResourceSGID = NewNode.data.Param[3][1][0];//Identificar o SG
                let SGID = FindNodeIDFromResourceID(ResourceSGID, ImportedNodes);
                console.log("SGID", SGID)
                if (SGID == "") {
                    SGID = CreateImportedSG(ResourceSGID, SubnetID, NodeData, SplitResourceListParam, ListNewNodes, ListResourceID);
                }
                var GetID = GetNodeId(); var id = GetID[0];
                NewNode = { id, parentNode: SGID, ...NewNode }
                ListNewNodes.push(NewNode);
            }
            let ListPermit = ["aws_vpc_security_group_ingress_rule", "aws_vpc_security_group_egress_rule", "aws_network_acl_Ingress", "aws_network_acl_Egress"];
            //Evita replicar a criação de nodes com mesmo ResourceID
            if (((!ListResourceID.includes(ResourceID)) ||
                (ListPermit.includes(ImportedData[i][1][0])) && (ImportedData[i][1][0] !== "aws_security_group"))) {
                console.log("Add NewNode", ImportedData[i][1][0])
                for (let j = 0; j < ListNewNodes.length; j++) {
                    let NewNode = ListNewNodes[j];
                    let Name = SearchNameNodeType(GlobalNodes.concat(ImportedNodes), NewNode.type, NewNode.data.Param[1][1]);
                    NewNode.data.Param[1][1] = Name;
                    console.log("ListNewNodes", NewNode);
                    ImportedNodes.push(NewNode);
                    ListResourceID.push(ResourceID);
                }
            }
            i += 1;
        }
        //Faz as conexões das Edges
        console.log("EdgeConnections", EdgeConnections);
        console.log("ImportedNodes", JSON.parse(JSON.stringify(ImportedNodes)));
        var SourceID = "";
        var TargetID = "";
        for (let i = 0; i < EdgeConnections.length; i++) {
            //Encontra Node ID Source
            for (let j = 0; j < ImportedNodes.length; j++) {
                if (ImportedNodes[j].data.ResourceID === EdgeConnections[i][0]) {
                    SourceID = ImportedNodes[j].id
                    break;
                }
            }
            for (let j = 0; j < ImportedNodes.length; j++) {
                if (ImportedNodes[j].data.ResourceID === EdgeConnections[i][1]) {
                    TargetID = ImportedNodes[j].id;
                    break;
                }
            }
            if ((SourceID != "") && (TargetID !== "")) {
                console.log("SourceID", SourceID, TargetID)
                ListConnectEdge.push([SourceID, TargetID]);
            }
        }
        //Conexões Chain
        console.log("Conexões Chain........................")
        let ListChain = ["SecurityGroupN", "NACLN", "ALoadBalancerN"];
        for (let i = 0; i < ImportedNodes.length; i++) {
            let ResourceID = ImportedNodes[i].data.ResourceID;
            console.log("Chain ResourceID", ResourceID, ImportedNodes[i].type)
            if (ListChain.includes(ImportedNodes[i].type)) {
                if (ImportedNodes[i].type === "ALoadBalancerN") {
                    ResourceID = ImportedNodes[i].data.Param[4][1][0];
                    console.log("New Resource ID for ALB", ResourceID)
                }
                console.log("ImportedNodes SG", ImportedNodes[i]);
                TargetID = ImportedNodes[i].id;
                console.log("TargetID SG", TargetID);
                for (let j = i + 1; j < ImportedNodes.length; j++) {
                    if (ResourceID === ImportedNodes[j].data.ResourceID) {
                        SourceID = ImportedNodes[j].id;
                        console.log("SourceID SG", SourceID);
                        ListConnectEdge.push([SourceID, TargetID]);
                        TargetID = SourceID;
                    }
                }
                //Conecta SG com SGRules
                SourceID = ImportedNodes[i].id;
                console.log("ImportedNodes[i].data.Param[2][1]", ImportedNodes[i].data.Param[2][1])
                console.log("TargetID SG-Rules", TargetID);
                for (let j = i + 1; j < ImportedNodes.length; j++) {
                    if ((["SGIRuleN", "SGERuleN"].includes(ImportedNodes[j].type)) && (ImportedNodes[j].data.Param[8][1].includes(ResourceID))) {
                        TargetID = ImportedNodes[j].id;
                        console.log("SourceID SG-Rules", SourceID);
                        ListConnectEdge.push([SourceID, TargetID]);
                    }
                }
                /*for (let j = 0; j < ImportedNodes.length; j++) {
                  let ResourceTargetID = ImportedNodes[j].data.ResourceID;
                  console.log("ResourceTargetID", ResourceTargetID);
                  console.log("ImportedNodes[j].type", ImportedNodes[j].type, j);
                  if (["SubnetN"].includes(ImportedNodes[j].type)) {
                    if (ImportedNodes[i].data.Param[2][1].includes(ResourceTargetID)) {
                      TargetID = ImportedNodes[j].id;
                      console.log("SourceID SG-Rules", SourceID);
                      ConnectEdge(SourceID, TargetID);
                    }
                  }
                }*/
            }
        }
        //Conexões ID e ARN
        console.log("Conexões ID e ARN")
        for (let i = 0; i < ListResourceRelationship.length; i++) {
            let ResourceID = ListResourceRelationship[i][1];
            let SourceID = FindNodeIDFromResourceID(ResourceID, ImportedNodes);
            ResourceID = ListResourceRelationship[i][0];
            let TargetID = FindNodeIDFromResourceID(ResourceID, ImportedNodes);
            console.log("ResourceID", ResourceID, SourceID, TargetID)
            ListConnectEdge.push([SourceID, TargetID]);
        }
    }
    ListConnectEdge.push([SourceID, TargetID]);
    return [ImportedNodes, ListConnectEdge]
}

function CreateNewNode(ResourceType, NodeData, IDParent, ListNewNodes) {
    let GetID = GetNodeId();
    let id = GetID[0];
    let NewNode = JSON.parse(JSON.stringify(NodeData[ResourceType]));
    NewNode = { id, parentNode: IDParent, ...NewNode }
    //setNodes((nodes) => { return [...nodes, NewNode]; });
    ListNewNodes.push(NewNode);
    console.log("CreateNewNode", ResourceType, id);
    return NewNode;
}

function FindImportedID(ID, ImportedList) {
    for (let i = 0; i < ImportedList.length; i++) {
        if (ImportedList[i].id == ID) {
            return i;
        }
    }
}

function CreateImportedSG(ResourceSGID, SubnetID, NodeData, SplitResourceListParam, ListNewNodes, ListResourceID, CreateRules = true) {
    //Verifica se o SG já existe
    console.log("CreateImportedSG")
    let GetID = GetNodeId();
    let id = GetID[0];
    let SGParam = FindResourceInSplitList(ResourceSGID, SplitResourceListParam);
    SGParam = SGParam[1];
    console.log("SGParam", SGParam);
    if (CreateRules) {
        TransferSplitListToImportedData(ResourceSGID, SplitResourceListParam, ImportedData);
    }
    console.log("ImportedData", JSON.parse(JSON.stringify(ImportedData)), JSON.parse(JSON.stringify(SplitResourceListParam)));
    let NewNodeSG = JSON.parse(JSON.stringify(NodeData["SecurityGroup"]));
    NewNodeSG.data.Param = JSON.parse(JSON.stringify(SGParam));
    NewNodeSG.data.ResourceID = ResourceSGID;
    console.log("NewSG", id);
    NewNodeSG = { id, parentNode: SubnetID, ...NewNodeSG };
    ListNewNodes.push(NewNodeSG);
    ListResourceID.push(ResourceSGID);
    console.log("SGID", id)
    return id;
}

function FindResourceInSplitList(ResourceID, SplitResourceListParam) {
    console.log("FindResourceInSplitList");
    console.log("SplitResourceListParam", SplitResourceListParam);
    for (let i = 0; i < SplitResourceListParam.length; i++) {
        if (ResourceID == SplitResourceListParam[i][0]) {
            let Split = SplitResourceListParam[i];
            console.log("Split", Split);
            return Split;
        }
    }
    return "";
}
function TransferSplitListToImportedData(ResourceID, SplitResourceListParam, ImportedData) {
    //let TempList = [];
    for (let i = 0; i < SplitResourceListParam.length; i++) {
        if (ResourceID == SplitResourceListParam[i][0]) {
            ImportedData.push(SplitResourceListParam[i])
            //TempList.push(i);
        }
        //console.log("TempList", TempList)
    }
}

function FindNodeIDFromResourceID(ResourceID, List) {
    console.log("ResourceID", ResourceID, List);
    for (let i = 0; i < List.length; i++) {
        console.log("FindNodeIDFromResourceID", List[i].data.ResourceID, ResourceID)
        if (List[i].data.ResourceID === ResourceID) {
            return List[i].id;
        }
    }
    return ""
}

export { ImportTerraform }