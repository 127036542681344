import React, { useState } from 'react';
import { useModal } from './ModalMain.js';
import { API } from "./Config.js";
import { ListaStandard, TypeRegion, ResourceLookUp } from './NodesData.js';
import { SearchNodesSource, SearchNodesTarget, ToJSONObject, ExtractTerraformResourceBlock } from './Functions';
import { FindNodesChieldIDFromParent } from './Functions'
const AskGPT = ({ GlobalNodes, NodeID, GlobalEdges }) => {
    const [LambdaDescription, setLambdaDescription] = useState("");
    const GenAsk = () => {
        console.log("*********************Gen Ask*******************************")
        let NodeParentID = parseInt(GlobalNodes[NodeID].parentNode);
        let ListNodes = FindNodesChieldIDFromParent(GlobalNodes, GlobalNodes[NodeParentID]);
        let TFID = null;
        while (TFID == null) {
            for (let i = 0; i < ListNodes.length; i++) {
                let NodeType = GlobalNodes[ListNodes[i]].type;
                if (NodeType == "TerraformN") {
                    console.log("TerraformN", GlobalNodes[ListNodes[i]].data.Param[1][1]);
                    TFID = ListNodes[i];
                    break;
                }
            }
            if (TFID == null) {
                NodeParentID = parseInt(GlobalNodes[NodeParentID].parentNode);
                let NodeType = GlobalNodes[NodeParentID].type;
                if (TypeRegion.includes(NodeType)) {
                    TFID = 0;
                    break;
                } else {
                    ListNodes = FindNodesChieldIDFromParent(GlobalNodes, GlobalNodes[NodeParentID]);
                }
            }
        }
        //console.log("TerraformN", TFID, GlobalNodes[TFID].data.Param[1][1]);
        let Code = "";
        if (TFID != 0) {
            Code = GlobalNodes[TFID].data.CodeGenerated;
        }
        if (Code != "") {
            let ResourceName = GlobalNodes[NodeID].data.Param[1][1];
            let NodeType = GlobalNodes[NodeID].type;
            let NodeIndex = ResourceLookUp[NodeType];
            var ResourceType = ListaStandard[NodeIndex].ResourceTerraformName;
            //console.log("ResourceType", ResourceType, ResourceName)
            let Data = ExtractTerraformResourceBlock(Code, ResourceName, ResourceType);
            if (Data === null) { return null }
            console.log("ExtractTerraformResourceBlock", Data);
            let JSONString = ToJSONObject(Data);
            console.log("Extrai code", JSONString);
            let runtime = JSONString.runtime;
            let Evar = ""
            if (NodeType == "LambdaN") {
                Evar = JSONString.environment.variables;
            } else {
                //pass
            }
            let EVarString = Object.entries(Evar)
                .slice(0, 3)  // Pegar apenas os 3 primeiros pares chave-valor
                .map(([key, value]) => `"${key}":"${value}"`)
                .join(', ');
            let KeyList = [];
            let NameVariableList = [];
            Object.entries(Evar).forEach(([key, value]) => {
                if (key.includes("_Name_")) {
                    KeyList.push(key);
                    let ResourceName = key.replace("Source", "")
                    ResourceName = ResourceName.replace("Target", "")
                    ResourceName = ResourceName.split("__")[0]
                    console.log("ResourceName", ResourceName)
                    NameVariableList.push([value.split(":")[0], ResourceName])
                }
            });
            //console.log("NameVariableList", NameVariableList)
            //console.log("Result", EVarString);
            //console.log("Evar", Evar)
            let VarListResource = { "aws_dynamodb_table": ["hash_key", "range_key", "attribute.type", "attribute.name", "attribute1.type", "attribute1.name"] }
            let ListResourceInstruction = {
                "aws_sqs_queue": "lembrete: URL do SQS deve ser gerada usando Name, Region, e Account das variáveis de ambiente.",
                "aws_sns_topic": "lembrete: ARN do SNS deve ser gerada usando Name, Region, e Account das variáveis de ambiente."
            }
            setLambdaDescription(`lambda name: ${ResourceName}, runtime: ${runtime}, environment variables : ${EVarString}. \nEnvironment variable para obter dados:\n`);
            let ListTerraformResourceName = [];
            for (let i = 0; i < NameVariableList.length; i++) {
                let ResourceName = NameVariableList[i][0];
                let TerraformResourceName = NameVariableList[i][1];

                let Data = ExtractTerraformResourceBlock(Code, ResourceName, TerraformResourceName);
                let VarList = TerraformResourceName in VarListResource ? VarListResource[TerraformResourceName] : [];
                console.log("VarList", VarList, ResourceName)
                Data = GetJsonFields(Data, VarList)
                let KeyPerResource = `${KeyList[i]}, ${KeyList[i].replace("Name", "Region")}, ${KeyList[i].replace("Name", "Account")}\n`
                setLambdaDescription(prevDescription => prevDescription + KeyPerResource + Data);
                ListTerraformResourceName.push(TerraformResourceName);
            }
            ListTerraformResourceName = [...new Set(ListTerraformResourceName)];
            console.log("ListTerraformResourceName", ListTerraformResourceName)
            let ResourceInstruction = ""
            for (let i = 0; i < ListTerraformResourceName.length; i++) {
                let Instruction = ListResourceInstruction[ListTerraformResourceName[i]];
                if (Instruction !== undefined) {
                    ResourceInstruction += Instruction + "\n";
                    console.log("Instruction", Instruction)
                    console.log("ResourceInstruction", ResourceInstruction)
                }
            }
            console.log("ResourceInstruction", ResourceInstruction)
            setLambdaDescription(prevDescription => prevDescription + ResourceInstruction +
                'Para obter o name, Region e account de cada recurso que vc usurá, leia os environment variables key de cada recurso relacionado. Apenas leia o texto, não escreva nada, e aguarde o proxima pergunta.');
            //console.log("LambdaDescription", LambdaDescription);
        } else {
            ///console.log("Sem code!!!")
        }
    };

    function GetJsonFields(Data, ListFields) {
        let JSONString = ToJSONObject(Data); // Suponho que ToJSONObject transforma sua Data em um objeto JSON
        console.log("JSONString", JSONString)
        let DataOut = "";
        for (let field of ListFields) {
            let keys = field.split("."); // Divide o campo em chaves baseado nos pontos
            let value = JSONString;
            for (let key of keys) {
                value = value[key];
                if (value === undefined) {
                    break; // Se a chave não existir, pare de buscar e continue para a próxima
                }
            }

            // Se o valor final não for undefined, adicione-o ao DataOut
            if (value !== undefined) {
                DataOut += field + ": " + value + "\n";
            }
        }

        console.log("DataOut", DataOut);
        return DataOut;
    }
    function NewlineText({ text }) {
        const newText = text.split('\n').map((str, index, array) =>
            index === array.length - 1 ? str : <>
                {str}
                <br />
            </>
        );
        return <>{newText}</>;
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(LambdaDescription).then(() => {
            console.log('Text copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };
    return (
        <>
            <div style={{ marginLeft: '8px', marginRight: '8px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                {/* Box de botões */}
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '6px 0' }}>
                    <button onClick={GenAsk} style={{ marginLeft: '8px', marginRight: '10px' }}>Generate Ask</button>
                    <button onClick={handleCopy}>Copy</button>
                </div>

                {/* Box de texto */}
                <div style={{ textAlign: 'left', flex: 1, overflowY: 'auto' }}>
                    <div style={{
                        background: 'white',
                        color: 'black',
                        padding: '8px 16px',
                        borderRadius: '6px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
                        fontSize: '16px',
                        whiteSpace: 'pre-line' // Isto irá respeitar as quebras de linha no conteúdo
                    }}>
                        {LambdaDescription}
                    </div>
                </div>
            </div>


        </>
    )

}
export { AskGPT };