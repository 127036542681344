import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const StepFuncSM = ({ data, selected, }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'StepFuncSM.png")';
    let LabelName = data.Param[1][1];

    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export { StepFuncSM };