import { useState, useEffect, useRef } from 'react';
import { Constraints } from './NodesData';


function FeedBack({ show, message, onClose, onButtonClick }) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (show && !isOpen) {
            setIsOpen(true);
        } else if (!show && isOpen) {
            setIsOpen(false);
        }
    }, [show, isOpen]);

    const sidebarStyle = {
        position: 'fixed',
        bottom: '-50px',
        left: 0,
        right: 0,
        lineHeight: '50px',
        backgroundColor: '#ccc',
        transition: 'bottom 0.15s ease-in-out',
        zIndex: 49,
        fontSize: '16px' // Adding font size here
    };


    const sidebarOpenStyle = {
        bottom: 0,
    };

    const contentStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    };

    let ValueA = message;
    let ValueB = "";
    let ValueC = "";
    let IsImageA = false;
    let IsImageB = false;
    let IsImageC = false;
    let IsImageD = false;
    let IsImageE = false;

    let IconA = ""
    let IconB = ""
    let IconC = ""
    let IconD = ""
    let IconE = ""
    let ShowButton = false;
    const IterCon = Constraints.IconsURL + "InterConnectionMapIcon.png";

    if (message.includes("$$")) {
        IsImageA = true;
        message = message.split("$$");
        //console.log("message", message)
        IconA = Constraints.IconsURL + message[1] + ".png";
        //console.log("message 3", message[3])
        if ((message[3] !== " ") && (message[3] !== undefined)) {
            IconB = Constraints.IconsURL + message[3] + ".png";
            IsImageB = true;
        } else {
            message[3] = "";
        }
        ValueA = message[0] + " ";
        if (message[2] !== undefined) {
            ValueB = " " + message[2];
        } else {
            ValueB = "";
        }
        if ((message.length > 4) && (message[4] != "")) { IconC = Constraints.IconsURL + message[4] + ".png"; IsImageC = true; }
        if ((message.length > 5) && (message[5] != "")) { IconD = Constraints.IconsURL + message[5] + ".png"; IsImageD = true; }
        if ((message.length > 6) && (message[6] != "")) { IconE = Constraints.IconsURL + message[6] + ".png"; IsImageE = true; }
    }
    if (message && message[0] && message[0].includes("connect")) {
        //console.log("connect")
        ShowButton = true;
    }

    return (
        <div className="sidebar" style={isOpen ? { ...sidebarStyle, ...sidebarOpenStyle } : sidebarStyle}>
            <div className="content" style={contentStyle}>
                <>
                    <span style={{ marginRight: "5px" }}>{ValueA}</span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {IsImageA && <img src={IconA} alt="Descrição da imagem"
                            style={{ height: "25px", width: "25px", borderRadius: "4px", marginRight: "5px" }} />}
                        <span style={{ marginLeft: "0px", marginRight: "0px" }}>{ValueB}</span>
                        {IsImageB && <img src={IconB} alt="Descrição da imagem"
                            style={{ height: "25px", width: "25px", borderRadius: "4px", margin: "0px 5px" }} />}
                        {IsImageC && <img src={IconC} alt="Descrição da imagem"
                            style={{ height: "25px", width: "25px", borderRadius: "4px", margin: "0px 5px" }} />}
                        {IsImageD && <img src={IconD} alt="Descrição da imagem"
                            style={{ height: "25px", width: "25px", borderRadius: "4px", margin: "0px 5px" }} />}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {IsImageE && <img src={IconE} alt="Descrição da imagem"
                                style={{ height: "25px", width: "25px", borderRadius: "4px", margin: "0px 0px 0px 0px" }} />}
                            {ShowButton && <button onClick={onButtonClick} style={{ display: 'flex', alignItems: 'center' }}>
                                Connections Map
                                <img src={IterCon} alt="Descrição da imagem" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />
                            </button>}

                        </div>
                    </div>

                </>
            </div>

        </div>
    );
}

export default FeedBack;

