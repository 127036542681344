import React, { memo, useContext, useState, useEffect, componentDidUpdate } from 'react';
import { ResourceLabelAdjust } from './Functions'
import { Constraints, DictResourceEvent, ListaStandard, ListDocAWS } from './NodesData';
import { API, APIDBName, IconsURL } from "./Config.js"
import ContextBridge from './ContextBridge.js';


const dragHandleStyle = {
    display: 'inline-bBoxChecked',
    width: 25,
    height: 25,
    backgroundColor: 'teal',
    marginLeft: 5,
    borderRadius: '50%',
};



function GetLastIndexPartValue(Index) {
    var YY = Index.split('.');
    //console.log("YY", YY, YY.length);
    let ID = 1;
    YY = YY[YY.length - 1];
    ID = parseInt(YY);
    //console.log("ID", ID);
    return ID
}



const ListRequired = [true, false]
const ConfigNode = ({ data, hidden, type }) => {
    var [Remount, setRemount] = useState(true);
    //const [Resp, setResp] = useState(null);
    //const { setImportedData } = useContext(ContextBridge);
    // Efeito para atualizar o contexto quando o estado Resp mudar
    //useEffect(() => {
    //    setImportedData(Resp);
    //}, [Resp, setImportedData]);

    let IsNode = data.IsNode;

    const splitLabel = (label) => {
        if (!label) return ["", ""];
        const parts = label.split("|").map(part => part.trim());
        return [parts[0], parts[1] || ""];
    };

    const [selectedLabel, setSelectedLabel] = useState("");
    const [suffix, setSuffix] = useState("");

    useEffect(() => {
        const [newSelectedLabel, newSuffix] = splitLabel(data.label);
        setSelectedLabel(newSelectedLabel);
        setSuffix(newSuffix);
    }, [data]);

    const updateLabel = (newSelectedLabel, newSuffix, Options) => {
        //console.log("newSelectedLabel", newSelectedLabel, newSuffix)
        if (newSuffix.includes('|')) {
            alert("The character '|' is reserved and cannot be used.");
            return;
        }
        setSelectedLabel(newSelectedLabel);
        setSuffix(newSuffix);
        let newLabel = "";
        if (newSelectedLabel == "") {
            newLabel = newSuffix;
        } else {
            newLabel = `${newSelectedLabel} | ${newSuffix}`;
        }
        data.setLabel(newLabel);
    };
    const LabelPosition = data.LabelPosition;
    //console.log("LabelPosition", LabelPosition)
    const updateLabelPosition = (Position) => {
        //console.log("Nova pos", Position)
        data.LabelPosition = Position;
    };

    if (IsNode == "Edge") {//Configura edge
        let Edge = data.nodeAction;
        //console.log("eh uma edge", data.labelAttr.length, data.labelAttr, Edge);
        if (data.labelAttr.length < 2) {
            let Prefix = "";
            let Label = data.label;
            let PrefixLabel = "";
            if (data.labelAttr.length == 1) {
                //console.log("Prefix", Prefix, data.labelAttr, Label)
                Prefix = data.labelAttr[0].split("?")[0];
                try {
                    if (Label.includes("|")) { Label = Label.split("|")[1]; PrefixLabel = "(" + Prefix + ")"; }
                    Label = Label.trim();

                } catch (error) {
                    //pass
                }
                //if (Prefix.length !== 1) { Prefix = ""; } else { Prefix = Prefix[0] }
                //console.log("Prefix pos", Prefix, data.labelAttr, Label)
            }
            return (
                <>
                    <div className="react-flow__node-custom">
                        <div className="custom-node__header">
                            <h2 className="custom-drag-handle">Edge Label {PrefixLabel}</h2>
                            <input
                                type="text"
                                placeholder="Enter Label"
                                defaultValue={Label}
                                onChange={(e) => updateLabel(Prefix, e.target.value)}
                            />
                        </div>
                    </div>
                </>
            )
        } else {
            //console.log("Options", data.labelAttr);
            return (
                <div className="react-flow__node-custom">
                    <div className="custom-node__header">
                        <h2 className="custom-drag-handle">Edge Label</h2>
                        <select
                            id={"Select"}
                            value={selectedLabel}
                            onChange={(e) => updateLabel(e.target.value, suffix)}
                        >
                            {data.labelAttr.map((Option, Index) =>
                                <option key={Index} value={Option}>{Option}</option>
                            )}
                        </select>
                        <input
                            type="text"
                            placeholder="Enter Label (Optional)"
                            value={suffix}
                            onChange={(e) => updateLabel(selectedLabel, e.target.value)}
                        />
                    </div>
                </div>
            )
        }

    } else {
        return (
            <>
            </>
        )
    }


}
export { ConfigNode };