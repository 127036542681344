import React, { useState, useEffect, useRef } from 'react';
import { useModal } from './ModalMain';
import { APIPricing } from "./Config.js"
import { GEdges, GNodes, GlobalToken } from './NodesData';
import { APIExecTerraform } from "./Config.js";
import { FindRegionAndCloud, DiscoveryTerraformNetwork, FindBackendStorage, CallAPI, Save } from './Functions';

export const ShowCode = (CodeObj) => {
    let TFID = CodeObj.TFID;
    //console.log("Restart func")
    const { activeModal, closeModal } = useModal();
    const [fontSize, setFontSize] = useState('medium');
    const [codeDeploy, setCodeDeploy] = useState('View Deploy');
    const [Logs, setLogs] = useState(() => {
        return GNodes[TFID]?.data?.TerraformExecStatus || '';
    });
    const [isDeployingView, setisDeployingView] = useState(false);
    GNodes[TFID].data.DeployingStateMachine = GNodes[TFID]?.data?.DeployingStateMachine || 'Idle';
    GNodes[TFID].data.LastDeployWith = GNodes[TFID]?.data?.LastDeployWith || '';
    let LastDeployWith;
    if (GNodes[TFID].data.LastDeployWith === "") {
        LastDeployWith = "None";
    } else {
        LastDeployWith = GNodes[TFID].data.LastDeployWith;
    }
    const StateName = GNodes[TFID].data.Param[1][1];
    const [CloudID, RegionID, RG] = FindRegionAndCloud(GNodes, TFID);
    let RoleARN = GNodes[parseInt(CloudID)].data.Param[5][1];
    console.log("GNodes[TFID].data.DeployingStateMachine", GNodes[TFID].data.DeployingStateMachine, TFID)
    console.log("GNodes[TFID].data.LastDeployWith", GNodes[TFID].data.LastDeployWith);
    const eventCountRef = useRef(GNodes[TFID]?.data?.eventCount || 0); // UseRef para armazenar o contador de eventos
    const logsRef = useRef(null);
    const intervalId = useRef(GNodes[TFID]?.data?.intervalId || null); // Inicializa com o valor armazenado em GNodes
    const [ListStates, BackendID, Loop] = DiscoveryTerraformNetwork(GEdges, GNodes, parseInt(TFID));
    const [StorageID, DBID, RepoID, BuildID, BackendErrorMSG] = FindBackendStorage(GEdges, GNodes, BackendID);
    let BucketName;
    const HasBuild = BuildID !== 0 || BackendID === 0;
    let RemoteBackend = "CloudMan";
    if (BackendID !== 0) {
        RemoteBackend = "User";
        BucketName = GNodes[StorageID].data.Param[1][1];
    }
    const HasCommit = HasBuild && RemoteBackend == "User";
    const HasBuildCloudMan = HasBuild && RemoteBackend == "CloudMan";
    const intervalo = 10000; // Intervalo definido como 10 segundos
    // Primeiro useEffect para lidar com a rolagem inicial ou quando estiver no final
    const [firstScroll, setFirstScroll] = useState(true);

    useEffect(() => {
        if (activeModal !== 'modalShowCode' || !logsRef.current) return;
        setTimeout(() => {
            const { scrollTop, scrollHeight, clientHeight } = logsRef.current;
            const margin = clientHeight; // Usando a altura do contêiner como margem
            const isAtBottom = (scrollHeight - scrollTop - clientHeight <= margin);
            console.log("isAtBottom", isAtBottom);
            console.log("firstScroll", firstScroll);
            // Verifica se é a primeira vez que a scrollHeight excede a clientHeight
            if (scrollHeight > clientHeight && firstScroll) {
                logsRef.current.scrollTop = scrollHeight;
                setFirstScroll(false); // Atualiza o estado para indicar que a primeira rolagem já ocorreu
            } else if (isAtBottom) {
                logsRef.current.scrollTop = scrollHeight;
            }
        }, 20); // Delay de 20ms
    }, [Logs, firstScroll]);

    const StartTimer = () => {
        if (intervalId.current) {
            console.log("O timer já está ativo.");
            return;
        }
        intervalId.current = setInterval(() => {
            console.log("Evento disparado!");
            eventCountRef.current += 1; // Incrementa o contador a cada evento
            GNodes[TFID].data.eventCount = eventCountRef.current; // Salva o contador no GNodes
            Command("UpdateStatus");
        }, intervalo);
        GNodes[TFID].data.intervalId = intervalId.current; // Salva o ID do intervalo no GNodes
        console.log("Timer iniciado.");
    };

    const StopTimer = () => {
        if (!intervalId.current) {
            console.log("Nenhum timer está ativo.");
            return;
        }
        clearInterval(intervalId.current);
        intervalId.current = null;
        GNodes[TFID].data.intervalId = null; // Limpa o ID do intervalo no GNodes
        console.log("Timer parado.");
    };

    // Reconfigura e inicia o temporizador quando o componente é montado
    useEffect(() => {
        if (activeModal === 'modalShowCode') {
            // Start the timer only if it was previously running
            if (GNodes[TFID]?.data?.intervalId) {
                StartTimer();
            }
        }
    }, [activeModal]);

    useEffect(() => {
        // Função de limpeza para garantir que o timer seja limpo corretamente quando o componente for desmontado
        return () => {
            if (intervalId.current) {
                clearInterval(intervalId.current);
                GNodes[TFID].data.intervalId = null;
            }
        };
    }, [TFID]);

    const Code = CodeObj.Code;
    let FlagOK = true;
    const fontSizes = {
        small: '12px',
        medium: '16px',
        large: '20px'
    };

    useEffect(() => {
        if (activeModal !== 'modalShowCode') return;
    }, [activeModal]);

    const indentCode = (codeString) => {
        const lines = codeString.split('\n');
        let indentCount = 0;
        const INDENT_SIZE = 3;
        let result = [];
        lines.forEach(line => {
            if (line.trim().startsWith('}')) {
                indentCount--;
                if (indentCount < 0) { indentCount = 0; }
            }
            if ((line.trim().startsWith('resource')) || (line.trim().startsWith('data')) || (line.trim().startsWith('output'))) {
                indentCount = 0;
            }
            const currentIndentation = ' '.repeat(indentCount * INDENT_SIZE);
            let indentedLine = currentIndentation + line;
            indentedLine = indentedLine.replace(/([^ $]){/g, '$1 {');
            result.push(indentedLine);
            if (line.trim().endsWith('{')) {
                indentCount++;
            }
            if (line.startsWith('}') && indentCount === 0) {
                result.push('');
            }
        });
        return result.join('\n');
    };

    const normalizeEqualsSign = (str) => {
        if (!str) return '';
        let result = str.replace(/(?<!\||\!|\=|\<) *=(?!\>|=)/g, ' = ');
        result = result.replace(/\|/g, '');
        return result;
    };

    const formattedCode = indentCode(normalizeEqualsSign(Code));

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(formattedCode)
            .then(() => {
                console.log("Texto copiado com sucesso!");
            })
            .catch(err => {
                console.error("Erro ao copiar o texto: ", err);
            });
    };

    const Command = async (CommandTerraform) => {
        if (RemoteBackend == "User") {
            handleCommit();
            console.log("User Backend", BucketName, StateName)
            const ProjectName = GNodes[BuildID].data.Param[1][1];
            console.log("ProjectName", ProjectName)
            const [CloudID, RegionID, RG] = FindRegionAndCloud(GNodes, BuildID);
            const RegionName = GNodes[parseInt(RegionID)].data.Param[2][2];
            const Account = GNodes[parseInt(CloudID)].data.Param[3][1];
            const raw = [4, CommandTerraform, RoleARN, StateName, "user_id", ProjectName, RegionName]
            const Resp = await CallAPI(APIPricing, raw, true)
            console.log("Resp", Resp)
            const bodyJson = JSON.parse(Resp.body);
            const CodeBuildID = bodyJson.build_id;
            console.log("CodeBuildID", CodeBuildID)
            GNodes[TFID].data.DeployingStateMachine = "Idle";
            const URL = `https://${RegionName}.console.aws.amazon.com/codesuite/codebuild/${Account}/projects/${ProjectName}/build/${CodeBuildID}/log?${RegionName}`
            window.open(URL, CodeBuildID);
        } else {
            let Code = CodeObj.Code;
            if (Code === "") {
                console.log("Code vazio")
            } else {
                let UserID = "Teste"
                let Type = 1;
                console.log("CommandTerraform", CommandTerraform)
                console.log("eventCount", eventCountRef.current) // Usando eventCountRef.current
                if (CommandTerraform === "UpdateStatus") {
                    Type = 2;
                    Code = "";
                    RoleARN = "";
                    if (eventCountRef.current < 4) {
                        setLogs(prevLogs => prevLogs + ".");
                    }
                } else {
                    setFirstScroll(true);
                    StartTimer();
                    console.log("start Timer")
                    let LogsStart = "Starting " + CommandTerraform + " ...";
                    setLogs(LogsStart);
                    eventCountRef.current = 0; // Reseta o contador quando um novo comando começa
                    GNodes[TFID].data.eventCount = 0; // Reseta o contador no GNodes
                }
                if (eventCountRef.current >= 4 || CommandTerraform !== "UpdateStatus") {
                    console.log("Logs", Logs)
                    var raw = JSON.stringify([Type, CommandTerraform, UserID, StateName, Code, RoleARN]);
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
                    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
                    console.log("CommandTerraform B", CommandTerraform, raw)
                    fetch(APIExecTerraform, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            let Resp = JSON.parse(result).body;
                            console.log("Resp X", Resp);
                            console.log("CommandTerraform C", CommandTerraform)
                            var NewLogs = "";
                            if (CommandTerraform === "UpdateStatus") {
                                for (let j = 0; j < Resp.length; j++) {
                                    let NewLog = Resp[j]["Data"];
                                    NewLogs += NewLog
                                    let End = Resp[j]["End"]
                                    if (End === "T") {
                                        GNodes[TFID].data.DeployingStateMachine = "Idle";
                                        console.log("End")
                                        setFirstScroll(true)
                                        StopTimer();
                                    }
                                }
                                console.log(NewLogs)
                                console.log("Logs pre", Logs)
                                setLogs((prevLogs) => {
                                    const updatedLogs = prevLogs + NewLogs;
                                    GNodes[TFID].data.TerraformExecStatus = updatedLogs; // Salva o estado atualizado
                                    console.log("Logs pos", updatedLogs);
                                    return updatedLogs;
                                });
                            } else {
                                console.log("Resp Start", Resp);
                                // setLogs(Resp);  // Armazena os logs recebidos no estado
                            }
                        })
                        .catch(error => {
                            console.log('error', error);
                        });
                }
            }
        }
    };

    const handlePlan = async () => {
        let CommandTerraform = "plan";
        console.log("Remote Backend ", RemoteBackend, GNodes[TFID].data.LastDeployWit)
        if (GNodes[TFID].data.LastDeployWith === "" || GNodes[TFID].data.LastDeployWith === RemoteBackend) {
            if (GNodes[TFID].data.DeployingStateMachine === "Idle") {
                GNodes[TFID].data.DeployingStateMachine = "Deploying";
                if (RemoteBackend === "CloudMan") {
                    setisDeployingView(true);
                }
                const raw = [3, Code, RoleARN, BucketName, StateName]
                const Resp = await CallAPI(APIPricing, raw)
                await Command(CommandTerraform);
            }
        } else {
            console.log("Troca de backend")
        }
    };

    const handleApply = async () => {
        let CommandTerraform = "apply";
        if (GNodes[TFID].data.LastDeployWith === "" || GNodes[TFID].data.LastDeployWith === RemoteBackend) {
            if (GNodes[TFID].data.DeployingStateMachine === "Idle") {
                let Confirm = window.confirm("Confirm Apply using ", RemoteBackend, "?");
                if (Confirm) {
                    GNodes[TFID].data.DeployingStateMachine = "Deploying";
                    if (RemoteBackend === "CloudMan") {
                        setisDeployingView(true);
                    }
                    await Command(CommandTerraform);
                    GNodes[TFID].data.LastDeployWith = RemoteBackend;
                    Save(GEdges, GNodes);
                }
            }
        } else {
            console.log("Troca de backend")
        }
    };

    const handleDestroy = async () => {
        let CommandTerraform = "destroy";
        if (GNodes[TFID].data.LastDeployWith === "" || GNodes[TFID].data.LastDeployWith === RemoteBackend) {
            if (GNodes[TFID].data.DeployingStateMachine === "Idle") {
                let Confirm = window.confirm("Confirm Destroy using ", RemoteBackend, "?");
                if (Confirm) {
                    GNodes[TFID].data.DeployingStateMachine = "Deploying";
                    if (RemoteBackend === "CloudMan") {
                        setisDeployingView(true);
                    }
                    await Command(CommandTerraform);
                    GNodes[TFID].data.LastDeployWith = "";
                    Save(GEdges, GNodes);
                }
            }
        } else {
            console.log("Troca de backend")
        }
    };

    const handleCommit = async () => {
        const Code = GNodes[TFID].data.CodeGenerated;
        console.log("BucketName", BucketName, StateName, Code)
        const raw = [3, Code, RoleARN, BucketName, StateName]
        const Resp = await CallAPI(APIPricing, raw)
    };

    const handleUpDateStatus = () => {
        Command("UpdateStatus");
    };

    const handleCodeDeploy = () => {
        if (codeDeploy === "View Deploy") {
            setCodeDeploy('View Code');
            setisDeployingView(true);
        } else {
            setCodeDeploy('View Deploy');
            setisDeployingView(false);
        }
    };

    const processLine = (line) => {
        const regex = /(".*?"|\$\{.*?\}|[{ }[\]=()]|[^\s]+|[\s]+)/g;
        const segments = line.match(regex) || [];
        return segments;
    };

    const LightBlue = '#88FFFF';
    const LightGreen = '#32FF32';
    if (!FlagOK) return null;

    return (
        <div
            onDragStart={(e) => e.preventDefault()}
            style={{
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', background: '#f4f4f4', padding: '6px' }}>
                <span>Current Backend: {RemoteBackend} <br></br> Last Deployment Backend: {LastDeployWith}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', background: '#f4f4f4', padding: '6px' }}>

                <select
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                >
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                </select>
                <button onClick={handleCopyToClipboard} style={{ marginLeft: '1em' }}>Copy</button>
                {HasCommit && <button onClick={handleCommit} style={{ marginLeft: '1em' }}>Commit</button>}
                {HasBuild && (
                    <>
                        <button onClick={handlePlan} style={{ marginLeft: '1em' }}>Plan</button>
                        <button onClick={handleApply} style={{ marginLeft: '1em' }}>Apply</button>
                        <button onClick={handleDestroy} style={{ marginLeft: '1em' }}>Destroy</button>
                    </>
                )}
                {HasBuildCloudMan && (
                    <>
                        <button onClick={handleCodeDeploy} style={{ marginLeft: '1em' }}>{codeDeploy}</button>
                        <button onClick={handleUpDateStatus} style={{ marginLeft: '1em' }}>Update</button>
                    </>
                )}
                {/*<button onClick={handleAdd5L} style={{ marginLeft: '1em' }}>Add5L</button>
                <button onClick={AddPage} style={{ marginLeft: '1em' }}>AddPage</button>*/}
            </div>
            {
                isDeployingView ? (
                    <pre ref={logsRef} style={{
                        whiteSpace: 'pre',
                        color: LightBlue,
                        fontSize: fontSizes[fontSize],
                        flex: 1,
                        overflowY: 'auto'
                    }}>
                        {Logs.split('\n').map((line, index) => (
                            <div key={index} style={{ color: LightBlue, marginTop: '2px', marginBottom: '2px' }}>
                                <span style={{ color: 'gray', marginRight: '1em' }}>{index + 1}</span>
                                {line}
                            </div>
                        ))}
                    </pre>
                ) : (
                    <pre style={{
                        whiteSpace: 'pre',
                        color: LightBlue,
                        fontSize: fontSizes[fontSize],
                        flex: 1,
                        overflowY: 'auto'
                    }}>
                        {formattedCode.split('\n').map((line, index) => (
                            <div key={index} style={{ color: LightBlue, marginTop: '2px', marginBottom: '2px' }}>
                                <span style={{ color: 'gray', marginRight: '1em' }}>{index + 1}</span>
                                {processLine(line).map((segment, i) => {
                                    if (['{', '}', '[', ']', '(', ')'].includes(segment)) {
                                        return <span key={i} style={{ color: 'yellow' }}>{segment}</span>;
                                    } else if (segment === '=') {
                                        return <span key={i} style={{ color: 'white' }}>{segment}</span>;
                                    } else if (segment.startsWith('"') && segment.endsWith('"')) {
                                        return segment.split(/(\$\{.*?\})/).map((subSegment, j) => {
                                            if (subSegment.startsWith('${') && subSegment.endsWith('}')) {
                                                return <span key={`${i}-${j}`} style={{ color: LightBlue }}>{subSegment}</span>;
                                            } else {
                                                return <span key={`${i}-${j}`} style={{ color: 'orange' }}>{subSegment}</span>;
                                            }
                                        });
                                    } else if ((segment.trim() === 'provider' || segment.trim() === 'terraform' || segment.trim() === 'resource'
                                        || segment.trim() === 'data' || segment.trim() === 'required_providers' || segment.trim() === 'output')
                                        && /^[ ]*$/.test(line.substring(0, line.indexOf(segment))) && !line.substring(line.indexOf(segment) + segment.length).trim().startsWith('=')) {
                                        return <span key={i} style={{ color: LightGreen }}>{segment}</span>;
                                    } else if (segment.trim() === 'false' || segment.trim() === 'true') {
                                        return <span key={i} style={{ color: "#D8BFD8" }}>{segment}</span>;
                                    } else {
                                        return segment;
                                    }
                                })}
                            </div>
                        ))}
                    </pre>
                )
            }
        </div >
    );
}
