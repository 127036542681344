import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const CFDistribution = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFDistribution.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const CFOrigin = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFOrigin.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const CFCBehavior = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFCBehavior.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


const CFLambda = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFLambda.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


const CFFunctions = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFFunctions.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


const CFFunctionsA = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFFunctionsA.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const CFPublicKey = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFPublicKey.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );

}; const CFCachePolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFCachePolicy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );

}; const CFCER = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CFCER.png")';
    let LabelName = data.Param[3][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { CFDistribution, CFOrigin, CFCBehavior, CFLambda, CFFunctionsA, CFFunctions, CFPublicKey, CFCachePolicy, CFCER };

