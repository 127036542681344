import ErrorBoundary from './ErrorBoundary';  // Importe o componente ErrorBoundary
import 'reactflow/dist/style.css';
import { useState, useCallback, useEffect, useRef, useStore } from 'react';
import ReactFlow, {
  Background, applyNodeChanges, applyEdgeChanges, MiniMap, addEdge, ReactFlowProvider, MarkerType,
  ConnectionMode, useViewport,
} from 'reactflow';

import { HotKeys } from 'react-hotkeys';
import { useHotkeys } from 'react-hotkeys-hook';
import './App.css';
import './index.css';
import './VPC.css';
import { Lambda, LambdaLayer, LambdaESM, LambdaCSC, LambdaAlias, LambdaURL } from './AWS/Lambda';
import './styles.css'
import FeedBack from './FeedBack.js';
import SideBarNovo from './SideBarNovo.js';
import { ImportTerraform } from './ImportTerraform.js';
import SelectMenu from './RedBox.js';
import {
  GetFatherPosition, SearchNameNodeType, FindFiliationLevel, FindNameIDRouting, SearchNodesSource, SearchNodesTarget,
  FindNodesEdgesChieldSelected, FindNodesChieldID, FindRegionAndCloud, openConsole, FindIPv6BlockIndex, compareVersions,
  FindSelectedNodes, nextIPCIDR, GetVPCParent, FindNodesEdgesChield, FindResourceFather, AdjustSubnetCIDRAfterPaste,
  FindNodesChieldIDFromParent, GetCIDRList, BoxRestrictions, Save, findEdgeLabel, checkAndRefreshToken,
  AdjustSufixName, reorderNodesAndEdges, findIntersectingNodesBox, UpdateDataNodes,
  AdjustFatherPosition, ValidateArn, RemoveSelected, UpdateMetrics, checkNodeVisibility,
  IsEC2AutoScale, ListTerraformDomain, GetNodeId, UpDateGlobalNodeID, Compress, Descompact, mirrorNodes,
} from './Functions';
//import { Code } from './Code'; 
import { Cloud, Tag, Terraform, Null, Teste, AWSARN, Host, TerraformBackendS3, TerraformBackendFull, CanonicalID } from './AWS/Cloud';
import { Region, ListRegions } from './AWS/Region';
import { VPC, AZ, SBox, VPCPeeringC, VPCFlowLogs } from './AWS/VPC';
import { Subnet, NACLERule, NACLIRule, NACL } from './AWS/Subnet';
import { DynamoDB, DTReplica, DynamoDBItem } from './AWS/DynamoDB';
import { EBS, EBSA, EBD, LTBlockDeviceMappings } from './AWS/EBS';
import { EFS, EFSPolicy, EFSRC, EFSAP } from './AWS/EFS';
import {
  S3, S3ACL, S3Grant, S3CORSRule, S3T, S3LCR, S3Logging, S3OLC, S3RC, S3WebC, S3WebCRRule, S3RCR, S3NotFilter, S3Object,
  BucketPublicAccessBlock, S3OwnershipControls
} from './AWS/S3';
import { CFDistribution, CFOrigin, CFCBehavior, CFLambda, CFFunctions, CFFunctionsA, CFPublicKey, CFCachePolicy, CFCER } from './AWS/CloudFront';
import { SecurityGroup, SGERule, SGIRule } from './AWS/SecurityGroup';
import { AMI, AMIDS, AMIFromInst, AMICopy, EC2, LaunchTemplate, UserData, KeyPair, ENI, EIP, EIPA, PlacementGroup } from './AWS/EC2';
import { ECR, ECRP, ECSCluster, ECSRegistry, ECSService, Fargate, ECSCProvider, ECSFCProvider, ECSDockerVolume, ECSContainerDef } from './AWS/ECS';
import { EKSNodeGroup, EKSCluster, EKSfargateProfile, EKSAddon } from './AWS/EKS';
import { IGW, RT, NatGateway, EgressOnlyIG, VPCEndpointG, VPCEndpointI } from './AWS/IGW';
import { ASG, ASPolicy, ASLHook, AppASPolicy } from './AWS/AutoScaling';
import { KMSKey, KMSKeyD } from './AWS/KMS';
import { SSMParameter, EC2Connect } from './AWS/SSM';
import { RDS, RDSReplica, MariaDB, MySQL, PostGres, SQLServer, Oracle, RDSFinalSnapShot, RDSSnapShot, RDSSnapShotCopy } from './AWS/RDS';
import { ACM, ACMCValidation } from './AWS/ACM';
import { CWLogGroup, CWMAlarm, CWMQuery, XRay } from './AWS/CloudWatch';
import { Cognito, CogUPool, CogUPoolClient, CogUPoolDomain, CogSchema } from './AWS/Cognito';
import { SQS, SQSDLQ } from './AWS/SQS';
import { EBRule, EBTarget, EBBus } from './AWS/EventBridge';
import { StepFuncSM } from './AWS/StepFunc.js';
import { SNS, SNSSubscription, SNSFeedback, SNSPolicy, SNSDLT, SNSSMSPref } from './AWS/SNS';
import { APIGV2API, APIGV2Integration, APIGV2IntResp, APIGV2Route, APIGV2RouteResp, APIGV2Authorizer, APIGV2DomainName } from './AWS/APIGatewayV2';
import {
  APIGRest, APIGRestLambda, APIGMethod, APIGResource, APIGIntegration, APIGMethodResp, APIGIntegrationResp,
  APIGStage, APIGDeployment, APIGUsagePlan, APIGMSettings, APIGAuthorizer, APIGDomainName, APIGAccount,
} from './AWS/APIGateway';
import { IAMRole, IAMPolicy, IAMRolePolicy, IAMUser } from './AWS/IAM';
import { ALoadBalancer, LBTG, ALBListener, ALBAction, ALBCondition } from './AWS/LoadBalancer';
import { R53HCheck, R53Record, R53Zone, R53Routing, SubDomain } from './AWS/Route53';
import NodeText from './General/Text/NodeText/Text.js';
import { Graph } from "./General/Graph.js";
import { Cost } from "./General/Cost.js";
import { Info } from "./General/Info.js";
import { DockerHub, GitHub, Copy } from "./General/General.js";
import { Textract } from "./AWS/MachineLearning.js";
import { CloudMap, SDiscoveryService, SDiscoveryInstance } from "./AWS/CloudMap.js";
import { SecretsmanagerSVersion, SecretsmanagerSecret } from "./AWS/SecretsManager.js";
import { ConfigNode } from './ConfigNode';
import ContextBridge from "./ContextBridge.js";
import { ListaStandard, NodesData } from './NodesData';
import {
  CodeCommit, CodeDeployGroup, CodeBuild, CodePipeline, CodeBuildSource, CodeBuildArtifact, CodeBuildNotification, CodePipelineAction,
  CodePipelineStage
} from './AWS/DevTools';
import { IconsURL, TemplatesURL, APIDB, APIPricing, Stage } from './Config';
import Modal from './Modal';
import { ModalProvider } from './ModalMain';
import ModalMain from './ModalMain';
import FloatingEdge from './FloatingEdge';
import { AZCloud } from './Azure/AZCloud';
import { AZRegion, AZResourceGroup } from './Azure/AZRegion';
import { AZVNET, AZAZ, AZSBox, AZPublicIP } from './Azure/AZVNET';
import { AZSubnet, AZSecurityGroup, AZSGIRule, AZSGERule } from './Azure/AZSubnet';
import { AZKCluster, AZKNodePool } from './Azure/AZContainers';
import { AZmanagedDisk } from './Azure/AZStorage';
import { AZLinuxVM, AZNI } from './Azure/AZVM';
import { AZRT, AZInternet, AZNATGateway } from './Azure/AZGateway';
import { AZLinuxVMScaleSet } from './Azure/AZAutoScaling';
import { AZAppGateway, AZAppGListener, AZAppGRule, AZAppGBackend, AZAppGProbe, AZAppGPathRule } from './Azure/AZLoadBalancer.js';
import {
  KCluster, KNamespace, KPod, KDeployment, KPodAutoscaler, KLivenessProbe, KDNSConfig, KAffinit, KPodVolume, KSA,
  KIngress, KIngressPath, KIngressClass, KConfigMap, KSecret, KQuota, KServiceNodePort, KRole, KTaint, KContainer, KPVolume, KPVolumeC,
} from './Kubernets/Kubernets';

//import { SketchPad } from 'react-sketchpad';
//Global Variables
const zIndexPlan = { Cloud: 1, Account: 2, Region: 3, VPC: 4, SubNet: 5, SecurityGroup: 6, Items: 7, Config: 50 }
const connectionLineStyle = { stroke: '#AAA' };
const GetEdgeId = () => `${GlobalEdgeID++}`;
var GlobalSelectedList = new Set();
var GlobalListNodesEdgesCopy = [];
var TemporaryNodeList = [];
var TerraformNodesList = [];
var GlobalLastVCPCIDR = "10.0.0.0/16";
var GlobalLastSubnetCIDR = "10.0.1.0/24"
var GlobalAZQtity = 0;
var GlobalAZID = [0, 0, 0, 0, 0, 0, 0, 0];
var GlobalAZName = [0, 0, 0, 0, 0, 0, 0, 0];
var GlovalSelectMenuHidden = true;
var GlobalMousePosX = 0;
var GlobalMousePosY = 0;
var GlobalNodes = [];
var OldGlobalNodes = [];
var GlobalStatus = [];
var GlobalShowGraph;
var GlobalShowGraphFirstTime = true;
var GlobalCost = [];//[['23445.1', "37"], ["45987.22", "6"]];
var oldlen;
var len;
var GlobalEdges = [];
var GlobalHideEdges = false;
var GlobalShowLabel = 0;
var Counter = 0;
var GlobalCtrlPressed = false;
var FeedBackStatus = false;
var GlobalStack = [];
var GlobalNodeFeedBack = "";
var MaxStackSize = 100;
var modalNodeType = "";
for (let i = 0; i < MaxStackSize.length; i++) {
  GlobalStack.push([]);
}
var GlobalUnDoPointer = -1;
var GlobalRedoPointer = 0;
var GlobalCountStack = 0;
var GlobalSelectedTerraform = 0;
var GlobalNodeModal = '';
var GlobalEdgeOpacity = 1;
var GlobalEdgeDisplay = "block";
var GlobalEdgeBackgroundColor = "white";
var GlobalEdgeCICDOpacity = 1;
var GlobalEdgeCICDDisplay = "block";
var GlobalEdgeCICDBackgroundColor = "white";
let CICDType = ["TerraformN", "TerraformBackendS3N", "CodePipelineN", "CodePipelineStageN", "CopyN", "CodePipelineActionN"];
let transform;
const nodeTypes = {
  CloudN: Cloud, IGWN: IGW, RTN: RT, SGERuleN: SGERule, SGIRuleN: SGIRule, LambdaAliasN: LambdaAlias, EFSAPN: EFSAP, XRayN: XRay,
  VPCN: VPC, SubnetN: Subnet, ConfigN: ConfigNode, RegionN: Region, EC2N: EC2, AZN: AZ, AMIN: AMI, AMIDSN: AMIDS, ASGN: ASG,
  LaunchTemplateN: LaunchTemplate, ALoadBalancerN: ALoadBalancer, ASPolicyN: ASPolicy, LBTGN: LBTG, APIGMSettingsN: APIGMSettings,
  ALBListenerN: ALBListener, UserDataN: UserData, SecurityGroupN: SecurityGroup, TagN: Tag, HostN: Host, ALBActionN: ALBAction,
  TerraformN: Terraform, KeyPairN: KeyPair, NullN: Null, NatGatewayN: NatGateway, EgressOnlyIGN: EgressOnlyIG, ALBConditionN: ALBCondition,
  ENIN: ENI, SelectMenuN: SelectMenu, EBSN: EBS, EBSAN: EBSA, EBDN: EBD, EFSN: EFS, EFSPolicyN: EFSPolicy, APIGUsagePlanN: APIGUsagePlan,
  EFSRCN: EFSRC, S3N: S3, S3GrantN: S3Grant, S3CORSRuleN: S3CORSRule, S3TN: S3T, S3LCRN: S3LCR, S3LoggingN: S3Logging, S3ACLN: S3ACL,
  S3OLCN: S3OLC, CFDistributionN: CFDistribution, CFOriginN: CFOrigin, CFCBehaviorN: CFCBehavior, CFLambdaN: CFLambda, LambdaURLN: LambdaURL,
  CFFunctionsN: CFFunctions, CFFunctionsAN: CFFunctionsA, S3RCN: S3RC, KMSKeyN: KMSKey, KMSKeyDN: KMSKeyD, S3WebCN: S3WebC, SBoxN: SBox,
  TesteN: Teste, S3RCRN: S3RCR, DynamoDBN: DynamoDB, DTReplicaN: DTReplica, IAMRoleN: IAMRole, IAMPolicyN: IAMPolicy, VPCPeeringCN: VPCPeeringC,
  AWSARNN: AWSARN, IAMRolePolicyN: IAMRolePolicy, LambdaN: Lambda, LambdaLayerN: LambdaLayer, APIGRestN: APIGRest, APIGDomainNameN: APIGDomainName,
  APIGRestLambdaN: APIGRestLambda, APIGMethodN: APIGMethod, APIGResourceN: APIGResource, APIGIntegrationN: APIGIntegration,
  RDSN: RDS, RDSReplicaN: RDSReplica, APIGMethodRespN: APIGMethodResp, APIGIntegrationRespN: APIGIntegrationResp, ACMCValidationN: ACMCValidation,
  APIGStageN: APIGStage, APIGDeploymentN: APIGDeployment, ACMN: ACM, CWLogGroupN: CWLogGroup, S3NotFilterN: S3NotFilter, EC2ConnectN: EC2Connect,
  LambdaCSCN: LambdaCSC, SNSN: SNS, SNSSubscriptionN: SNSSubscription, SNSFeedbackN: SNSFeedback, SNSPolicyN: SNSPolicy, SQSN: SQS,
  LambdaESMN: LambdaESM, EBRuleN: EBRule, EBTargetN: EBTarget, EBBusN: EBBus, SQSDLQN: SQSDLQ, SNSDLTN: SNSDLT, R53RoutingN: R53Routing,
  CFPublicKeyN: CFPublicKey, CFCachePolicyN: CFCachePolicy, CFCERN: CFCER, S3WebCRRuleN: S3WebCRRule, EIPN: EIP, EIPAN: EIPA,
  NACLERuleN: NACLERule, NACLIRuleN: NACLIRule, NACLN: NACL, R53HCheckN: R53HCheck, R53RecordN: R53Record, R53ZoneN: R53Zone,
  CognitoN: Cognito, CogUPoolN: CogUPool, CogUPoolClientN: CogUPoolClient, CogSchemaN: CogSchema, APIGAuthorizerN: APIGAuthorizer,
  TerraformBackendS3N: TerraformBackendS3, ECRN: ECR, ECRPN: ECRP, ECSClusterN: ECSCluster, ECSRegistryN: ECSRegistry, ECSServiceN: ECSService,
  VPCEndpointGN: VPCEndpointG, VPCEndpointIN: VPCEndpointI, SNSSMSPrefN: SNSSMSPref, SSMParameterN: SSMParameter, GraphN: Graph,
  TerraformBackendFullN: TerraformBackendFull, PlacementGroupN: PlacementGroup, LTBlockDeviceMappingsN: LTBlockDeviceMappings,
  CogUPoolDomainN: CogUPoolDomain, CWMAlarmN: CWMAlarm, FargateN: Fargate, ECSCProviderN: ECSCProvider, ECSDockerVolumeN: ECSDockerVolume,
  CodeDeployGroupN: CodeDeployGroup, ECSContainerDefN: ECSContainerDef, SubDomainN: SubDomain, ASLHookN: ASLHook, AppASPolicyN: AppASPolicy,
  CanonicalIDN: CanonicalID, TextN: NodeText, APIGV2APIN: APIGV2API, APIGV2IntegrationN: APIGV2Integration, DynamoDBItemN: DynamoDBItem,
  CWMQueryN: CWMQuery, APIGV2IntRespN: APIGV2IntResp, APIGV2RouteN: APIGV2Route, APIGV2RouteRespN: APIGV2RouteResp, APIGV2AuthorizerN: APIGV2Authorizer,
  APIGV2DomainNameN: APIGV2DomainName, CodeBuildN: CodeBuild, CodePipelineN: CodePipeline, CodeBuildSourceN: CodeBuildSource,
  CodeBuildArtifactN: CodeBuildArtifact, CodeCommitN: CodeCommit, SecretsmanagerSVersionN: SecretsmanagerSVersion, APIGAccountN: APIGAccount,
  SecretsmanagerSecretN: SecretsmanagerSecret, CodeBuildNotificationN: CodeBuildNotification, CostN: Cost, TextractN: Textract,
  S3ObjectN: S3Object, AMIFromInstN: AMIFromInst, AMICopyN: AMICopy, MariaDBN: MariaDB, MySQLN: MySQL, PostGresN: PostGres, SQLServerN: SQLServer,
  OracleN: Oracle, InfoN: Info, BucketPublicAccessBlockN: BucketPublicAccessBlock, S3OwnershipControlsN: S3OwnershipControls, IAMUserN: IAMUser,
  DockerHubN: DockerHub, CloudMapN: CloudMap, SDiscoveryServiceN: SDiscoveryService, SDiscoveryInstanceN: SDiscoveryInstance,
  VPCFlowLogsN: VPCFlowLogs, RDSFinalSnapShotN: RDSFinalSnapShot, RDSSnapShotN: RDSSnapShot, RDSSnapShotCopyN: RDSSnapShotCopy,
  EKSNodeGroupN: EKSNodeGroup, EKSClusterN: EKSCluster, EKSfargateProfileN: EKSfargateProfile, EKSAddonN: EKSAddon, ECSFCProviderN: ECSFCProvider,
  StepFuncSMN: StepFuncSM, GitHubN: GitHub, CopyN: Copy,
  AZCloudN: AZCloud, AZRegionN: AZRegion, AZResourceGroupN: AZResourceGroup,
  AZVNETN: AZVNET, AZAZN: AZAZ, AZSBoxZ: AZSBox, AZSubnetN: AZSubnet, AZLinuxVMN: AZLinuxVM, AZRTN: AZRT, AZInternetN: AZInternet,
  AZNIN: AZNI, AZmanagedDiskN: AZmanagedDisk, AZSecurityGroupN: AZSecurityGroup, AZPublicIPN: AZPublicIP, AZSGIRuleN: AZSGIRule,
  AZSGERuleN: AZSGERule, AZLinuxVMScaleSetN: AZLinuxVMScaleSet, AZAppGatewayN: AZAppGateway, AZAppGListenerN: AZAppGListener,
  AZAppGRuleN: AZAppGRule, AZAppGBackendN: AZAppGBackend, AZNATGatewayN: AZNATGateway, AZAppGProbeN: AZAppGProbe, AZKClusterN: AZKCluster,
  AZKNodePoolN: AZKNodePool, AZAppGPathRuleN: AZAppGPathRule, CodePipelineActionN: CodePipelineAction, CodePipelineStageN: CodePipelineStage,

  KClusterN: KCluster, KNamespaceN: KNamespace, KPodN: KPod, KDeploymentN: KDeployment, KPodAutoscalerN: KPodAutoscaler,
  KLivenessProbeN: KLivenessProbe, KDNSConfigN: KDNSConfig, KAffinitN: KAffinit, KPodVolumeN: KPodVolume, KSAN: KSA,
  KIngressN: KIngress, KIngressPathN: KIngressPath, KIngressClassN: KIngressClass, KConfigMapN: KConfigMap,
  KSecretN: KSecret, KQuotaN: KQuota, KServiceNodePortN: KServiceNodePort, KRoleN: KRole, KTaintN: KTaint, KContainerN: KContainer, KPVolumeN: KPVolume,
  KPVolumeCN: KPVolumeC,
};
const edgeTypes = {
  FloatingEdgeE: FloatingEdge,
};
//  SelectEdgeE: SelectEdge,};
var initialNodes = [];
var initialEdges = [];
var GlobalEdgeID = 0;
var FirstLoop = true;
var CountGeneral = 0;
let NewNodes = [];
let NewEdges = [];

if (Stage === "DevLocal") {
  let ReloadNodes = sessionStorage.getItem("ReloadNodes") || "[]";
  if (ReloadNodes === undefined) {
    ReloadNodes = "[]"; // Garante que ReloadNodes seja uma string JSON válida
  }
  console.log("ReloadNodes", ReloadNodes)
  const NewNodesRead = JSON.parse(ReloadNodes); // Converte a string para um array real
  console.log("NewNodesRead", NewNodesRead);

  let ImportError = false;
  for (let i = 0; i < NewNodesRead.length; i++) { //verificação se parent id existe (erro se não existir)
    if (parseInt(NewNodesRead[i].parentNode) > (NewNodesRead.length - 1)) {
      if (Stage == "DevLocal" && ImportError == false) { alert("Erro na importação!"); ImportError = true; }
    } else {
      //NewNodesRead[i].id = (i - 1).toString();
      NewNodes.push(NewNodesRead[i]);
    }
  }
  NewEdges = JSON.parse(sessionStorage.getItem("ReloadEdges") || "[]");
  console.log("ReloadNodes", NewNodes, NewEdges);
}

let TemplateSaved = sessionStorage.getItem("TemplateSaved");
console.log("TemplateSaved", TemplateSaved);
if (TemplateSaved !== undefined && TemplateSaved !== null && TemplateSaved !== "") {
  TemplateSaved = JSON.parse(TemplateSaved);
  //console.log("TemplateSaved B", TemplateSaved);
  NewNodes = TemplateSaved[1];
  NewEdges = TemplateSaved[0];
  sessionStorage.setItem('TemplateSaved', "");
  var FileNameInit = sessionStorage.getItem("FileName");
  Save(FileNameInit, NewEdges, NewNodes);

}
if (NewNodes.length != 0) {
  NewNodes[0].id = 'Config';
  initialNodes = NewNodes;//.slice(0, 3);
  //console.log("initialNodes", initialNodes);
  initialEdges = NewEdges;
  UpDateGlobalNodeID(NewNodes.length);
  //GlobalNodeID = NewNodes.length;
  GlobalEdgeID = NewEdges.length;
} else {
  initialEdges = [];
  initialNodes = [
    {
      id: 'Config',
      type: 'ConfigN',
      dragHandle: '.custom-drag-handle',
      position: { x: 100, y: 200 },
      data: {
        nodeAction: "a", ForceRender: true, IsNode: false,
      },
      zIndex: 100,
      hidden: true,
      Refresh: false,
    },
  ];
}


let GlobalToken = sessionStorage.getItem("GlobalToken");
function App(Prop) {

  //console.log("Prop.LoadedNodes", Prop.LoadedNodes)
  if (Prop.LoadedNodes.length !== 0) {
    initialNodes = Prop.LoadedNodes;
    initialEdges = Prop.LoadedEdges;
    //console.log("initialNodes", initialNodes)
    //console.log("initialEdges", initialEdges)
  }
  const [ShowGraph, setShowGraph] = useState(false);
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [GlobalIsSaved, setGlobalIsSaved] = useState(true);
  const Saved = () => {
    setGlobalIsSaved(true);
  };
  OldGlobalNodes = GlobalNodes;
  len = OldGlobalNodes.length;
  if (oldlen != len) { oldlen = len; } // setGlobalIsSaved(false); }
  GlobalNodes = nodes;
  GlobalEdges = edges;
  GlobalShowGraph = ShowGraph;
  oldlen = len;

  const [refreshNode, setRefreshNode] = useState(false);
  var Constraints = Prop.DBFull[4];
  Constraints.IconsURL = IconsURL;
  Constraints.TemplatesURL = TemplatesURL;
  var Fan = Constraints["Fan"];
  var ListBoxNodesType = Prop.DBFull[4].ListBoxes;
  var DictTarget = Prop.DBFull[2][0];
  var DictSource = Prop.DBFull[2][3];
  var DictEdgesAtributes = Prop.DBFull[13];
  var ResourceToNodeLookUp = Prop.DBFull[14];
  var ResourceGroup = Prop.DBFull[12];
  var PropListDoc = Prop.DocList;
  const TypeCloud = Prop.TypeCloud;
  const TypeVPC = Prop.TypeVPC;
  const TypeRegion = Prop.TypeRegion;
  const TypeAZ = Prop.TypeAZ;
  const TypeSubnet = Prop.TypeSubnet;
  const TypeSBox = ["SBoxN"];
  var TypeTerraform = ["TerraformN", "TerraformBackendS3N"];
  var CognitoRegion = Prop.CognitoRegion;
  var CognitoDomain = Prop.CognitoDomain;
  var CognitoClient = Prop.CognitoClient;
  const NodeData = NodesData(Prop.DBFull, Prop.ListStandard, GlobalNodes, GlobalEdges, DictEdgesAtributes, PropListDoc,
    Prop.ListAWSRegions, Prop.TypeCloud, Prop.TypeRegion, Prop.TypeVPC, Prop.TypeAZ, Prop.TypeSubnet,
    GlobalToken, Prop.Code, Prop.ListTemplates, Prop.GlobalCognitoSub, Prop.GlobalUserName, setNodes,
    Prop.AccessExpiresAt, Prop.RefreshExpiresAt, Prop.RefreshToken, Prop.Stage, ShowGraph, TypeTerraform);
  var ResourceLookUp = Prop.DBFull[7];

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("One minute has passed.");
      timerCallback();
    }, 60000); // 60000 milissegundos = 1 minuto

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente for desmontado
  }, []); // Array de dependências vazio garante que o efeito só seja executado uma vez

  const timerCallback = () => {
    console.log("Executing callback function.");
    GlobalToken = sessionStorage.getItem("GlobalToken");
    BatchUpdateMetrics(true);
    checkAndRefreshToken(GlobalEdges, GlobalNodes, Prop.Stage, Prop.GlobalCognitoSub, GlobalToken);
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log("Visibility changed to visible. Executing callback function.");
        GlobalToken = sessionStorage.getItem("GlobalToken");
        BatchUpdateMetrics(false);
        checkAndRefreshToken(GlobalEdges, GlobalNodes, Prop.Stage, Prop.GlobalCognitoSub, GlobalToken);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    const intervalId = setInterval(() => {
      console.log("One minute has passed.");
      timerCallback();
    }, 60000); // 60000 milissegundos = 1 minuto
    return () => {
      clearInterval(intervalId); // Limpa o intervalo quando o componente for desmontado
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []); // Array de dependências vazio garante que o efeito só seja executado uma vez

  const [showRedBox, setShowRedBox] = useState(false);
  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [captureElementDoubleClick, setCaptureElementDoubleClick] = useState(true);
  const [capturePaneClick, setCapturePaneClick] = useState(true);
  const [captureElementClick, setCaptureElementClick] = useState(true);
  const [nodesDraggable, setNodesDraggable] = useState(true);
  const [showModalMain, setShowModalMain] = useState(false);
  const [ModalMainIndex, setModalMainIndex] = useState(0);
  const [MenuVisible, setMenuVisible] = useState(false);
  const [AreEdgesFront, setAreEdgesFront] = useState(false);
  const [HideEdges, setHideEdges] = useState(false);
  const [HideCICDEdges, setHideCICDEdges] = useState(false);
  const [HideEdgeLabels, setHideEdgeLabels] = useState(false);
  const [ShowCost, setShowCost] = useState(false);
  const [ShowStatus, setShowStatus] = useState(false);
  const [ExpandParent, setExpandParent] = useState(false);
  const [MenuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const contextValue = { ImportedData, setImportedData };
  const [MousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  GlobalHideEdges = HideEdges;
  var [ImportedData, setImportedData] = useState(null);

  const handleContextMenu = (e) => {
    e.preventDefault();
    setMousePosition({ x: e.clientX, y: e.clientY });
    setMenuVisible(true);
  };

  const handleClick = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const handleChildValueChange = useCallback((newValue) => {
    //console.log('O valor do Custom Node mudou: ', newValue, newValue[0]);
    GlobalSelectedList.clear();
    GlobalNodes[parseInt(newValue[0])].data.Mode = "Compile";
    //console.log("GlobalNodes[parseInt(newValue[0])]", GlobalNodes[parseInt(newValue[0])])
    GlobalSelectedList.add(newValue[1].toString());
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === newValue[1]) {
          //console.log("node.id", node.id)
          return { ...node, selected: true };
        } else {
          return { ...node, selected: false };
        }
      })
    )
  }, []);

  const [labels, setLabels] = useState({});  // Usando um objeto para armazenar labels
  const setLabel = (id, label) => {  // Função para atualizar uma label individual
    setLabels(prevLabels => {
      //console.log("labels before:", prevLabels);
      const updatedLabels = { ...prevLabels, [id]: label };
      //console.log("labels after:", updatedLabels);
      return updatedLabels;
    });
  };


  useEffect(() => {
    let Return = ImportTerraform(ImportedData, GlobalNodes, ResourceToNodeLookUp, NodeData);
    let ImportedNodes = Return[0];
    let ListConnectEdge = Return[1];
    nodes[0].hidden = true;
    if (ImportedNodes.length > 0) {
      for (let i = 0; i < ImportedNodes.length; i++) {
        setNodes((nodes) => { return [...nodes, ImportedNodes[i]]; });
      }
      for (let i = 0; i < ListConnectEdge.length; i++) {
        ConnectEdge(ListConnectEdge[i][0], ListConnectEdge[i][1]);
      }
    }
    GlobalSelectedList.clear();
    selectNodes();
  }, [ImportedData]);

  const ZoomLevelIndicator = () => {
    transform = useViewport();
    //console.log("transform", transform.x / transform.zoom, transform.y / transform.zoom, transform.zoom)
  }

  const BatchUpdateMetrics = async (ShoudIncrement, GlobalShowGraphFirstTime = false) => {
    console.log("transform", transform, GlobalShowGraph);
    if (GlobalShowGraph || GlobalShowGraphFirstTime) {
      const visibleNodes = checkNodeVisibility(nodes, transform);
      console.log("visibleNodes", visibleNodes, GlobalShowGraph);
      for (let i = 0; i < nodes.length; i++) {
        let NodeType = nodes[i].type;
        //console.log("NodeTypeXXX", NodeType, visibleNodes[i], i);
        if (NodeType === "GraphN") {
          await UpdateMetrics(edges, nodes, GlobalToken, i, visibleNodes[i], setNodes, ShoudIncrement);
        }
      }
    }
  };

  const handleDeleteEdge = (id, ManageStack = true) => {
    try {
      if (!GlobalHideEdges) {
        //console.log("GlobalEdges", id, GlobalEdges)
        let EdgeIndex = 0;
        setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== id));
        for (let i = 0; i < GlobalEdges.length; i++) {
          let Edge = GlobalEdges[i];
          if (Edge.id == id) {
            EdgeIndex = i;
            //console.log("del GlobalEdges", i, Edge);
            if (ManageStack) {
              StackInsert([[Edge], []]);
            }
            break;
          }
        }
        let SourceID = parseInt(GlobalEdges[EdgeIndex].source);
        let SourceType = GlobalNodes[SourceID].type;
        let TargetID = parseInt(GlobalEdges[EdgeIndex].target);
        let TargetType = GlobalNodes[TargetID].type;
        UpdateIcons(SourceID, SourceType, TargetID, TargetType, false);
        UpdateSubnetIcon(SourceID, SourceType, TargetID, TargetType, false);
        GlobalEdges.splice(EdgeIndex, 1);
        //console.log("GlobalEdges", GlobalEdges)
      }
    } catch (error) {
      //pass
    }
  };

  function StackInsert(Item) {
    if (GlobalCountStack < MaxStackSize) {
      GlobalUnDoPointer += 1;
      GlobalStack[GlobalUnDoPointer] = Item;
      GlobalCountStack += 1;
      //console.log("GlobalStack", GlobalCountStack, GlobalUnDoPointer, GlobalStack);
    } else {
      GlobalStack.push([]);
      GlobalStack.shift();
      GlobalStack[GlobalUnDoPointer] = Item;
    }
  }

  function RemoveStack() {
    GlobalUnDoPointer -= 1;
    GlobalCountStack -= 1;
    if (GlobalUnDoPointer < 0) { GlobalUnDoPointer = -1; }
  }

  const handleKeyDown = useCallback(
    (event) => {
      //const element = event.target;
      if (event.ctrlKey && GlobalNodes[0].hidden == true) {
        setNodesDraggable(false);
        GlobalCtrlPressed = true;
      }
    },
    []
  );

  const handleKeyUp = useCallback((event) => {
    //const element = event.target;
    if (GlobalNodes[0].hidden == true) {
      setNodesDraggable(true);
      GlobalCtrlPressed = false;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  const [GlobalMiniMap, setGlobalMiniMap] = useState(false);

  // Use a função setForceRender para atualizar o valor do estado e forçar a renderização novamente
  const HandleGlobalMiniMap = () => {
    setGlobalMiniMap(!GlobalMiniMap);
  };

  const HighligthTerraformNodes = useCallback(() => {
    //console.log("HighligthTerraform")
    setNodes((nds) =>
      nds.map((node) => {
        if (TerraformNodesList.includes(node.id)) {//(node.type == "AZN") {
          node.selected = true;
        } else {
          node.selected = false;
        }
        return node;
      })
    );
  }, [setNodes]);

  const selectNodes = useCallback(() => {
    const SelectedArray = Array.from(GlobalSelectedList);
    console.log("Select", SelectedArray);
    let FlagEnableSelect = false;
    if ((SelectedArray.length === 0) || (typeof SelectedArray[0] === "undefined")) {
      //console.log("Vazio ou indefinido")
      FlagEnableSelect = true;
    } else {
      let PrimeiroElementoArray = GlobalNodes[parseInt(SelectedArray[0])];
      try {
        if (PrimeiroElementoArray.type !== "TerraformN") {
          //console.log("Primeiro não é terraform")
          FlagEnableSelect = true;
        } else {
          if (PrimeiroElementoArray.data.Mode !== "Compile") {
            //console.log("Terraform != compile")
            FlagEnableSelect = true;
          }
        }
      } catch (error) {
        //pass
      }
    }
    if (FlagEnableSelect) {
      setNodes((nds) =>
        nds.map((node) => {
          if ((SelectedArray.includes(node.id)) && !((TypeAZ.includes(node.type)) && !(node.data.Select))) {
            console.log("nodeB", node);
            return { ...node, selected: true };
          } else {
            return { ...node, selected: false };
          }
        })
      );
    }

  }, [setNodes]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  function UpdateIconsOnUnDo(SourceID, SourceType, Connect = true) {
    if (TypeSubnet.includes(SourceType)) {//Update type label icon of Subnet (Public/Private)
      let TargetList = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[SourceID], "RTN");
      if (TargetList.length > 0) {
        //console.log("Achou RTN", TargetList.length)
        let RTNID = parseInt(TargetList[0]);
        TargetList = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[RTNID], "IGWN");
        if (TargetList.length > 0) {
          //console.log("Achou IGWN", TargetList.length)
          if (GlobalNodes[SourceID].data.Param[13][1]) {
            GlobalNodes[SourceID].data.Public = Connect;
            GlobalNodes[SourceID].data.PublicIPv6 = Connect;
          }
        }
      }
    }
    if (SourceType === "EC2") { //Update icon of EC2 (single/ASG)
      let SourceList = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[SourceID], "ASGN");
      if (SourceList.length > 0) {
        GlobalNodes[SourceID].data.AS = Connect;
      }
    }
    if (SourceType === "RDSN") { //Update icon of RDS to RDS Replica
      let SourceList = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[SourceID], "RDSN");
      if (SourceList.length > 0) {
        GlobalNodes[SourceID].data.MultiAZ = Connect;
      };
    }
  }

  function UpdateGeneralIcon() {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.type === "SubnetN") {
          let SubnetStatus = false;
          let SubnetStatusIPv6 = false;
          let SubnetID = parseInt(node.id);
          let RTTargetList = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[SubnetID], "RTN");
          let RTID = parseInt(RTTargetList[0]);
          let IGWTargetList = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[RTID], "IGWN");
          if (IGWTargetList.length > 0) {
            let IGWID = parseInt(IGWTargetList[0]);
            let Label = findEdgeLabel(GlobalEdges, RTID, IGWID);
            if (Label.ctrl === "" || Label.ctrl === "IPv4" || Label.ctrl === "Dual") {
              if (node.data.Param[13][1] === true) {
                SubnetStatus = true;
              }
            }
            if (Label.ctrl === "IPv6" || Label.ctrl === "Dual") {
              let EgressTargetList = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[RTID], "EgressOnlyIGN");
              if (EgressTargetList.length === 0) { SubnetStatusIPv6 = true; }
            }
          }
          node.data.Public = SubnetStatus;
          node.data.PublicIPv6 = SubnetStatusIPv6;
        }
        return node;
      })
    )
  }

  function UpdateSubnetIcon(SourceID, SourceType, TargetID, TargetType, Connect) {
    if ((TargetType === "RTN") || (TargetType === "IGWN")) {
      UpdateGeneralIcon();
    }
  }
  function UpdateIcons(SourceID, SourceType, TargetID, TargetType, Connect = true) {
    let Label = ""
    //console.log("Utodate Edge")
    if (SourceType === "TextN") {
      //console.log("Attatch", TargetType)
      GlobalNodes[SourceID].parentNode = TargetID.toString();
    }
    if ((SourceType === "ASGN") && (TargetType == "EC2N")) { //Update icon of EC2 (single/ASG)
      GlobalNodes[TargetID].data.AS = Connect;
    }
    if ((SourceType === "RDSN") && (TargetType == "RDSN")) { //Update icon of RDS to RDS Replica
      GlobalNodes[TargetID].data.MultiAZ = Connect;
    };
    if ((SourceType === "RDSN") && (TargetType == "RDSReplicaN")) { //Update icon of RDS to RDS Replica
      GlobalNodes[TargetID].data.Param = JSON.parse(JSON.stringify(GlobalNodes[SourceID].data.Param));
    };
    let NodeGroup = DictEdgesAtributes["CogUPoolN"][0][0];
    if ((SourceType === "CogUPoolN") && (NodeGroup.includes(TargetType))) { //Update Edge between CogUPool and Lambda 
      Label = "?|";
    };
    NodeGroup = DictEdgesAtributes["CFDistributionN"][0][0];
    if ((NodeGroup.includes(SourceType)) && (TargetType == "CFDistributionN")) { //Update Edge between CFDistributionN and Source 
      Label = "?|";
    };
    //NodeGroup = DictEdgesAtributes["S3N"][0][0];
    //if ((SourceType == "S3N") && (NodeGroup.includes(TargetType))) { //Update Edge between S3N and LambdaN 
    //  Label = "?|";
    //};
    if ((SourceType == "ALBListenerN") && (TargetType == "ALBActionN")) {
      Label = "?|";
    };
    //if ((SourceType == "ALBActionN") && (TargetType == "LBTGN")) {
    //  Label = "Weight?|";
    //};
    if ((SourceType == "LambdaAliasN") && (TargetType == "CFCBehaviorN")) {
      Label = "?|";
    };
    if ((SourceType == "CFFunctionsN") && (TargetType == "CFCBehaviorN")) {
      Label = "?|";
    };
    NodeGroup = DictEdgesAtributes["ACMN"][0][0];
    if ((SourceType == "ACMN") && (NodeGroup.includes(TargetType))) {
      Label = "?|";
    };
    //NodeGroup = DictEdgesAtributes["ECSServiceN"][0][0];
    //if ((SourceType == "ECSServiceN") && (NodeGroup.includes(TargetType))) {
    //  Label = "?|";
    //};
    NodeGroup = DictEdgesAtributes["R53RoutingN"][0][0];
    if ((SourceType == "R53RoutingN") && (NodeGroup.includes(TargetType))) {
      Label = "?|";
    };
    NodeGroup = DictEdgesAtributes["CWMAlarmN"][0][0];
    if ((SourceType == "CWMAlarmN") && (NodeGroup.includes(TargetType))) {
      Label = "?|";
    };
    if ((TargetType == "LambdaN") && (SourceType == "CogUPoolClientN")) {
      Label = "Get Vars";
    };
    if ((TargetType == "LambdaN") && (SourceType == "R53ZoneN")) {
      Label = "Get Vars";
    };
    NodeGroup = DictEdgesAtributes["SSMParameterN"][0][0];
    if ((TargetType == "SSMParameterN") && (NodeGroup.includes(SourceType))) {
      Label = "?|";
    };
    NodeGroup = DictEdgesAtributes["StepFuncSMN"][0][0];
    if ((TargetType == "StepFuncSMN") && (NodeGroup.includes(SourceType))) {
      Label = "?|";
    };
    if ((SourceType == "SSMParameterN") && (TargetType == "EC2N")) {
      Label = "?|";
    };
    if ((TargetType == "ENIN") && (SourceType == "EC2N")) {
      Label = "?|";
    };
    //if ((TargetType == "AZNIN") && (SourceType == "AZLinuxVMN")) {
    //  Label = "?|";
    //};
    if ((TargetType == "S3N") && (SourceType == "CodeBuildN")) {
      Label = "?|";
    };
    if ((TargetType == "CodeBuildN") && (SourceType == "CodeBuildSourceN")) {
      Label = "?|";
    };
    if ((TargetType == "CodeBuildArtifactN") && (SourceType == "CodeBuildN")) {
      Label = "?|";
    };
    if ((TargetType == "EC2N") && (SourceType == "EBSN")) {
      Label = "?|";
    };
    if ((TargetType == "EC2N") && (SourceType == "EBDN")) {
      Label = "?|";
    };
    if ((TargetType == "S3RCN") && (SourceType == "S3RCRN")) {
      Label = "?|";
    };
    if ((TargetType == "VPCPeeringCN") && (SourceType == "VPCN")) {
      Label = "?|";
    };
    if ((["KPodN", "EKSNodeGroupN"].includes(TargetType)) && (SourceType == "KPodN")) {
      Label = "?|";
    };
    if ((TargetType == "AZAppGBackendN") && (SourceType == "AZAppGListenerN")) {
      Label = "Default";
    };
    if ((TargetType == "CodeBuildN") && (SourceType == "CodePipelineActionN")) {
      Label = "Build|";
    };
    return Label
  }
  const onConnect = useCallback((params) => {
    //console.log("Oi", GlobalEdges.length, params);
    var TargetID = parseInt(params.target);
    var SourceID = parseInt(params.source);
    let TargetType = GlobalNodes[TargetID].type;
    let SourceType = GlobalNodes[SourceID].type;
    let ListTarget = DictTarget[SourceType];
    //console.log("True")
    let Label = UpdateIcons(SourceID, SourceType, TargetID, TargetType, true);
    //Test Fanin
    let Fanin = Fan[TargetType][0];
    let Fanout = Fan[SourceType][1];
    //console.log("Fan", Fanin, Fanout);
    //console.log("TargetType", TargetType, TargetID);
    let FlagConnect = true
    if (Fanin == "1") {
      //console.log("Fanin", Fanin, GlobalEdges.length);
      for (let i = 0; i < GlobalEdges.length; i++) {
        //console.log("Edge-target", parseInt(GlobalEdges[i]["target"]));
        if (parseInt(GlobalEdges[i]["target"]) == TargetID) {
          let ResourceTarget = TargetType.slice(0, -1);
          handleFeedback("$$" + ResourceTarget + "$$ permits only one input connection $$ $$");
          FlagConnect = false;
          break;
        }
      }
    }
    if (Fanout == "1") {
      for (let i = 0; i < GlobalEdges.length; i++) {
        if (parseInt(GlobalEdges[i]["source"]) == SourceID) {
          let ResourceSource = SourceType.slice(0, -1);
          handleFeedback("$$" + ResourceSource + "$$ permits only one output connection $$ $$");
          FlagConnect = false;
          break;
        }
      }
    }
    //Não permite conectar o próprio node
    if (SourceID == TargetID) {
      FlagConnect = false;
    }
    //ListResourceTargetCtrl
    let ListEdgeCtrl = ListaStandard[ResourceLookUp[SourceType]].ListEdgeCtrl;
    console.log("ListEdgeCtrl", ListEdgeCtrl)
    if (ListEdgeCtrl !== undefined) {
      for (let i = 0; i < ListEdgeCtrl.length; i++) {
        //console.log("ListEdgeCtrl", ListEdgeCtrl[i])
        let MaxConnect = ListEdgeCtrl[i].Max;
        let ListTypeTarget = ListEdgeCtrl[i].Target || [];
        let ListTypeSource = ListEdgeCtrl[i].Source || [];
        //console.log("Lists", MaxConnect, ListTypeTarget, ListTypeSource)
        let Connections = 0;
        if (ListTypeTarget.includes(TargetType)) {
          //console.log("ListTypeTarget", ListTypeTarget)
          for (let j = 0; j < ListTypeTarget.length; j++) {
            let LocalTargetType = ListTypeTarget[j];
            let ListSearchTarget = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[SourceID], LocalTargetType);
            Connections += ListSearchTarget.length;
            console.log("NodeTarget", LocalTargetType, ListTarget)
            console.log("Connections target", Connections)
          }
          //console.log("ListTypeSource", ListTypeSource)
          for (let j = 0; j < ListTypeSource.length; j++) {
            let LocalSourceType = ListTypeSource[j];
            let ListSearchSource = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[SourceID], LocalSourceType);
            Connections += ListSearchSource.length;
            console.log("Node As Source", LocalSourceType, ListTypeSource)
            console.log("Connections source", Connections)
          }
        }
        if (MaxConnect > 0) {
          if (Connections >= MaxConnect) {
            //FlagConnect = false;
            handleFeedback("Exceeded the maximum number of connections (Target)")
            console.log("Exceeded the maximum A")
          }
        }
      }
    }
    ListEdgeCtrl = ListaStandard[ResourceLookUp[TargetType]].ListEdgeCtrl;
    //console.log("ListEdgeCtrl TargetType", ListEdgeCtrl)
    if (ListEdgeCtrl !== undefined) {
      for (let i = 0; i < ListEdgeCtrl.length; i++) {
        //console.log("ListEdgeCtrl", ListEdgeCtrl[i])
        let MaxConnect = ListEdgeCtrl[i].Max;
        let ListTypeTarget = ListEdgeCtrl[i].Target || [];
        let ListTypeSource = ListEdgeCtrl[i].Source || [];
        //console.log("Lists", MaxConnect, ListTypeTarget, ListTypeSource)
        let Connections = 0;
        for (let j = 0; j < ListTypeTarget.length; j++) {
          let LocalTargetType = ListTypeTarget[j];
          let ListSearchTarget = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[TargetID], LocalTargetType);
          Connections += ListSearchTarget.length;
          //console.log("NodeTarget", LocalTargetType, ListTarget)
          //console.log("Connections target", Connections)
        }
        if (ListTypeSource.includes(SourceType)) {
          for (let j = 0; j < ListTypeSource.length; j++) {
            let LocalSourceType = ListTypeSource[j];
            let ListSearchSource = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[TargetID], LocalSourceType);
            Connections += ListSearchSource.length;
            console.log("Node As Source", LocalSourceType, ListTypeSource)
            console.log("Connections source", Connections)
          }
        }
        if (MaxConnect > 0) {
          if (Connections >= MaxConnect) {
            //FlagConnect = false; handleFeedback("Exceeded the maximum number of connections (Source)")
            console.log("Exceeded the maximum B")
          }
        }
        //console.log("MaxConnect", MaxConnect)
      }
    }

    let FlagConnected = false;
    //Teste se a edge já existe
    for (let j = 0; j < GlobalEdges.length; j++) { //Testa se já existe a edge
      //console.log("Teste", j, parseInt(GlobalEdges[j].source), SourceID, parseInt(GlobalEdges[j].target), TargetID)
      if ((parseInt(GlobalEdges[j].source) == SourceID) && (parseInt(GlobalEdges[j].target) == TargetID)) {
        FlagConnected = true;
        FlagConnect = false;
        //console.log("Já existe a edge")
        break;
      }
    }
    let Category = "Default";
    const CICDEdges = ListaStandard[ResourceLookUp[SourceType]]?.CICDEdges || [""];
    console.log("CICDEdges", CICDEdges, TargetType, SourceType)
    if (CICDType.includes(SourceType) || CICDType.includes(TargetType) || CICDEdges.includes(TargetType)) {
      Category = "CICD";
    }
    if (FlagConnect) {
      let EdgeID = GetEdgeId();
      //console.log("EdgeID", EdgeID, GlobalEdges.length);
      for (let i = 0; i < ListTarget.length; i++) {
        if ((ListTarget.includes(TargetType)) || ListTarget.includes("All")) {
          let NewEdge = {
            ...params, id: EdgeID, zIndex: 1000, animated: false, hidden: false, type: "FloatingEdgeE",
            style: { stroke: '#AAA' },
            markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20, color: '#000000', },
            label: labels[EdgeID] || Label, data: { "Category": Category },
            labelStyle: { fontSize: 5, },
            //data: { LabelPosition: 25, }
          };
          setEdges((eds) =>
            addEdge(NewEdge, eds));
          GlobalEdges.push(NewEdge);
          //console.log("Criou nova edges ASG EC2", i, SourceID, TargetID)
          FlagConnected = true;
          break;
        }
        if (FlagConnected) { break; }
      }
      //Feed Back
      if (FlagConnected == false) {
        let ResourceSource = SourceType.slice(0, -1);
        let ResourceTarget = TargetType.slice(0, -1);
        GlobalNodeFeedBack = SourceType;
        handleFeedback("You can not connect $$" + ResourceSource + "$$ with $$" + ResourceTarget + "$$");
      }
      //console.log("FimConnect")
      UpdateSubnetIcon(SourceID, SourceType, TargetID, TargetType, true);
    }
  }, [setEdges]);

  function handleKeyPress(event) {
    if (event.key === " ") {
      setShowRedBox(!showRedBox);
    }
  }
  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);
  const onEdgeDoubleClick = (event, edge) => {
    //console.log("Edge doubleClick", edge);
    setNodes((nds) =>
      nds.map((NodeCfg) => {
        if (NodeCfg.id === "Config") {
          let ListLabelAttrAux = [];
          let SourceID = parseInt(edge.source);
          let TargetID = parseInt(edge.target);
          let SourceType = GlobalNodes[SourceID].type;
          let TargetType = GlobalNodes[TargetID].type;
          console.log("Types: ************", SourceType, TargetType)
          let Position = { x: 0, y: 0 };
          //Testa se o node tem uma lista de atributos para edge
          let ListEdgesAttributes = DictEdgesAtributes[SourceType];
          console.log("DictEdgesAtributes", DictEdgesAtributes)
          console.log("SourceType", SourceType)
          console.log("ListEdgesAttributes", ListEdgesAttributes)
          let NodeIsTarget = true;
          let SourceAttrIncludesTarget = false;
          for (let i = 0; i < ListEdgesAttributes.length; i++) {
            SourceAttrIncludesTarget = ListEdgesAttributes[i][0].includes(TargetType);
            if (SourceAttrIncludesTarget) { break; }
            console.log("Try¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨¨", ListEdgesAttributes[i][0]);
          }
          if (SourceAttrIncludesTarget == false) {
            ListEdgesAttributes = DictEdgesAtributes[TargetType];
            NodeIsTarget = false;
            console.log("EdgesAtributes from target")
          }
          console.log("ListEdgesAttributes: ************", ListEdgesAttributes)
          let NodesGroup = [];
          if (ListEdgesAttributes.length > 0) {
            for (let i = 0; i < ListEdgesAttributes.length; i++) {
              NodesGroup = ListEdgesAttributes[i][0];
              //console.log("ListEdgesAttributes[i][0] : ************", ListEdgesAttributes[i][0], ListEdgesAttributes[i][2])
              if (ListEdgesAttributes[i][2] == "Target") {
                if (NodesGroup.includes(TargetType)) {
                  ListLabelAttrAux = JSON.parse(JSON.stringify(ListEdgesAttributes[i][1]));
                  //console.log("NodeIsTarget = true");
                  break;
                }
              } else {
                if (NodesGroup.includes(SourceType)) {
                  ListLabelAttrAux = JSON.parse(JSON.stringify(ListEdgesAttributes[i][1]));
                  //console.log("NodeIsTarget = false");
                  break;
                }
              }
            }
          }
          ListLabelAttrAux.splice(0, 1);
          let ListLabelAttr = [];
          for (let i = 0; i < ListLabelAttrAux.length; i++) {
            ListLabelAttr.push(ListLabelAttrAux[i][0])
          }
          console.log("ListLabelAttr : ************", ListLabelAttr)
          if (NodeIsTarget) {
            for (let i = 0; i < GlobalEdges.length; i++) {//Procura todos os labels das edges entre o node Source e o tipo target
              if (GlobalEdges[i].target == edge.target) {  // objetivo de remover os labels exsitentes na exibição
                //let TargetTypeGroup = GlobalNodes[parseInt(GlobalEdges[i].target)].type
                if (NodesGroup.includes(TargetType)) {
                  let LabelGroup = GlobalEdges[i].label;
                  let index = ListLabelAttr.indexOf(LabelGroup);
                  //console.log("LabelGroup", LabelGroup, index)
                  //Remove a linha abaixo pois estava removendo o label de forma errada. Revisar
                  //if (index !== -1) { ListLabelAttr.splice(index, 1); console.log("Removeu edges Node target") }
                  //console.log("Passou edges Node target")
                }
              }
            }
          } else {
            for (let i = 0; i < GlobalEdges.length; i++) {//Procura todas as os labels das edges entre o node Target e o tipo source
              if (GlobalEdges[i].target == edge.target) {
                //let SourceTypeGroup = GlobalNodes[parseInt(GlobalEdges[i].source)].type
                if (NodesGroup.includes(SourceType)) {
                  let LabelGroup = GlobalEdges[i].label;
                  let index = ListLabelAttr.indexOf(LabelGroup);

                }
              }
            }
          }
          ListLabelAttr.unshift("?|");
          //console.log("ListEdgesAttributes length", ListEdgesAttributes, ListEdgesAttributes[0].length)
          try {
            let Prefix = ListEdgesAttributes[0][1];
            if (Prefix.length == 1) {//Para inserir um texto fixo (= ListEdgesAttributes[0]) antes do valor a ser inserido no label da edge
              ListLabelAttr = [Prefix[0] + "?|"]
            }
          } catch (error) {
            //pass
          }
          let SourcePosition = GetFatherPosition(nodes, SourceID, { x: 0, y: 0 });
          let TargetPosition = GetFatherPosition(nodes, TargetID, { x: 0, y: 0 });
          let TargetX = GlobalNodes[TargetID].position.x;
          let TargetY = GlobalNodes[TargetID].position.y;
          let SourceX = GlobalNodes[SourceID].position.x;
          let SourceY = GlobalNodes[SourceID].position.y;
          //console.log("ListLabelAttr", ListLabelAttr)
          if (TargetX < SourceX) {
            Position.x = (TargetX - SourceX) / 2 + TargetPosition.x;
          } else {
            Position.x = (SourceX - TargetX) / 2 + SourcePosition.x;
          }
          if (TargetY < SourceY) {
            Position.y = (TargetY - SourceY) / 2 + TargetPosition.y;
          } else {
            Position.y = (SourceY - TargetY) / 2 + SourcePosition.y;
          }
          Position.x = SourcePosition.x + (TargetPosition.x - SourcePosition.x) / 2 + 60;
          Position.y = SourcePosition.y + (TargetPosition.y - SourcePosition.y) / 2 + 60;
          NodeCfg.hidden = false;
          NodeCfg.selected = true;
          NodeCfg.data.nodeAction = edge;
          NodeCfg.data.id = edge.id;
          NodeCfg.data.IsNode = "Edge";
          NodeCfg.position = Position;
          NodeCfg.data.label = labels[edge.id];
          NodeCfg.data.labelAttr = ListLabelAttr;
          NodeCfg.data.setLabel = setLabel.bind(null, edge.id);
          //console.log("Edges Label pos", edge)
        }
        return NodeCfg;
      })
    )
  }
  useEffect(() => {
  }, [showModalMain]);


  //Node Doubleclick do appear Config Node
  const onNodeDoubleClick = (event, node) => {
    console.log("onNodeDoubleClick")
    if (event.altKey) {//Do selection of many nodes with alt  pressed
      console.log("node.type", node.type)
      let ConsoleLinkResource = null;
      try {
        ConsoleLinkResource = ListaStandard[ResourceLookUp[node.type]].GeneralParam.Console;
      } catch (error) {
        ConsoleLinkResource = null;
      }
      console.log("ConsoleLinkResource", ConsoleLinkResource)
      const NodeID = parseInt(node.id);
      const NodesWithSufix = AdjustSufixName(GlobalNodes, NodeID)

      openConsole(GlobalEdges, NodesWithSufix, "awsConsoleWindow", NodeID, node.type, ConsoleLinkResource);
    } else {
      //try {
      let Position = { x: event.clientX, y: event.clientY };
      let FatherNodeID = node.id;
      let NewPosition = AdjustFatherPosition(nodes, FatherNodeID, Position);
      handleCloseFeedback();
      if ((node.id === "Config") || (node.type === "TextN")) {
      } else {
        GlobalNodeModal = node;
        setModalMainIndex(node.id);
        setShowModalMain(true);
        if (TypeRegion.includes(node.type)) {
          let ListNodes = FindNodesEdgesChield(nodes, edges, node)[0];
          node.data.Param[2][6] = ListNodes.length > 1;
        }
        //Bloco controle de ListInputSelectSubform
        let ListSourceType = [];
        let NodeTypeSource = "Null";
        let ListSource = SearchNodesSource(edges, nodes, node, "all");
        let SourceID0 = parseInt(ListSource[0]);
        //let ListInputOptions = 
        if (ListSource.length > 0) {
          NodeTypeSource = nodes[SourceID0].type;
          for (let i = 0; i < ListSource.length; i++) {
            ListSourceType.push(GlobalNodes[parseInt(ListSource[i])].type);
          }
        }
        for (let loop = 0; loop < 20; loop++) {
          if (NodeTypeSource !== "GraphN") {
            break;
          } else {
            ListSource = SearchNodesSource(edges, nodes, nodes[SourceID0], "all");
            if (ListSource.length > 0) {
              SourceID0 = parseInt(ListSource[0]);
              NodeTypeSource = nodes[SourceID0].type;
            } else { NodeTypeSource = "Null"; }
          }
        }
        let NodeID = parseInt(node.id);
        if (node.type === "CogUPoolDomainN") {
          let RegionID = parseInt(FindRegionAndCloud(GlobalNodes, node.id)[1]);
          let RegionName = GlobalNodes[RegionID].data.Param[2][2];
          node.data.Param[5][1] = RegionName;
        }
        if (node.type === "SSMParameterN") {
          for (let i = 0; i < ListSource.length; i++) {
            if (ListSourceType[i] === "KMSKeyN" || ListSourceType[i] === "KMSKeyDN") {
              if (ListSourceType.length === 1) { ListSourceType = []; }
            } else {
              let SourceID = parseInt(ListSource[i]);
              let Label = findEdgeLabel(GlobalEdges, SourceID, NodeID);
              if (Label.ctrl.includes("R/W")) { ListSourceType = []; }
            }
            /*if (ListSourceType.includes("KMSKeyN") || ListSourceType.includes("KMSKeyDN")) {
              if (ListSourceType.length === 1) { ListSourceType = []; }
              if (ListSourceType.length === 2) {
                let Label = findEdgeLabel(GlobalEdges, SourceID0, NodeID);
                if (Label.ctrl.includes("Var")) { ListSourceType = []; }
              }
            } else {
              if (ListSourceType.length === 1) {
                let Label = findEdgeLabel(GlobalEdges, SourceID0, NodeID);
                if (Label.ctrl.includes("R/W")) { ListSourceType = []; }
              }*/
          }
        }
        if (node.type === "R53ZoneN") {
          if (ListSourceType.includes("R53ZoneN")) { NodeTypeSource = "SubDomain"; } else { NodeTypeSource = "MainDomain"; }
        }
        if (node.type === "CWMAlarmN") {
          if (NodeTypeSource == "Null") { NodeTypeSource = "Custom"; } else { NodeTypeSource = "NoCustom"; }
        }
        if (node.type === "CodeBuildSourceN") {
          if (NodeTypeSource == "Null") { NodeTypeSource = "NO_SOURCE"; }
        }
        if (node.type === "CodePipelineActionN") {
          NodeTypeSource = "ManualApproval"
          for (let i = 0; i < ListSourceType.length; i++) {
            if (!["CodePipelineActionN", "CodePipelineStageN"].includes(ListSourceType[i])) {
              NodeTypeSource = ListSourceType[i];
              break;
            }
          }
        }
        console.log("NodeTypeSource CodePipelineActionN", NodeTypeSource);
        if (node.type === "CFCBehaviorN") {
          if (ListSourceType.includes("CFCachePolicyN")) {
            NodeTypeSource = "CFCachePolicyN";
          } else {
            NodeTypeSource = "NotCFCachePolicyN";
          }
        }
        console.log("NodeTypeSource", NodeTypeSource, ListSourceType)
        if (node.type === "LBTGN") {
          let [ListenerID, ListenerName] = FindNameIDRouting(GlobalEdges, GlobalNodes, NodeID, [["ALBActionN", "Source"], ["ALBListenerN", "Source"]]);
          if (ListenerID === NodeID) {
            [ListenerID, ListenerName] = FindNameIDRouting(GlobalEdges, GlobalNodes, NodeID, [["ALBActionN", "Source"], ["ALBActionN", "Source"], ["ALBListenerN", "Source"]]);
          }
          //console.log("ListenerID, ListenerName", ListenerID, ListenerName, NodeID)
          if (ListenerID !== NodeID) {
            let SourceList = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[ListenerID], "ALoadBalancerN");
            //console.log("SourceList", SourceList)
            if (SourceList.length > 0) {
              { NodeTypeSource = "ALoadBalancerN"; }
            }
          }
        }
        if (node.type === "LambdaN") {
          if (ListSourceType.includes("ECRN")) {
            NodeTypeSource = "ECRN";
          } else {
            if (ListSourceType.includes("S3N")) {
              NodeTypeSource = "S3N";
            } else { NodeTypeSource = "LocalFile"; }
          }
        }
        //Bloco controle de ListOutputSelectSubform
        //console.log("NodeTypeSource", NodeTypeSource);
        let NodeTypeTarget = "Null"
        let ListTargetType = [];
        let ListTarget = SearchNodesTarget(edges, nodes, node, "all");
        for (let i = 0; i < ListTarget.length; i++) {
          ListTargetType.push(GlobalNodes[parseInt(ListTarget[i])].type);
        }
        if (ListTarget.length > 0) {
          NodeTypeTarget = nodes[parseInt(ListTarget[0])].type;
          if (node.type === "S3GrantN") {
            if (NodeTypeTarget !== "S3ACLN") { NodeTypeTarget = "NotS3ACL"; }
          }
        }
        if (node.type === "SNSSubscriptionN") {
          if (["LambdaN", "SQSN", "FirehoseN"].includes(NodeTypeTarget)) {
            //pass
          } else { NodeTypeTarget = "Other"; }
        }
        if (node.type === "CodePipelineActionN") {
          if (NodeTypeTarget == "Null") { NodeTypeTarget = "ManualApproval"; }
        }
        if (ListTargetType.length === 0) { ListTargetType = ["Null"] }
        if (ListSourceType.length === 0) { ListSourceType = ["Null"] }
        console.log("NodeTypeTarget", NodeTypeTarget)
        //ReadOnly Credentials
        let ReadOnlyCredentials = "";
        if ((TypeCloud.includes([node.type]) == false) && (TypeRegion.includes(node.type) === false)) {
          let CloudID = parseInt(FindRegionAndCloud(GlobalNodes, node.id)[0]);
          try {
            ReadOnlyCredentials = [GlobalNodes[CloudID].data.Param[5][1], GlobalNodes[CloudID].data.Param[6][1]];
          } catch (error) {
            //pass
          }
        }
        setNodes((nds) =>
          nds.map((NodeCfg) => {
            //Teste para identificar o id "Config" em nds (map), mas node.type tem que ser diferente de "ConfigN",
            //pois o node alvo da configuração não pode ser o próprio Config.
            //console.log("Edges", edges, nodes, node);
            if ((NodeCfg.selected == true) && (NodeCfg.id != 'Config')) {
              console.log("Nodetype B", node.type, node.id);
              NodeCfg.selected = false;
            }
            if ((NodeCfg.id === 'Config') && (node.type != "ConfigN")) {
              console.log("Nodetype C", node.type, node.id);
              let Position = { x: 0, y: 0 };
              Position = GetFatherPosition(nodes, parseInt(node.id), Position); //Solve the Config node position
              Position.x += 120;
              NodeCfg.hidden = true;
              NodeCfg.selected = true;
              NodeCfg.data.nodeAction = node;
              NodeCfg.data.NodeTypeSource = NodeTypeSource;
              NodeCfg.data.NodeTypeTarget = NodeTypeTarget;
              NodeCfg.data.ListSourceType = ListSourceType;
              NodeCfg.data.ListTargetType = ListTargetType;
              NodeCfg.data.id = node.id;
              NodeCfg.position = Position;
              NodeCfg.data.IsNode = "Node";
              NodeCfg.data.FirstRender = true;
              NodeCfg.data.ReadOnlyCredentials = ReadOnlyCredentials;
            };
            return NodeCfg;
          })
        )
      }
      //} catch (error) {
      //pass
      //}
    }
  }

  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const handleFeedback = (Message) => {
    setFeedbackMessage(Message);
    setShowFeedback(true);
  };
  const handleCloseFeedback = () => {
    setShowFeedback(false);
  };

  const onPaneClick = (event, node) => {
    handleCloseFeedback();
    setMenuVisible(false);
    GlobalMousePosX = event.clientX;
    GlobalMousePosY = event.clientY;
    GlobalSelectedList.clear();
    RemoveSelected(nodes);
    setNodes((nds) =>
      nds.map((node) => {
        //console.log("PaneClick")
        if (node.id === 'Config') {
          node.hidden = true;
        };
        return node;
      })
    )
  }

  const onEdgeClick = (event, EdgeSelected) => {
    handleCloseFeedback();
    if (!HideEdges) {
      GlobalMousePosX = event.clientX;
      GlobalMousePosY = event.clientY;
      setEdges((eds) =>
        eds.map((edge) => {
          if (EdgeSelected.id == edge.id) {
            edge.style = { stroke: '#000', width: 1 };
          } else {
            edge.style = { stroke: '#AAA', width: 1 };
          }
          return edge;
        })
      )
    }
  }

  const onNodeClick = (event, nodeSelected) => {
    handleCloseFeedback();
    UpdateGeneralIcon();
    setMenuVisible(false);
    GlobalMousePosX = event.clientX;
    GlobalMousePosY = event.clientY;
    if (GlobalCtrlPressed) {//Do selection of many nodes with ctrl pressed
      if ((TypeAZ.includes(nodeSelected.type)) || (nodeSelected.type === "SBoxN")) {
        nodeSelected.data.Select = true;
      }
      if (nodeSelected.type != "ConfigN") {
        if (GlobalSelectedList.has(nodeSelected.id)) {
          GlobalSelectedList.delete(nodeSelected.id);
          nodeSelected.data.Select = false;
        } else {
          GlobalSelectedList.add(nodeSelected.id);
        }
      };
      RemoveSelected(nodes);
      let array = Array.from(GlobalSelectedList);
      try {
        for (let i = 0; i < array.length; i++) {
          nodes[parseInt(array[i])].selected = true;
        }
      } catch (error) {
        //pass
      }
      selectNodes();
    } else { //Select just one Node (no ctrl pressed)
      if (nodeSelected.id === "Config") {
        //pass
      } else {
        RemoveSelected(nodes);
        GlobalSelectedList.clear();
        if (TypeAZ.includes(nodeSelected.type)) {
          selectNodes();
          for (let i = 0; i < nodes.length; i++) {
            nodes[i].data.Select = false;
          }
        } else {
          for (let i = 0; i < nodes.length; i++) {
            nodes[i].data.Select = false;
          }
          GlobalSelectedList.add(nodeSelected.id);
          let array = Array.from(GlobalSelectedList);
          //console.log("array", array);
          for (let i = 0; i < array.length; i++) {
            nodes[parseInt(array[i])].selected = true;
          }
          selectNodes();
          if (nodeSelected.type === "TerraformN") { //List all resources under Terraform node
            let CurrentNodes = JSON.parse(JSON.stringify(GlobalNodes)); //Separação em CurrentesNodes é pq ListTerraformDomain limpa o data.CodeGenered e sem isso limparia todos os codes gerados no canva corrente
            let TerraformNodesList = ListTerraformDomain(GlobalEdges, CurrentNodes, nodeSelected);
            nodeSelected.data.Param.ListNodesID = TerraformNodesList;
            GlobalSelectedTerraform = parseInt(nodeSelected.id);
            nodeSelected.data.TFID = parseInt(nodeSelected.id);
          }
          for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].selected == true) {
              if (nodes[i].id != nodeSelected.id) {
                nodes[i].selected = false;
              }
            }
          }
          TemporaryNodeList = [];
          for (let i = 0; i < edges.length; i++) {
            const edge = edges[i];
            if (edge.source === nodeSelected.id || edge.target === nodeSelected.id) {
              edges[i] = {
                ...edge, animated: true, style: { stroke: '#000', width: 10, zIndex: 1000 },
              };
            } else {
              edges[i] = {
                ...edge, animated: false, style: { stroke: '#AAA', width: 1, zIndex: 1000 },
              };
            }
          }
          setEdges([...edges]);
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === 'Config') {
                node.hidden = true;
              };
              return node;
            })
          )
        }
      }
    }
  }
  function Move() {
    setShowModalMain(false);
    //try {
    const MoveID = parseInt(GlobalListNodesEdgesCopy[0][0].id) || 0;
    const SelectedNodeID = parseInt(FindSelectedNodes(GlobalNodes)[0].id) || 0;
    const MoveType = GlobalNodes[MoveID].type;
    const SelectedNodeType = GlobalNodes[SelectedNodeID].type;
    let NoMove = false;
    //Constraints
    //Verifica se há AZ dentro de um SBox que irá ser movido. Se sim só pode ser movido se tiver uma VPC acima
    if (MoveType === "SBoxN") {
      let TestNodes = JSON.parse(JSON.stringify(GlobalNodes));
      TestNodes[MoveID].parentNode = SelectedNodeID.toString();
      const List = FindNodesChieldID(TestNodes, MoveID);
      //let ListAZ = [];
      for (let i = 0; i < List.length; i++) {
        let NodeID = parseInt(List[i]);
        if (TypeAZ.includes(TestNodes[NodeID].type)) {
          console.log("Tem AZ")
          //ListAZ.push(i)
          let VPCID = GetVPCParent(TestNodes, NodeID);
          if (VPCID === 0) {
            handleFeedback("The Box cannot be moved because there is an AZ inside it, and it can only be moved into a VPC.");
            return ""
          }
          break;
        }
        if (TypeVPC.includes((TestNodes[NodeID].type))) {
          console.log("tem vpc")
          let VPCID = GetVPCParent(TestNodes, MoveID);
          if (VPCID !== 0) {
            handleFeedback("VPC over VPC error: the Box cannot be moved because there is a VPC inside it, and it is inside other VPC.");
            return ""
          }
        }
      }
      //Verifica se há uma VPC dentro do um SBox que irá ser movido. Se sim só pode ser movido se não estiver uma VPC acima
    }
    let NodeIndex = ResourceLookUp[MoveType];
    let NodeFatherType = ListaStandard[NodeIndex].GeneralParam.NodeFatherType;
    console.log("NodeFatherType", NodeFatherType, SelectedNodeType, MoveType)
    if (MoveID !== 0 && SelectedNodeID !== 0 && NodeFatherType.includes(SelectedNodeType)) {
      GlobalNodes[MoveID].parentNode = SelectedNodeID.toString();
      //GlobalNodes[SelectedNodeID].height = 1000;
      //GlobalNodes[SelectedNodeID].width = 1000;
      let ordered = reorderNodesAndEdges(GlobalNodes, GlobalEdges);
      let orderedEdges = ordered.edges;
      let orderedNodes = ordered.nodes;
      console.log("GlobalNodes", GlobalNodes)
      console.log("orderedNodes", orderedNodes)

      setNodes(orderedNodes);
      setEdges(orderedEdges)
      GlobalNodes = orderedNodes;
      GlobalEdges = orderedEdges;

      /*if (ListFatherType.includes(SelectedNodeType)) {
        console.log("MoveID < SelectedNodeID", MoveID, SelectedNodeID)
        if (MoveID < SelectedNodeID) {
          let MovedIDSet = {}
          // Clone the nodes array to avoid mutating the original array
          let adjustedNodes = JSON.parse(JSON.stringify(GlobalNodes));
          // Preserve the original IDs to update child nodes
          const originalIDs = adjustedNodes.map(node => node.id);
          // Remove the node from the original position
          const [movedNode] = adjustedNodes.splice(parseInt(SelectedNodeID), 1);
          // Insert the node at the new position
          adjustedNodes.splice(MoveID, 0, movedNode);
          const NewSelectedNodeID = MoveID;
          const NewMoveID = NewSelectedNodeID + 1;
          console.log("NewMoveID", NewMoveID, NewSelectedNodeID)
          adjustedNodes[NewMoveID].parentNode = NewSelectedNodeID.toString();
          adjustedNodes[NewMoveID].id = NewMoveID.toString();
          MovedIDSet[SelectedNodeID] = NewSelectedNodeID;
          MovedIDSet[MoveID] = NewMoveID;
          console.log("MovedIDSet", MovedIDSet)
          adjustedNodes[NewSelectedNodeID].id = NewSelectedNodeID.toString();
          let MovedList = [NewSelectedNodeID, NewMoveID];
          for (let i = 2; i < adjustedNodes.length; i++) {
            if (!MovedList.includes(i)) {
              let ParentID = parseInt(adjustedNodes[i].parentNode);
              if (i !== parseInt(adjustedNodes[i].id)) {
                console.log("Type", adjustedNodes[i].type, parseInt(adjustedNodes[i].id))
                MovedIDSet[parseInt(adjustedNodes[i].id)] = i;
                adjustedNodes[i].id = i.toString();
                let NewParentID = MovedIDSet[ParentID];
                if (NewParentID !== undefined) {
                  console.log("NewParentID", NewParentID, ParentID)
                  console.log("MovedIDSet", MovedIDSet)
                  adjustedNodes[i].parentNode = NewParentID.toString();
                }
              } else {
                console.log("mesmo id")
                let Old = JSON.parse(JSON.stringify(GlobalNodes[ParentID]));
                Old.id = 0;
                let New = JSON.parse(JSON.stringify(adjustedNodes[ParentID]));
                New.id = 0;
                console.log("Old", Old, New)
                if (!deepEqual(Old, New)) {
                  console.log("Mudou parent")
                  for (let j = 2; j < adjustedNodes.length; j++) {
                    New = JSON.parse(JSON.stringify(adjustedNodes[j]));
                    New.id = 0;
                    console.log("New new", New)
                    if (deepEqual(Old, New)) {
                      console.log("encontrou novo parent")
                      adjustedNodes[i].parentNode = j.toString();
                      break;
                    }
                  }
                }
              }
            }
          }
          for (let i = NewMoveID + 1; i < adjustedNodes.length; i++) {
            //Ajusta o parentNode de todos os nodes filhos do SelectedNode (box que irá receber os nodes)
            if (adjustedNodes[i].parentNode === SelectedNodeID.toString()) {
              adjustedNodes[i].parentNode = NewSelectedNodeID.toString();
              adjustedNodes[i].id = i.toString();
              console.log("Moveu SelectedNodeID", SelectedNodeID, NewMoveID)
              MovedList.push(i)
            } else {
              //Ajusta o parentNode de todos os nodes filhos do MoveID (box que irá mover para o SelectedNode)
              if (adjustedNodes[i].parentNode === MoveID.toString()) {
                adjustedNodes[i].parentNode = NewMoveID.toString();
                adjustedNodes[i].id = i.toString();
                console.log("Moveu Move", MoveID, NewMoveID, i)
                MovedList.push(i)
              }
            }
          }
          console.log("MovedList", MovedList)
          let MovedListParent = JSON.parse(JSON.stringify(MovedList));
          //Ajusta os outros nodes não envolvidos diretamente no move
          for (let i = 2; i < adjustedNodes.length; i++) {
            if (!MovedList.includes(i)) {
              let OldID = adjustedNodes[i].id;
              if (i !== parseInt(adjustedNodes[i].id)) {
                adjustedNodes[i].id = i.toString();
                MovedList.push(i)
                MovedListParent.push(i)
                console.log("Change ID", OldID, i, adjustedNodes[i].type)
                for (let j = i; j < adjustedNodes.length; j++) {
                  if (!MovedList.includes(j) && !MovedListParent.includes(j)) {
                    if (adjustedNodes[j].parentNode === OldID) {
                      adjustedNodes[j].parentNode = i.toString();
                      console.log("Change ParentID", OldID, i, adjustedNodes[j].type)
                      MovedListParent.push(j)
                    }
                  }
                }
              }
            }
          }
          let NewGlobalNodes = [...GlobalNodes];
          console.log("GlobalNodes", NewGlobalNodes)
          console.log("adjustedNodes", adjustedNodes)
          setNodes(adjustedNodes);
          GlobalNodes = adjustedNodes;
        } else {
          console.log("MoveID", MoveID, SelectedNodeID);
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === MoveID.toString()) {
                node.parentNode = SelectedNodeID.toString();
              }
              return node;
            })
          )
        }
      } else {
        handleFeedback("The node $$" + MoveType.slice(0, -1) + "$$ cannot be moved to $$" + SelectedNodeType.slice(0, -1) + "$$");
      }*/
    }
    //} catch (error) {
    //  console.log("Erro Move")
    //}
  }

  function Mirror(direction) {
    const SelectedNodes = FindSelectedNodes(GlobalNodes);
    for (let i = 0; i < SelectedNodes.length; i++) {
      console.log("SelectedNodes", SelectedNodes)
      const Node = SelectedNodes[i];
      mirrorNodes(nodes, Node, direction, Constraints);
    }
  }

  function Paste(nodes, edges, DestinationList = [], Position = { x: 0, y: 0 }) {
    console.log("Start PAste *******************")
    let GlobalListCIDRSubnet = [];
    let GlobalListCIDRVPC = [];
    for (let i = 0; i < nodes.length; i++) {
      if (TypeSubnet.includes(nodes[i].type)) {
        let SubnetCIDRIndex = nodes[i].type == "SubnetN" ? 7 : 4;
        GlobalListCIDRSubnet.push(nodes[i].data.Param[SubnetCIDRIndex][1]);
      }
      if (TypeVPC.includes(nodes[i].type)) {
        let VPCCIDR = nodes[i].type == "VPCN" ? nodes[i].data.Param[3][2][1][1] : nodes[i].data.Param[3][1];
        GlobalListCIDRVPC.push(VPCCIDR)
      }
    }
    Counter += 1;
    let IsTemplate = DestinationList.length !== 0;
    let FlagRegionCloud = false;
    let ListNewNodesID = [];
    let CopyError = false;
    let SourceParentID = 0;
    let NewNodesCopy = [], NewEdges = [];
    //console.log("GlobalListNodesEdgesCopy[0]", GlobalListNodesEdgesCopy[0])
    try {
      for (let i = 0; i < GlobalListNodesEdgesCopy[0].length; i++) {
        if (TypeCloud.includes(GlobalListNodesEdgesCopy[0][i].type)) {
          FlagRegionCloud = true;
          break;
        }
      }
    } catch (error) {
      CopyError = true;
    }
    //try {
    if (CopyError) {
      if (FlagRegionCloud) {
        handleFeedback(" Copying is not allowed from Cloud $$Cloud$$ or Region $$Region$$");
      }
    } else {
      let IsFirstRegion = TypeRegion.includes(GlobalListNodesEdgesCopy[0][0].type);
      if (IsFirstRegion) {
        console.log("IsFirstRegion", IsFirstRegion);
        SourceParentID = GlobalListNodesEdgesCopy[0][0].id;
        GlobalListNodesEdgesCopy[0].shift();
        for (let i = 1; i < 4; i++) {
          for (let j = 0; j < GlobalListNodesEdgesCopy[i].length; j++) {
            let Source = parseInt(GlobalListNodesEdgesCopy[i][j].source)
            Source -= 1;
            let Target = parseInt(GlobalListNodesEdgesCopy[i][j].target)
            Target -= 1;
          }
        }
      }
      var DestinationNodeList = FindSelectedNodes(nodes);//List of Destination to nodes be copied
      DestinationNodeList = DestinationNodeList.concat(DestinationList);
      DestinationNodeList = [...new Set(DestinationNodeList)];
      if (IsTemplate) {  //Se for template só permite a cópia para um destino
        DestinationNodeList = DestinationNodeList.slice(-1);
      }
      var SubnetCIDR = "";
      var VPCCIDR = "";
      var NextIP = "";
      var IPPart = "";
      var CIDRPart = "";
      var FlagNextIP = true;
      var FlagVPCCopied = false; //This flag indicates if a VPC was copied or not
      let ListNodesToBeCopied = [];
      let GList = GlobalListNodesEdgesCopy[0];
      let NoCopy = ["TerraformN", "TerraformBackendS3N", "IGWN"];
      let ListID = [];
      for (let i = 0; i < GList.length; i++) {
        ListID.push(GList[i].id);
        let ParentNodeGListID = GList[i].parentNode;
        if (!NoCopy.includes(GList[i].type) || ListID.includes(ParentNodeGListID)) {
          //insere na lista apenas não é um NoCopy ou se for for, o node pai está na lista. Isso significa que está dentro de um box
          ListNodesToBeCopied.push(GList[i]);
        }
      }
      if (ListNodesToBeCopied.length > 0) {
        let ListEdgesToBeCopied = GlobalListNodesEdgesCopy[1];
        console.log("ListEdgesToBeCopied", ListEdgesToBeCopied);
        let FirstNodeIDToBeCopied = parseInt(ListNodesToBeCopied[0].id);
        console.log("DestinationNodeList", DestinationNodeList);
        //try {
        //for (let h = 0; h < DestinationNodeList.length; h++) {//Acha primeiro selected Node
        for (let h = 0; h < 1; h++) {//Acha primeiro selected Node
          //console.log("Passou aqui")
          let DestinationParentID = parseInt(DestinationNodeList[h].id);
          //console.log("Passou aqui");
          //Se o Node Box selecionado == Primeiro NodeID (box) a ser copiado, indica que é o mesmo node e por isso deve ser descartado 
          let DestinationParentName = nodes[DestinationParentID].data.Param[1][1];
          let DestinationParentType = nodes[DestinationParentID].type;
          let FirstNodeToBeCopiedType = nodes[FirstNodeIDToBeCopied].type;
          let NodeIndex = ResourceLookUp[DestinationParentType];
          let NodeFatherType = ListaStandard[NodeIndex].GeneralParam.NodeFatherType;
          console.log("Passou aqui", FirstNodeToBeCopiedType)
          let FirstNodeToBeCopiedName = nodes[FirstNodeIDToBeCopied].data.Param[1][1];
          console.log("FirstNodeToBeCopiedType", FirstNodeToBeCopiedType);
          let SourceParentID = parseInt(nodes[FirstNodeIDToBeCopied].parentNode);
          let SourceParentName = nodes[SourceParentID].data.Param[1][1];
          let SourceParentType = nodes[SourceParentID].type;
          console.log("DestinationParent", DestinationParentType, DestinationParentName, DestinationParentID)
          console.log("SourceParent", SourceParentType, SourceParentName, SourceParentID)
          let IsParentStage = false;
          let SourceList = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[DestinationParentID], "CodePipelineStageN");
          if (SourceList.length > 0) {
            IsParentStage = true;
          }
          console.log("IsParentStage", IsParentStage)
          let ChangeName = !(FirstNodeToBeCopiedType === "SBoxN" && nodes[FirstNodeIDToBeCopied].data.Param[4][1] !== "") &&
            !(FirstNodeToBeCopiedType === "VPCN" && nodes[FirstNodeIDToBeCopied].data.Param[13][1] !== "") &&
            !(FirstNodeToBeCopiedType === "SBoxN" && nodes[FirstNodeIDToBeCopied].data.Param[1][1].toLowerCase().includes("static") && IsParentStage)
          console.log("DestinationParentID !== FirstNodeIDToBeCopied", DestinationParentID, FirstNodeIDToBeCopied, FirstNodeToBeCopiedType, ChangeName)
          let AZInTheBox = false;
          if (FirstNodeToBeCopiedType === "SBoxN") {
            for (let i = 0; i < ListNodesToBeCopied.length; i++) {
              if (TypeAZ.includes(ListNodesToBeCopied[i].type)) {
                AZInTheBox = true;
                break;
              }
            }
          }
          const IsDestinationParentTypeVPC = TypeVPC.includes(DestinationParentType);
          const PermitCopy = IsDestinationParentTypeVPC || !IsDestinationParentTypeVPC && !AZInTheBox || IsTemplate;
          console.log("AZInTheBox", AZInTheBox, PermitCopy, IsDestinationParentTypeVPC)
          if (DestinationParentID !== FirstNodeIDToBeCopied && PermitCopy) {
            let NodeIndex = ResourceLookUp[DestinationParentType];
            var ListChieldType = ListaStandard[NodeIndex].GeneralParam.ListChieldType;
            console.log("TypeRegion", TypeRegion, DestinationParentType, ListChieldType)
            let RegionIndex = 0;
            if (DestinationParentType == "RegionN") { RegionIndex = nodes[DestinationParentID].data.Param[2][1]; } //Index da listRegions
            console.log("Passou aqui")
            var CurrentEdgesToBeCopied = [];
            if (IsTemplate) {
              for (let i = 0; i < ListEdgesToBeCopied.length; i++) {
                CurrentEdgesToBeCopied.push(ListEdgesToBeCopied[i]);
              }
            } else {
              for (let i = 0; i < ListEdgesToBeCopied.length; i++) {
                CurrentEdgesToBeCopied.push(ListEdgesToBeCopied[i]);
              }
            }
            NewNodesCopy = JSON.parse(JSON.stringify(ListNodesToBeCopied));
            NewEdges = JSON.parse(JSON.stringify(CurrentEdgesToBeCopied));
            console.log("NewNodesCopy", NewNodesCopy, NewEdges)
            let CloneStage = SourceParentType === DestinationParentType && SourceParentName === DestinationParentName;
            console.log("CloneStage", CloneStage)
            let NewEdgesOutSource = JSON.parse(JSON.stringify(GlobalListNodesEdgesCopy[2])); //new
            let NewEdgesOutTarget = JSON.parse(JSON.stringify(GlobalListNodesEdgesCopy[3])); //new
            if (CloneStage) {
              //Verifica se o box a ser copiado já existe no ambiente destination. Se sim deleta o box atual e substitui pelo novo
              const ListChildSource = FindNodesChieldID(GlobalNodes, SourceParentID.toString());
              const ListChildTarget = FindNodesChieldID(GlobalNodes, DestinationParentID.toString());
              console.log("ListChildSource", ListChildSource, NewEdgesOutSource)
              //Verifica as edges de conexão externa para substituir target e source destas edges pelos nodes do ambiente target
              // ao invés do ambiente source
              for (let i = 0; i < NewEdgesOutSource.length; i++) {
                let PushEdge = false;
                let EdgeTarget = NewEdgesOutSource[i].target;
                if (ListChildSource.includes(EdgeTarget)) {
                  let NodeSourceName = GlobalNodes[parseInt(EdgeTarget)].data.Param[1][1];
                  let NodeSourceType = GlobalNodes[parseInt(EdgeTarget)].type;
                  for (let j = 0; j < ListChildTarget.length; j++) {
                    let NodeTargetID = parseInt(ListChildTarget[j]);
                    let NodeTargetType = GlobalNodes[NodeTargetID].type;
                    if (NodeTargetType === NodeSourceType) {
                      let NodeTargetName = GlobalNodes[NodeTargetID].data.Param[1][1];
                      if (NodeTargetName === NodeSourceName) {
                        NewEdgesOutSource[i].target = NodeTargetID.toString();
                        NewEdges.push(NewEdgesOutSource[i])
                        PushEdge = true;
                      }
                    }
                  }
                }
                if (!PushEdge) {
                  NewEdges.push(NewEdgesOutSource[i])
                }
              }
              for (let i = 0; i < NewEdgesOutTarget.length; i++) {
                let EdgeSource = NewEdgesOutTarget[i].source;
                let PushEdge = false;
                console.log("EdgeSource", EdgeSource);
                if (ListChildSource.includes(EdgeSource)) {
                  let NodeSourceName = GlobalNodes[parseInt(EdgeSource)].data.Param[1][1];
                  let NodeSourceType = GlobalNodes[parseInt(EdgeSource)].type;
                  console.log("NodeSourceName", NodeSourceName, NodeSourceType);
                  for (let j = 0; j < ListChildTarget.length; j++) {
                    let NodeTargetID = parseInt(ListChildTarget[j]);
                    let NodeTargetType = GlobalNodes[NodeTargetID].type;
                    if (NodeTargetType === NodeSourceType) {
                      let NodeTargetName = GlobalNodes[NodeTargetID].data.Param[1][1];
                      if (NodeTargetName === NodeSourceName) {
                        NewEdgesOutTarget[i].source = NodeTargetID.toString();
                        NewEdges.push(NewEdgesOutTarget[i])
                        PushEdge = true;
                      }
                    }
                  }
                }
                if (!PushEdge) {
                  NewEdges.push(NewEdgesOutTarget[i])
                }
              }
            } else {
              if (NewEdgesOutSource.length !== 0 || NewEdgesOutTarget.length !== 0) {
                let Confirm = window.confirm("Do you want to copy edges external to the block? Click OK to copy or Cancel to skip.");
                if (Confirm) {
                  console.log("NewEdgesOutSource", NewEdgesOutSource, NewEdgesOutTarget)
                  NewEdges = NewEdges.concat(NewEdgesOutSource);//new
                  NewEdges = NewEdges.concat(NewEdgesOutTarget);//new
                }
              }
            }
            RemoveSelected(nodes);
            GlobalSelectedList.clear();
            selectNodes();
            //ListOldID = [];
            let LookUpOldIDToNew = {};
            let ExistsBox = false;
            let NewIPv6BlockIndex = "-1";
            console.log("ListNodesToBeCopied", ListNodesToBeCopied, ListChieldType)
            if (ListChieldType.includes(NewNodesCopy[0].type)) {

              for (let i = 0; i < NewNodesCopy.length; i++) {
                let GetID = GetNodeId(true);
                let ID = GetID[0];
                let OldID = NewNodesCopy[i].id;
                //ListOldID.push(OldID);
                NewNodesCopy[i].id = ID;
                ListNewNodesID.push(ID);
                LookUpOldIDToNew[parseInt(OldID)] = ID;
                let NewNodeType = NewNodesCopy[i].type;
                if (TypeSBox.includes(NewNodeType) || NewNodeType === "SecurityGroupN"
                  || TypeSubnet.includes(NewNodeType) || TypeAZ.includes(NewNodeType)) {
                  ExistsBox = true;
                }
                if (NewNodeType === "TerraformN") {
                  NewNodesCopy[i].data.CodeGenerated = "No Code";
                  NewNodesCopy[i].data.Compile = { "ErrorList": [], "WarningList": [] };
                }
                //*** trecho que ajusta os CIDRs e nomes dos nodes para valors únicos evitando repetições */
                let SubnetCIDRIndex = NewNodeType === "SubnetN" ? 7 : 4;
                if (TypeAZ.includes(NewNodeType) === false) {
                  //Chunxo, verificar pq AZN está dando problema na escrita. Qual a diferença? Msg de erro indica escrita em var read only.
                  let NodeName = NewNodesCopy[i].data.Param[1][1];
                  if (ChangeName) {
                    NodeName = SearchNameNodeType(nodes.concat(TemporaryNodeList), NewNodeType, NodeName);//Chage Name if alredy exists
                    NewNodesCopy[i].data.Param[1][1] = NodeName;
                  }
                  TemporaryNodeList.push(NewNodesCopy[i]); //Tirar qdo descobrir como atualizar automaticamente os nodes
                  if (TypeSubnet.includes(NewNodeType)) {
                    console.log("Passou Aqui Z2 ")
                    if (VPCCIDR != "") { //VPCCIDR != "" means a VPC was copied. So Subnet CIDR must follow VPC CIDR
                      FlagVPCCopied = true;
                      IPPart = VPCCIDR.split("/")[0];
                      console.log("SubnetCIDR do VPC", SubnetCIDR);
                      FlagNextIP = false; //This flag control NextIP call nextIPCIDR function
                      VPCCIDR = ""; //This is for not enter in this block again. This block must be ran just one time.
                    }
                    if (FlagVPCCopied) {
                      CIDRPart = NewNodesCopy[i].data.Param[SubnetCIDRIndex][1].split("/")[1];
                      SubnetCIDR = IPPart + "/" + CIDRPart;
                      NextIP = SubnetCIDR;
                      NewIPv6BlockIndex = parseInt(NewIPv6BlockIndex) + 1;
                      NewIPv6BlockIndex = NewIPv6BlockIndex.toString();
                    } else {
                      if (SubnetCIDR == "") {
                        SubnetCIDR = NewNodesCopy[i].data.Param[SubnetCIDRIndex][1];
                        //console.log("primeiro SubnetCIDR", SubnetCIDR);
                      } else {
                        SubnetCIDR = NextIP;
                        //console.log("proximos SubnetCIDR", SubnetCIDR);
                      }
                      let OldNodeType = GlobalNodes[OldID].type;
                      let VPCType = "VPCN";
                      let SubnetType = "SubnetN";
                      if (OldNodeType.startsWith("AZ")) {
                        VPCType = "AZVNETN";
                        SubnetType = "AZSubnetN"
                      }
                      let VPCID = FindResourceFather(GlobalNodes, GlobalNodes[OldID], VPCType);
                      let SubnetListID = FindNodesChieldID(GlobalNodes, VPCID, SubnetType);
                      NewIPv6BlockIndex = FindIPv6BlockIndex(GlobalNodes, SubnetListID);
                    }
                    //console.log("SubnetCIDR", SubnetCIDR);
                    if (FlagNextIP) {
                      NextIP = nextIPCIDR(SubnetCIDR, GlobalListCIDRSubnet);
                    }
                    GlobalListCIDRSubnet.push(NextIP);
                    //console.log("NextpIP", NextIP);
                    NewNodesCopy[i].data.Param[SubnetCIDRIndex][1] = NextIP;
                    //FlagNextIP = true;
                    //console.log("NewIPv6BlockIndex", NewIPv6BlockIndex);
                    NewNodesCopy[i].data.Param[16][1] = NewIPv6BlockIndex;
                  }
                  if (TypeVPC.includes(NewNodeType)) {
                    let VPCID = GetVPCParent(GlobalNodes, DestinationParentID);
                    if (VPCID !== 0) {
                      handleFeedback("Uma VPC não pode ser inserida dentro de outra VPC.");
                      return [[], []]
                    } else {
                      console.log("Inseriu VPC", NewNodeType, ID)
                    }
                    console.log("VPCCIDR aqui", VPCCIDR);
                    if (VPCCIDR == "") {
                      VPCCIDR = NewNodeType == "VPCN" ? NewNodesCopy[i].data.Param[3][2][1][1] : NewNodesCopy[i].data.Param[3][1];
                      //console.log("primeiro VPCCIDR", VPCCIDR);
                    } else {
                      VPCCIDR = NextIP;
                      //console.log("proximos VPCCIDR", VPCCIDR);
                    }
                    NextIP = nextIPCIDR(VPCCIDR, GlobalListCIDRVPC);
                    GlobalListCIDRVPC.push(NextIP);
                    if (NewNodeType == "VPCN") {
                      NewNodesCopy[i].data.Param[3][2][1][1] = NextIP;
                    } else { NewNodesCopy[i].data.Param[3][1] = NextIP; }
                    VPCCIDR = NextIP;
                  }
                } else {
                  if (TypeAZ.includes(FirstNodeToBeCopiedType)) {
                    let VPCID = GetVPCParent(GlobalNodes, DestinationParentID);
                    if (VPCID === 0) {
                      handleFeedback("Deve haver um VPC acima da AZ");
                      return [[], []]
                    }
                  }
                }
              }
              //console.log("LookUpOldIDToNew", LookUpOldIDToNew)
              console.log("ExistsBox", ExistsBox)
              // Atualiza o campo parentnode do nodes a serem copiados
              DestinationParentID = DestinationParentID.toString();
              let ISSameFather = true;
              for (let i = 0; i < NewNodesCopy.length; i++) {
                if (ExistsBox) {
                  if (i === 0 && !IsFirstRegion || IsFirstRegion && NewNodesCopy[i].parentNode === SourceParentID) {
                    if (IsFirstRegion) {
                      NewNodesCopy[i].position.x = NewNodesCopy[i].position.x + Position.x;
                      NewNodesCopy[i].position.y = NewNodesCopy[i].position.y + Position.y;
                      NewNodesCopy[i].selected = true;
                    }
                    NewNodesCopy[i].parentNode = DestinationParentID;
                  } else {
                    let OldParentNode = parseInt(NewNodesCopy[i].parentNode);
                    let NewParentNode = LookUpOldIDToNew[OldParentNode];
                    NewNodesCopy[i].parentNode = NewParentNode; //refaz Link dos demais nodes com o Node corrente, se  for filho deste
                  }
                } else {
                  NewNodesCopy[i].parentNode = DestinationParentID;
                  NewNodesCopy[i].selected = true;
                  let NodeIndex = ResourceLookUp[NewNodesCopy[i].type];
                  let NodeFatherType = ListaStandard[NodeIndex].GeneralParam.NodeFatherType;
                  if (NodeFatherType.includes(DestinationParentType) === false) {
                    ISSameFather = false;
                  }
                }
              }
              if (IsTemplate) {
                console.log("IsTemplate")
                for (let j = 0; j < NewNodesCopy.length; j++) {
                  let NodeType = NewNodesCopy[j].type;
                  if (TypeVPC.includes(NodeType)) {
                    console.log("VPC da subnet", NewNodesCopy[j].id, ISSameFather)
                    //AdjustSubnetCIDR(GlobalEdges, NewNodesCopy, j);
                  }
                }
              }
              if (ISSameFather) {
                for (let i = 0; i < NewNodesCopy.length; i++) {
                  setNodes((nodes) => {
                    return [...nodes, NewNodesCopy[i]];
                  });
                }
              }
              for (let j = 0; j < NewEdges.length; j++) {
                let Source = NewEdges[j].source;
                let NewSource = LookUpOldIDToNew[Source];
                if (typeof NewSource === 'undefined') { NewSource = Source; }
                NewEdges[j].source = NewSource;
                let Target = NewEdges[j].target;
                let NewTarget = LookUpOldIDToNew[Target];
                if (typeof NewTarget === 'undefined') { NewTarget = Target; }
                NewEdges[j].target = NewTarget;
                //console.log("new edge", NewEdges[j].source, NewEdges[j].target)
              }
              if (NewNodesCopy.length > 1) {//Se houver apenas um node para ser copiado não deve copiar as edges
                let ListEdgesPair = [];
                for (let j = 0; j < NewEdges.length; j++) {
                  let EdgePair = NewEdges[j].source + ":" + NewEdges[j].target;
                  //console.log(EdgePair);
                  if (NewEdges[j].source !== NewEdges[j].target) {
                    if (ListEdgesPair.includes(EdgePair)) {
                      //pass
                    } else {
                      NewEdges[j].id = GetEdgeId();
                      NewEdges[j].type = "FloatingEdgeE";
                      NewEdges[j].sourceHandle = "right";
                      NewEdges[j].targetHandle = "right";
                      NewEdges[j].markerEnd = {
                        type: MarkerType.ArrowClosed, width: 20, height: 20, color: '#000000',
                      };
                      setEdges((edges) => { return [...edges, NewEdges[j]] });
                      ListEdgesPair.push(EdgePair);
                    }
                  }
                }
                console.log("Edges after paste", edges);
              }
              RemoveSelected(nodes);
              GlobalSelectedList.clear();
              selectNodes();
              for (let i = 0; i < nodes.length; i++) {
                nodes[i].data.Select = false;
              }
              AdjustSubnetCIDRAfterPaste(GlobalEdges, GlobalNodes.concat(NewNodesCopy), NewNodesCopy);
            }
          }
        }
      }
    }
    GlobalNodes = GlobalNodes.concat(NewNodesCopy)
    GlobalEdges = GlobalEdges.concat(NewEdges)
    return [NewEdges, NewNodesCopy]
    //} catch (error) {
    //pass
    //}
  }

  const [IsEdgeFront, setIsEdgeFront] = useState(true);
  useHotkeys('e' || "E", () => {
    setIsEdgeFront(!IsEdgeFront)
    //console.log("Edge position", IsEdgeFront)
  })

  useHotkeys('shift+ctrl+l', () => {
    handleShowName();
  });
  useHotkeys('ctrl+c', () => {
    Copy();
  });
  const handleShowName = () => {
    //console.log("ShowLabel");
    //console.log("Nodes", nodes, GlobalNodes);
    setNodes((nds) =>
      nds.map((node) => {
        if (GlobalShowLabel == 0) {
          node.data.ShowLabel = true;
          node.data.ShowLabelName = true;
        }
        if (GlobalShowLabel == 1) {
          node.data.ShowLabel = false;
          node.data.ShowLabelName = true;
        }
        if (GlobalShowLabel == 2) {
          node.data.ShowLabel = false;
          node.data.ShowLabelName = false;
        }
        if (GlobalShowLabel == 3) {
          node.data.ShowLabel = true;
          node.data.ShowLabelName = false;
        }
        return node;
      })
    )
    //console.log(GlobalShowLabel)
    GlobalShowLabel += 1
    if (GlobalShowLabel == 4) {
      GlobalShowLabel = 0;
    }
  }

  const handlePinNodes = () => {
    console.log("Selected Nodes", GlobalSelectedList)
    let SelectedList = Array.from(GlobalSelectedList);
    setMenuVisible(false);
    setNodes((nds) =>
      nds.map((node) => {
        if (SelectedList.includes(node.id)) {
          if (node.draggable === undefined) { node.draggable = true }
          node.draggable = !node.draggable;
          console.log("draggable", node.draggable, node.id)
        }
        return node;
      })
    )
  }
  const handleExpandParent = () => {
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        expandParent: !ExpandParent,
      }))
    );
    setExpandParent(!ExpandParent)
  }



  const Copy = () => {
    //console.log("Copiar");
    console.log("GlobalSelectedList", GlobalSelectedList)
    GlobalListNodesEdgesCopy = FindNodesEdgesChieldSelected(GlobalNodes, GlobalEdges);
    console.log("GlobalListNodesEdgesCopy", GlobalListNodesEdgesCopy)
    let SelectArray = Array.from(GlobalSelectedList);
    let ListNodesCopyID = [];
    let FlagBox = false;
    handleFeedback("Copied: Now, select a destination box to paste (Ctrl+V) or Move (Ctrl+M).");
    try {
      for (let i = 0; i < GlobalListNodesEdgesCopy[0].length; i++) {
        ListNodesCopyID.push(GlobalListNodesEdgesCopy[0][i].id);
        if (ListBoxNodesType.includes(GlobalListNodesEdgesCopy[0][i].type)) { FlagBox = true; } //Permite selecionar apenas um BoxType por vez para cópia
      }
      let FirstNodeToBeCopiedParentNode = GlobalNodes[parseInt(SelectArray[0])].parentNode;
      for (let i = 1; i < SelectArray.length; i++) {
        if (GlobalNodes[parseInt(SelectArray[i])].parentNode !== FirstNodeToBeCopiedParentNode) {
          handleFeedback("All selected nodes must be on the same parent level to be able to copy.");
          FlagBox = true;
        }
      }
    } catch (error) {
      ListNodesCopyID = [];
      FlagBox = true;
    }
    if (FlagBox === false) {
      //console.log("GlobalListNodesEdgesCopy", GlobalListNodesEdgesCopy);
      //console.log("ListNodesCopyID", ListNodesCopyID, SelectArray)
      let ListNewEdges = [];
      for (let i = 0; i < SelectArray.length; i++) {
        if (ListNodesCopyID.includes(SelectArray[i]) == false) {
          let Node = GlobalNodes[parseInt(SelectArray[i])];
          GlobalListNodesEdgesCopy[0].push(Node);
          for (let j = 0; j < GlobalEdges.length; j++) {
            if ((SelectArray.includes(GlobalEdges[j].source)) && (SelectArray.includes(GlobalEdges[j].target))) {
              if (ListNewEdges.includes(GlobalEdges[j].id) == false) {
                GlobalListNodesEdgesCopy[1].push(GlobalEdges[j]);
                //console.log("Inseriu Nova Edge");
                ListNewEdges.push(GlobalEdges[j].id);
              }
            }
          }
        }
      }
    }
    //console.log("GlobalListNodesEdgesCopy", GlobalListNodesEdgesCopy);
  };

  useHotkeys('esc', () => {
    console.log("Esc");
    setNodes((nds) =>
      nds.map((node) => {
        node.selected = false;
        return node;
      })
    )
    setEdges((eds) =>
      eds.map((edge) => {
        edge.selected = false;
        edge.animated = false;
        edge.style = { stroke: '#AAA', width: 1 }
        return edge;
      })
    )
  });

  useHotkeys(
    'ctrl+v',
    () => {
      //console.log("Colar com external edges");
      Paste(nodes, edges);
    },
    {
      filter: event => {
        // Se o elemento ativo for um select, não dispara o hotkey
        if (document.activeElement.tagName === 'SELECT') {
          return false;
        }
        // Em outros casos, dispara o hotkey
        return true;
      }
    }
  );


  useHotkeys('ctrl+q', async () => {
    //console.log("Save Tamplete", GlobalSelectedList);
    if (Stage == "DevLocal") {
      for (let i = 0; i < GlobalNodes.length; i++) {
        if (GlobalNodes[i].type == "SBoxN" || GlobalNodes[i].type == "VPCN" || GlobalNodes[i].type == "RegionN") {
          //console.log("Save TampleteB", i, GlobalSelectedList);
          if (GlobalSelectedList.has(i.toString())) {
            var FileNameInit = sessionStorage.getItem("FileName");
            //console.log("Save TampleteC", FileNameInit);
            const TemplateList = FindNodesEdgesChield(GlobalNodes, GlobalEdges, GlobalNodes[i]);
            //console.log("TemplateList", TemplateList);
            const TemplateNodes = TemplateList[0];
            const TemplateEdges = TemplateList[1];
            const base64CompressedData = Compress(TemplateNodes, TemplateEdges);
            var raw = JSON.stringify([2, "Template", FileNameInit, base64CompressedData]);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
            var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
            let response = await fetch(APIDB, requestOptions);
            //console.log("Save Response. ", response)
            break;
          }
        }
      }
    }
  });

  async function PasteTemplate(TamplateName, Node, position) {
    var raw = JSON.stringify([7, TamplateName]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
    let response = await fetch(APIDB, requestOptions);
    const jsonData = Descompact(await response.json());
    let NodesEdges = await response.json();
    //console.log("NodesEdges", NodesEdges)
    GlobalListNodesEdgesCopy = NodesEdges.body;
    GlobalListNodesEdgesCopy[0][0].position = AdjustFatherPosition(GlobalNodes, Node.id, position);
    //console.log("Load Tamplete", GlobalListNodesEdgesCopy);
    Paste(GlobalNodes, GlobalEdges);
  }

  useHotkeys('shift+ctrl+q', async () => {
    PasteTemplate("BackEndFull")
  });

  useHotkeys('ctrl+z', () => {
    handleUnDo();
  }, {
    filter: event => {
      const element = event.target;
      return !(element.tagName.toLowerCase() === 'select' && element.multiple);
    }
  });

  const handleCopy = () => {
    Copy();
  }
  const handlePaste = () => {
    Paste(GlobalNodes, GlobalEdges);
  }
  const handleMove = () => {
    Move();
  }
  const handleXMirror = () => {
    Mirror("horizontal");
    setMenuVisible(false);
    selectNodes();
  }
  const handleYMirror = () => {
    Mirror("vertical");
    setMenuVisible(false);
    selectNodes();
  }

  const handleUnDo = () => {
    //console.log("Undo");
    setMenuVisible(false);
    if (GlobalUnDoPointer > -1) {
      //UnDo Nodes
      //console.log("GlobalUnDoPointer", GlobalUnDoPointer, GlobalCountStack);
      let UnDoNodes = GlobalStack[GlobalUnDoPointer][1];
      let UnDoEdges = GlobalStack[GlobalUnDoPointer][0];
      //console.log("UnDo", UnDoNodes, UnDoEdges);
      setNodes((nds) =>
        nds.map((node) => {
          for (let i = 0; i < UnDoNodes.length; i++) {
            if (UnDoNodes[i] == parseInt(node.id)) {
              let UnDoNodeType = GlobalNodes[UnDoNodes[i]].typeDel;
              //console.log("UnDoNodeType", UnDoNodeType);
              if (TypeSubnet.includes(UnDoNodeType)) {
                //console.log("GlobalNodes[UnDoNodes[i]]", GlobalNodes[UnDoNodes[i]])
                GlobalNodes[UnDoNodes[i]].parentNode = GlobalNodes[UnDoNodes[i]].parentNodeDel;
                let VPCID = FindResourceFather(GlobalNodes, GlobalNodes[UnDoNodes[i]], "VPCN");
                //console.log("VPCID", VPCID);
                let SubnetListID = FindNodesChieldID(GlobalNodes, VPCID, "SubnetN");
                //console.log("SubnetListID", SubnetListID);
                let NewIndex = FindIPv6BlockIndex(GlobalNodes, SubnetListID);
                GlobalNodes[UnDoNodes[i]].data.Param[16][1] = NewIndex;
              }
              let SourceType = JSON.parse(JSON.stringify(node.typeDel));
              node.type = SourceType;
              node.parentNode = JSON.parse(JSON.stringify(node.parentNodeDel));
              node.hidden = false;
              break;
            }
          }
          node.selected = false;
          return node;
        })
      );
      //UnDo Edges
      //console.log("UnDoEdges", UnDoEdges);
      for (let i = 0; i < UnDoEdges.length; i++) {
        let OldEdge = UnDoEdges[i];
        //console.log("OldEdge", OldEdge);
        ConnectEdge(OldEdge.source, OldEdge.target, OldEdge.label, OldEdge.labelStyle);
      }
      RemoveStack();
      //console.log("GlobalEdges", GlobalEdges);
      for (let i = 0; i < UnDoNodes.length; i++) {
        UpdateIconsOnUnDo(parseInt(UnDoNodes[i]), GlobalNodes[UnDoNodes[i]].type, true);
      }
      UpdateGeneralIcon();
    }
  };

  function Delete(ConfirmInput = true) {
    try {
      setShowModalMain(false);
      let FlagDeleteEdge = false;
      let EdgeID = 0;
      let SourceID = 0;
      let SourceType = 0;
      let TargetID = 0;
      let TargetType = 0;
      for (let i = 0; i < GlobalEdges.length; i++) {
        if ((GlobalEdges[i].selected === true) && (GlobalEdges[i].id.includes("UD") === false)) {
          EdgeID = GlobalEdges[i].id;
          SourceID = parseInt(GlobalEdges[i].source);
          SourceType = GlobalNodes[SourceID].type;
          TargetID = parseInt(GlobalEdges[i].target);
          TargetType = GlobalNodes[TargetID].type;
          FlagDeleteEdge = true;
          break
        }
      }
      if (FlagDeleteEdge) {
        //console.log("Deletou convencional edge", EdgeID, SourceID, TargetID);
        let DeleteList = [];
        for (let i = 0; i < GlobalEdges.length; i++) {
          let EdgeUDID = GlobalEdges[i].id;
          //console.log("UD", i, EdgeUDID);
          //console.log("Edge", GlobalEdges[i]);
        }
        handleDeleteEdge(EdgeID);
      } else {
        var SelectedArray = Array.from(GlobalSelectedList);
        //console.log("Delete Inicio", SelectedArray);
        for (let i = 1; i < nodes.length; i++) {
          if (nodes[i].selected == true) {
            //console.log("Node Selected =", i);
            if (SelectedArray.includes(nodes[i]) == false) {
              SelectedArray.push(i.toString());
            }
          }
        }
        let j = 0;
        let ErrorSelected = false; //usado para sair do code caso (GlobalNodes[SelectedArray[j]].selected == false) gere erro
        //Remove da lista SelectedArray qualquer node que tiver selected == false
        //console.log("SelectedArray", SelectedArray)
        while (j < SelectedArray.length) {
          try {
            let Node = GlobalNodes[SelectedArray[j]];
            let NodeType = Node.type;
            if (TypeAZ.includes(NodeType)) {
              //console.log("Node.Selected", Node.selected)
              //if (Node.Select === true) { Node.selected = true; } else { Node.selected = false; }
            }
            if (Node.selected == false) {
              //console.log("não Removeu", NodeType);
              SelectedArray.splice(j, 1);
            } else {
              j += 1;
            }
          } catch (error) {
            ErrorSelected = true;
            //console.log("erro delected")
            break;
          }
        }
        if (ErrorSelected === false) {
          var NodeParent = "";
          //console.log("SelectedArray", SelectedArray);
          let ListChield = [];
          let FlagFim = false;
          for (let i = 0; i < SelectedArray.length; i++) { //Search all chield nodes
            NodeParent = nodes[parseInt(SelectedArray[i])].parentNode;
            //console.log("NodeParent", NodeParent);
            let ListNodeEgdes = FindNodesChieldIDFromParent(nodes, nodes[parseInt(SelectedArray[i])]);
            //console.log("ListNodeEgdes", ListNodeEgdes);
            ListChield = ListChield.concat(ListNodeEgdes);
            //console.log("ListChield", ListChield);
            if (SelectedArray[i] == "1") {
              FlagFim = true;
            }
          }
          let Confirm = true;
          SelectedArray = SelectedArray.concat(ListChield); //join Selected nodes with Chield nodes
          SelectedArray = [...new Set(SelectedArray)];
          //console.log("SelectedArray", SelectedArray);
          //Cria SelectedArrayTest para servir de teste de confirmarção Removendo os nodes Text e Draw 
          let i = 0;
          let SelectedArrayTest = JSON.parse(JSON.stringify(SelectedArray));
          while (i < SelectedArrayTest.length) {
            //console.log("NodeType", GlobalNodes[SelectedArrayTest[i]].type, GlobalNodes[SelectedArrayTest[i]].selected);
            if (GlobalNodes[SelectedArrayTest[i]].type == "TextN") {
              SelectedArrayTest.splice(i, 1);
            } else {
              i += 1;
            }
          }
          if (ConfirmInput) {
            for (let i = 0; i < SelectedArrayTest.length; i++) {
              let NodeType = nodes[SelectedArrayTest[i]].type;
              if ((TypeCloud.includes(NodeType)) || (TypeRegion.includes(NodeType)) || (TypeVPC.includes(NodeType)) || (TypeSubnet.includes(NodeType))
                || (NodeType == "SBoxN") || (NodeType == "SecurityGroupN") || (SelectedArrayTest.length > 1)) {
                Confirm = window.confirm("This operation will delete multiples resources. Are you sure?");
                break;
              }
            }
          } else {
            Confirm = true;
          }
          if (Confirm) {
            //search edges to delete
            if (SelectedArray.length > 0) { //if == 0 means none node selected. do nothing
              let ListDeletedEdgesID = [];
              for (let i = 0; i < SelectedArray.length; i++) {
                for (let j = 0; j < GlobalEdges.length; j++) {
                  if ((GlobalEdges[j].source == GlobalNodes[parseInt(SelectedArray[i])].id)
                    || (GlobalEdges[j].target == GlobalNodes[parseInt(SelectedArray[i])].id)) {
                    //console.log("Edges aqui", GlobalEdges[j].id)
                    ListDeletedEdgesID.push(GlobalEdges[j].id);
                  }
                }
              }
              ListDeletedEdgesID = [...new Set(ListDeletedEdgesID)];;
              //console.log("ListDeletedEdgesID", ListDeletedEdgesID);
              let ListDeletedEdges = [];
              for (let i = 0; i < ListDeletedEdgesID.length; i++) {
                for (let j = 0; j < GlobalEdges.length; j++) {
                  let EdgeID = GlobalEdges[j].id;
                  if (ListDeletedEdgesID[i] == EdgeID) {
                    ListDeletedEdges.push(JSON.parse(JSON.stringify(GlobalEdges[j])));
                    handleDeleteEdge(EdgeID, false);
                  }
                }
              }
              for (let i = 0; i < SelectedArray.length; i++) { SelectedArray[i] = parseInt(SelectedArray[i]); }
              //console.log("SelectedArray", SelectedArray);
              SelectedArray = [...new Set(SelectedArray)];
              //console.log("SelectedArray", SelectedArray);
              StackInsert([ListDeletedEdges, SelectedArray]);
              setNodes((nds) =>
                nds.map((node) => {
                  for (let i = 0; i < SelectedArray.length; i++) {
                    //console.log("Pass", node.id, SelectedArray[i])
                    if ((SelectedArray[i] == node.id) && (node.type != "NullN")) {
                      node.typeDel = JSON.parse(JSON.stringify(node.type));
                      try { //chuncho, pq ocorreu um caso em que um node não tinha parentNode. Verificar
                        node.parentNodeDel = JSON.parse(JSON.stringify(node.parentNode));
                      } catch (error) {
                        node.parentNodeDel = "0"
                      }
                      node.type = "NullN";
                      node.hidden = true;
                      node.parentNode = "Config";
                      //console.log("Deleted Node", SelectedArray[i], node.typeDel)
                      break;
                    }
                  }
                  node.selected = false;
                  return node;
                })
              )
              GlobalSelectedList.clear();
              /*console.log("ListEdges", ListEdges, ListEdges.length);
              for (let i = ListEdges.length; i > 0; i--) {
                edges.pop(0);
               //console.log("Esges list", edges);
              }*/
              if (!HideEdges) {
                setEdges((eds) =>
                  eds.map((edge) => {
                    return edge;
                  })
                )
              }
              //console.log("Delete Fim");
            }
            //console.log("Delete Non", nodes);
          }
        }
      }
    } catch (error) {
      //pass
    }
  }
  useHotkeys('delete', () => {
    //console.log("Delete")
    Delete();
  });

  useHotkeys('shift+s', () => {
    //console.log("GlobalSelectedList", GlobalSelectedList);
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].selected == true) {
        //console.log("Node Selected =", i);
      }
    }
  });

  useHotkeys('shift+ctrl+h', () => {   // Era 'shift+ctlr+h', mas deve ser 'shift+ctrl+h'
    //console.log("HighLight");
    HighligthTerraformNodes(TerraformNodesList);
  });

  useHotkeys('shift+ctrl+m', () => {   // Era 'ctlr+m', mas deve ser 'ctrl+m'
    HandleGlobalMiniMap();
    //console.log("Minimap", GlobalMiniMap);
    console.log("Nodes", GlobalNodes)
    console.log("Edges", GlobalEdges)
  });

  useHotkeys('ctrl+m', () => {
    Move();
  });

  const RelativeSelectAll = () => {
    try {
      var SelectedNode = FindSelectedNodes(nodes)[0];
      console.log("SelectedNode", SelectedNode)
      let ListNodes = FindNodesChieldIDFromParent(nodes, SelectedNode);
      ListNodes.shift();
      console.log("ListNodes", ListNodes)
      for (let j = 0; j < ListNodes.length; j++) {
        if (TypeAZ.includes(GlobalNodes[parseInt(ListNodes[j])].type)) {
          GlobalNodes[parseInt(ListNodes[j])].data.Select = true;
        }
      }
      GlobalSelectedList.clear();
      ListNodes.forEach(item => GlobalSelectedList.add(item));
      selectNodes();
      setMenuVisible(false);
    } catch (error) {
      //pass
    }
  };

  function HandlerGeneral(NodeType, nodes, NodeData, NewDataParams = "", position = "", ListBoxNodesType = "") {
    let Resource = NodeType.slice(0, -1);
    console.log("NodeType", NodeType)
    let ID = "";
    if (position != "") {
      let NodeIndex = ResourceLookUp[NodeType];
      let NodeFatherType = ListaStandard[NodeIndex].GeneralParam.NodeFatherType;
      let NodesPotentialFather = findIntersectingNodesBox(GlobalNodes, position, ListBoxNodesType);
      console.log("NodesPotentialFather", NodesPotentialFather, NodeFatherType)
      let ListPotentialFather = [];
      for (let i = NodeFatherType.length - 1; i >= 0; i--) {//Lista todos os nodes pai potenciais que podem ser pai do novo node
        for (let j = 0; j < NodesPotentialFather.length; j++) {
          if (NodeFatherType[i] == NodesPotentialFather[j][1]) {
            ListPotentialFather.push(NodesPotentialFather[j][0]);
          }
        }
      }
      console.log("ListPotentialFather", ListPotentialFather)
      let FlagInsert = false;
      let IDParent = 0;
      if (ListPotentialFather.length == 1) {
        IDParent = ListPotentialFather[0];
        FlagInsert = true;
      }
      if (ListPotentialFather.length > 1) {
        IDParent = ListPotentialFather[ListPotentialFather.length - 1];
        let Level = 0;
        FlagInsert = true;
        //Procura o node pai potencial de maior descendÃªncia hierÃ¡rquica
        for (let j = 0; j < ListPotentialFather.length; j++) {
          let FindLevel = FindFiliationLevel(GlobalNodes, ListPotentialFather[j]);
          if (FindLevel > Level) {
            Level = FindLevel;
            IDParent = ListPotentialFather[j];
            const Temporary = (GlobalNodes[parseInt(IDParent)].data.Temporary === true) ? true : false;
            if (Temporary) { IDParent = ListPotentialFather[j - 1]; console.log("******************************************* Temporary**************************") }
          }
        }
      }
      const ErrorMsg = BoxRestrictions(GlobalNodes, NodeType, IDParent);
      if (ErrorMsg == "") {
        if (FlagInsert == true) {
          console.log("Passou aqui")
          const NewPosition = JSON.parse(JSON.stringify(AdjustFatherPosition(nodes, IDParent, position)));
          let Position = {};
          //***********************Criando Group Nodes ********************************************
          if ((ResourceGroup[NodeType].length > 0) && (GlobalCtrlPressed || NodeType === "TerraformBackendS3N")) {
            GlobalSelectedList.clear();
            let NewNodeID = 0;
            let ListNodes = ResourceGroup[NodeType][0];
            let DictNodesID = {};
            let NewNodeList = [];
            for (let k = 0; k < ListNodes.length; k++) {
              let NewNode = ListNodes[k][0];
              Position.x = position.x + ListNodes[k][1]; Position.y = position.y + ListNodes[k][2];
              NewNodeID = NodeGeneral(IDParent, NewNode.slice(0, -1), nodes, NodeData, NewDataParams, Position).toString();
              DictNodesID[NewNode] = NewNodeID;
              NewNodeList.push(NewNodeID);
            }
            ID = NewNodeID;
            let ListEdges = ResourceGroup[NodeType][1];
            //***********************Conectando Edges Group Nodes***********************************
            let NewEdgesList = [];
            for (let k = 0; k < ListEdges.length; k++) {
              let Source = ListEdges[k][0];
              for (let l = 1; l < ListEdges[k].length; l++) {
                let Target = ListEdges[k][l];
                NewEdgesList.push([DictNodesID[Source], DictNodesID[Target]]);
              }
            }
            GlobalSelectedList.clear();
            for (let k = 0; k < NewNodeList.length; k++) {
              GlobalSelectedList.add(NewNodeList[k]);
            }
            for (let k = 0; k < NewEdgesList.length; k++) {
              ConnectEdge(NewEdgesList[k][0], NewEdgesList[k][1]);
            }
            FlagInsert = false;
            return ID
          }
          if (FlagInsert) {
            console.log("AZ passou")
            ID = NodeGeneral(IDParent, Resource, nodes, NodeData, NewDataParams, NewPosition);
          }
          selectNodes();
          return ID;
        } else {
          let ListSGChield = ["EC2N", "RDSN", "RDSReplicaN", "ENIN", "FargateN", "CodeBuildN"];
          if (ListSGChield.includes(NodeType)) {
            let ID = HandlerGeneral("SecurityGroupN", nodes, NodeData, NewDataParams, position, ListBoxNodesType);
            if (ID != "") {
              ID = NodeGeneral(ID, Resource, nodes, NodeData, NewDataParams, { x: 25, y: 29 });
              return ID;
            } else {
              let ID = HandlerGeneral("SubnetN", nodes, NodeData, NewDataParams, position, ListBoxNodesType);
              if (ID != "") {
                ID = NodeGeneral(ID, "SecurityGroup", nodes, NodeData, NewDataParams, { x: 33, y: 46 });
                ID = NodeGeneral(ID, Resource, nodes, NodeData, NewDataParams, { x: 25, y: 29 });
                return ID;
              }
            }
          } else { //Azure
            let ListSGChield = ["AZLinuxVMN", "AZNIN"];
            if (ListSGChield.includes(NodeType)) {
              let ID = HandlerGeneral("AZSecurityGroupN", nodes, NodeData, NewDataParams, position, ListBoxNodesType);
              if (ID != "") {
                ID = NodeGeneral(ID, Resource, nodes, NodeData, NewDataParams, { x: 25, y: 29 });
                return ID;
              } else {
                let ID = HandlerGeneral("AZSubnetN", nodes, NodeData, NewDataParams, position, ListBoxNodesType);
                if (ID != "") {
                  ID = NodeGeneral(ID, "AZSecurityGroup", nodes, NodeData, NewDataParams, { x: 33, y: 46 });
                  ID = NodeGeneral(ID, Resource, nodes, NodeData, NewDataParams, { x: 25, y: 29 });
                  return ID;
                }
              }
            }
            return "";
          }
        }
      } else {
        handleFeedback('ErrorMsg');
        console.log("ErrorMsg", ErrorMsg)
      }
    }
  }
  function AddNodeFunc(newNode, Recycle, id, IDParent, nodes) {
    IDParent = parseInt(IDParent);
    //console.log("IDParent", IDParent, typeof (IDParent));
    //Adjust new node Size and position 
    let ResourceX = newNode["position"]["x"];
    let ResourceY = newNode["position"]["y"];
    let ResourceWidth = 40;
    let ResourceHeight = 60;
    let NodeParentWidth = 0;
    let NodeParentHeight = 0;
    if (TypeCloud.includes(newNode.type) == false) {
      //console.log("Config", nodes[IDParent]);
      try {
        NodeParentWidth = nodes[IDParent]["style"]["width"];
        NodeParentHeight = nodes[IDParent]["style"]["height"];
      } catch (error) {
        //pass
      }
    }
    if ((TypeSubnet.includes(newNode.type)) || (TypeVPC.includes(newNode.type)) || (TypeRegion.includes(newNode.type))
      || (newNode.type == "SecurityGroupN") || (newNode.type == "SBoxN")) {
      ResourceWidth = newNode["style"]["width"];
      ResourceHeight = newNode["style"]["height"];
      let ResourceMinWidth = newNode["data"]["MinWidth"];
      let ResourceMinHeight = newNode["data"]["MinHeight"];
      //console.log("nodes[IDParent]", nodes[IDParent])
      let NodeParentMinWidth = nodes[IDParent]["data"]["MinWidth"];
      let NodeParentMinHeight = nodes[IDParent]["data"]["MinHeight"];
      //console.log("ResourceHeight", ResourceHeight, NodeParentHeight)
      if (ResourceHeight > NodeParentHeight) {
        ResourceHeight = NodeParentHeight;
        newNode["style"]["height"] = NodeParentHeight;
      }
      if (ResourceWidth > NodeParentWidth) {
        newNode["style"]["width"] = NodeParentWidth;
        ResourceWidth = NodeParentWidth;
      }
    }
    if (TypeCloud.includes(newNode.type) === false) {
      if ((ResourceY + ResourceHeight) > NodeParentHeight) { newNode["position"]["y"] = NodeParentHeight - ResourceHeight; }
      if ((ResourceX + ResourceWidth) > NodeParentWidth) { newNode["position"]["x"] = NodeParentWidth - ResourceWidth; }
      if (ResourceY < 0) { newNode["position"]["y"] = 0; }
      if (ResourceX < 0) { newNode["position"]["x"] = 0; }
    }
    //console.log("NovoNode******", newNode);
    //Insert the new node
    newNode.draggable = true;
    setNodes((nodes) => { return [...nodes, newNode]; });
    GlobalNodes.push(newNode);
    RemoveSelected(GlobalNodes);
    RemoveSelected(nodes);
    GlobalSelectedList.clear();
    selectNodes();
  }

  function NodeGeneral(IDParent, Resource, nodes, NodeData, NewDataParams, position = "") {
    let NodeType = Resource + "N";
    let NodeIndex = ResourceLookUp[NodeType];
    let NodeFatherType0 = ListaStandard[NodeIndex].GeneralParam.NodeFatherType[0];
    console.log("NodeFatherType0", NodeFatherType0, IDParent)
    const VPCParentID = GetVPCParent(GlobalNodes, IDParent);
    const VPCParentType = nodes[VPCParentID].type;
    console.log("VPCParentType", VPCParentID, VPCParentType);
    if (TypeVPC.includes(NodeFatherType0) && VPCParentType === NodeFatherType0 || !TypeVPC.includes(NodeFatherType0)) {
      let GetID = GetNodeId();
      const id = GetID[0];
      const Recycle = GetID[1];
      var NewData = "";
      if (Recycle) {
        NewData = NodeData[Resource];
        //console.log("NewData", NewData)
      } else {
        //console.log("Resource", Resource)
        NewData = JSON.parse(JSON.stringify(NodeData[Resource]));
      }
      let newNode = { id, parentNode: `${IDParent}`, ...NewData }
      if (NewDataParams != "") {
        newNode.data.EspecificParams = NewDataParams;
      }
      if (position != "") {
        newNode.position.x = position.x - 10;
        newNode.position.y = position.y - 10;
      }
      //console.log("newNode", newNode, position)
      if (!TypeAZ.includes(Resource + "N")) {
        let Name = SearchNameNodeType(nodes, Resource + "N", newNode.data.Param[1][1]);
        newNode.data.Param[1][1] = Name;
      }
      if (Resource == "Terraform") {
        newNode.data.ParentID = newNode.parentNode;
        newNode.data.onValueChange = handleChildValueChange;
      }
      if ((Resource == "Subnet") || (Resource == "AZSubnet")) {
        let AZID = parseInt(newNode.parentNode);
        //let VPCID = parseInt(nodes[AZID].parentNode);
        let VPCName = GlobalNodes[VPCParentID].data.Param[1][1];
        let VPCType = GlobalNodes[VPCParentID].type;
        console.log("VPCID", VPCParentID);
        let SubnetCIDRRange = nodes[VPCParentID].data.Param[12][1];
        //console.log("SubnetCIDRRange", SubnetCIDRRange);
        let VPCCIDR = VPCType == "VPCN" ? nodes[VPCParentID].data.Param[3][2][1][1].toString() : nodes[VPCParentID].data.Param[3][1].toString()
        let FirstSubnetCIDR = VPCCIDR.slice(0, -2) + SubnetCIDRRange;
        //console.log("FirstSubnetCIDR", FirstSubnetCIDR);
        let SubnetListID = FindNodesChieldID(nodes, VPCParentID, Resource + "N");
        //console.log("SubnetListID", SubnetListID);
        let SubnetCIDRList = [];
        let SubnetCIDRIndex = VPCType == "VPCN" ? 7 : 4;
        for (let i = 0; i < SubnetListID.length; i++) {
          SubnetCIDRList.push(nodes[SubnetListID[i]].data.Param[SubnetCIDRIndex][1])
        }
        //console.log("SubnetCIDRList", SubnetCIDRList);
        if (SubnetCIDRList.length == 0) {
          newNode.data.Param[SubnetCIDRIndex][1] = FirstSubnetCIDR;
        } else {
          let NewSubnet = nextIPCIDR(FirstSubnetCIDR, SubnetCIDRList, VPCCIDR);
          newNode.data.Param[SubnetCIDRIndex][1] = NewSubnet;
        }
        //Preenche o IPv6_Block_Index 
        //SubnetListID.push(parseInt(id));
        let NewIndex = FindIPv6BlockIndex(GlobalNodes, SubnetListID);
        newNode.data.Param[16][1] = NewIndex;
      }
      if ((TypeAZ.includes(Resource))) {
        //let VPCID = parseInt(newNode.parentNode);
        let RegionID = parseInt(GlobalNodes[VPCParentID].parentNode);
        let RegionName = GlobalNodes[RegionID].data.Param[2][2];
        if (Resource === "AZ") {
          newNode.data.Param[1][1] = RegionName + "a";
        } else {
          newNode.data.Param[1][1] = "1";
        }
      }
      RemoveSelected(nodes);
      //console.log("RemoveSelected", IDParent);
      AddNodeFunc(newNode, Recycle, id, IDParent, GlobalNodes);
      return id;
    } else {
      console.log("erro")
      handleFeedback("Must be inside a VPC $$VPC");
    }
  }

  function ConnectEdge(SourceID, TargetID, Label = "", LabelStyle = "") {
    if (Label == "") {
      let SourceType = GlobalNodes[SourceID].type;
      let TargetType = GlobalNodes[TargetID].type;
      Label = UpdateIcons(SourceID, SourceType, TargetID, TargetType);
      LabelStyle = { fontSize: 5 }
    }
    let EdgeID = GetEdgeId()
    const NewEdge = {
      id: EdgeID, source: SourceID, target: TargetID, className: 'normal-edge', type: "FloatingEdgeE",
      style: { stroke: '#AAA' }, zIndex: 7, animated: false, hidden: false,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: '#000000',
      }, markerStart: { type: MarkerType.ArrowClosed }, label: labels[EdgeID] || Label,
      labelStyle: LabelStyle,
    };
    //GlobalEdges.push([newEdge]);
    setEdges((edges) => { return [...edges, NewEdge] });
  }

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    async (event) => {
      event.preventDefault();
      if (GlobalCtrlPressed) {
        //console.log("Ctrl pressed");
      }
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,// - 5,
        y: event.clientY - reactFlowBounds.top// - 10,
      });
      //console.log("Typexxxx", type)
      //console.log("position Drop", position)
      let FlagAdd = false;
      let ID = "";
      console.log("Lista nodes &&&&&&&&&&")
      if (type == "TemplateN") {
        let RegionToPaste = "";
        const TemplateFileName = sessionStorage.getItem("TemplateFileName");
        if (TemplateFileName !== "undefined") {
          let FatherList = findIntersectingNodesBox(GlobalNodes, position, ListBoxNodesType);
          for (let i = 0; i < FatherList.length; i++) {
            if (FatherList[i][1] === "RegionN") {
              RegionToPaste = GlobalNodes[parseInt(FatherList[i][0])];
              RegionToPaste.selected = true;
              FlagAdd = true;
              break;
            }
          }
          if (FlagAdd) {
            RemoveSelected(GlobalNodes);
            var raw = JSON.stringify([3, "Template", TemplateFileName]);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
            var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
            fetch(APIDB, requestOptions)
              .then(response => response.text())
              .then(result => {
                GlobalListNodesEdgesCopy = Descompact(JSON.parse(result).body);
                GlobalListNodesEdgesCopy[0][0].position = AdjustFatherPosition(GlobalNodes, RegionToPaste.id, position);
                GlobalListNodesEdgesCopy[0] = UpdateDataNodes(GlobalListNodesEdgesCopy[0])
                console.log("GlobalListNodesEdgesCopy[0]", GlobalListNodesEdgesCopy[0])
                Paste(GlobalNodes, GlobalEdges, RegionToPaste, position);
              })
              .catch(error => {
                //console.log('error', error);
              })
          } else {
            handleFeedback(" The templates can only be dropped onto a Region $$Region");
          }
        } else {
          handleFeedback(" Click on the template button to select a template");
        }
      } else {
        if (type == "TerraformBackendFullN") {
          let FatherList = findIntersectingNodesBox(GlobalNodes, position, ListBoxNodesType);
          if (FatherList.length == 2) {//Significa que father pode ser apenas Cloud ou Region. Se for == 1 apenas Cloud, se for maior do que
            //2 pode ser VPC e/ou SBox
            let RegionID = FatherList[1][0];
            GlobalNodes[RegionID].selected = true;
            FlagAdd = true;
            PasteTemplate("BackEndFull", GlobalNodes[RegionID], position);
          }
        } else {
          if (TypeCloud.includes(type)) {
            ID = addCloudHandler(position, type);
            FlagAdd = true;
          } else {
            if (TypeVPC.includes(type)) {
              RemoveSelected(GlobalNodes);
              let FatherList = findIntersectingNodesBox(GlobalNodes, position, ListBoxNodesType);
              console.log("FatherList", FatherList, ListBoxNodesType);
              for (let i = 0; i < FatherList.length; i++) {
                //if (TypeRegion.includes(FatherList[i][1])) {
                if ((FatherList[i][1] === "RegionN" && type === "VPCN") || (FatherList[i][1] === "AZRegionN" && type === "AZVNETN")) {
                  GlobalNodes[parseInt(FatherList[i][0])].selected = true;
                  FlagAdd = true;
                  break;
                }
              }
              ID = addVPCHandler(GlobalNodes, position, type, FatherList);
            } else {
              console.log("Lista nodes &&&&&&&&&&BBB", ListBoxNodesType)
              ID = HandlerGeneral(type, GlobalNodes, NodeData, "", position, ListBoxNodesType);
              //console.log("positon HandlerGeneral", ID, position, type, GlobalNodes, nodes);
              if (ID != "") {
                FlagAdd = true;
              }
            }
          }
        }
        //Block new Node feedback
        //console.log("Block new Node feedback")
        let Resource = type.slice(0, -1);
        //let ListNodeFatherTypes = NodeData[Resource].data.NodeFatherType;
        let NodeIndex = ResourceLookUp[type];
        var ListNodeFatherTypes = ListaStandard[NodeIndex].GeneralParam.NodeFatherType;
        //console.log("ListNodeFatherTypes", ListNodeFatherTypes)
        if (FlagAdd == false) {
          let AddText = "";
          if (ListNodeFatherTypes.length > 1) {
            AddText = " any of these"
            for (let i = 0; i < ListNodeFatherTypes.length - 1; i++) {
              if ((TypeAZ.includes(ListNodeFatherTypes[i]) === false)) {
                AddText += "$$" + ListNodeFatherTypes[i + 1].slice(0, -1);
              }
            }
          }
          let NodeFatherType = ListNodeFatherTypes[0].slice(0, -1)
          if (Resource == "Subnet") {
            AddText = " any AZ in ";
            NodeFatherType = "VPC"
          }
          if (Resource == "AZSubnet") {
            AddText = " any AZ in ";
            NodeFatherType = "AZVNET"
          }
          if ((Resource == "EC2") || (Resource == "ENI") || (Resource == "RDS")) {
            AddText = " any of these $$AZ$$Subnet";
            NodeFatherType = "SecurityGroup"
          }
          //console.log("NodeFatherType", NodeFatherType, Resource);
          handleFeedback("You must drop $$" + Resource + "$$ only on" + AddText + "$$" + NodeFatherType);
        } else { handleCloseFeedback() }
      }
      //console.log("Aqi")
      RemoveSelected(nodes);
      GlobalSelectedList.add(ID);
      //console.log("GlobalSelectedList", GlobalSelectedList);
      selectNodes();
    },

    [reactFlowInstance]
  );

  const addCloudHandler = (position, type) => {
    RemoveSelected(nodes);
    let GetID = GetNodeId();
    const id = GetID[0];
    const Recycle = GetID[1];
    //console.log("NodeData", NodeData)
    let NewData = JSON.parse(JSON.stringify(NodeData[type.slice(0, -1)]));
    //console.log("position Cloud", position)
    NewData.position = position;
    //console.log("NewData", NewData)
    const newNode = { id, ...NewData };
    AddNodeFunc(newNode, Recycle, id, 0, GlobalNodes);
    return GetID
  };

  function VPCFunc(VPCName, nodes, position, VPCType, FatherList) {
    const VPCWidth = 800;
    const VPCHeight = 900;
    let ResourceVPC = VPCType.slice(0, -1);
    let FatherID = FatherList[FatherList.length - 1][0];
    if (VPCType.includes(GlobalNodes[FatherID].type)) {
      FatherID = FatherList[FatherList.length - 2][0];
    }
    if (GlobalNodes[FatherID].type === "SBoxN") {
      console.log("é um SBOX")
      if ((GetVPCParent(GlobalNodes, FatherID)) !== 0) {
        console.log("Havia um VPC sobre")
        return "";
      }
    }
    console.log("Father", FatherID, nodes[FatherID])
    if (GlobalNodes[FatherID].width < VPCWidth) {
      GlobalNodes[FatherID].width = VPCWidth;
    }
    if (GlobalNodes[FatherID].height < VPCHeight) {
      GlobalNodes[FatherID].height = VPCHeight;
    }
    let RegionID = parseInt(FindRegionAndCloud(GlobalNodes, GlobalNodes[FatherID].id)[1]);
    console.log("Region", RegionID, GlobalNodes[RegionID].data.Param[2][1], GlobalNodes[RegionID].data.Param[1][1]);
    //for (let i = 0; i < nodes.length; i++) {
    if ((GlobalNodes[RegionID].selected == true) && (TypeRegion.includes(GlobalNodes[RegionID].type))) {
      let AvailablesAZs = 4;
      let RegionIndex = 0;
      if (VPCType == "VPCN") {
        RegionIndex = GlobalNodes[RegionID].data.Param[2][1]; //Index da listRegions
        //console.log("RegionIndex", RegionIndex, ListRegions)
        AvailablesAZs = ListRegions[RegionIndex][3];
      }
      console.log("AvailablesAZs", AvailablesAZs);
      let IDParent = nodes[FatherID].id;
      let GetID = GetNodeId();
      var IDVPC = GetID[0];
      const Recycle = GetID[1];
      //console.log("IDVPC", IDVPC, nodes);
      RemoveSelected(GlobalNodes);
      let NewData = JSON.parse(JSON.stringify(NodeData[ResourceVPC]));
      const newNode = { id: IDVPC, parentNode: `${IDParent}`, draggable: true, deletable: false, ...NewData }
      newNode.style.height = 670;
      let Name = SearchNameNodeType(GlobalNodes, VPCType, VPCName);
      newNode.data.Param[1][1] = Name;
      let VPCCIDRList = GetCIDRList(GlobalNodes, VPCType);
      GlobalLastVCPCIDR = nextIPCIDR(GlobalLastVCPCIDR, VPCCIDRList, GlobalLastVCPCIDR);
      //console.log("CIDRVPC", VPCCIDRList, GlobalLastVCPCIDR);
      if (VPCType == "VPCN") {
        newNode.data.Param[3][2][1][1] = GlobalLastVCPCIDR;
      } else { newNode.data.Param[3][1] = GlobalLastVCPCIDR; } //AZVNET
      if (position != "") {
        newNode.position = AdjustFatherPosition(GlobalNodes, IDParent, position);
      }
      AddNodeFunc(newNode, Recycle, IDVPC, IDParent, GlobalNodes);
      //const newNodeAZ;
      GlobalAZQtity = AvailablesAZs;
      let AZType = VPCType == "VPCN" ? "AZ" : "AZAZ";
      let AZName;
      let AZNameSufix;
      for (let Index = 0; Index < 2; Index++) {
        let GetID = GetNodeId();
        var IDAZ = GetID[0];
        const Recycle = GetID[1];
        if (AZType === "AZ") {
          AZNameSufix = Index; //ListRegions[RegionIndex][4][Index];//
          AZName = ListRegions[RegionIndex][0] + AZNameSufix//ListRegions[RegionIndex][4][Index];// String.fromCharCode(97 + Index);
          console.log("AZNameSufix", ListRegions, Index, AZNameSufix, AZName)
        } else {
          if (Index === 0) { AZNameSufix = "Regional"; } else { AZNameSufix = (Index).toString(); }
          AZName = ListRegions[RegionIndex][0] + AZNameSufix;
        }
        let NewData = JSON.parse(JSON.stringify(NodeData[AZType]));
        let newNode = { id: IDAZ, parentNode: `${IDVPC}`, ...NewData }
        //console.log("AZName", AZName, AZNameSufix);
        //newNode.data.Param = [AZNameSufix, AZName]; // Insere Param do AZ [Letra do AZ (a,b,c..), RegionShort+AZn]
        console.log("newNode", newNode, AZName)
        newNode.data.Param[1][1] = AZName;
        newNode.data.Param[2][1] = Index;
        newNode.data.Param[2][2] = AZNameSufix;
        newNode.data.Param[0] = AZName;
        newNode.position.y = NodeData[AZType].position.y + (Index * 210);
        AddNodeFunc(newNode, Recycle, IDAZ, IDParent, GlobalNodes);
        GlobalAZID[Index] = IDAZ;
        GlobalAZName[Index] = AZName;
      }
      let Position = {};
      Position.x = 18; Position.y = 18;
      let IDTerraform = NodeGeneral(IDVPC, "Terraform", GlobalNodes, NodeData, "", Position);
      setNodes((nds) =>
        nds.map((node) => {
          return node;
        })
      )
      return IDVPC
    };
    //console.log("Aqui");
    //}
    return ""
  }

  const addVPCHandler = (GlobalNodes, position = "", Type, FatherList) => {
    //console.log("PAssou aqui addVPCHandler")
    let VPCName = "VNet"
    if (Type === "VPCN") {
      VPCName = "VPC";
    }
    VPCFunc(VPCName, GlobalNodes, position, Type, FatherList);
  };

  const [modalNodeType, setModalNodeType] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [sharedNodeTypeSearch, setsharedNodeTypeSearch] = useState(null);
  const handleSetsharedNodeTypeSearch = (Value) => {
    setsharedNodeTypeSearch(Value);
  };

  function FindBoxVersionIDList(ListNodes) {
    //console.log("ListNodes", ListNodes)
    let BoxVersionIDList = [];
    for (let i = 0; i < ListNodes.length; i++) {
      let BoxVersion = parseInt(ListNodes[i]);
      if (GlobalNodes[BoxVersion].type == "SBoxN" && GlobalNodes[BoxVersion].data.Param[1][1].includes("Version")) {
        BoxVersionIDList.push([BoxVersion, GlobalNodes[BoxVersion].data.Param[4][1]])
      }
    }
    return BoxVersionIDList;
  }

  function FindLastBoxVersion(BoxVersionIDList) {
    let LastVersion = "-1", LastID = 0, OldID = 0;
    console.log("BoxVersionIDList", BoxVersionIDList)
    for (let i = 0; i < BoxVersionIDList.length; i++) {
      let Version = BoxVersionIDList[i][1];
      console.log("Version", Version, i, BoxVersionIDList[i][0], GlobalNodes[BoxVersionIDList[i][0]].data.BlueGreen);
      const CMP = compareVersions(Version, LastVersion)
      if (CMP === 1) {
        LastVersion = Version;
        LastID = BoxVersionIDList[i][0];
      }
      if (GlobalNodes[BoxVersionIDList[i][0]].data.BlueGreen === "Blue") {
        OldID = BoxVersionIDList[i][0];
      }
    }
    return [LastID, LastVersion, OldID];
  }

  const HandleCopyStage = (Value) => {
    console.log("HandleCopyStage", Value);
    RemoveSelected(GlobalNodes);
    let Error = false;
    const SourceBoxID = Value[0];
    if (SourceBoxID === undefined) { Error = true; handleFeedback("A box must be connected to the output of the previous CodePipeline stage.") }
    const TargetBoxID = Value[1];
    if (TargetBoxID === undefined) { Error = true; handleFeedback("A box must be connected to the output of this CodePipeline Stage.") }
    const IsTest = Value[2];
    const CMD = Value[3];
    const ListChildSource = FindNodesChieldID(GlobalNodes, SourceBoxID);
    const ListChildTarget = FindNodesChieldID(GlobalNodes, TargetBoxID);
    console.log("TargetBoxID", GlobalNodes[TargetBoxID],)
    //Achar lista de IDs de Box com nome Version dentro de TargetBoxID
    const BoxVersionSourceIDList = FindBoxVersionIDList(ListChildSource);
    const BoxVersionTargetIDList = FindBoxVersionIDList(ListChildTarget);
    let [LastSourceID, LastSourceVersion, OldSourceID] = FindLastBoxVersion(BoxVersionSourceIDList)
    if (LastSourceID == 0) { Error = true; handleFeedback("A 'version' box was not found in the previous state.") }
    let [LastTargetID, LastTargetVersion, OldTargetID] = FindLastBoxVersion(BoxVersionTargetIDList)
    const HasVersion = !(LastTargetID === 0 && OldTargetID === 0);
    console.log("LastTargetVersion", LastTargetVersion, LastSourceVersion)
    if (CMD === "Copy") {
      const CMP = compareVersions(LastSourceVersion, LastTargetVersion)
      if (CMP !== 1 && LastSourceVersion !== "-1") {
        alert("The source stage version must be greater than the target stage version.")
        Error = true;
      }
      if (!Error) {
        if (IsTest) {
          if (LastTargetID != 0) {
            GlobalNodes[TargetBoxID].data.Position = GlobalNodes[LastTargetID].position;
            console.log("GlobalNodes[LastTargetID].position", GlobalNodes[TargetBoxID].data.Position)
            //console.log("GlobalNodes[OldTargetID].data.Position", GlobalNodes[OldTargetID].data.Position)
            GlobalNodes[LastTargetID].selected = true;
            Delete(false);
          }
          GlobalListNodesEdgesCopy = FindNodesEdgesChield(GlobalNodes, GlobalEdges, GlobalNodes[LastSourceID]);
          console.log("GlobalListNodesEdgesCopy Copy stage", GlobalListNodesEdgesCopy);
          Paste(GlobalNodes, GlobalEdges, GlobalNodes[TargetBoxID]);
          const Position = GlobalNodes[TargetBoxID].data.Position;
          console.log("Position here", Position)
          if (Position !== undefined) {
            let [NewLastTargetID, NewLastTargetVersion, NA] = FindLastBoxVersion(BoxVersionTargetIDList)
            console.log("NewLastTargetID", NewLastTargetID)
            GlobalNodes[NewLastTargetID].position = Position
            console.log("Moved")
          }
        } else {
          console.log("BoxVersionTargetIDList.length", BoxVersionTargetIDList.length)
          if (BoxVersionTargetIDList.length > 1) {
            console.log("OldTargetID", OldTargetID)
            GlobalNodes[OldTargetID].selected = true;
            Delete(false);
            console.log("Delete Old Blue")
            BoxVersionTargetIDList.pop();
          }
          if (BoxVersionTargetIDList.length < 2) {
            GlobalListNodesEdgesCopy = FindNodesEdgesChield(GlobalNodes, GlobalEdges, GlobalNodes[LastSourceID]);
            console.log("GlobalListNodesEdgesCopy Copy stage", GlobalListNodesEdgesCopy);
            let [NewEdges, NewNodes] = Paste(GlobalNodes, GlobalEdges, GlobalNodes[TargetBoxID]);
            console.log("PAste ")
            let NodeList = [];
            for (let i = 0; i < NewNodes.length; i++) {
              NodeList.push(parseInt(NewNodes[i].id));
            }
            //console.log("NodeList", NodeList)
            const BoxVersionTargetIDList = FindBoxVersionIDList(NodeList);
            if (LastTargetID !== 0) {
              GlobalNodes[LastTargetID].data.BlueGreen = "Blue";
              console.log("LastTargetID blue", LastTargetID)
            }
            let [NewLastTargetID, NewLastTargetVersion, NA] = FindLastBoxVersion(BoxVersionTargetIDList)
            if (NewLastTargetID !== 0) {
              GlobalNodes[NewLastTargetID].data.BlueGreen = "Green";
              console.log("NewLastTargetID green", NewLastTargetID, GlobalNodes[NewLastTargetID].data.Param[4][1])
            }
            const Position = GlobalNodes[TargetBoxID].data.Position;
            console.log("Position ********", Position, TargetBoxID)
            if (Position !== undefined) {
              GlobalNodes[NewLastTargetID].position = Position
              GlobalNodes[LastTargetID].position = Position
            }
            console.log("BoxVersionTargetIDList", BoxVersionTargetIDList, LastTargetID, LastTargetVersion, NewLastTargetID)
            /*if (BoxVersionTargetIDList.length == 1) {
              GlobalNodes[LastTargetID].data.BlueGreen = "Blue";
              console.log("Blue aqui")
            } else {
              GlobalNodes[LastTargetID].data.BlueGreen = "Green";
              console.log("Green aqui")
            }*/
          }
        }
      }
    } else {
      console.log("LastTargetID", LastTargetID, OldTargetID)
      if (CMD === "DelBlue") {
        if (OldTargetID !== 0) {
          GlobalNodes[TargetBoxID].data.Position = GlobalNodes[LastTargetID].position;
          GlobalNodes[LastTargetID].position = GlobalNodes[OldTargetID].position;
          GlobalNodes[OldTargetID].selected = true;
          Delete(false);
          console.log("GlobalNodes[TargetBoxID].data.Position Blue", TargetBoxID, GlobalNodes[TargetBoxID].data.Position)
        }
        GlobalNodes[LastTargetID].data.BlueGreen = "Blue";
      } else {
        GlobalNodes[LastTargetID].selected = true;
        Delete(false);
        GlobalNodes[TargetBoxID].data.Position = GlobalNodes[LastTargetID].position;
        console.log("GlobalNodes[TargetBoxID].data.Position Green", TargetBoxID, GlobalNodes[TargetBoxID].data.Position)
      }
    }
    console.log("HasVersion", HasVersion)
    return Error;
  }
  const handleConnectionMap = () => {
    setModalNodeType(GlobalNodeFeedBack); //GlobalNodeFeedBack contém o valor do Node Sorce da tentativa de conexão
    setModalOpen(true);
  };

  useEffect(() => {
    // Atualiza as arestas com os novos labels
    console.log("useEffect edges", edges)

    if (!HideEdges) {
      setEdges((currentEdges) => currentEdges.map(edge => {
        if (labels[edge.id]) {
          return { ...edge, label: labels[edge.id] };
        } else {
          return edge;
        }
      }));
    }
  }, [labels]);

  const handleIDChange = (ID) => {
    GlobalSelectedList.clear();
    GlobalSelectedList.add(ID.toString());
    //console.log("GlobalSelectedList", GlobalSelectedList);
    selectNodes();
    //console.log("*****************************", ID)
  };

  const handleModalClose = () => {
    setShowModalMain(false);
    //console.log("Fechar o modal")
  };

  /*const accessKeyId = "AKIAX7EKARWWOWI36CUL";
  const secretAccessKey = "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";
  const [xCounter, setXCounter] = useState(1);
  const ParamMetrics = {
    StartTime: new Date(Date.now() - 3600000), // 10 horas atrás
    EndTime: new Date(),
    MetricDataQueries: [{
      Id: 'query1',
      MetricStat: {
        Metric: {
          Namespace: 'AWS/DynamoDB',
          MetricName: 'ConsumedReadCapacityUnits',
          Dimensions: [{
            Name: 'TableName',
            Value: 'CloudManDB'
          }]
        },
        Period: 60,  // Em segundos (1 minuto)
        Stat: 'Sum'
      }
    }]
  };
  useEffect(() => {
    const fetchAndUpdateGraph = async () => {
      const currentNodes = [...nodes]; // Pegue uma cópia do estado atual
      for (let i = 0; i < currentNodes.length; i++) {
        let NodeType = currentNodes[i].type;
        if (NodeType === "GraphN") {
          let SourceList = SearchNodesSource(GlobalEdges, currentNodes, currentNodes[i], "all");
          if (SourceList.length > 0) {
            let [accessKeyId, secretAccessKey, RegionName, ParamMetrics] = MakeCWParams(GlobalEdges, currentNodes, i);
            try {
              const metricData = await fetchCloudWatchMetric({
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                region: RegionName,
                params: ParamMetrics,
              });
              const updatedNode = {
                ...currentNodes[i],
                data: { ...currentNodes[i].data, Graph: metricData }
              };
              currentNodes[i] = updatedNode;
              //console.log("metricData", metricData)
            } catch (error) {
              // handle error
            }
          }
        }
      }
      setNodes(currentNodes);
    };
   
    const interval = setInterval(() => {
      fetchAndUpdateGraph();
    }, 1000000);
   
    return () => clearInterval(interval);
  }, [accessKeyId, secretAccessKey, nodes]); */

  const handleRightClick = (event) => {
    event.preventDefault(); // previne o menu de contexto padrão
    setMenuPosition({
      top: event.clientY,
      left: event.clientX + 10 // 10px à direita do ponto do click
    });
    setMenuVisible(true);
  }
  function FindCloudRegionForUpdate() {
    const List = [...GlobalSelectedList];
    let SelectedID = 0;
    let CrossAccountRole;
    let PreSelectID;
    for (let j = 0; j < List.length; j++) {
      PreSelectID = parseInt(List[j]);
      let SelectedType = GlobalNodes[PreSelectID].type;
      console.log("SelectedType", SelectedType)
      if (SelectedType === "CloudN") {
        SelectedID = PreSelectID;
        CrossAccountRole = GlobalNodes[PreSelectID].data.Param[5][1];
        break;
      }
      if (SelectedType === "RegionN") {
        SelectedID = PreSelectID;
        const CloudID = parseInt(GlobalNodes[PreSelectID].parentNode);
        CrossAccountRole = GlobalNodes[CloudID].data.Param[5][1];
        let Valid = ValidateArn(CrossAccountRole);
        if (!Valid) { handleFeedback("Cross Account Role has a invalid format!"); }
        break;
      }
    }
    return [SelectedID, CrossAccountRole]
  }

  const removeDuplicates = (arr) => {
    const map = new Map();

    // Passa pelo array e atualiza o map com o último valor para cada número
    arr.forEach(([num, bool]) => {
      map.set(num, [num, bool]);
    });

    // Converte o map de volta para array
    return Array.from(map.values());
  };

  const HandleUpdateStatus = () => {
    setMenuVisible(false);
    setShowStatus(true);
    let ListNodes = [];
    let [SelectedID, CrossAccountRole] = FindCloudRegionForUpdate();
    if (SelectedID !== 0) {
      const NodesWithSufix = AdjustSufixName(GlobalNodes, SelectedID);
      //console.log("SelectedID, CrossAccountRole", SelectedID, CrossAccountRole)
      let ListBlockNodes = FindNodesChieldIDFromParent(NodesWithSufix, NodesWithSufix[SelectedID]);
      //console.log("ListBlockNodes", ListBlockNodes)
      for (let j = 0; j < ListBlockNodes.length; j++) {
        let NodeID = parseInt(ListBlockNodes[j]);
        let NodeType = NodesWithSufix[NodeID].type;
        let NodeIndex = ResourceLookUp[NodeType];
        let HasStatus;
        let Category;
        try {
          Category = ListaStandard[NodeIndex].Category;
        } catch (error) {
          Category = [];
          console.log("No Category", NodeType)
        }
        if (Category.includes("AWS")) {
          try {
            HasStatus = ListaStandard[NodeIndex].GeneralParam.HasStatus;
          } catch (error) {
            HasStatus = false;
          }
          //console.log("HasStatus", HasStatus, NodeType);
          if (HasStatus) {
            let NodeName = NodesWithSufix[NodeID].data.Param[1][1];
            let AWSNodeType = ListaStandard[NodeIndex].GeneralParam.AWSName;
            let RegionID = parseInt(FindRegionAndCloud(NodesWithSufix, NodesWithSufix[NodeID].id)[1]);
            let RegionName = NodesWithSufix[RegionID].data.Param[2][2];
            ListNodes.push([AWSNodeType, NodeName, RegionName, NodeID]);
            //console.log("AWSNodeType", AWSNodeType, NodeName);
          }
        } else {
          //console.log("No AWS HasStatus", NodeType, Category)
        }
      }
      console.log("ListNodes", ListNodes)
      var raw = JSON.stringify([0, ListNodes, CrossAccountRole]);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
      var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
      console.log("APIPricing", APIPricing)
      fetch(APIPricing, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP status ${response.status}`);  // Lança um erro se a resposta HTTP não for bem-sucedida
          }
          return response.text();
        })
        .then(result => {
          const responseData = JSON.parse(result);
          //console.log("Response Data:", responseData);
          // Primeiro, trata o caso de credenciais inválidas:
          if (responseData.statusCode === 201) {
            throw new Error("Invalid Credentials configured in Cloud > Cross Account Role");  // Usa Error para pular direto para o catch
          }
          GlobalStatus = GlobalStatus.concat(responseData.body);
          for (let j = 0; j < GlobalStatus.length; j++) {
            if (GlobalStatus[j][1] === true) {
              GlobalNodes[GlobalStatus[j][0]].data.Deployed = true;
              console.log("Deployed ", GlobalNodes[GlobalStatus[j][0]].type)
            } else {
              GlobalNodes[GlobalStatus[j][0]].data.Deployed = false;
              console.log("UNDeployed ", GlobalNodes[GlobalStatus[j][0]].type)
            }
          }
          HandleShowStatus(true);
        })
        .catch(error => {
          console.error('Fetch error:', error.message);
        });
    } else {
      handleFeedback("You should select a Region");
    }
  }
  const HandleShowStatus = async (ForceShow = false) => {
    setMenuVisible(false);
    console.log("*****************Showstatus****************", ShowStatus)
    console.log("ForceShow", ForceShow)
    for (let j = 0; j < GlobalNodes.length; j++) {
      let NodeType = GlobalNodes[j].type;
      if (NodeType === "SBoxN") { GlobalNodes[j].data.Deployed = false } // inicializa Sbox como não deployed  
    }
    let List = [];
    if (ShowStatus || ForceShow) {
      console.log("Hide ---------------------")
      for (let j = 0; j < GlobalNodes.length; j++) {
        let NodeType = GlobalNodes[j].type;
        let NodeIndex = ResourceLookUp[NodeType];
        if (NodeType !== "GraphN") {
          let HasStatus = ListaStandard[NodeIndex]?.GeneralParam?.HasStatus || false;
          if (HasStatus) {
            //DeployedStatus
            let Deployed = GlobalNodes[j].data.Deployed || false;
            if (Deployed) {
              GlobalNodes[j].data.Opacity = 1;
              console.log("Exibiu Hasstatus", NodeType, Deployed)
            } else {
              GlobalNodes[j].data.Opacity = .3;
              console.log("escondeu Hasstatus", NodeType, Deployed)
            }
            /*if (NodeType === "R53ZoneN") { //Ajusta R53
              let SubDomain = GlobalNodes[j].data.Param[3][1];
              let List = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[j], "R53ZoneN");
              console.log("SubDomain", SubDomain, List)
              if (List.length > 0 && SubDomain == 0) {
                GlobalNodes[j].data.Opacity = 1;
              }
            }*/
            let DeployedStatus = ListaStandard[NodeIndex]?.GeneralParam?.DeployedStatus || "";
            if (DeployedStatus.includes("Source")) {
              List = List.concat(SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[j], "all"));
            }
          } else {
            GlobalNodes[j].data.Opacity = .3;
          }
          let DeployedStatus = ListaStandard[NodeIndex]?.GeneralParam?.DeployedStatus || "";
          List = [];
          if (DeployedStatus !== "") {
            console.log("NodeType", NodeType, DeployedStatus)
            if (DeployedStatus.includes("Target")) {
              List = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[j], "all");
            }
            if (DeployedStatus.includes("Source")) {
              List = List.concat(SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[j], "all"));
            }
            if (DeployedStatus.includes("Father")) {
              List.push(parseInt(GlobalNodes[j].parentNode))
            }
            if (DeployedStatus.includes("Child")) {
              List = List.concat(FindNodesChieldIDFromParent(GlobalNodes, GlobalNodes[j]));
            }
            if (DeployedStatus.includes("Always")) {
              GlobalNodes[j].data.Opacity = 1;
            }
            for (let k = 0; k < List.length; k++) {
              List[k] = parseInt(List[k]);
            }
            List = [...new Set(List)];
            console.log("List", List, NodeType)
          }
          for (let k = 0; k < List.length; k++) {
            let NodeID = parseInt(List[k])
            let DeployedMain = GlobalNodes[NodeID]?.data?.Deployed || false;
            console.log("DeployedMain", DeployedMain, NodeID)
            if (DeployedMain) {
              GlobalNodes[j].data.Opacity = 1;
              console.log("Exibiu nostatus", NodeType)
              if (NodeType === "SBoxN") { GlobalNodes[j].data.Deployed = true }
              console.log("NodeTypw", NodeType, GlobalNodes[j].data.Opacity)
              break;
            }
          }
        }

      }
    } else {
      for (let j = 0; j < GlobalNodes.length; j++) {
        let NodeType = GlobalNodes[j].type;
        if (NodeType !== "GraphN") { GlobalNodes[j].data.Opacity = 1; }
      }
    }
    setShowStatus(!ShowStatus);
  }

  const HandleUpdateCost = () => {
    setMenuVisible(false);
    let Confirm = window.confirm("CloudMan uses AWS Cost Explorer API. Each call will incur a cost of USD 0.01. Are you sure?");
    if (Confirm) {
      let ListNodes = [];
      let [SelectedID, CrossAccountRole] = FindCloudRegionForUpdate();
      if (SelectedID !== 0) {
        let ListBlockNodes = FindNodesChieldIDFromParent(GlobalNodes, GlobalNodes[SelectedID]);
        console.log("ListBlockNodes", ListBlockNodes)
        for (let j = 0; j < ListBlockNodes.length; j++) {
          let NodeID = parseInt(ListBlockNodes[j]);
          let NodeType = GlobalNodes[NodeID].type;
          let NoCost1 = IsEC2AutoScale(GlobalEdges, GlobalNodes, NodeID)
          let NoCost2 = NodeType === "R53ZoneN" && GlobalNodes[NodeID].data.Param[3][1] === 0;
          let NoCost = NoCost1 || NoCost2;
          if (!NoCost) {
            let NodeIndex = ResourceLookUp[NodeType];
            let HasCost;
            let Category;
            try {
              Category = ListaStandard[NodeIndex].Category;
            } catch (error) {
              Category = [];
              console.log("No Category", NodeType);
            }
            if (Category.includes("AWS")) {
              try {
                HasCost = ListaStandard[NodeIndex].GeneralParam.HasCost;
              } catch (error) {
                HasCost = false;
              }
            }
            console.log("HasCost", HasCost, NodeType);
            if (HasCost) {
              let NodeName = GlobalNodes[NodeID].data.Param[1][1];
              let AWSNodeType = ListaStandard[NodeIndex].GeneralParam.AWSName;
              ListNodes.push([AWSNodeType, NodeName, NodeID.toString(), HasCost]);
              console.log("AWSNodeType", AWSNodeType, NodeName);
            }
          }
        }
        console.log("ListNodes", ListNodes)
        var raw = JSON.stringify([1, ListNodes, CrossAccountRole]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
        var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
        fetch(APIPricing, requestOptions)
          .then(response => response.text())
          .then(result => {
            let HasCost;
            GlobalCost = JSON.parse(result).body;
            console.log("GlobalCost", GlobalCost)
            for (let j = 0; j < GlobalCost.length; j++) {
              HasCost = GlobalCost[j][1];
              if (HasCost === "Error") { break; }
            }
            if (HasCost === "Error") {
              handleFeedback("Credential Error");
            } else {
              HandleShowCost();
            }
          })
          .catch(error => {
            console.log('error', error);
          })
      } else {
        handleFeedback("You should select a Cloud or Region");
      }
    }
  }
  const HandleShowCost = () => {
    setMenuVisible(false);
    console.log("Show Cost", GlobalCost)
    setShowCost(!ShowCost);
    if (ShowCost) {
      for (let j = 0; j < GlobalNodes.length; j++) {
        let NodeType = GlobalNodes[j].type;
        let NodeIndex = ResourceLookUp[NodeType];
        let HasCost;
        try {
          HasCost = ListaStandard[NodeIndex].GeneralParam.HasCost;
        } catch (error) {
          HasCost = false;
        }
        if (HasCost) {
          GlobalNodes[j].data.Cost = "0.00";
          GlobalNodes[j].data.ShowCost = true;
        }
      }
      for (let j = 0; j < GlobalCost.length; j++) {
        let NodeID = GlobalCost[j][0];
        let Cost = GlobalCost[j][1];
        GlobalNodes[NodeID].data.Cost = Cost;
        GlobalNodes[NodeID].data.ShowCost = true;
        console.log("Node", NodeID, Cost)
      }
      for (let j = 0; j < GlobalNodes.length; j++) {
        let NodeType = GlobalNodes[j].type;
        const CostTypeBox = ["SBoxN", "VPCN", "SubnetN", "RegionN"]
        if (CostTypeBox.includes(NodeType)) {
          console.log("NodeType", NodeType, j)
          let ListNodes = FindNodesChieldIDFromParent(GlobalNodes, GlobalNodes[j]);
          console.log("ListNodes", ListNodes)
          let BoxCost = 0;
          for (let k = 0; k < ListNodes.length; k++) {
            let ChildNodeID = parseInt(ListNodes[k]);
            let NodeChildType = GlobalNodes[ChildNodeID].type;
            let NodeCost;
            try {
              NodeCost = GlobalNodes[ChildNodeID].data.Cost;
            } catch (error) {
              NodeCost = 0;
            }
            if (!isNaN(NodeCost) && (!CostTypeBox.includes(NodeChildType))) {
              console.log("NodeCost", NodeCost, NodeChildType)
              BoxCost += parseFloat(NodeCost)
            }
          }
          console.log("BoxCost", BoxCost)
          BoxCost = BoxCost.toString();
          GlobalNodes[j].data.Cost = BoxCost;
          GlobalNodes[j].data.ShowCost = true;
        }
      }
    } else {
      for (let j = 0; j < GlobalNodes.length; j++) {
        GlobalNodes[j].data.ShowCost = false;
      }
    }
  }

  const HandleShowGraph = () => {
    if (!ShowGraph || !GlobalShowGraphFirstTime) {
      BatchUpdateMetrics(false, GlobalShowGraphFirstTime);
      GlobalShowGraphFirstTime = false;
    }
    console.log("Show Graph")
    setMenuVisible(false);
    for (let j = 0; j < GlobalNodes.length; j++) { //Ajusta R53
      let NodeType = GlobalNodes[j].type;
      if (NodeType === "GraphN") {
        setEdges((eds) =>
          eds.map((edge) => {
            if (GlobalNodes[j].id == edge.target) {
              if (!ShowGraph) {
                edge.hidden = false;
                GlobalNodes[j].data.Opacity = 1;
              } else {
                edge.hidden = true;
                GlobalNodes[j].data.Opacity = 0;
              }
            }
            return edge;
          })
        )
      }
      setShowGraph(!ShowGraph);
    }
  }

  const handleHideCICDEdges = () => {
    setHideCICDEdges(!HideCICDEdges);
    setMenuVisible(false);
    if (!HideCICDEdges) { GlobalEdgeCICDOpacity = 0; GlobalEdgeCICDDisplay = "none"; GlobalEdgeCICDBackgroundColor = "transparent"; }
    else {
      GlobalEdgeCICDOpacity = 1; GlobalEdgeCICDDisplay = "block"; GlobalEdgeCICDBackgroundColor = "white";
    }
    setEdges((currentEdges) =>
      currentEdges.map(edge => ({
        ...edge,
        //style: { ...edge.style, opacity: GlobalEdgeOpacity },
        labelStyle: { ...edge.labelStyle, display: GlobalEdgeDisplay, backgroundColor: GlobalEdgeBackgroundColor },
        hidden: edge.data && edge.data.Category === "CICD" ? !HideCICDEdges : (edge.hidden !== undefined ? edge.hidden : false)
      }))
    );
  }

  const handleHideEdgeLabels = () => {
    setHideEdgeLabels(!HideEdgeLabels);
    setMenuVisible(false);
    setEdges((currentEdges) =>
      currentEdges.map(edge => ({
        ...edge,
        labelStyle: { ...edge.labelStyle, display: HideEdgeLabels ? "block" : "none", backgroundColor: GlobalEdgeBackgroundColor },
      }))
    );
  }
  const handleMoveEdges = () => {
    setAreEdgesFront(!AreEdgesFront);
    setEdges((currentEdges) =>
      currentEdges.map(edge => ({
        ...edge,
        zIndex: AreEdgesFront ? 3 : 1000,
      }))
    );
  };


  const handleHideEdges = () => {
    setHideEdges(!HideEdges);
    setHideCICDEdges(!HideEdges);
    setMenuVisible(false);
    if (!HideEdges) { GlobalEdgeOpacity = 0; GlobalEdgeDisplay = "none"; GlobalEdgeBackgroundColor = "transparent"; }
    else {
      GlobalEdgeOpacity = 1; GlobalEdgeDisplay = "block"; GlobalEdgeBackgroundColor = "white";
    }
    setEdges((currentEdges) =>
      currentEdges.map(edge => ({
        ...edge,
        //style: { ...edge.style, opacity: GlobalEdgeOpacity },
        labelStyle: { ...edge.labelStyle, display: GlobalEdgeDisplay, backgroundColor: GlobalEdgeBackgroundColor },
        hidden: !HideEdges
      }))
    );
  }


  const RightMenuStyle = {
    position: 'absolute',
    backgroundColor: '#ffffff',
    border: '1px solid #ccc',
    padding: '1px 5px',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    top: MenuPosition.top + 'px',
    left: MenuPosition.left + 'px'
  };

  const menuItemStyle = {
    display: 'block', // Isso deve garantir que cada item do menu apareça em uma nova linha
    padding: '2px 10px',
    cursor: 'pointer',
    marginBottom: '2px' // Espaçamento entre os itens do menu
  };

  const menuItemHoverStyle = {
    backgroundColor: '#f0f0f0', // Ou qualquer outra cor para destacar
    cursor: 'pointer'
  };


  useEffect(() => {
    if (MenuVisible) {
      const menuHeight = 460; // Altura estimada do menu
      const menuWidth = 250;  // Largura estimada do menu
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      let newTop = MousePosition.y;
      let newLeft = MousePosition.x;

      // Ajuste para manter o menu dentro da tela verticalmente
      if (MousePosition.y + menuHeight > viewportHeight) {
        newTop = viewportHeight - menuHeight;
      }

      // Ajuste para manter o menu dentro da tela horizontalmente
      if (MousePosition.x + menuWidth > viewportWidth) {
        newLeft = viewportWidth - menuWidth;
      }

      setMenuPosition({ top: newTop, left: newLeft });
    }
  }, [MenuVisible, MousePosition.x, MousePosition.y]);



  const paragraphStyle = {
    margin: '0',
    padding: '0',
    lineHeight: '1.2' // Ajuste para a linha parecer mais apertada se necessário
  };


  //Atualiza Parametros em Nodes
  if (FirstLoop) {
    for (let i = 0; i < initialNodes.length; i++) {
      initialNodes[i].data.Refresh = { refreshNode, setRefreshNode }
    }
    for (let i = 1; i < initialNodes.length; i++) {
      let NodeType = initialNodes[i].type;
      if (NodeType === "GraphN") {
        initialNodes[i].data.Opacity = 0;
      }

      let Param = initialNodes[i].data.Param
      let ResourceType = initialNodes[i].type.slice(0, -1)
      console.log("ResourceType", ResourceType)
      let ParamDB = NodeData[ResourceType].data.Param
      if (ParamDB.length > Param.length) {
        for (let j = Param.length; j < ParamDB.length; j++) {
          //console.log("ParamDB Init dif", ParamDB[j])
          Param.push(ParamDB[j])
        }
      }
      //console.log("Param Final", Param)
      UpDateGlobalNodeID(initialNodes.length);
      //GlobalNodeID = NewNodes.length;
      GlobalEdgeID = initialEdges.length;
      FirstLoop = false;
      //setShowGraph(true);
      //HandleShowGraph();

    }
  }

  return (
    <ErrorBoundary>
      <div id="focus" style={{ height: '100%' }}>
        <ContextBridge.Provider value={contextValue}>
          <div className="providerflow">
            <ModalProvider>
              <ReactFlowProvider>
                <div className="reactflow-wrapper simple-floatingedges" ref={reactFlowWrapper}>
                  <ReactFlow
                    minZoom={0.1}
                    maxZoom={12}
                    nodes={nodes}
                    edges={edges}
                    connectionMode={ConnectionMode.Loose}
                    deleteKeyCode={null}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onNodeDoubleClick={captureElementDoubleClick ? onNodeDoubleClick : undefined}
                    onPaneClick={capturePaneClick ? onPaneClick : undefined}
                    onNodeClick={captureElementClick ? onNodeClick : undefined}
                    onEdgeClick={captureElementClick ? onEdgeClick : undefined}
                    onEdgeDoubleClick={captureElementDoubleClick ? onEdgeDoubleClick : undefined}
                    onConnect={onConnect}
                    nodesDraggable={nodesDraggable}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    connectionLineStyle={connectionLineStyle}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    onContextMenu={handleRightClick}
                    fitView
                    style={{
                      backgroundColor: '#D3D2E5',
                    }}
                    attributionPosition="bottom-left"
                    className="bg-teal-50">
                    {GlobalMiniMap && <MiniMap />}
                    <Background />
                  </ReactFlow>
                  <ZoomLevelIndicator />
                  <Modal x={400} y={200} width={700} height={400} isOpen={isModalOpen} NodeType={modalNodeType} DictTarget={DictTarget}
                    onSetsharedNodeTypeSearch={handleSetsharedNodeTypeSearch} DictSource={DictSource}>
                    <div>
                      <button onClick={handleCloseModal}>Close</button>
                    </div>
                  </Modal>
                  {<SideBarNovo nodes={nodes} edges={edges} initialNodes={initialNodes} GlobalIsSaved={GlobalIsSaved} Saved={Saved}
                    setNodes={setNodes} setEdges={setEdges} GetEdgeId={GetEdgeId} GetNodeId={GetNodeId} sharedNodeTypeSearch={sharedNodeTypeSearch}
                    CognitoRegion={CognitoRegion} CognitoDomain={CognitoDomain} CognitoClient={CognitoClient} />}
                  <FeedBack show={showFeedback} message={feedbackMessage} onClose={handleCloseFeedback} onButtonClick={handleConnectionMap} />
                </div>
              </ReactFlowProvider>
              {showModalMain && <ModalMain isOpen={showModalMain} onClose={handleModalClose} GlobalNodes={GlobalNodes}
                GlobalEdges={GlobalEdges} GlobalNodeModal={GlobalNodeModal} ModalMainIndex={ModalMainIndex} onIDChange={handleIDChange}
                onSetsharedNodeTypeSearch={handleSetsharedNodeTypeSearch} onCopyStage={HandleCopyStage} />}
            </ModalProvider>
          </div>
        </ContextBridge.Provider>
      </div>
      {MenuVisible && (
        <div style={{ ...RightMenuStyle, top: MenuPosition.top, left: MenuPosition.left }}>
          {/* Primeira Seção */}
          <div className="firstSection">
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleMoveEdges}> {AreEdgesFront ? "Send Edges to Back" : "Bring Edges to Front"} </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleHideEdges}> {HideEdges ? "Show All Edges" : "Hide All Edges"} </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleHideCICDEdges}> {HideCICDEdges ? "Show CI/CD Edges" : "Hide CI/CD Edges"} </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleHideEdgeLabels}> {HideEdgeLabels ? "Show Edge Labels" : "Hide Edge Labels"} </span>
          </div>
          <div className="menuSection">
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleShowName}> Change Label Info (Ctrl+shift+l) </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handlePinNodes}> Pin/UnPin Selected Nodes </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleExpandParent}> {ExpandParent ? "Expand Parent Deactivate" : "Expand Parent Activate"} </span>
          </div>
          {/* Segunda Seção */}
          <div className="menuSection">
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleUnDo}> Undo (Ctrl+z) </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleCopy}> Copy (Ctrl+C) </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handlePaste}> Paste (Ctrl+V)  </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleMove}> Move (Ctrl+M) </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleXMirror}> X Mirror </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={handleYMirror}> Y Mirror </span>
          </div>

          {/* Terceira Seção - Apenas Visível em Modo de Desenvolvimento */}

          <div className="menuSection">
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={HandleUpdateStatus}> Update Resources Status </span>
            <span style={menuItemStyle}
              onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
              onClick={HandleUpdateCost}> Update Resources Costs </span>
            <div className="menuSection">
              <span style={menuItemStyle}
                onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
                onClick={() => HandleShowStatus(false)}> {ShowStatus ? "Hide Undeployed Resources" : "Show Undeployed Resources"} </span>
              <span style={menuItemStyle}
                onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
                onClick={HandleShowCost}> {ShowCost ? "Show Cost Layer" : "Hide Cost Layer"} </span>
              <span style={menuItemStyle}
                onMouseOver={e => e.currentTarget.style.backgroundColor = menuItemHoverStyle.backgroundColor} onMouseOut={e => e.currentTarget.style.backgroundColor = ''}
                onClick={HandleShowGraph}> {ShowGraph ? "Hide Metrics" : "Show Metrics"} </span>
            </div>
          </div>

        </div>
      )}
    </ErrorBoundary>

  );
}
export { App };
