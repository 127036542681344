import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const SNS = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SNS.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const SNSDLT = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SNSDLT.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const SNSSubscription = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SNSSubscription.png")';
    let Label = data.Label;
    if (data.Param[2][3] == "Other") {
        Label += " " + data.Param[2][2][4][2][1][2];
    }
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} Label={Label} />
    );
};

const SNSFeedback = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SNSFeedback.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const SNSPolicy = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SNSPolicy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const SNSSMSPref = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SNSSMSPref.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { SNS, SNSSubscription, SNSFeedback, SNSPolicy, SNSDLT, SNSSMSPref };

