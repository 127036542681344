

const Info = ({ data, selected }) => {
    const number = data.Param[3][1]
    let Symbol = "i";
    let Opacity = data.Opacity;
    if (data.Param[2][1] == 1) { Symbol = "!"; }
    if (data.Param[2][1] == 2) { Symbol = "?"; }
    const iconStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px', // Tamanho reduzido pela metade
        height: '20px', // Tamanho reduzido pela metade
        borderRadius: '50%',
        background: 'linear-gradient(135deg, #03f0fc, #7748AA)', // Degradê de azul
        cursor: 'pointer',
        fontSize: '8px', // Fonte reduzida pela metade
        lineHeight: '8px', // Ajustar linha para alinhamento vertical
        border: selected ? '1px solid black' : '1px solid white', // Borda branca
        boxSizing: 'border-box', // Para incluir a borda no tamanho total do ícone
        filter: selected ? 'saturate(700%)' : 'none',
        color: selected ? 'black' : 'white',
    };


    const numberStyle = {
        marginLeft: '2px', // Espaço entre o "i" e o número ajustado
        fontSize: '8px', // Fonte reduzida pela metade
        lineHeight: '8px', // Ajustar linha para alinhamento vertical
    };

    //const Text = "double click to access";
    const Text = data.Param[4][1];
    return (
        <div style={{
            opacity: Opacity
        }}>
            <span style={iconStyle} title={Text}>
                i<span style={numberStyle}>{number}</span>
            </span>
        </div>

    )
};

export { Info };

