import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const R53HCheck = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'R53HCheck.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const R53Record = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'R53Record.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const R53Zone = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'R53Zone.png")';
    let LabelName = data.Param[2][1] + " " + data.Param[3][3];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const R53Routing = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'R53Routing.png")';
    let LabelName = data.Param[2][3];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const SubDomain = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'SubDomain.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { R53HCheck, R53Record, R53Zone, R53Routing, SubDomain };

