import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const CloudMap = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'CloudMap.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const SDiscoveryService = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SDiscoveryService.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const SDiscoveryInstance = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'SDiscoveryInstance.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};



export { SDiscoveryService, CloudMap, SDiscoveryInstance };

