import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const ACM = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'ACM.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ACMCValidation = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'ACMCValidation.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { ACM, ACMCValidation };

