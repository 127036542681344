import React, { memo, useState } from 'react';
import { Handle, Position, useEdges } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import { Constraints, GNodes, GEdges } from '../NodesData';
import GenericNode from '../GenericNode';
import { CalculateScale, SearchSubnetHead } from '../Functions';

const LockStyle = {
    //display: 'block',
    position: "absolute",
    right: 3,
    width: 25,
    height: 25,
};
const MinWidth = 120;
const MinHeight = 120;

function formatIPv6(ipv6) {
    if (ipv6.length > 18) {
        const halfIndex = Math.floor(ipv6.length / 2);
        const breakIndex = ipv6.indexOf(':', halfIndex - 5);
        if (breakIndex !== -1) {
            return ipv6.substring(0, breakIndex + 1) + "\n" + ipv6.substring(breakIndex + 1);
        }
    }
    return ipv6;
}

const AZSubnet = ({ id, data, selected }) => {
    const [isMouseNear, setIsMouseNear] = useState(false);
    try {
        let IPv4CIDR = data.Param[4][1];
        let SubnetName = data.Param[1][1];
        let SubnetID = parseInt(id);
        let SubnetHead = SearchSubnetHead(GEdges, GNodes, SubnetID);
        if (SubnetHead !== SubnetID) {

            SubnetName = GNodes[SubnetHead].data.Param[1][1];
            IPv4CIDR = GNodes[SubnetHead].data.Param[4][1];
        }
        let AZID = parseInt(GNodes[parseInt(id)].parentNode);
        let VPCID = parseInt(GNodes[AZID].parentNode);
        const SubnetIPv4PublicImg = Constraints.IconsURL + 'SubnetIPv4Public.png';
        const SubnetIPv4PrivateImg = Constraints.IconsURL + 'SubnetIPv4Private.png';
        const SubnetIPv6PublicImg = Constraints.IconsURL + 'SubnetIPv6Public.png';
        const SubnetIPv6PrivateImg = Constraints.IconsURL + 'SubnetIPv6Private.png';
        const AZSubnetImg = Constraints.IconsURL + "AZSubnet.svg";
        let ipv6_native = false;//data.Param[11][1];
        let VPC_assign_generated_ipv6_cidr_block = false;//GNodes[VPCID].data.Param[4][1] === 0 && GNodes[VPCID].data.Param[4][2][1][1];
        let IPv6CIDRBlock = "";//GNodes[VPCID].data.Param[4][1] === 1 && GNodes[VPCID].data.Param[4][2][2][1] !== "";
        let IPAMv6 = "";//GNodes[VPCID].data.Param[4][1] === 2 && GNodes[VPCID].data.Param[4][2][3][2][2][1] !== ""
        let IsIPv6 = VPC_assign_generated_ipv6_cidr_block || IPv6CIDRBlock || IPAMv6;
        let IsIPv4 = ipv6_native === false;
        let IPv6CIDR = "IPv6 Auto Assigned";
        if (IPv6CIDRBlock) {
            IPv6CIDR = formatIPv6(GNodes[VPCID].data.Param[4][2][2][1]);
        } else {
            if (IPAMv6) { IPv6CIDR = "IPAMv6" };
        };
        if (ipv6_native && !IsIPv6) { IPv6CIDR = "Error: VPC doesn't permit IPv6!"; IsIPv6 = true; }
        if (IsIPv4 == false) { IPv4CIDR = ""; };
        let SrcSubnetIPv4 = SubnetIPv4PublicImg;
        if (data.Public == false) {
            SrcSubnetIPv4 = SubnetIPv4PrivateImg;
        }
        let SrcSubnetIPv6 = SubnetIPv6PublicImg;
        if (data.PublicIPv6 == false) {
            SrcSubnetIPv6 = SubnetIPv6PrivateImg;
        }
        let Height;
        if (GNodes[parseInt(id)] && GNodes[parseInt(id)].style && GNodes[parseInt(id)].style.height) {
            Height = GNodes[parseInt(id)].style.height;
        } else {
            Height = MinHeight;
        }
        let Width;
        if (GNodes[parseInt(id)] && GNodes[parseInt(id)].style && GNodes[parseInt(id)].style.width) {
            Width = GNodes[parseInt(id)].style.width;
        } else {
            Width = MinWidth;
        }
        const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
        const scaleFactor = CalculateScale(Math.sqrt(Height * Height + Width * Width), 300, 900) * 1.25;
        const flexContainerStyle = {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center" // Adiciona centralização horizontal
        };
        const baseStyles = {
            img: { width: 88, height: 90, margin: "2px 3px" },
            text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold", textAlign: 'center' },
            providerImg: { width: 88, height: 90, margin: "0px 0px" },
            title: { fontSize: 32 }
        }
        const scaledStyles = {
            img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor, height: baseStyles.img.height * scaleFactor },
            text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor * 1.3 },
            providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
            title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' },
            container: { height: 120 * scaleFactor - 2 }
        }
        const scaledStylesTitle = { title: { ...scaledStyles.title, marginTop: '1px' } };
        return (
            <div
                onMouseEnter={() => setIsMouseNear(true)}
                onMouseLeave={() => setIsMouseNear(false)}
            >
                <NodeResizer style={{ padding: 0 }} color="#ff0000" isVisible={selected} minWidth={MinWidth} minHeight={MinHeight} />
                <div style={{
                    position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#E2E2E2",
                    borderRadius: "15px 15px 0 0", border: data.EnableBorder ? data.Border : 'none',
                    ...scaledStyles.container
                }}>
                    <div className="custom-drag-handle" style={flexContainerStyle}>
                        <div style={{ ...scaledStylesTitle.title, display: 'flex', alignItems: 'center', transform: 'translateY(-5px)' }}>
                            <img src={AZSubnetImg} alt="Icon" style={{ ...scaledStyles.img, marginRight: '2px', width: '15px' }} />
                            <span>{SubnetName}</span>
                        </div>
                    </div>
                    <div className="custom-drag-handle" style={{
                        display: "flex", justifyContent: "center", alignItems: "center", transform: 'translateY(-10px)'
                    }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {IsIPv4 && <div style={scaledStyles.text}> {IPv4CIDR} </div>}
                            {IsIPv6 && <div style={scaledStyles.text}> {IPv6CIDR} </div>}
                        </div>
                    </div>

                    <div style={{ borderRadius: "10%" }}>
                    </div>
                </div>
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />

                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />
            </div>
        );
    } catch (error) {
        console.log("Error", error)
    }
};
/*const AZSubnet = ({ id, data, selected }) => {
    const PublicSubnetImg = Constraints.IconsURL + 'AZSubnet.svg';
    const PrivateSubnetImg = Constraints.IconsURL + 'AZSubnet.svg';
    let SubnetName = data.Param[1][1];
    let CIDR = data.Param[4][1]
    let SrcSubnet = PublicSubnetImg;
    if (data.Public == false) {
        SrcSubnet = PrivateSubnetImg;
    }
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    return (
        <>
            <NodeResizer style={{ padding: 10 }} color="#ff0000" isVisible={selected} minWidth={20} minHeight={20} />
            <div style={{
                position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#E2E2E2",
                borderRadius: "15px 15px 0 0"
            }}>
                <div className="custom-drag-handle" style={{
                    fontSize: 10, display: "flex", flexDirection: "row", alignItems: "center"
                }}>
                    <img src={SrcSubnet} style={{ borderRadius: "4px", height: "25px", width: "auto", margin: "0px 11px" }} />
                    <span>{SubnetName} {CIDR}</span>
                </div>
                <div style={{ borderRadius: "10%" }}>
                </div>
            </div>
            <Handle type="source" position="right" style={{
                background: 'transparent', backgroundImage: `url(${URLArrow})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center', border: 'none', afterBorderColor: 'none', width: '12px', height: '12px', top: '11px',
                transform: 'translateX(-2px)', imageRendering: 'crisp-edges', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
            }} isConnectable={true} />
            <Handle type="target" position="left" style={{
                background: 'transparent', backgroundImage: `url(${URLArrow})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center', border: 'none', afterBorderColor: 'none', width: '12px', height: '12px', top: '11px',
                transform: 'translateX(2px)', imageRendering: 'crisp-edges', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
            }}
                onConnect={(params) => console.log('handle onConnect', params)} isConnectable={true} />
        </>
    );
};*/

const AZSGIRule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZSGIRule.svg")';
    let Label = data.Label;
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};

const AZSGERule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZSGERule.svg")';
    let Label = data.Label;
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};


const AZSecurityGroup = ({ id, data, selected }) => {
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    const [isMouseNear, setIsMouseNear] = useState(false);
    return (
        <div
            onMouseEnter={() => setIsMouseNear(true)}
            onMouseLeave={() => setIsMouseNear(false)}
        >
            <NodeResizer style={{ padding: 50 }} color="#ff0000" isVisible={selected} minWidth={75} minHeight={80} />
            <div style={{
                position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#f2f2f2",
                borderRadius: "15px 15px 0 0", border: data.EnableBorder ? data.Border : 'none'
            }}>
                <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: 10 }}>
                    Security Group</div>
                <div style={{ borderRadius: "10%" }}>
                </div>
            </div >
            <Handle
                className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                id="right"
                position={Position.Right}
                type="source"
                isConnectable={true} />

            <Handle
                className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                id="left"
                position={Position.Left}
                type="source"
                isConnectable={true} />
            <Handle
                className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                id="left"
                position={Position.Top}
                type="source"
                isConnectable={true} />
            <Handle
                className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                id="top"
                position={Position.Bottom}
                type="source"
                isConnectable={true} />

        </div>
    );
};

export { AZSubnet, AZSGIRule, AZSGERule, AZSecurityGroup };