import React, { useState, useContext, createContext, useEffect } from 'react';
import { Constraints, ListaStandard, ResourceLookUp } from './NodesData';
import { ShowCode } from './ShowCode.js';
import { Compile } from './Compile.js';
import { Config } from './ConfigNodeNew';
import { Others } from './Others.js';
import { AskGPT } from './AskGPT.js';
import { Action } from './Action.js';

// Modal Context

export const ModalContext = createContext({
    activeModal: null,
    showModal: () => { },
    closeModal: () => { }
});

export const useModal = () => {
    return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
    const [activeModal, setActiveModal] = useState(null);

    const showModal = (modalName) => {
        setActiveModal(modalName);
    };

    const closeModal = () => {
        setActiveModal(null);
    };

    return (
        <ModalContext.Provider value={{ activeModal, showModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};


// Componente principal
const ModalMain = ({ isOpen, onClose, GlobalNodes, GlobalEdges, GlobalNodeModal, ModalMainIndex, onIDChange, onSetsharedNodeTypeSearch,
    onCopyStage, Saved }) => {
    let NodeType = GlobalNodeModal.type;
    let NodeID = GlobalNodeModal.id;
    //console.log("modal NodeID", NodeID, NodeType)
    let NodeName = GlobalNodes[parseInt(NodeID)].data.Param[1][1];
    const ResourceName = NodeType.slice(0, -1);
    const URLIcon = Constraints.IconsURL + ResourceName + '.png';
    //console.log("ResourceName", ResourceName, NodeType)
    let ListaStandardIndex = ResourceLookUp[NodeType];
    //console.log("ListaStandardIndex", ListaStandardIndex)
    const NodeDescription = ListaStandard[ListaStandardIndex].ResourceName;
    let Properties = ["Config"];
    let NewProperties = ListaStandard[ListaStandardIndex]["GeneralParam"]["ModalProperties"];
    if (NewProperties !== undefined) {
        Properties = Properties.concat(NewProperties);
    }
    Properties.push("Others");
    const ShowConfig = Properties.includes("Config");
    const ShowCompile = Properties.includes("Compile");
    const ShowShowCode = Properties.includes("Show Code");
    const ShowOthers = Properties.includes("Others");
    const ShowAskGPT = Properties.includes("AskGPT");
    const ShowAction = Properties.includes("Action");

    const [width, setWidth] = useState('30%');
    const [isResizing, setIsResizing] = useState(false);
    const [LastNodeIndex, setLastNodeIndex] = useState(0);
    const { activeModal, showModal, closeModal } = useModal();
    const [initialWidth, setInitialWidth] = useState(window.innerWidth * 0.15);

    const startResize = (event) => {
        setIsResizing(true);
    };

    const doResize = (e) => {
        if (isResizing) {
            const maxWidth = window.innerWidth * 0.75;
            const calculatedWidth = window.innerWidth - e.clientX;
            const newWidth = Math.min(maxWidth, Math.max(initialWidth, calculatedWidth));
            setWidth(`${newWidth}px`);
        }
    };

    const stopResize = () => {
        setIsResizing(false);
    };

    useEffect(() => {
        window.addEventListener('mousemove', doResize);
        window.addEventListener('mouseup', stopResize);
        return () => {
            window.removeEventListener('mousemove', doResize);
            window.removeEventListener('mouseup', stopResize);
        };
    }, [isResizing]);

    useEffect(() => {
        if (!isOpen) {
            closeModal(); // Certifique-se de que todos os modais filhos também são fechados quando o ModalMain é fechado
        }
    }, [isOpen]);

    useEffect(() => {
        //console.log("isOpen mudou para: ", isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (ModalMainIndex !== LastNodeIndex) {
            closeModal(); // Isso definirá activeModal para null
            setLastNodeIndex(ModalMainIndex); // Atualize LastNodeIndex para o valor atual de ModalMainIndex
            showModal('modalShowConfig'); // Mostra o submódulo Config
        }
    }, [ModalMainIndex, LastNodeIndex, closeModal, showModal]);

    const [clickCount, setClickCount] = useState(0);
    const handleModalClick = () => {
        setClickCount(prevCount => prevCount + 1);
    };

    return (
        <div onClick={handleModalClick}
            onDragStart={(e) => e.preventDefault()}  // Adicione esta linha
            style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                top: 0,
                right: 0,
                width: width,
                height: 'calc(100% - 15px)',
                backgroundColor: 'black',
                border: '1px solid white',
                padding: '1em 1px 2em 8px',
                paddingBottom: '2em',
                marginTop: '-2px',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                boxShadow: '-2px 0px 4px 0px rgba(0,0,0,0.60)',
            }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                padding: '3px 5px',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 auto',
                    marginLeft: 'max(10px, calc(50% - (widthDaImagem / 2) - metadeDoConteiner))',
                }}>
                    <img src={URLIcon} alt="Descrição da imagem" style={{ height: '30px', marginRight: '10px', boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%" }} />
                    <h3 style={{ margin: 0 }}>{NodeDescription}{": " + NodeName}</h3>
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>
                {ShowConfig && <button onClick={() => showModal('modalShowConfig')}
                    style={{ flexGrow: 1, marginRight: '1px', backgroundColor: activeModal === 'modalShowConfig' ? 'darkgray' : 'lightgray' }}>
                    Config  </button>}
                {ShowCompile && <button onClick={() => showModal('modalShowCompile')}
                    style={{ flexGrow: 1, marginRight: '1px', backgroundColor: activeModal === 'modalShowCompile' ? 'darkgray' : 'lightgray' }} >
                    Compile  </button>}
                {ShowShowCode && <button onClick={() => showModal('modalShowCode')}
                    style={{ flexGrow: 1, marginRight: '1px', backgroundColor: activeModal === 'modalShowCode' ? 'darkgray' : 'lightgray' }} >
                    Show Code  </button>}
                {ShowOthers && <button onClick={() => showModal('modalShowOthers')}
                    style={{ flexGrow: 1, marginRight: '1px', backgroundColor: activeModal === 'modalShowOthers' ? 'darkgray' : 'lightgray' }} >
                    Documentation </button>}
                {ShowAskGPT && <button onClick={() => showModal('modalShowAskGPT')}
                    style={{ flexGrow: 1, marginRight: '1px', backgroundColor: activeModal === 'modalShowAskGPT' ? 'darkgray' : 'lightgray' }} >
                    Ask GPT </button>}
                {ShowAction && <button onClick={() => showModal('modalAction')}
                    style={{ flexGrow: 1, marginRight: '1px', backgroundColor: activeModal === 'modalAction' ? 'darkgray' : 'lightgray' }} >
                    Action </button>}
                <button onClick={() => onClose && onClose()} style={{ flexGrow: 1, backgroundColor: 'lightgray' }}  > Close  </button>
            </div>

            <div className="modal-content" style={{ flex: 1, overflowY: 'auto' }}>
                {activeModal === 'modalShowCode' && <ShowCode Code={GlobalNodeModal.data.CodeGenerated} TFID={NodeID} />}
                {activeModal === 'modalShowOthers' && <Others NodeID={NodeID} GlobalNodes={GlobalNodes} GlobalEdges={GlobalEdges} onSetsharedNodeTypeSearch={onSetsharedNodeTypeSearch} />}
                {activeModal === 'modalShowCompile' && <Compile TFID={NodeID} GlobalNodes={GlobalNodes} GlobalEdges={GlobalEdges}
                    onIconClick={onIDChange} Saved={Saved} />}
                {activeModal === 'modalShowConfig' && <Config key={ModalMainIndex} Node={GlobalNodes[0]} GlobalNodes={GlobalNodes} />}
                {activeModal === 'modalShowAskGPT' && <AskGPT NodeID={NodeID} GlobalEdges={GlobalEdges} GlobalNodes={GlobalNodes} />}
                {activeModal === 'modalAction' && <Action NodeID={NodeID} GlobalEdges={GlobalEdges} GlobalNodes={GlobalNodes} onCopyStage={onCopyStage} />}
            </div>
            <span
                onMouseDown={startResize}
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    cursor: 'ew-resize',
                    width: '10px',
                    backgroundColor: 'transparent',
                    zIndex: 2000
                }}
            />
        </div>
    );
};


export default ModalMain;
