import { Handle, Position } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'
import { useState } from 'react';

const AZAppGBackend = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZAppGBackend.svg")';
    let Label = ResourceLabelAdjust(data.Label + " " + data.Param[1][1]);
    return (
        <GenericNode Label={Label} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            LabelName={data.Param[1][1]} ShowLabelName={data.ShowLabelName} />
    );
};

const AZAppGRule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZAppGRule.svg")';
    let Label = ResourceLabelAdjust(data.Label);
    return (
        <GenericNode Label={Label} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            LabelName={data.Param[1][1]} ShowLabelName={data.ShowLabelName} />
    );
};
const AZAppGListener = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZAppGListener.svg")';
    let Label = ResourceLabelAdjust(data.Label + " Port" + data.Param[3][1]);
    return (
        <GenericNode Label={Label} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            LabelName={data.Param[1][1]} ShowLabelName={data.ShowLabelName} />
    );
};
const AZAppGateway = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZAppGateway.svg")';
    let Label = ResourceLabelAdjust(data.Label);
    return (
        <GenericNode Label={Label} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            LabelName={data.Param[1][1]} ShowLabelName={data.ShowLabelName} />
    );
};
const AZAppGProbe = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZAppGProbe.svg")';
    let Label = ResourceLabelAdjust(data.Label);
    return (
        <GenericNode Label={Label} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            LabelName={data.Param[1][1]} ShowLabelName={data.ShowLabelName} />
    );
};
const AZAppGPathRule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZAppGPathRule.svg")';
    let Label = ResourceLabelAdjust(data.Label + " " + data.Param[2][1]);
    return (
        <GenericNode Label={Label} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            LabelName={data.Param[1][1]} ShowLabelName={data.ShowLabelName} />
    );
};



export { AZAppGateway, AZAppGListener, AZAppGRule, AZAppGBackend, AZAppGProbe, AZAppGPathRule };