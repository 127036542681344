import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { App } from './App';
import { APIDB, Stage, APIAuth, S3Url } from "./Config.js";
import { ProcessLoad } from './Functions';

// Importando fflate
import { unzlibSync } from 'fflate';

async function loadAppData() {
  const urlParams = new URLSearchParams(window.location.search);
  const Code = urlParams.get('code');
  let ErrorCtrl = false;
  let GlobalToken = sessionStorage.getItem("Code" + Code);
  let CognitoRegion, CognitoDomain, CognitoClient, AccessExpiresIn, RefreshExpiresIn, AccessExpiresAt, RefreshExpiresAt, RefreshToken;
  let LoadedEdges = [];
  let LoadedNodes = [];
  let GlobalUserName, GlobalCognitoSub, FileName, ParamFull, ListStandard, ResourceToNodeLookUp, ListAWSRegions, ListAzureRegions,
    DocList, ListTemplates;
  var ListAlarms = [];
  let appProps = {};

  if (Stage === 'DevLocal') {
    GlobalUserName = "Ricardo";
    GlobalCognitoSub = "";
  } else {
    GlobalUserName = sessionStorage.getItem("UserName");
    GlobalCognitoSub = sessionStorage.getItem("CognitoSub");
    CognitoRegion = sessionStorage.getItem("CognitoRegion");
    CognitoDomain = sessionStorage.getItem("CognitoDomain");
    CognitoClient = sessionStorage.getItem("CognitoClient");
    FileName = sessionStorage.getItem("FileName");
  }

  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  //if (Stage !== "DevLocal") {
  if (GlobalToken === null) {
    try {
      console.log("APIAuth", APIAuth)
      const response = await fetch(APIAuth, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ "code": Code })
      });
      const authData = await response.json();
      const parsedBody = JSON.parse(authData.body);
      console.log("parsedBody", parsedBody)
      if (parsedBody.error) {
        ErrorCtrl = true;
        console.log("Erro")
      } else {
        GlobalToken = parsedBody.access_token;
        myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
        console.log("GlobalToken", GlobalToken)
        sessionStorage.setItem("Code" + Code, GlobalToken);
        sessionStorage.setItem("GlobalToken", GlobalToken);
        RefreshToken = parsedBody.refresh_token;
        sessionStorage.setItem("RefreshToken", RefreshToken);
        AccessExpiresIn = parsedBody.access_expires_in;
        sessionStorage.setItem("AccessExpiresIn", AccessExpiresIn);
        RefreshExpiresIn = parsedBody.refresh_expires_in;
        const currentTime = new Date().getTime();
        AccessExpiresAt = currentTime + (AccessExpiresIn - 90) * 1000;
        RefreshExpiresAt = currentTime + RefreshExpiresIn * 1000;
        sessionStorage.setItem("AccessExpiresAt", AccessExpiresAt);
        sessionStorage.setItem("RefreshExpiresAt", RefreshExpiresAt);
        console.log("RefreshToken", AccessExpiresIn, RefreshExpiresIn, RefreshToken)
        GlobalUserName = parsedBody.user_name;
        sessionStorage.setItem("UserName", GlobalUserName);
        console.log("GlobalUserName", GlobalUserName)
        GlobalCognitoSub = parsedBody.user_sub + ":";
        sessionStorage.setItem("CognitoSub", GlobalCognitoSub);
        FileName = parsedBody.user_sub + ":P1"
        sessionStorage.setItem("FileName", FileName);
        console.log("FileName", FileName)
        CognitoRegion = parsedBody.cog_region;
        sessionStorage.setItem("CognitoRegion", CognitoRegion);
        CognitoDomain = parsedBody.cog_domain;
        sessionStorage.setItem("CognitoDomain", CognitoDomain);
        CognitoClient = parsedBody.cog_client;
        sessionStorage.setItem("CognitoClient", CognitoClient);
        console.log("Cognito", CognitoRegion, CognitoDomain, CognitoClient)
      }
    } catch (error) {

      console.error('Erro durante a autenticação:', error);
      ErrorCtrl = true;
    }
  }
  //} else {
  //ErrorCtrl = false;
  //}

  console.log("ErrorCtrl", ErrorCtrl)
  if (!ErrorCtrl) {
    let raw = JSON.stringify([3, "Nodes", FileName]);
    let requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
    try {
      const response = await fetch(APIDB, requestOptions);
      console.log("response", response)
      const text = await response.text();
      const Array = ProcessLoad(text);
      LoadedEdges = Array[0];
      LoadedNodes = Array[1];
      LoadedNodes[0].id = 'Config';
      console.log("EdgesNodes", LoadedEdges, LoadedNodes)
    } catch (error) {
      console.error("Fetch error:", error);
    }

    console.log("s3Url", S3Url)
    const response = await fetch(S3Url, { method: 'GET' });
    const buffer = await response.arrayBuffer();
    const compressedData = new Uint8Array(buffer);
    console.log(compressedData.slice(0, 100));

    const decompressedData = unzlibSync(compressedData);
    const decompressedString = new TextDecoder('utf-8').decode(decompressedData);
    console.log(decompressedString);

    let CloudManData = JSON.parse(decompressedString);
    ParamFull = CloudManData[0];
    ListStandard = CloudManData[1];
    ResourceToNodeLookUp = CloudManData[2];
    ListAWSRegions = CloudManData[3];
    ListAzureRegions = CloudManData[4];
    DocList = CloudManData[5];
    ListTemplates = CloudManData[6];
    console.log("ParamFull", ParamFull);
    console.log("ListaStandard", ListStandard);
    console.log("ResourceToNodeLookUp", ResourceToNodeLookUp);
    console.log("ListAWSRegions", ListAWSRegions);
    console.log("DocList", DocList);
    console.log("ListTemplates", ListTemplates)

    var TypeCloud = ["CloudN", "AZCloudN"];
    var TypeRegion = ["RegionN", "AZRegionN", "AZResourceGroupN"];
    var TypeVPC = ["VPCN", "AZVNETN"];
    var TypeSubnet = ["SubnetN", "AZSubnetN"];
    var TypeAZ = ["AZN", "AZAZN"];
    console.log("GlobalUserName", GlobalUserName)
  }

  if (!ErrorCtrl) {
    appProps = {
      LoadedEdges,
      LoadedNodes,
      GlobalToken,
    };
  }

  const root = createRoot(document.getElementById('root'));
  if (!ErrorCtrl) {
    root.render(
      <App DBFull={ParamFull} ListStandard={ListStandard} DocList={DocList} TypeCloud={TypeCloud} TypeVPC={TypeVPC} TypeRegion={TypeRegion}
        TypeAZ={TypeAZ} TypeSubnet={TypeSubnet} ListAWSRegions={ListAWSRegions} ListAzureRegions={ListAzureRegions} ListAlarms={ListAlarms}
        GlobalToken={GlobalToken} Code={Code} ListTemplates={ListTemplates} GlobalCognitoSub={GlobalCognitoSub} GlobalUserName={GlobalUserName}
        LoadedNodes={LoadedNodes} LoadedEdges={LoadedEdges} CognitoRegion={CognitoRegion} CognitoDomain={CognitoDomain}
        CognitoClient={CognitoClient} AccessExpiresAt={AccessExpiresAt} RefreshExpiresAt={RefreshExpiresAt} RefreshToken={RefreshToken}
        Stage={Stage} />
    );
  } else {
    if (Stage === "DevLocal") {
      root.render(<div>Erro ao carregar dados. Por favor, tente novamente mais tarde.</div>);
    } else {
      window.location.replace('https://cloudman.pro');
    }
  }
}

loadAppData();
