import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'





const ECR = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECR.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ECRP = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECRP.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const ECSCluster = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECSCluster.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const ECSRegistry = ({ data, selected }) => {
    let LabelName = data.Param[1][1];
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECSRegistry.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ECSService = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECSService.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const Fargate = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'Fargate.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};

const ECSCProvider = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECSCProvider.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ECSFCProvider = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECSFCProvider.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ECSDockerVolume = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECSDockerVolume.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ECSContainerDef = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ECSContainerDef.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { ECR, ECSCluster, ECSRegistry, ECSService, Fargate, ECSCProvider, ECSFCProvider, ECSDockerVolume, ECSContainerDef, ECRP };

