import { Handle, NodeToolbar } from "reactflow";
import { SearchNodesSource } from '../Functions'
import { Constraints, GNodes, GEdges } from '../NodesData';
import GenericNode from '../GenericNode'

const AZNI = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZNI.svg")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            FontSize={data.FontSize} Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};

const AZLinuxVM = ({ id, data, selected }) => {
    if (true) { //(data.Mode == "Regular") {
        let Label = data.Label + " " + data.Param[1][1] + " " + data.Param[3][1].replace(" ", "").replace("Standard", "STD-");
        let URLIcon = 'url("' + Constraints.IconsURL + 'AZLinuxVM.svg")';
        let ASGList = SearchNodesSource(GEdges, GNodes, GNodes[parseInt(id)], "AZLinuxVMScaleSetN");
        ASGList += SearchNodesSource(GEdges, GNodes, GNodes[parseInt(id)], "AZKNodePoolN");
        if (ASGList.length > 0) {
            URLIcon = 'url("' + Constraints.IconsURL + 'AZLinuxVMScaleSet.svg")';
            Label = "Linux VM with AS"
        }
        return (
            <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
                Source={data.Source} Target={data.Target} Selected={selected}
                ShowLabelName={data.ShowLabelName} />
        );
    }
};

export { AZLinuxVM, AZNI };

