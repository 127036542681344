let IsLocalHost;
const url = window.location.href;
let Subdomain = '';
let APIGID = "wc6mv5w9h0"
//const url = "localhost"

try {
    const parsedUrl = new URL(url);
    IsLocalHost = parsedUrl.hostname === 'localhost' || parsedUrl.hostname === '127.0.0.1';
    if (!IsLocalHost) {
        const hostnameParts = parsedUrl.hostname.split('.');
        if (hostnameParts.length > 2) {
            Subdomain = hostnameParts.slice(0, -2).join('.');
        } else {
            Subdomain = "Dev";
        }
    }
} catch (e) {
    // Se a URL for inválida, você pode tratar o erro aqui.
    console.error("URL inválida:", e);
    IsLocalHost = false;
}
let APIAWSTerraformCode, API, IconsURL, TemplatesURL, DownloadTemplateScripts, APIPricing, APIDB, Stage, APIExecTerraform, APIAuth, S3Url,
    APIAWSReader, APIAzureTerraformCode;
if (Subdomain === "test") {  //Variáveis se for test, então deve usar a versão antiga de test 
    APIAWSTerraformCode = `https://test.cloudman.pro/Dev/GenTerraformCode`
    API = `https://test.cloudman.pro/Dev/`;
    IconsURL = 'https://test.cloudman.pro/icones/';
    TemplatesURL = 'https://test.cloudman.pro/Templates/';
    DownloadTemplateScripts = 'https://test.cloudman.pro/downloadscripts/Cloudman.rar';
    APIDB = "https://test.cloudman.pro/Dev/Info";
    APIExecTerraform = "https://azilhnv821.execute-api.us-east-1.amazonaws.com/Dev/ExecTerraformCBuild"
    APIAuth = "https://test.cloudman.pro/Dev/CognitoAuthorizerCM"
    Stage = "Test"
    S3Url = `https://test.cloudman.pro/downloadscripts/CloudManDataTest.bin`;
    APIPricing = `https://test.cloudman.pro/Dev/Pricing`
    APIAWSReader = `https://test.cloudman.pro/Dev/AWSReader`
} else {
    if (IsLocalHost) {
        Stage = "DevLocal";
        Subdomain = "dev";
        //APIDB = `https://${APIGID}.execute-api.us-east-1.amazonaws.com/main/InfoDB-dev`;
        APIDB = `https://dev.cloudman.pro/main/InfoDB-dev`;
        IconsURL = 'https://dev.cloudman.pro/icones/';
        APIPricing = `https://${APIGID}.execute-api.us-east-1.amazonaws.com/main/AWSPricingStatusMetrics-dev`;
        API = `https://${APIGID}.execute-api.us-east-1.amazonaws.com/main/`;
        APIAWSTerraformCode = `https://${APIGID}.execute-api.us-east-1.amazonaws.com/main/AWSTerraformCode-dev`;
        APIAzureTerraformCode = `https://${APIGID}.execute-api.us-east-1.amazonaws.com/main/AzureTerraformCode-dev`;
        //APIAuth = `https://${APIGID}.execute-api.us-east-1.amazonaws.com/main/CloudManCognitoAuthorizer`;
        APIAuth = "https://cloudman.pro/main/CloudManCognitoAuthorizer";
        S3Url = `https://dev.cloudman.pro/downloadscripts/CloudManDataDev.bin`;
        TemplatesURL = 'https://test.cloudman.pro/Templates/';
        APIAWSReader = `https://${APIGID}.execute-api.us-east-1.amazonaws.com/main/AWSReader-dev`;
        APIExecTerraform = `${API}/ExecTerraform-${Subdomain}`;
    } else {
        Stage = Subdomain.charAt(0).toUpperCase() + Subdomain.slice(1);
        const APIBase = `https://${Subdomain}.cloudman.pro`;
        APIPricing = `${APIBase}/main/AWSPricingStatusMetrics-${Subdomain}`;
        APIAWSTerraformCode = `${APIBase}/main/AWSTerraformCode-${Subdomain}`;
        APIAzureTerraformCode = `${APIBase}/main/AzureTerraformCode-${Subdomain}`;
        APIExecTerraform = `${APIBase}/main/ExecTerraform-${Subdomain}`;
        APIDB = `${APIBase}/main/InfoDB-${Subdomain}`;
        APIAuth = "https://cloudman.pro/main/CloudManCognitoAuthorizer";
        //APIAuth = `https://3xgh0wi1fd.execute-api.us-east-1.amazonaws.com/main/CloudManCognitoAuthorizer`
        S3Url = `${APIBase}/downloadscripts/CloudManDataDev.bin`;
        IconsURL = `${APIBase}/icones/`;
        TemplatesURL = `${APIBase}/Templates/`;
        APIAWSReader = `${APIBase}/main/AWSReader-${Subdomain}`;
    }
}
console.log("APIDB ", APIDB)
console.log("Stage", Stage);
console.log("Subdomain", Subdomain);
const CloudManVersion = "0.0.12"
export {
    IconsURL, TemplatesURL, APIDB, Stage, CloudManVersion, DownloadTemplateScripts, APIPricing, APIExecTerraform, APIAWSTerraformCode,
    Subdomain, APIAuth, S3Url, APIAWSReader, APIAzureTerraformCode,
}


