import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'

const DockerHub = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'DockerHub.png")';
    let Label = data.Label;
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const GitHub = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'GitHub.png")';
    let Label = data.Label;
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const Copy = ({ data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'Copy.png")';
    let Label = data.Label;
    return (
        <GenericNode Label={Label} LabelName="" URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};



export { DockerHub, GitHub, Copy };