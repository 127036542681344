import React, { useState, useEffect } from 'react';
import { Handle, Position, useEdges } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import { Constraints, GNodes } from '../NodesData';
import GenericNode from '../GenericNode';
import { CalculateScale, GetVPCParent } from '../Functions';
import GenericBoxNode from '../GenericBoxNode';
const MinWidth = 120;
const MinHeight = 120;
const MaxWidth = 240;

function formatIPv6(ipv6) {
    if (ipv6.length > 18) {
        const halfIndex = Math.floor(ipv6.length / 2);
        const breakIndex = ipv6.indexOf(':', halfIndex - 5);
        if (breakIndex !== -1) {
            return ipv6.substring(0, breakIndex + 1) + "\n" + ipv6.substring(breakIndex + 1);
        }
    }
    return ipv6;
}

const Subnet = ({ id, data, selected }) => {
    const [isMouseNear, setIsMouseNear] = useState(false);
    let Opacity = data.Opacity;
    try {
        let AZID = parseInt(GNodes[parseInt(id)].parentNode);
        let VPCID = GetVPCParent(GNodes, AZID)
        const SubnetIPv4PublicImg = Constraints.IconsURL + 'SubnetIPv4Public.png';
        const SubnetIPv4PrivateImg = Constraints.IconsURL + 'SubnetIPv4Private.png';
        const SubnetIPv6PublicImg = Constraints.IconsURL + 'SubnetIPv6Public.png';
        const SubnetIPv6PrivateImg = Constraints.IconsURL + 'SubnetIPv6Private.png';
        let ipv6_native = data.Param[11][1];
        let VPC_assign_generated_ipv6_cidr_block = GNodes[VPCID].data.Param[4][1] === 0 && GNodes[VPCID].data.Param[4][2][1][1];
        let IPv6CIDRBlock = GNodes[VPCID].data.Param[4][1] === 1 && GNodes[VPCID].data.Param[4][2][2][1] !== "";
        let IPAMv6 = GNodes[VPCID].data.Param[4][1] === 2 && GNodes[VPCID].data.Param[4][2][3][2][2][1] !== ""
        let IsIPv6 = VPC_assign_generated_ipv6_cidr_block || IPv6CIDRBlock || IPAMv6;
        let IsIPv4 = ipv6_native === false;
        let SubnetName = data.Param[1][1];
        let IPv4CIDR = data.Param[7][1];
        let IPv6CIDR = "IPv6 Auto Assigned";
        if (IPv6CIDRBlock) {
            IPv6CIDR = formatIPv6(GNodes[VPCID].data.Param[4][2][2][1]);
        } else {
            if (IPAMv6) { IPv6CIDR = "IPAMv6" };
        };
        if (ipv6_native && !IsIPv6) { IPv6CIDR = "Error: VPC doesn't permit IPv6!"; IsIPv6 = true; }
        if (IsIPv4 == false) { IPv4CIDR = ""; };
        let SrcSubnetIPv4 = SubnetIPv4PublicImg;
        if (data.Public == false) {
            SrcSubnetIPv4 = SubnetIPv4PrivateImg;
        }
        let SrcSubnetIPv6 = SubnetIPv6PublicImg;
        if (data.PublicIPv6 == false) {
            SrcSubnetIPv6 = SubnetIPv6PrivateImg;
        }
        let Height;
        if (GNodes[parseInt(id)] && GNodes[parseInt(id)].style && GNodes[parseInt(id)].style.height) {
            Height = GNodes[parseInt(id)].style.height;
        } else {
            Height = MinHeight;
        }
        let Width;
        if (GNodes[parseInt(id)] && GNodes[parseInt(id)].style && GNodes[parseInt(id)].style.width) {
            Width = GNodes[parseInt(id)].style.width;
        } else {
            Width = MinWidth;
        }
        const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
        const scaleFactor = CalculateScale(Math.sqrt(Height * Height + Width * Width), .7) * .8 + .05;
        const flexContainerStyle = {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center" // Adiciona centralização horizontal
        };
        const baseStyles = {
            img: { width: 88, height: 90, margin: "2px 3px" },
            text: { whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold", textAlign: 'center' },
            providerImg: { width: 88, height: 90, margin: "0px 0px" },
            title: { fontSize: 32 }
        }
        const scaledStyles = {
            img: { ...baseStyles.img, width: baseStyles.img.width * scaleFactor, height: baseStyles.img.height * scaleFactor },
            text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor },
            providerImg: { ...baseStyles.providerImg, width: baseStyles.providerImg.width * scaleFactor, height: baseStyles.providerImg.height * scaleFactor },
            title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor, fontWeight: 'bold' }
        }
        const scaledStylesTitle = {

            text: { ...baseStyles.text, fontSize: baseStyles.text.fontSize * scaleFactor * 1.5 },
            title: { ...baseStyles.title, fontSize: baseStyles.title.fontSize * scaleFactor * 1.5, fontWeight: 'bold' }
        }
        return (
            <div
                onMouseEnter={() => setIsMouseNear(true)}
                onMouseLeave={() => setIsMouseNear(false)}
            >
                <GenericBoxNode
                    id={id}
                    data={data}
                    selected={selected}
                    HasHandle={true}
                >
                    <div style={{
                        position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)", backgroundColor: selected ? "#A2A2A2" : "#E2E2E2",
                        borderRadius: "15px 15px 0 0", border: data.EnableBorder ? data.Border : 'none', opacity: Opacity
                    }}>

                        <div className="custom-drag-handle" style={flexContainerStyle}>
                            <div style={scaledStylesTitle.title}>{SubnetName}</div>
                        </div>
                        <div className="custom-drag-handle" style={{ display: "flex", alignItems: "center" }}>
                            {IsIPv4 && <img src={SrcSubnetIPv4} alt="Icon" style={scaledStyles.img} />}
                            {IsIPv6 && <img src={SrcSubnetIPv6} alt="Icon" style={scaledStyles.img} />}
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                {IsIPv4 && <div style={scaledStyles.title}> {IPv4CIDR} </div>}
                                {IsIPv6 && <div style={scaledStyles.title}> {IPv6CIDR} </div>}
                            </div>
                        </div>
                        <div style={{ borderRadius: "10%" }}>
                        </div>
                    </div>
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="right"
                        position={Position.Right}
                        type="source"
                        isConnectable={true} />

                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="left"
                        position={Position.Left}
                        type="source"
                        isConnectable={true} />
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="left"
                        position={Position.Top}
                        type="source"
                        isConnectable={true} />
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="top"
                        position={Position.Bottom}
                        type="source"
                        isConnectable={true} />
                </GenericBoxNode>
            </div>
        );
    } catch (error) {
        console.log("Error", error)
    }
};

const NACLERule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'NACLERule.png")';
    let LabelName = " R" + data.Param[2][1] + " " + data.Param[3][3] + " " + data.Param[7][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const NACLIRule = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'NACLIRule.png")';
    if (data.Param[3][3].includes("Custom")) { }
    let LabelName = " R" + data.Param[2][1] + " " + data.Param[12][2] + " " + data.Param[3][3] + " " + data.Param[7][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const NACL = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'NACL.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { Subnet, NACLERule, NACLIRule, NACL };