import React, { useCallback } from 'react';
import { useStore, getBezierPath } from 'reactflow';
import { getEdgeParams } from './UtilEdge.js'; // Ajuste o caminho conforme necessário
import { Constraints, GNodes } from './NodesData';
import './App.css';

function FloatingEdge({ id, source, target, markerEnd, style, label, labelStyle, data }) {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    const labelPosition = 50;
    //const ImageURL = Constraints.IconsURL + 'IAMPolicy.png';
    let ImageURL;
    try {
        let ImageURL = data.ImageURL;
    } catch (error) {
        let ImageURL = undefined;
    }

    const HasButton = ImageURL !== undefined;



    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

    const [edgePath] = getBezierPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetX: tx,
        targetY: ty,
        targetPosition: targetPos,
    });

    // Função para calcular o ponto intermediário com base no labelPosition
    const getMidPoint = (percent) => {
        const t = percent / 100;
        const cx1 = (sx + tx) / 2;
        const cy1 = sy;
        const cx2 = (sx + tx) / 2;
        const cy2 = ty;

        const x = (1 - t) * (1 - t) * (1 - t) * sx + 3 * (1 - t) * (1 - t) * t * cx1 + 3 * (1 - t) * t * t * cx2 + t * t * t * tx;
        const y = (1 - t) * (1 - t) * (1 - t) * sy + 3 * (1 - t) * (1 - t) * t * cy1 + 3 * (1 - t) * t * t * cy2 + t * t * t * ty;
        return { x, y };
    };

    // Calcula a posição do rótulo
    const labelPos = getMidPoint(labelPosition);

    // Estilo aprimorado para o rótulo
    const enhancedLabelStyle = {
        ...labelStyle,
        fontSize: labelStyle.fontSize ? `${labelStyle.fontSize * 1.3}px` : '1.3em', // Aumenta o tamanho da fonte em 30%
        fontWeight: 'bold', // Negrito
        userSelect: 'none', // Impede a seleção do texto
    };

    const handleButtonClick = () => {
        console.log(`Edge ${id} clicked`);
    };

    return (
        <>
            <path
                d={edgePath}
                className="react-flow__edge-path"
                markerEnd={markerEnd}
                style={style}
            />
            <path
                d={edgePath}
                strokeWidth={20}
                style={{
                    stroke: 'transparent',
                    fill: 'none',
                    cursor: 'pointer'
                }}
            />
            <text
                x={labelPos.x}
                y={labelPos.y}
                style={enhancedLabelStyle}
                textAnchor="middle"
                dominantBaseline="central"
            >
                {label}
            </text>
            <foreignObject
                x={labelPos.x - 10}
                y={labelPos.y - 10}
                width={20}
                height={20}
                style={{ overflow: 'visible' }}
            >
                {HasButton && <button className="edgebutton" onClick={handleButtonClick}>
                    <img src={ImageURL} alt="button icon" style={{ width: '20px', height: '20px' }} />
                </button>}
            </foreignObject>
        </>
    );
}

export default FloatingEdge;
