import { Handle, Position, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import GenericNode from '../GenericNode';
import { Constraints } from '../NodesData';


const AZLinuxVMScaleSet = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AZLinuxVMScaleSet.svg")';
    let LabelName = data.Param[1][1];// + " Max=" + data.Param[3][1] + " Min=" + data.Param[4][1] + " Des=" + data.Param[5][1]
    return (
        <GenericNode Label={data.Label} LabelName={LabelName} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};

const ASPolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ASPolicy.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};
const ASLHook = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ASLHook.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};

const AppASPolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AppASPolicy.png")';
    return (
        <GenericNode Label={data.Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} />
    );
};


export { AZLinuxVMScaleSet, ASPolicy, ASLHook, AppASPolicy };