import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const Textract = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'Textract.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { Textract };

