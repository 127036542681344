
import { Constraints } from '../NodesData';
import '../VPC.css';
import GenericNode from '../GenericNode'


const IAMRole = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'IAMRole.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const IAMPolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'IAMPolicy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const IAMRolePolicy = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'IAMRolePolicy.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const IAMUser = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'IAMUser.png")';
    let LabelName = data.Param[1][1];
    let Label = data.Label + " " + data.Param[2][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { IAMRole, IAMPolicy, IAMRolePolicy, IAMUser };

