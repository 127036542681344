import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'
const Methods = ["GET", "POST", "PUT", "DELETE", "HEAD", "OPTIONS", "ANY"]

const APIGV2API = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGV2API.png")';
    let Label = data.Label + " " + data.Param[1][1];
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};

const APIGV2Integration = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGV2Integration.png")';
    let Label = data.Label + " " + data.Param[1][1];
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};
const APIGV2IntResp = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGV2IntResp.png")';
    let Label = data.Label + " " + data.Param[1][1];
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};
const APIGV2Route = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGV2Route.png")';
    let Label = data.Label + " " + data.Param[1][1];
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};
const APIGV2RouteResp = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGV2RouteResp.png")';
    let Label = data.Label + " " + data.Param[1][1];
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};
const APIGV2Authorizer = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGV2Authorizer.png")';
    let Label = data.Label + " " + data.Param[1][1];
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};
const APIGV2DomainName = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGV2DomainName.png")';
    let Label = data.Label + " " + data.Param[1][1];
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};

const APIGRestLambda = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'APIGRestLambda.png")';
    let Get = data.Param[3][2][1][1] != 0 ? "GET " : "";
    let Post = data.Param[3][2][2][1] != 0 ? "POST " : "";
    let Put = data.Param[3][2][3][1] != 0 ? "PUT " : "";
    let Delete = data.Param[3][2][4][1] != 0 ? "DELETE " : "";
    let Head = data.Param[3][2][5][1] != 0 ? "HEAD " : "";
    let Options = data.Param[3][2][6][1] != 0 ? "OPTIONS " : "";
    let Any = data.Param[3][2][7][1] != 0 ? "ANY" : "";
    let Label = data.Label + " (" + Get + Post + Put + Delete + Head + Options + Any + ")"
    return (
        <GenericNode Label={Label} LabelName={data.Param[1][1]} URL={URLIcon} ShowLabel={data.ShowLabel}
            Source={data.Source} Target={data.Target} Selected={selected}
            ShowLabelName={data.ShowLabelName} Opacity={data.Opacity} />
    );
};



export {
    APIGV2API, APIGRestLambda, APIGV2Integration, APIGV2IntResp, APIGV2Route, APIGV2RouteResp, APIGV2Authorizer, APIGV2DomainName,
};

