import React, { useState } from 'react';
import { useModal } from './ModalMain.js';
import { Constraints, ListDocAWS, ResourceLookUp, ListaStandard, DictTarget, DictSource } from './NodesData.js';
import { openConsole } from './Functions';
const Others = ({ GlobalNodes, NodeID, onSetsharedNodeTypeSearch, GlobalEdges }) => {
    const { activeModal, closeModal } = useModal();
    const NodeParam = GlobalNodes[NodeID].data.Param;
    const NodeType = GlobalNodes[NodeID].type;
    const ListTargetNodes = DictTarget[NodeType];
    const ListSourceNodes = DictSource[NodeType] || [];
    if (activeModal !== 'modalShowOthers') return null;

    //Documentation
    const TerraformIcon = Constraints.IconsURL + "Terraform.png";
    const IterCon = Constraints.IconsURL + "InterConnectionMapIcon.png";
    const AWSIcon = Constraints.IconsURL + "AWS.png";
    let ResourceTerraformName = NodeParam[0];
    let Sufix = ResourceTerraformName.split("$")[0];
    let Provider = Sufix.split("_")[0];
    Sufix = Sufix.replace("aws_", "").replace("azurerm_", "");
    let TerraformURL = "";
    if (Sufix.toUpperCase().startsWith("NA")) {
        TerraformURL = null;
    } else {
        if (Sufix === "required_providers") {
            TerraformURL = "https://registry.terraform.io/namespaces/hashicorp";
        } else {
            TerraformURL = Constraints.TerraformDocLink + Sufix;
            TerraformURL = TerraformURL.replace("$provider", Provider)
        }
    }

    let AWSURL = null;
    let ConsoleLinkResource = null;
    console.log("ListDocAWS", ListDocAWS);
    try {
        AWSURL = ListDocAWS[ResourceTerraformName][0];
    } catch (error) {
        AWSURL = null;
    }
    try {
        ConsoleLinkResource = ListaStandard[ResourceLookUp[NodeType]].GeneralParam.Console;
    } catch (error) {
        ConsoleLinkResource = null;
    }
    let EnableTerraformImg = true;
    let EnableAWSImg = true;
    if (AWSURL == null) { EnableAWSImg = false; }
    if (TerraformURL == null) { EnableTerraformImg = false; }
    let LookUpNamesByNodeType = Constraints['NodeNames'];
    const handleOnClick = (Node) => {
        onSetsharedNodeTypeSearch(LookUpNamesByNodeType[Node]);
    };



    return (
        <div style={{
            backgroundColor: 'white',
        }}>
            <div className="custom-node__header">
                <h2 className="custom-drag-handle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Documentation
                    <a href={TerraformURL} target="_blank" rel="noopener noreferrer">
                        {EnableTerraformImg && <img src={TerraformIcon} alt="Descrição da imagem" style={{
                            height: "50px", width: "50px",
                            borderRadius: "3px", marginLeft: "8px", borderRadius: "7%"
                        }} />}
                    </a>
                    <a href={AWSURL} target="_blank" rel="noopener noreferrer">
                        {EnableAWSImg && <img src={AWSIcon} alt="Descrição da imagem"
                            style={{ height: "50px", width: "50px", borderRadius: "3px", marginLeft: "8px", borderRadius: "7%" }} />}
                    </a>
                </h2>
            </div>
            {ConsoleLinkResource && <div className="custom-node__header">
                <h2 className="custom-drag-handle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Console
                    <img
                        src={AWSIcon}
                        alt="Descrição da imagem"
                        style={{ height: "50px", width: "50px", borderRadius: "3px", marginLeft: "8px", borderRadius: "7%" }}
                        onClick={() => openConsole(GlobalEdges, GlobalNodes, "awsConsoleWindow", NodeID, NodeType, ConsoleLinkResource)}
                    />
                </h2>
            </div>}
            <h2 className="custom-drag-handle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Connections Map
                <img src={IterCon} alt="Descrição da imagem" style={{
                    height: "50px", width: "50px", borderRadius: "3px", marginLeft: "8px", borderRadius: "7%"
                }} />
            </h2>
            <h2 style={{ backgroundColor: 'lightgray', width: '100%', display: 'block', textAlign: 'center' }}>Target Nodes</h2>
            {ListTargetNodes.map((Node, Index) => (
                <div style={{ display: 'flex', alignItems: 'center' }} key={Index}>
                    <img
                        src={Constraints.IconsURL + Node.slice(0, -1) + '.png'}
                        onClick={() => handleOnClick(Node)}
                        alt="Descrição da imagem"
                        style={{
                            boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                            cursor: 'pointer', height: "40px", width: "40px", borderRadius: "3px", marginLeft: "8px", marginRight: "8px", position: "relative", top: "3px"// ... estilos omitidos para brevidade
                        }}
                    />
                    <h3>{LookUpNamesByNodeType[Node]}</h3>
                </div>
            ))}
            <h2 style={{ backgroundColor: 'lightgray', width: '100%', display: 'block', textAlign: 'center' }}>Source Nodes</h2>

            {ListSourceNodes.map((Node, Index) => (
                <div style={{ display: 'flex', alignItems: 'center' }} key={Index}>
                    <img
                        src={Constraints.IconsURL + Node.slice(0, -1) + '.png'}
                        onClick={() => handleOnClick(Node)}
                        alt="Descrição da imagem"
                        style={{
                            boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                            cursor: 'pointer', height: "40px", width: "40px", borderRadius: "3px", marginLeft: "8px", marginRight: "8px", position: "relative", top: "3px"// ... estilos omitidos para brevidade
                        }}
                    />
                    <h3>{LookUpNamesByNodeType[Node]}</h3>
                </div>
            ))}
        </div>
    );
};
export { Others };