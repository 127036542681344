
import { APIAuth, APIDB, APIPricing } from "./Config.js"
import pako from 'pako';
import AWS from 'aws-sdk';
import { MarkerType } from 'reactflow';
import {
  ListaStandard, ResourceLookUp, TypeVPC, AccessExpiresAt, RefreshExpiresAt, RefreshToken, GlobalToken, Code,
  TypeTerraform, GlobalCognitoSub,
} from './NodesData';
import { Stage } from "./Config.js"
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';
import { Line } from '@nivo/line';
import { createRoot } from 'react-dom/client';
import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import Konva from 'konva';

var GlobalNodeID = 1;

/*function GetVPCParent(Nodes, ID) {
  //console.log("GetVPCParent", ID, Nodes[ID].type);

  if (TypeVPC.includes(Nodes[ID].type)) {
    //console.log("return")
    return ID;
  } else {
    //console.log("Nodes[ID].type", Nodes[ID].type)
    if (TypeCloud.includes(Nodes[ID].type)) {
      //console.log("FIm")
      return 0;
    } else {
      let FatherID = parseInt(Nodes[ID].parentNode);
      return GetVPCParent(Nodes, FatherID);
    }
  }
}*/
function GetVPCParent(Nodes, ID) {
  try {
    while (true) {
      if (TypeVPC.includes(Nodes[ID].type)) {
        return ID;
      } else if (TypeCloud.includes(Nodes[ID].type)) {
        return 0;
      } else {
        ID = parseInt(Nodes[ID].parentNode);
      }
    }
  } catch (error) {
    console.log("error", ID, error)
  }
}


//Return a List of source nodes connected
function SearchNodesSource(Edges, Nodes, Node, Type = "all", EdgeID = "") {
  let List = [];
  try {
    for (let j = 0; j < Edges.length; j++) {
      if (EdgeID == "") {
        if (Edges[j].target === Node.id) {
          let SourceNodeID = parseInt(Edges[j].source);
          if (Type == "all") {
            List.push(SourceNodeID);
          } else {
            if (Nodes[SourceNodeID].type == Type) {
              List.push(SourceNodeID);
            }
          }
        }
      } else {
        //If exists more than one handle, EdgeID != "". 
        //So it must check targetHadle to get information from appropriate handle
        if ((Edges[j].target === Node.id) && (Edges[j].targetHandle === EdgeID)) {
          let SourceNodeID = parseInt(Edges[j].source);
          if (Type == "all") {
            List.push(SourceNodeID);
          } else {
            if (Nodes[SourceNodeID].type == Type) {
              List.push(SourceNodeID);
            }
          }
        }

      }
    }
    return List;
  } catch (error) {
    return [];
  }
}

//Return a List of target nodes ID connected
function SearchNodesTarget(Edges, Nodes, Node, Type = "all") {
  try {
    let List = [];
    for (let j = 0; j < Edges.length; j++) {
      if (Edges[j].source === Node.id) {
        let TargetNodeID = parseInt(Edges[j].target);
        if (Type == "all") {
          List.push(TargetNodeID);
        } else {
          if (Nodes[TargetNodeID].type == Type) {
            List.push(TargetNodeID);
          }
        }
      }
    }
    return List;
  } catch (error) {
    return [];
  }
}

function ResourceLabelAdjust(InputLabel) {
  let Label = InputLabel;
  if (Label.includes("_")) {
    Label = InputLabel.replaceAll("_", " ");
  }
  if (InputLabel.includes("aws")) {
    Label = Label.replace("aws", "");
  }
  let List = Label.split(" ");
  Label = "";
  for (let i = 0; i < List.length; i++) {
    Label += List[i].charAt(0).toUpperCase() + List[i].slice(1) + " ";
  }
  return Label;
}

////Return true if find a especific target type node connected at two levels from a source node
function SearchNodesTargetTwoLevels(Edges, Nodes, NodeSource, ConnectionTypeL1, ConnectionTypeL2) {
  let ListTargetL1 = SearchNodesTarget(Edges, Nodes, NodeSource, ConnectionTypeL1);
  if (ListTargetL1.length == 0) {
    return false;
  } else {
    for (let i = 0; i < ListTargetL1.length; i++) {
      let ListTargetL2 = SearchNodesTarget(Edges, Nodes, Nodes[ListTargetL1[i]], ConnectionTypeL2);
      if (ListTargetL2.length == 0) {
        return false;
      } else {
        return true;
      }
    }
  }
}

////Return true if find a especific target of a node connected out
function VerifyNodesTypeTarget(Edges, Nodes, NodeSource, ConnectionType) {
  let ListTargetL1 = SearchNodesTarget(Edges, Nodes, NodeSource, ConnectionType);
  if (ListTargetL1.length == 0) {
    return false;
  } else {
    return true;
  }
}

function RemoveSelected(nodes) {
  nodes.map((node) => {
    if (node.id === 'Config') {
      node.hidden = true;
    };
    node.selected = false;
  })
}

//Returm the absolute position considering all father nodes until Cloud
function GetFatherPosition(nodes, FatherNodeID, Position) {
  try {
    let Node = nodes[FatherNodeID];
    if (typeof (FatherNodeID) != "number") {
      Node = nodes[parseInt(FatherNodeID)];
    }
    Position.x += JSON.parse(JSON.stringify(Node.position.x));
    Position.y += JSON.parse(JSON.stringify(Node.position.y));
    let NodeParent = parseInt(Node.parentNode);
    if (NodeParent !== 0) {
      //console.log("Position", Node.type, Position)
      let NewPosition = JSON.parse(JSON.stringify(GetFatherPosition(nodes, NodeParent, JSON.parse(JSON.stringify(Position)))));
      return NewPosition;
    }
    return Position;
  } catch (error) {
    return Position;
  }
}

//Returms the relative node Father position
function AdjustFatherPosition(nodes, FatherNodeID, position, It = 0) {
  try {
    let Node = nodes[parseInt(FatherNodeID)];
    position.x -= Node.position.x;
    position.y -= Node.position.y;
    let NodeParent = parseInt(Node.parentNode);
    if (NodeParent !== 0) {
      It += 1;
      let newPosition = { x: 0, y: 0 };
      if (It < 20) {
        newPosition = AdjustFatherPosition(nodes, NodeParent, position, It);
      } else { console.log("proteção de Loop infinito"); }
      position = newPosition;
    }
  } catch (error) {
    //pass
  }
  return position;
}

//Return ID List of one especific type 
function SearchNodeType(nodes, type) {
  let List = [];
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].type == type) {
      List.push(nodes[i].id);
    }
  }
  return List
}

//Return Name List of one especific type 
function SearchNodeTypeReturnNameList(nodes, type) {
  let List = [];
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].type == type) {
      List.push(nodes[i].data.Param[1][1]);
    }
  }
  return List
}

/*function compareAndAdd(str, arr) {
  let newStr = str;
  while (arr.includes(newStr)) {
    let match = newStr.match(/-(\d+)$/);
    if (match) {
      let number = parseInt(match[1]) + 1;
      newStr = newStr.replace(/-\d+$/, "-" + number);
    } else {
      newStr = newStr + "-1";
    }
  }
  return newStr;
}*/
function compareAndAdd(str, arr) {
  let newStr = str;
  while (arr.includes(newStr)) {
    const match = newStr.match(/(\d+)$/);
    if (match) {
      const number = parseInt(match[1], 10) + 1;
      newStr = newStr.replace(/\d+$/, number);
    } else {
      newStr += "1";
    }
  }
  return newStr;
}
//Compare if Node Name already exist. If true, insert a sufix for the name to be unique
//Return Name to be add.
function SearchNameNodeType(nodes, type, Name) {
  let List = SearchNodeTypeReturnNameList(nodes, type);
  //console.log("compareAndAdd(Name, List);", Name, List);
  Name = compareAndAdd(Name, List);
  return Name;
}

//Return Chield List from Selected Node and ID of selected node
function FindNodesEdgesChieldSelected(nodes, Edges) {
  var SelectedNode = "0";
  for (let i = 0; i < nodes.length; i++) {//Acha primeiro selected Node
    if (nodes[i].selected) {
      var SelectedNode = nodes[i];
    }
  }
  if (SelectedNode != "0") {
    let SelectedGroup = FindNodesEdgesChield(nodes, Edges, SelectedNode);
    //console.log("SelectedGroup", SelectedGroup)
    return SelectedGroup;
  } else {
    return [];
  }
}

//Return a list Nodes ID above the SelectedNode.
function FindNodesChieldIDFromParent(nodes, NodeParent) {
  let SelectedNode = NodeParent;
  let NodesList = FindNodesEdgesChield(nodes, [], SelectedNode);
  NodesList = NodesList[0];
  let NodesListID = [];
  for (let i = 0; i < NodesList.length; i++) {
    NodesListID.push(NodesList[i].id);
  }
  return NodesListID;
}


//Return a list of Edes and Nodes above the SelectedNode, including it.
//SelectedNode = node
function FindNodesEdgesChield(nodes, Edges, SelectedNode) {
  var SelectedNodeID = SelectedNode.id;
  var ListNodes = [SelectedNode];
  var ListNodesID = [SelectedNodeID];
  var ListSearchID = [SelectedNodeID];
  while (ListSearchID.length > 0) {
    for (let i = 0; i < nodes.length; i++) {
      let ChieldNode = nodes[i].parentNode;
      if ((ChieldNode == SelectedNodeID) && (ListNodesID.includes(nodes[i].id) == false)) {
        ListNodes.push(nodes[i]);
        ListNodesID.push(nodes[i].id);
        ListSearchID.push(nodes[i].id);
      }
    }
    ListSearchID.shift();
    SelectedNodeID = ListSearchID[0];
  }
  var ListEdges = [];
  var ListEdgesID = [];
  for (let i = 0; i < Edges.length; i++) {
    if ((ListNodesID.includes(Edges[i].target)) && (ListNodesID.includes(Edges[i].source))) {
      ListEdges.push(Edges[i]);
      ListEdgesID.push(Edges[i].id);
    }
  }
  //Find all other nodes, are connected with any of these nodes Chield.
  //First list of Nodes target these nodes Chield
  var ListEdgesOutSource = [];
  for (let i = 0; i < Edges.length; i++) {
    if (ListNodesID.includes(Edges[i].source)) {
      if (ListEdgesID.includes(Edges[i].id)) {
        //pass
      } else {
        ListEdgesOutSource.push(Edges[i]);
      }
    }
  }
  var ListEdgesOutTarget = [];
  for (let i = 0; i < Edges.length; i++) {
    if (ListNodesID.includes(Edges[i].target)) {
      if (ListEdgesID.includes(Edges[i].id)) {
        //pass
      } else {
        ListEdgesOutTarget.push(Edges[i]);
      }
    }
  }
  return [ListNodes, ListEdges, ListEdgesOutSource, ListEdgesOutTarget];
}

//Return a list of all Nodes ID below the SelectedNode, including it.
function FindNodesChieldID(nodes, SelectedNodeID, NodeType = "All") {
  try {
    console.log("SelectedNodeID", SelectedNodeID)
    var ListNodesID = [SelectedNodeID.toString()];
    var ListSearchID = [SelectedNodeID];
    while (ListSearchID.length > 0) {
      for (let i = 0; i < nodes.length; i++) {
        let ChieldNode = nodes[i].parentNode;
        if (ChieldNode == SelectedNodeID) {
          ListNodesID.push(nodes[i].id);
          ListSearchID.push(nodes[i].id);
        }
      }
      ListSearchID.shift();
      SelectedNodeID = ListSearchID[0];
    }
    if (NodeType != "All") {
      let NewListNodesID = [];
      for (let i = 0; i < ListNodesID.length; i++) {
        if (nodes[parseInt(ListNodesID[i])].type == NodeType) {
          NewListNodesID.push(ListNodesID[i])
        }
      }
      ListNodesID = NewListNodesID;
    }
    return ListNodesID;
  } catch (error) {
    //pass
  }
}

//Return a list of only Nodes ID above parent and below the SelectedNode, including it.
function FindNodesChieldOneLevelID(nodes, FatherNodeID) {
  let ListNodesID = [];
  for (let i = 0; i < nodes.length; i++) {
    let NodeID = nodes[i].parentNode;
    if (FatherNodeID == NodeID) {
      ListNodesID.push(nodes[i].id);
    }
  }
  return ListNodesID;
}

//retrun a list of the selected nodes
function FindSelectedNodes(Nodes) {
  var ListSelected = [];
  for (let i = 0; i < Nodes.length; i++) {
    if (Nodes[i].selected == true) {
      ListSelected.push(Nodes[i]);
    }
  }
  return ListSelected;
}

//Return the especifiec resource Father ID. If didnt find, return "0"
//Nodes = array with list of nodes to be searched
//Node = Node leaf
//NodeFatherType = node type to be found
function FindResourceFather(Nodes, Node, NodeFatherType) {
  while (true) {
    try {
      var ParentNode = parseInt(Node.parentNode);
    } catch (error) { return "0"; }
    let NodeType = Nodes[ParentNode].type;
    if (NodeType == NodeFatherType) {
      return parseInt(Nodes[ParentNode].id);
    } else {
      Node = Nodes[ParentNode]
    }
  }
}
var TypeCloud = ["CloudN", "AZCloudN"];
var TypeAZ = ["AZN", "AZAZN"];
//return a nodeID list of the father and above
function FindAllFathers(Nodes, NodeID) {
  let ListFather = [];
  if (TypeCloud.includes(Nodes[parseInt(NodeID)].type)) {
    return ListFather;
  } else {
    let x = 30;
    while (true) {
      try {
        var ParentNode = Nodes[NodeID].parentNode;
      } catch (error) { return []; }
      if (ParentNode == "Config") { return ListFather }
      ListFather.push(ParentNode);
      NodeID = ParentNode;
      let NodeType = Nodes[ParentNode].type;
      if (TypeCloud.includes(NodeType)) {
        return ListFather;
      }
      x -= 1;
      if (x == 0) {
        console.log("Queboru loop");
        break;
      }
    }
  }
}

var TypeRegion = ["RegionN", "AZRegionN", "KClusterN"];
var TypeSubnet = ["SubnetN", "AZSubnetN"];
//Return ID of Region and Cloud above Node
function FindRegionAndCloud(Nodes, NodeID) {
  try {
    //console.log("TypeCloud", TypeCloud)
    if (TypeCloud.includes(Nodes[parseInt(NodeID)].type)) {
      return [NodeID, NodeID]
    } else {
      var Node = Nodes[parseInt(NodeID)];
      var NodeType = Node.type;
      var NodeID = Node.id;
      let x = 30;
      while (true) {
        //console.log("NodeType", NodeType);
        if (TypeRegion.includes(NodeType)) {
          let Region = NodeID;
          let Cloud = Node.parentNode;
          //console.log("AZResourceGroupN", Nodes[(parseInt(Cloud))].type)
          if (Nodes[(parseInt(Cloud))].type == "AZResourceGroupN") {
            let RG = Cloud;
            Cloud = Nodes[(parseInt(Cloud))].parentNode;
            return [Cloud, Region, RG];
          } else {
            return [Cloud, Region];
          }
        } else {
          Node = Nodes[parseInt(Node.parentNode)]
          try {
            NodeType = Node.type;
          } catch (error) {
            return [NodeID, NodeID]
          }
          NodeID = Node.id;
          x -= 1;
          if (x == 0) {
            //console.log("Queboru loop");
            return [NodeID, NodeID]
          }
        }
      }
    }
  } catch (error) {
    return [NodeID, NodeID]
  }
}

//Return ID of Region and Cloud above Node
function FindFiliationLevel(Nodes, NodeID) {
  var Node = Nodes[parseInt(NodeID)];
  var NodeFather = Nodes[parseInt(Node.parentNode)];
  var NodeFatherType = NodeFather.type;
  //console.log("NodeXXX", Node, NodeFather, NodeFatherType);
  if (TypeRegion.includes(Node.type)) {
    return 0;
  }
  let Level = 1;
  let i = 0;
  while (true) {
    if (TypeRegion.includes(NodeFatherType)) {
      return Level;
    } else {
      Level += 1;
      Node = NodeFather;
      NodeFather = Nodes[parseInt(Node.parentNode)];
      //console.log("NodeFather", NodeFather);
      NodeFatherType = NodeFather.type;
    }
    i += 1
    if (i == 20) {
      return 0;
    }
  }
}

function nextIPCIDR(ipCIDR, ListCIDR, MainCIDR = "") {
  //let PassAllList = false;
  let NextIPCIDR = FindnextIPCIDR(ipCIDR);
  //console.log("NextIPCIDR", NextIPCIDR)
  //console.log("MAinCIDR", MainCIDR)
  while (true) {
    for (let i = 0; i < ListCIDR.length; i++) {
      let FoundCIDR = true;
      for (let j = 0; j < ListCIDR.length; j++) {
        if (checkCIDROverlap(NextIPCIDR, ListCIDR[j])) {
          NextIPCIDR = FindnextIPCIDR(NextIPCIDR);
          //console.log("NextIPCIDR", NextIPCIDR)
          FoundCIDR = false;
          break;
        }
      }
      if (FoundCIDR) {
        return NextIPCIDR;
      }
    }
    //if ((MainCIDR == "") || (checkCIDROverlap(NextIPCIDR, MainCIDR) == false)) {
    //  break;
    //}
    if (MainCIDR == "") {
      break;
    }
    if (checkCIDROverlap(NextIPCIDR, MainCIDR) == false) {
      return NextIPCIDR;
    }
  }
  if (ListCIDR.length == 0) {
    return ipCIDR;
  } else {
    return "No CIDR available";
  }
}

function FindnextIPCIDR(ipCIDR, IncNumber = 1) {
  // Separa o IP do CIDR
  let cidr = ipCIDR.split("/")[1];
  let ip = ipCIDR.split("/")[0];
  let octets = ip.split(".");
  let SumOctets = getIpOctets(parseInt(cidr));
  // Converte os octetos para inteiros
  octets = octets.map(octet => parseInt(octet));
  // Calcula a máscara de rede
  let netmask = Array(4).fill(0);
  for (let i = 0; i < cidr; i++) {
    netmask[Math.floor(i / 8)] += Math.pow(2, 7 - (i % 8));
  }
  // Aplica a máscara de rede aos octetos do IP
  octets = octets.map((octet, index) => octet & netmask[index]);
  for (let i = 3; i >= 0; i--) {
    octets[i] += SumOctets[i];
    if (octets[i] > 255) {
      octets[i] = 0;
      octets[i - 1] += IncNumber; //checkIPCIDRConflict function no must inc
    }
  }
  // Monta o próximo IP como uma string
  let nextIP = octets.join(".");
  // Retorna o próximo IP/CIDR
  return `${nextIP}/${cidr}`;
}

function getIpOctets(cidr) {
  let octets = [];
  octets[0] = 0;
  octets[1] = 0;
  octets[2] = 0;
  octets[3] = 0;
  if ((cidr > 24) && (cidr < 33)) {
    octets[3] = Math.pow(2, (24 - cidr + 8))
  }
  if ((cidr > 16) && (cidr < 25)) {
    octets[2] = Math.pow(2, (16 - cidr + 8))
  }
  if ((cidr > 8) && (cidr < 17)) {
    octets[1] = Math.pow(2, (8 - cidr + 8))
  }
  if ((cidr > 0) && (cidr < 9)) {
    octets[0] = Math.pow(2, (0 - cidr + 8))
  }
  return octets;
}

function checkCIDROverlap(cidr1, cidr2) {
  // Extrai o endereço IP e o comprimento da máscara para cada bloco CIDR
  const [ip1, mask1] = cidr1.split('/');
  const [ip2, mask2] = cidr2.split('/');
  // Converte o endereço IP em um número inteiro de 32 bits
  const intIP1 = ipToNumber(ip1);
  const intIP2 = ipToNumber(ip2);
  // Converte o comprimento da máscara em um número inteiro
  const intMask1 = parseInt(mask1, 10);
  const intMask2 = parseInt(mask2, 10);
  // Calcula o intervalo de endereços IP para cada bloco CIDR
  const start1 = intIP1 & getMask(intMask1);
  const end1 = start1 + (~getMask(intMask1) >>> 0);
  const start2 = intIP2 & getMask(intMask2);
  const end2 = start2 + (~getMask(intMask2) >>> 0);
  // Verifica se há alguma sobreposição entre os intervalos de endereços IP
  return start1 <= end2 && end1 >= start2;
}
// Converte um endereço IP em um número inteiro de 32 bits
function ipToNumber(ip) {
  return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0) >>> 0;
}
// Obtém a máscara de sub-rede correspondente a um comprimento de máscara dado
function getMask(maskLength) {
  return (0xffffffff << (32 - maskLength)) >>> 0;
}
function ipToInt(ip) {
  let octets = ip.split(".");
  let result = 0;
  for (let i = 0; i < octets.length; i++) {
    result += octets[i] * Math.pow(256, octets.length - i - 1);
  }
  return result;
}

function cidrToInt(cidr) {
  let mask = 0;
  for (let i = 0; i < 32; i++) {
    if (i < cidr) {
      mask += Math.pow(2, 31 - i);
    }
  }
  return mask;
}

function dec2bin(dec) {
  return (dec >>> 0).toString(2);
}

function checkIPCIDRConflict(ipCIDR1, ipCIDR2) {
  let List = [];
  let newIp = "";
  let Dif = 0;
  let ip1 = ipCIDR1.split("/")[0];
  let cidr1 = ipCIDR1.split("/")[1];
  let ip2 = ipCIDR2.split("/")[0];
  let cidr2 = ipCIDR2.split("/")[1];
  if (cidr1 > cidr2) {
    let aux = cidr2;
    cidr2 = cidr1;
    cidr1 = aux;
    aux = ip2;
    ip2 = ip1;
    ip1 = aux;
  } else {
    if (cidr1 === cidr2) {
      List = [[ip2, cidr2]];
    } else {
    }
  }
  //console.log("ip1", ip1, "cidr1", cidr1, "ip2", ip2, "cidr2", cidr2)
  if (cidr1 !== cidr2) {
    let netmask = Array(4).fill(0);
    for (let i = 0; i < cidr2; i++) {//find the netmask of cidr2
      netmask[Math.floor(i / 8)] += Math.pow(2, 8 - (i % 8));
    }
    let result = 0;
    for (let i = 0; i < netmask.length; i++) {
      result += netmask[i] * Math.pow(256, netmask.length - i - 1);
    }
    netmask = result;

    //console.log("netmask", dec2bin(netmask));
    let IP2array = ip2.split(".");
    Dif = Math.pow(2, cidr2 - cidr1);
    ip2 = ipToInt(ip2.split("/")[0]) & netmask;
    let adder = Math.pow(2, (cidr2 - 32) * (-1));
    //console.log("adder", dec2bin(adder), cidr2, dec2bin(ip2));
    for (let i = 0; i < Dif; i++) {
      List.push([ip2, dec2bin(ip2)]);
      ip2 += adder;
    }
  }
  let Len = List.length;
  //console.log("list", List, Len);
  let Res = false;
  for (let j = 0; j < Len; j++) {
    // Converte os endereços IP para números inteiros
    ip2 = List[j]//ipToInt(ip1);
    let ip1Int = ipToInt(ip1);
    let mask2 = cidrToInt(cidr2);
    let mask1 = cidrToInt(cidr1);
    // Aplica a máscara de rede
    let network1 = ip1Int & mask1;
    let network2 = ip2 & mask2;
    // Verifica se os endereços de rede são iguais
    if (network1 === network2) {
      Res = true;
    }

  }
  return Res
}

//Return id of Father Node of node to be add. Return "0" if not permit
function PermitionToAddNode(NodeToBeAdd, NodeSelected, NodeData) {
  if (NodeData[NodeToBeAdd.slice(0, -1)].data.NodeFatherType.includes(NodeSelected.type)) {
    //Verifica se a lista em NodeFatherType inclui o node selecionado
    return NodeSelected.id;
  } else {
    if (NodeSelected.data.NodeFatherType == "NA") {//Ex.: CloudN e AZN
      return "0";
    }
    for (let k = 0; k < NodeSelected.data.NodeFatherType.length; k++) {//Percorre a lista de nodes que podem ser pai
      let NodeFatherType = NodeSelected.data.NodeFatherType[k].slice(0, -1);
      let ListChieldType = NodeData[NodeFatherType].data.ListChieldType;
      for (let i = 0; i < ListChieldType.length; i++) {
        if (ListChieldType[i] == NodeToBeAdd) {
          let NodeFatherID = NodeSelected.parentNode;
          return NodeFatherID;
        }
      }
      return "0";
    }
  }
}
function HandlerGeneral(NodeType, Func, nodes, NodeData) {
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].selected == true) {
      let IDParent = PermitionToAddNode(NodeType, nodes[i], NodeData);
      if (IDParent != "0") {
        Func(IDParent);
        break
      }
    }
  }
}
function GetCIDRList(nodes, NodeType) {
  let CIDRList = [];
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].type == NodeType) {
      if (NodeType == "VPCN") {
        CIDRList.push(nodes[i].data.Param[3][2][1][1]);
      }
      if (NodeType == "SubnetN") {
        CIDRList.push(nodes[i].data.Param[7][1]);
      }
    }
  }
  return CIDRList;
}

function RemoveNodesFromList(OriginalListNodes, RemoveListId) {
  let RemoveListID = RemoveListId;
  RemoveListID.sort((a, b) => b - a);
  for (let i = 0; i < RemoveListID.length; i++) {
    for (let j = 0; j < OriginalListNodes.length; j++) {
      if (parseInt(OriginalListNodes[j].id) == RemoveListID[i]) {
        OriginalListNodes.splice(j, 1);
        break;
      }
    }
  }
  return OriginalListNodes;
}

function RemoveEdgesFromList(OriginalListEdges, RemoveListId) {

  let listaFinal = OriginalListEdges.filter(item => !RemoveListId.includes(item));
  //console.log("RemoveEdgesFromList", OriginalListEdges, RemoveListId)

  /*let RemoveListID = RemoveListId;
  RemoveListID.sort((a, b) => b - a);
  for (let i = 0; i < RemoveListID.length; i++) {
    let RemovedItem = parseInt(RemoveListID[i]);
    for (let j = 0; j < OriginalListEdges.length; j++) {
      if (parseInt(OriginalListEdges[j].id) == RemoveListID[i]) {
        OriginalListEdges.pop(RemovedItem - 1);
        break;
      }
    }
  }*/
  return listaFinal;
}

//Returm a list of nodes boxes could be father of the new node
//It calculates the absolute of this potential father box nodes
function ListBoxNodes(ListBoxNodesType, nodes) {
  console.log("ListBoxNodesType, nodes", ListBoxNodesType, nodes)
  var ListBox = [];
  let position = 0;
  for (let i = 1; i < nodes.length; i++) {
    if (ListBoxNodesType.includes(nodes[i].type)) {
      if (nodes[i].parentNode != 0) {
        position = JSON.parse(JSON.stringify(GetFatherPosition(nodes, nodes[i].parentNode, JSON.parse(JSON.stringify(nodes[i].position)))));
      } else {
        position = JSON.parse(JSON.stringify(nodes[i].position));
      }
      ListBox.push({
        "id": nodes[i].id, "x": position.x, "y": position.y,
        "height": nodes[i].style.height, "width": nodes[i].style.width, "type": nodes[i].type
      });

    }
  }
  return ListBox;
}

//Returm an IDs list of nodes potetially father, according position X,Y and ListBoxNodesType given
function findIntersectingNodesBox(nodes, position, ListBoxNodesType) {
  console.log("nodes, position, ListBoxNodesType", nodes, position, ListBoxNodesType)
  let intersectingRectangles = [];
  let rectangles = ListBoxNodes(ListBoxNodesType, nodes);
  rectangles.forEach((rectangle) => {
    if (
      position.x >= rectangle.x &&
      position.x <= rectangle.x + rectangle.width &&
      position.y >= rectangle.y &&
      position.y <= rectangle.y + rectangle.height
    ) {
      intersectingRectangles.push([rectangle.id, rectangle.type]);
    }
  });

  return intersectingRectangles;
}

function isS3BucketNameValid(bucketName) {
  // Bucket names must be between 3 and 63 characters long.
  if (bucketName.length < 3 || bucketName.length > 63) {
    return false;
  }

  // Bucket names must be made up of lowercase letters, numbers, and hyphens.
  if (!/^[a-z0-9-]+$/.test(bucketName)) {
    return false;
  }

  // Bucket names must start and end with a lowercase letter or a number.
  if (!/^[a-z0-9]/.test(bucketName) || !/[a-z0-9]$/.test(bucketName)) {
    return false;
  }

  // Bucket names cannot contain two adjacent hyphens.
  if (/--/.test(bucketName)) {
    return false;
  }

  return true;
}

function AjustEdgesNodesToSave(Edges, Nodes) {
  var NodesToDB = [];
  var EdgesToDB = [];
  var LookUp = {};
  let ContLookUp = 0
  for (let i = 0; i < Nodes.length; i++) {//Start with 1, cause do not save the Config Node
    if (TypeRegion.includes(Nodes[i].type)) {
      Nodes[i].data.Param[2][3] = "-1" //Remover, apenas testes de Region
    }
    if (Nodes[i].type !== "NullN") {
      const NewNode = JSON.parse(JSON.stringify(Nodes[i]));
      NodesToDB.push(NewNode);
      LookUp[Nodes[i].id] = ContLookUp.toString();
      ContLookUp += 1;
    }
  }
  for (let i = 0; i < Edges.length; i++) {
    let NoSourceOrTarget = false;
    let X = Edges[i].source;
    let Y = Edges[i].target;
    //console.log("X,Y", X, Y)
    if (typeof X === "undefined" || typeof Y === "undefined") { NoSourceOrTarget = true; console.log("undefined") }
    if (Edges[i].source != "null" && NoSourceOrTarget == false) {
      const NewEdge = JSON.parse(JSON.stringify(Edges[i]));
      //console.log("NoSourceOrTarget", NoSourceOrTarget, NewEdge)
      EdgesToDB.push(NewEdge);
    }
  }
  for (let i = 0; i < NodesToDB.length; i++) {
    let OldNodeID = NodesToDB[i].id;
    NodesToDB[i].id = i.toString();
    for (let j = 0; j < NodesToDB.length; j++) {
      if (NodesToDB[j].parentNode == OldNodeID) {
        NodesToDB[j].parentNode = i.toString();
      }
    }
    for (let j = 0; j < EdgesToDB.length; j++) {
      if (EdgesToDB[j].source == OldNodeID) {
        EdgesToDB[j].source = i.toString();
      }
      if (EdgesToDB[j].target == OldNodeID) {
        EdgesToDB[j].target = i.toString();
      }
    }
  }
  return [EdgesToDB, NodesToDB, LookUp]
}

//const pako = require('pako');


function uint8ArrayToBase64(buffer) {
  let binary = '';
  let len = buffer.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(buffer[i]);
  }
  return window.btoa(binary);
}
function Compress(NodesToDB, EdgesToDB) {
  const originalData = JSON.stringify([NodesToDB, EdgesToDB, [], []]);
  const compressedData = pako.deflate(originalData);
  const base64CompressedData = uint8ArrayToBase64(compressedData);
  const originalSize = new Blob([originalData]).size;
  //console.log("Tamanho original:", originalSize, "bytes");
  const compressedSize = compressedData.byteLength;
  //console.log("Tamanho comprimido:", compressedSize, "bytes");
  const reductionPercentage = ((originalSize - compressedSize) / originalSize) * 100;
  //console.log("Redução:", reductionPercentage.toFixed(2), "%");
  //console.log("base64CompressedData", base64CompressedData);
  return base64CompressedData;
}

function Descompact(Descompact) {
  //console.log("Descompact ", Descompact)
  const compressedData = Uint8Array.from(atob(Descompact), c => c.charCodeAt(0));
  const decompressedData = pako.inflate(compressedData);
  const decompressedString = new TextDecoder().decode(decompressedData);
  const jsonData = JSON.parse(decompressedString);
  return jsonData
}

async function Save(Edges, Nodes) {
  let FileName, UserName;
  let NewNodes = JSON.parse(JSON.stringify(Nodes));
  Edges = TestDuplicateEdges(Edges);
  console.log("EdgesNodes Save", Edges, NewNodes)
  for (let i = 0; i < NewNodes.length; i++) {
    let IsGraph = NewNodes[i].type === "GraphN";
    NewNodes[i].selected = false;
    if (IsGraph) {
      NewNodes[i].data.img = "";
      NewNodes[i].data.MaxCounter = undefined;
    }
    console.log("TypeTerraform", TypeTerraform)
    if (TypeTerraform.includes(NewNodes[i].type)) {
      NewNodes[i].data.DeployingStateMachine = "Idle";
      console.log("Idle", i, NewNodes[i].data.DeployingStateMachine)
    }
  }
  let Adjust = AjustEdgesNodesToSave(Edges, NewNodes);
  console.log("Adjust", Adjust)
  let NewEdges = Adjust[0];
  let NodesToDB = Adjust[1];
  let EdgesToDB = [];
  console.log("NewEdgesaaa", NewEdges)
  const MaxNodeID = NodesToDB.length;
  for (let i = 0; i < NewEdges.length; i++) {//Remove edges que estão apontando para nodes não existentes
    //console.log("NewEdges.source", MaxNodeID, NewEdges[i])
    if (parseInt(NewEdges[i].source) > MaxNodeID || parseInt(NewEdges[i].target) > MaxNodeID) {
      //console.log("Removew edge", i)
      //pass
    } else {
      EdgesToDB.push(NewEdges[i]);
    }
  }
  const base64CompressedData = Compress(NodesToDB, EdgesToDB);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
  if (Stage === "DevLocal") {
    UserName = document.getElementById("Login").value;
    sessionStorage.setItem("FileName", UserName);
    console.log("UserName", UserName)
    var raw = JSON.stringify([5, UserName]);// Testa se FileName existe
    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
    console.log("APIDB", APIDB)
    let response = await fetch(APIDB, requestOptions);
    let Resp = await response.json();
    Resp = Resp.body;
    console.log("Resp", Resp)
    if (Resp == false) {
      alert("User not found!");
    } else {
      var raw = JSON.stringify([2, "Nodes", UserName, base64CompressedData]);
      var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
      response = await fetch(APIDB, requestOptions);
    }
  } else {
    FileName = GlobalCognitoSub + "P1";
    sessionStorage.setItem("FileName", FileName);
    console.log("FileName", FileName)
    var raw = JSON.stringify([2, "Nodes", FileName, base64CompressedData]);
    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
    let response = await fetch(APIDB, requestOptions);
    console.log("response", response)
  }
}

function ProcessLoad(result) {
  let NewNodes = [];
  let NewEdges = [];
  const jsonData = Descompact(JSON.parse(result).body);
  let LoadNodes = jsonData[0];
  let LoadEdges = jsonData[1];
  const NewNode = JSON.parse(JSON.stringify(LoadNodes[0]));
  NewNode.id = "0";
  NewNodes.push(NewNode)
  for (let i = 1; i < LoadNodes.length; i++) {
    let NodeID = i.toString();
    const NewNode = JSON.parse(JSON.stringify(LoadNodes[i]));
    let NodeType = NewNode.type;
    NewNode.id = NodeID;
    NewNodes.push(NewNode);
    if (NewNode.data.LifeCycle === undefined) {
      NewNode.data.LifeCycle = { "IC": [], "CBD": false, "PD": false } //IC = IgnoreChanges, CBD = Create Before Destroy, PD = Prevent Destroy
    }
  }
  for (let i = 0; i < LoadEdges.length; i++) {
    let EdgeID = i.toString();
    const NewEdge = JSON.parse(JSON.stringify(LoadEdges[i]));
    NewEdge.id = EdgeID;
    NewEdge.type = "FloatingEdgeE";
    NewEdge.markerEnd = { type: MarkerType.ArrowClosed, width: 20, height: 20, color: '#000000', };
    NewEdge.sourceHandle = "right";
    NewEdge.targetHandle = "right";
    NewEdges.push(NewEdge);
  }
  return [NewEdges, NewNodes]
}

function ListTerraformDomain(GlobalEdges, GlobalNodes, TerraformNode) {
  let TerraformID = TerraformNode.id;
  let NodeFather = TerraformNode.parentNode;
  let Aux = FindNodesChieldID(GlobalNodes, NodeFather);
  let NodesAbove = FindRegionAndCloud(GlobalNodes, NodeFather);
  //console.log("NodesAbove", NodesAbove)
  Aux = Aux.concat(NodesAbove);
  ///let Adjust = AjustEdgesNodesToSave(GlobalEdges, GlobalNodes)[2];
  let TerraformNodeList = [];
  ///for (let j = 0; j < Aux.length; j++) {
  ///  TerraformNodeList.push(Adjust[Aux[j]].toString());
  ///}
  for (let j = 0; j < Aux.length; j++) {
    //let NodeType = GlobalNodes[parseInt(Aux[j])].type;
    if (Aux[j] !== "Config") {
      TerraformNodeList.push(Aux[j].toString());
      //console.log("Node", Aux[j].toString())
    }
  }
  //for (let j = 0; j < TerraformNodeList.length; j++) {
  //let NodeID = parseInt(TerraformNodeList[j]);
  //if ((GlobalNodes[NodeID].type === "TerraformN") && (GlobalNodes[NodeID].id !== TerraformID)) {
  //console.log("Terraforms", NodeID);
  //let SubTerraformNodeList = ListTerraformDomain(GlobalEdges, GlobalNodes, GlobalNodes[NodeID]);
  //console.log("SubTerraformNodeList", SubTerraformNodeList)
  //}
  //}
  return TerraformNodeList;
}

function CheckIdenticalName(Nodes, Constraints) {
  const ListExcluded = ["InfoN", "AZN", "AZAZN", "NullN", "DockerHubN", "RegionN", "CloudN", "GraphN", "TextN", "Discard"]
  let NameError = [];
  let ListProcessed = []
  for (let i = 1; i < Nodes.length; i++) {
    let Icon = Constraints.IconsURL + Nodes[i].type.slice(0, -1) + ".png";
    if (ListExcluded.includes(Nodes[i].type) === false) {
      for (let j = 1; j < Nodes.length; j++) {
        if (ListExcluded.includes(Nodes[j].type) === false) {
          if ((i != j) && !(ListProcessed.includes(j)) && !((Nodes[i].type == "EC2N") && (Nodes[i].data.AS == true))) {
            let NodeType = Nodes[i].type;
            if (NodeType === Nodes[j].type) {
              let [CloudA, RegionA] = FindRegionAndCloud(Nodes, Nodes[i].id);
              let [CloudB, RegionB] = FindRegionAndCloud(Nodes, Nodes[j].id);
              if (CloudA === CloudB) {
                //console.log("Nodes[i]", Nodes[i].type, Nodes[j].type)
                if (Nodes[i].data.Param[1][1] == Nodes[j].data.Param[1][1] && NodeType !== "InfoN") {
                  if ((NodeType === "SBoxN"
                    && (Nodes[i].data.Param[4][1] !== Nodes[j].data.Param[4][1]) || Nodes[i].data.Param[1][1].toLowerCase() !== "static") ||
                    (NodeType === "VPCN" && Nodes[i].data.Param[13][1] !== Nodes[j].data.Param[13][1])) {
                    //pass
                  } else {
                    NameError.push([i, j, Icon, "have the same name. Change the name of one of them."]);
                    ListProcessed.push(i);
                  }
                }
              }
              if (Nodes[i].type === "SubnetN") {//check subnet cidr
                if (Nodes[i].data.Param[7][1] === Nodes[j].data.Param[7][1]) {
                  NameError.push([i, j, Icon, "have the same IPv4 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
                //console.log("Subnet IPv6", Nodes[j].data.Param[10][1])
                if ((Nodes[i].data.Param[10][1] === Nodes[j].data.Param[10][1]) && (Nodes[j].data.Param[10][1] !== "")) {
                  NameError.push([i, j, Icon, "have the same IPv6 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
              }
              if (Nodes[i].type === "VPCN") {//check subnet cidr
                if (Nodes[i].data.Param[3][2][1][1] === Nodes[j].data.Param[3][2][1][1]) {
                  NameError.push([i, j, Icon, "have the same IPv4 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
                if ((Nodes[i].data.Param[4][2][2][1] === Nodes[j].data.Param[4][2][2][1]) && (Nodes[j].data.Param[4][2][2][1] !== "")) {
                  NameError.push([i, j, Icon, "have the same IPv6 CIDR. Change the name of one of them."]);
                  ListProcessed.push(i);
                }
              }
            }
          }
        }
      }
    }
  }
  return NameError;
}
//Corrge o ID incluindo os nodes = null (deletados). é enviado para complitação apenas o nodes não null e no retorno deve-se ajustar o ID incluindo os null
function AdjustID(ID, Nodes) {
  let NullCount = 0;
  for (let i = 1; i < Nodes.length; i++) {
    if (Nodes[i].type == "NullN") {
      NullCount += 1;
    }
    if (i == ID + NullCount) {
      return ID + NullCount;
    }
  }
  return ID;
}

function arraysAreEqual(arr1, arr2) {
  // Verifica se os arrays têm o mesmo comprimento
  if (arr1.length !== arr2.length) {
    return false;
  }
  // Compara os elementos dos arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  // Se passar por todas as verificações, os arrays são iguais
  return true;
}

function GetNodeId() {
  var ID = `${GlobalNodeID++}`
  //console.log("GetNodeId", ID);
  return [ID, false];
}

function UpDateGlobalNodeID(NewGlobalNodeID) {
  GlobalNodeID = NewGlobalNodeID;
}

function getUniqueKey(baseKey, obj) {
  let count = 1;
  let newKey = baseKey;
  while (obj.hasOwnProperty(newKey)) {
    newKey = baseKey + count;
    count++;
  }
  return newKey;
}
//Tranforma o bloco de dados Terraform em uma estrutura JSON
function ToJSONObject(input) {
  // Convertendo em linhas e removendo linhas vazias
  let lines = input.split("\n").filter(line => line.trim() !== '');
  let result = {};
  let stack = [result]; // Stack para lidar com objetos aninhados

  lines.forEach(line => {
    line = line.trim();
    if (line.endsWith('{')) {
      // Início de um novo objeto
      let key = line.split(' ')[0];
      let uniqueKey = getUniqueKey(key, stack[stack.length - 1]);
      let obj = {};
      stack[stack.length - 1][uniqueKey] = obj;
      stack.push(obj);
    } else if (line === '}') {
      // Fim de um objeto
      stack.pop();
    } else {
      // Par chave=valor
      let [key, value] = line.split('=').map(item => item.trim());
      let uniqueKey = getUniqueKey(key, stack[stack.length - 1]);
      // Removendo aspas, se houver
      console.log("key", key)
      if (value && value.startsWith('"') && value.endsWith('"')) {
        value = value.slice(1, -1);
      } else if (value && value.startsWith('[') && value.endsWith(']')) {
        // Lidando com listas
        value = value.slice(1, -1).split(',').map(item => item.trim());
      }
      stack[stack.length - 1][uniqueKey] = value;
    }
  });

  return result;
}


//Extrai o bloco de dados de um recurso Terraform de um arquivo TF (tfContent). ResourceType é o typo de recursos Terraofo
function ExtractTerraformResourceBlock(tfContent, ResourceName, ResourceType) {
  // Constrói uma expressão regular para encontrar o início do recurso.
  const pattern = new RegExp(`resource\\s+"${ResourceType}"\\s+"${ResourceName}"\\s*\\{`);
  const match = pattern.exec(tfContent);
  if (!match) {
    return null;  // Retorna null se o recurso não for encontrado.
  }
  const startIndex = match.index;
  let openBraces = 1; // Já que começamos logo após a chave de abertura.
  let i = startIndex + match[0].length; // Começa a iteração após a chave de abertura.
  // Itera sobre o conteúdo a partir da posição de início.
  while (i < tfContent.length) {
    if (tfContent[i] === '{') {
      openBraces++;
    } else if (tfContent[i] === '}') {
      openBraces--;
    }
    // Se o contador chegar a zero, encontramos o final do bloco.
    if (openBraces === 0) {
      break;
    }
    i++;
  }
  // Retorna a substring do recurso encontrado, excluindo as chaves { e }.
  return tfContent.substring(startIndex + match[0].length, i).trim();
}

function formatTimestamp(timestamp, isLongPeriod) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  if (isLongPeriod) {
    return `${day}/${month} ${hours}h`;
  } else {
    return `${hours}:${minutes}`;
  }
}


/*const fetchCloudWatchMetric = async ({ RoelARN, region, params }) => {
  // Criar um objeto AWS.CloudWatch
  const cloudwatch = new AWS.CloudWatch({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region
  });
  console.log("Params", params, region);
  console.log("accessKeyId", accessKeyId, secretAccessKey);
  const response = await cloudwatch.getMetricData(params).promise();
  // Log the MetricDataResults for inspection
  console.log("Complete CloudWatch Response:", response);
  let formattedData = [];
  if (response.MetricDataResults && response.MetricDataResults.length > 0) {
    response.MetricDataResults.forEach((result, metricIndex) => {
      const timestamps = result.Timestamps;
      const values = result.Values;
      // Use the metric name as the ID, or you can assign custom IDs if you prefer
      const metricId = result.Id.replace('query_', '');
      const isLongPeriod = (timestamps[0] - timestamps[timestamps.length - 1]) > (24 * 60 * 60 * 1000);  // Verifica se o período é maior que um dia
      const metricData = {
        id: metricId,
        data: timestamps.map((timestamp, index) => {
          const formattedTime = formatTimestamp(timestamp, isLongPeriod);
          return { x: formattedTime, y: values[index] };
        }).reverse()
      };
      formattedData.push(metricData);
    });
  }
  return formattedData;
}*/



async function FindDimensionValue(Dimension, TagValue) {
  const ec2 = new AWS.EC2();
  const params = {
    Filters: [
      {
        Name: 'tag:Name',
        Values: [TagValue]
      }
    ]
  };

  if (Dimension.includes("InstanceId")) {
    const response = await ec2.describeInstances(params).promise();
    const instances = response.Reservations.flatMap(reservation => reservation.Instances);
    return instances.map(instance => instance.InstanceId);
  }

  throw new Error(`Dimension ${Dimension} not supported`);
}



function mockGetCostAndUsage(params) {
  return new Promise((resolve, reject) => {
    const mockData = {

      "ResultsByTime": [
        {
          "TimePeriod": {
            "Start": "2023-10-01",
            "End": "2023-10-31"
          },
          "Groups": [
            {
              "Keys": ["Cloudman_State_Tag$State1"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "100.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Cloudman_State_Tag$State2"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "150.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Name$EC2"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "50.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Name$RDS"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "60.00",
                  "Unit": "USD"
                }
              }
            },
            {
              "Keys": ["Name$EC2B"],
              "Metrics": {
                "BlendedCost": {
                  "Amount": "70.00",
                  "Unit": "USD"
                }
              }
            }
          ],
          "Total": {
            "BlendedCost": {
              "Amount": "430.00",
              "Unit": "USD"
            }
          }
        }
      ]
    };
    resolve(mockData);
  });
}
//Le CostExplorer e salva a resposta em Cloud[8][1] com os dados lidos e o date-time
const getCosts = async (tagKey, tagValue, accessKeyId, secretAccessKey, startDate, endDate, CloudNode) => {
  const costExplorer = new AWS.CostExplorer({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: 'us-east-1'  // ou a região de sua preferência
  });
  const params = {
    TimePeriod: {
      Start: "2023-10-01", // startDate,
      End: "2023-10-30"   // endDate,
    },
    Granularity: 'MONTHLY',
    Metrics: ['BlendedCost'],
    GroupBy: [
      { Type: "TAG", Key: "Name" }
    ]
  };
  console.log("Cost Explorer:", accessKeyId, secretAccessKey, params)
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Os meses começam de 0 (janeiro) até 11 (dezembro)
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
  let allResults = [];
  let Time = getAmericanDateTime();
  try {
    do {
      const data = await costExplorer.getCostAndUsage(params).promise();
      // Adicione os resultados desta página ao array allResults
      allResults.push(...data.ResultsByTime);
      console.log("Dados lidos", [formattedDate, data]);
      // Se houver um NextPageToken, defina-o nos parâmetros para a próxima chamada
      console.log("data.NextPageToken", data.NextPageToken)
      params.NextPageToken = data.NextPageToken;
    } while (params.NextPageToken);  // Continue enquanto houver um NextPageToken
    return [true, [Time, allResults]];
  } catch (err) {
    if (err.code === 'AccessDeniedException') {
      console.error("Erro ao buscar custos: Acesso negado.");
    } else {
      console.error("Erro ao buscar custos:", err.message);
    }
    return [false, ""]; // Retorne algo específico em caso de erro, como `null`.
  }
}

//ReadCloud é usada para ler qualquer recurso de um cloud provider. Pode ser usado para ler diretamente com as credencias de leitura
//ou chamar o backend, caso se opte por usar as credenciais salvas no back end (futura implementação)
async function ReadCloud(Nodes, FunctionType, SourceID, Params) {
  const [Cloud, Region] = FindRegionAndCloud(Nodes, SourceID);
  const CloudID = parseInt(Cloud);
  const accessKeyId = Nodes[CloudID].data.Param[5][1];
  const secretAccessKey = Nodes[CloudID].data.Param[6][1];
  console.log("Params Key", Params.Key)
  if (FunctionType == "GetCurrentCosts") {
    return await getCosts(Params.Key, Params.Value, accessKeyId, secretAccessKey, Params.StartDate, Params.EndDate, Nodes[CloudID]);
  }
  if (FunctionType == "GetStaticCosts") {
    return await getCosts(Params.Key, Params.Value, accessKeyId, secretAccessKey, Params.StartDate, Params.EndDate, Nodes[CloudID]);
  }
}
const getCurrentMonthDates = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  return {
    startDate: startDate.toISOString().split('T')[0],  // formato 'yyyy-mm-dd'
    endDate: endDate.toISOString().split('T')[0]       // formato 'yyyy-mm-dd'
  };
}

function GetCostFromCostExporerResponse(response, key, value) {
  // Verifica se a resposta é válida
  //console.log("response", response)
  if (!Array.isArray(response)) {
    throw new Error('Resposta inválida do Cost Explorer.');
  }
  // Itera sobre os resultados para encontrar a combinação de chave-valor
  for (let timeResult of response) {
    for (let group of timeResult.Groups) {
      if (group.Keys.includes(key + '$' + value)) {
        return parseFloat(group.Metrics.BlendedCost.Amount);
      }
    }
  }
  // Se não encontrar a combinação de chave-valor, retorna null
  return null;
}


function getAmericanDateTime() {
  const now = new Date();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Os meses começam de 0 (janeiro) até 11 (dezembro)
  const day = String(now.getDate()).padStart(2, '0');
  const year = now.getFullYear();
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');

  return `${month}/${day}/${year} ${hour}:${minute}`;
}

function FindNameIDRouting(Edges, Nodes, FirstNodeID, RoutingList) {
  let CurrentNodeID = FirstNodeID;
  let Name = "";
  for (let i = 0; i < RoutingList.length; i++) {
    let RoutingNodeType = RoutingList[i][0];
    let Routing = RoutingList[i][1];
    let List = [];
    if (Routing === "Source") {
      List = SearchNodesSource(Edges, Nodes, Nodes[CurrentNodeID], RoutingNodeType);
    } else {
      List = SearchNodesTarget(Edges, Nodes, Nodes[CurrentNodeID], RoutingNodeType);
    }
    if (List.length > 0) {
      CurrentNodeID = parseInt(List[0], 10);
      Name = Nodes[CurrentNodeID].data.Param[1][1];
    } else {
      return [FirstNodeID, ""];
    }
  }
  return [CurrentNodeID, Name];
}
function openConsole(GlobalEdges, GlobalNodes, windowName, LocalNodeID, NodeType, ConsoleLinkResource) {
  try {
    if (ConsoleLinkResource !== null) {
      let ConsoleUrl = ConsoleLinkResource;
      const NodeName = GlobalNodes[LocalNodeID].data.Param[1][1];
      if (NodeType == "SSMParameterN") { NodeName = GlobalNodes[LocalNodeID].data.Param[2][1]; }
      const NodeNameB = GlobalNodes[LocalNodeID].data.Param[2][1];
      let CloudID, RegionID, RG, RGName, RegionName = "";
      if (NodeType.startsWith("AZ")) {
        if (NodeType === "AZResourceGroupN") {
          RG = GlobalNodes[LocalNodeID].id;
          CloudID = GlobalNodes[LocalNodeID].parentNode;
          //ConsoleUrl = ConsoleUrl.replace("${Cons}", "portal.azure.com/#");
        } else {
          [CloudID, RegionID, RG] = FindRegionAndCloud(GlobalNodes, LocalNodeID);
        }
        const User = "cloudmanpro111gmail";
        RGName = GlobalNodes[parseInt(RG)].data.Param[1][1];
        ConsoleUrl = ConsoleUrl.replace("${User}", User);
        ConsoleUrl = ConsoleUrl.replace("${RGName}", RGName);

      } else { //AWS
        [CloudID, RegionID] = FindRegionAndCloud(GlobalNodes, LocalNodeID);
        RegionName = GlobalNodes[parseInt(RegionID)].data.Param[2][2];
        console.log("RegionID", RegionID, RegionName)
        ConsoleUrl = ConsoleUrl.replace("${Region}", RegionName).replace("${Region}", RegionName);
        ConsoleUrl = ConsoleUrl.replace("${Cons}", "console.aws.amazon.com");
      }
      let Account = GlobalNodes[parseInt(CloudID)].data.Param[3][1];
      ConsoleUrl = ConsoleUrl.replace("${Account}", Account);
      ConsoleUrl = ConsoleUrl.replace("${NameB}", NodeNameB);
      console.log("NodeType", NodeType)
      if (NodeType == "ECSServiceN") {
        let List = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[LocalNodeID], "ECSClusterN");
        let ClusterID = parseInt(List[0]);
        let ClusterName = GlobalNodes[ClusterID].data.Param[1][1];
        console.log("NodeType", NodeType, List, ClusterName)
        ConsoleUrl = ConsoleUrl.replace("${ClusterName}", ClusterName);
      }
      ConsoleUrl = "https://" + ConsoleUrl.replace("${Name}", NodeName);
      console.log("ConsoleUrlB", ConsoleUrl)
      console.log("NodeName", NodeName)
      // Tenta abrir uma nova janela ou focar em uma janela existente com o mesmo nome
      let openedWindow = window.open(ConsoleUrl, windowName);

      // Se a janela já estiver aberta, traga-a para o foco
      if (openedWindow && !openedWindow.closed) {
        openedWindow.focus();
      }
    }
  } catch (error) {
    //pass
  }
}

function compareMetricDataQueries(metricDataQueries) {
  for (let i = 0; i < metricDataQueries.length; i++) {
    for (let j = i + 1; j < metricDataQueries.length; j++) {
      if (areMetricDataQueriesEqual(metricDataQueries[i], metricDataQueries[j])) {
        return { Equal: true, Index1: i + 1, Index2: j + 1 };
      }
    }
  }
  return { Equal: false };
}

function areMetricDataQueriesEqual(query1, query2) {
  return query1.MetricStat.Metric.MetricName === query2.MetricStat.Metric.MetricName &&
    query1.MetricStat.Stat === query2.MetricStat.Stat &&
    JSON.stringify(query1.MetricStat.Metric.Dimensions) === JSON.stringify(query2.MetricStat.Metric.Dimensions);
}

async function findInstancesByTags(tagValues, accessKeyId, secretAccessKey, RegionName) {
  // Configura as credenciais e a região da AWS
  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: RegionName
  });
  const ec2 = new AWS.EC2();
  const params = {
    Filters: [
      {
        Name: 'tag:Name',
        Values: tagValues,
      },
    ],
  };
  const response = await ec2.describeInstances(params).promise();
  console.log("response", response);
}

function TestDuplicateEdges(Edges) {
  let NewEdges = [];
  let ListEdges = [];
  for (let i = 0; i < Edges.length; i++) {
    let EdgePair = Edges[i].source + ":" + Edges[i].target;
    if (ListEdges.includes(EdgePair)) {
      console.log("Encontrou Edge duplicada.", EdgePair, ListEdges)
    } else {
      ListEdges.push(EdgePair);
      NewEdges.push(Edges[i]);
    }

  }
  return NewEdges;
}

function CalculateScale(width, ScaleMax) {
  let scale;
  scale = 0.12 + (width * 0.0002);
  if (scale > ScaleMax) { scale = ScaleMax }

  return scale;
}

function FindIPv6BlockIndex(Nodes, SubnetListID) {
  let ListIPv6_Block_Index = [];
  for (let i = 0; i < SubnetListID.length; i++) {
    let IPv6_Block_Index = Nodes[SubnetListID[i]].data.Param[16][1];
    console.log("IPv6_Block_Index", IPv6_Block_Index, i)
    ListIPv6_Block_Index.push(parseInt(IPv6_Block_Index));
  }
  console.log("ListIPv6_Block_Index", ListIPv6_Block_Index)
  ListIPv6_Block_Index.sort(function (a, b) {
    return a - b;
  });
  let NewIndex = 0;
  for (let i = 0; i < ListIPv6_Block_Index.length + 1; i++) {
    console.log("i", i)
    if (ListIPv6_Block_Index[i] !== i) {
      NewIndex = i.toString();
      break;
    }
  }
  return NewIndex;
}

function findEdgeLabel(edges, sourceID, targetID) {
  for (let i = 0; i < edges.length; i++) {
    if (edges[i]["source"] === String(sourceID) && edges[i]["target"] === String(targetID)) {
      try {
        let label = edges[i]["label"];
        let ctrl = "";
        let labelInfo = "";
        if (label.includes("|")) {
          const parts = label.split("|");
          ctrl = parts[0].trim();
          labelInfo = parts[1];
        } else {
          labelInfo = label;
        }
        return { ctrl, labelInfo };
      } catch {
        return { ctrl: "", labelInfo: "" };
      }
    }
  }
  return { ctrl: "", labelInfo: "" };
}

function AdjustSubnetCIDR(edges, nodes, VPCID) {
  const CIDRIndex = 7;
  let NodeList = FindNodesEdgesChield(nodes, edges, nodes[VPCID])[0];
  //console.log("NodeList", NodeList);
  let VPCCIDR = nodes[VPCID].data.Param[3][2][1][1];
  //console.log("VPCCIDR", VPCCIDR, VPCCIDRRange)
  let ListSubnetCIDR = [];
  let SubnetList = [];
  //console.log("SubnetCIDR inicial", SubNetCIDR)
  for (let i = 0; i < NodeList.length; i++) {
    let NodeType = NodeList[i].type;
    if (TypeSubnet.includes(NodeType)) {
      let NodeID = parseInt(NodeList[i].id);
      let SubnetCIDR = nodes[NodeID].data.Param[CIDRIndex][1]
      ListSubnetCIDR.push(SubnetCIDR)
      let Overlap = checkCIDROverlap(SubnetCIDR, VPCCIDR)
      console.log("NodeType", NodeType, NodeID, SubnetCIDR, Overlap)
      if (!Overlap || ListSubnetCIDR.includes(SubnetCIDR)) {
        let CIDRBase = VPCCIDR.split("/")[0] + "/" + SubnetCIDR.split("/")[1]
        nodes[NodeID].data.Param[CIDRIndex][1] = CIDRBase;
        SubnetList.push(NodeID);
      }
      /*for (let j = 0; j < nodes.length; j++) {
        if (nodes[j].id === NodeID) {
          let SubnetCIDR = nodes[j].data.Param[CIDRIndex][1]
          console.log("CIDR Subnet", SubnetCIDR)
          let Overlap = checkCIDROverlap(SubnetCIDR, VPCCIDR)
          if (!Overlap) {
            nodes[j].data.Param[CIDRIndex][1] = "0.0.0.0/0";
            SubnetList.push(j);
          }
        }
        break;
      }*/

    }
  }
  ListSubnetCIDR = [];
  console.log("SubnetList", SubnetList)
  for (let i = 0; i < SubnetList.length; i++) {
    let SubNetCIDR = nodes[SubnetList[i]].data.Param[CIDRIndex][1];
    let NewSubnetCidr = nextIPCIDR(SubNetCIDR, ListSubnetCIDR);
    ListSubnetCIDR.push(NewSubnetCidr);
    nodes[SubnetList[i]].data.Param[CIDRIndex][1] = NewSubnetCidr;
    console.log("New CIDR", SubNetCIDR, NewSubnetCidr, ListSubnetCIDR)
  }
}

function MaskString(str) {
  if (str.length > 4) {
    let start = str.substring(0, 1);
    let end = str.substring(str.length - 3);
    let masked = '*'.repeat(str.length - 4);
    return start + masked + end;
  }
  return str;
}

function SearchSubnetHead(Edges, Nodes, Index) {
  let ListSubnet = SearchNodesSource(Edges, Nodes, Nodes[Index], "AZSubnetN");
  if (ListSubnet.length === 0) {
    return Index
  } else {
    let SubnetIDHead;
    let SubnetID0 = parseInt(ListSubnet[0]);
    SubnetIDHead = SearchSubnetHead(Edges, Nodes, parseInt(SubnetID0))
    console.log("SubnetIDHead", SubnetIDHead)
    return SubnetIDHead
  }
}

function UpdateDataNodes(Nodes) {
  for (let i = 0; i < Nodes.length; i++) {
    let NodeParam = Nodes[i].data.Param;
    let NodeType = Nodes[i].type;
    let LSParam = ListaStandard[ResourceLookUp[NodeType]].ListParam;
    let LSParamLength = LSParam.length + 1;
    console.log("NodeParam", NodeType, NodeParam.length, LSParamLength);
    let Dif = LSParamLength - NodeParam.length;
    if (LSParamLength > NodeParam.length) {
      for (let j = 0; j < Dif; j++) {
        let Index = LSParamLength - Dif + j - 1;
        let NewParam = [LSParam[Index].Index, LSParam[Index].Value, "", ""];
        Nodes[i].data.Param.push(NewParam)
        console.log("NewParem", NewParam)
      }
      console.log("New NodeParam", NodeType, Nodes[i]);
    }
    if (Nodes[i].data.LifeCycle === undefined) {
      Nodes[i].data.LifeCycle = { "IC": [], "CBD": false, "PD": false } //IC = IgnoreChanges, CBD = Create Before Destroy, PD = Prevent Destroy
    }
  }
  return Nodes
}
function IsEC2AutoScale(GEdges, GNodes, Index) {
  let ASGList = SearchNodesSource(GEdges, GNodes, GNodes[parseInt(Index)], "ASGN");
  ASGList = ASGList.concat(SearchNodesSource(GEdges, GNodes, GNodes[parseInt(Index)], "EKSNodeGroupN"));
  return (ASGList > 0);
}

function generateRandomSuffix(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let randomSuffix = '';
  for (let i = 0; i < length; i++) {
    randomSuffix += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return randomSuffix;
}

function GenerateRandomizedBucketName(bucketName) {
  const maxLength = 63;
  const randomSuffixMaxLength = maxLength - bucketName.length;
  const randomSuffix = generateRandomSuffix(randomSuffixMaxLength);
  return bucketName + '-' + randomSuffix;
}

function ValidateArn(arn) {
  // Regex para validar o ARN de uma IAM role cross-account
  const regex = /^arn:aws:iam::\d{12}:role\/[\w+=,.@-]+$/;
  if (regex.test(arn)) {
    console.log("O ARN é válido.");
    return true;
  } else {
    console.log("O ARN é inválido.");
    return false;
  }
}

function MinXYBox(nodes, ID,) {
  let XMax = -1;
  let YMax = -1;
  let XMin = 99999999;
  let YMin = 99999999;
  let X, Y;
  //console.log("nodes", nodes)
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].parentNode == ID) {
      //nodes[i].position.x += DeltaX;
      X = nodes[i].position.x;
      console.log("NodeType", nodes[i].type)
      console.log("X", X, nodes[i].width)
      if (X > XMax) { XMax = X + nodes[i].width; }
      if (X < XMin) { XMin = X; }
      Y = nodes[i].position.y;
      console.log("Y", Y)
      if (Y > YMax) { YMax = Y + nodes[i].height; }
      if (Y < YMin) { YMin = Y; }
    }
  }
  //const IntID = parseInt(ID);
  //const ParentID = parseInt(nodes[IntID].parentNode);
  //const MaxWidth = nodes[ParentID].width - nodes[IntID].position.x;
  //const MaxHeight = nodes[ParentID].height - nodes[IntID].position.y;
  //const MinWidth = X - XMin;
  //const MinHeight = Y - YMin;
  //console.log("MinWidth, MinHeight, MaxWidth, MaxHeight", MinWidth, MinHeight, MaxWidth, MaxHeight)
  console.log("XMin, YMin, XMax, YMax", XMin, YMin, XMax, YMax)
  return [XMin, YMin, XMax, YMax]
}

function ExtremePositionBox(nodes, ID, Direction) {
  let XMax = -1;
  let YMax = -1;
  let XMin = 99999999;
  let YMin = 99999999;
  let X, Y;
  //console.log("nodes", nodes)
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].parentNode == ID) {
      //nodes[i].position.x += DeltaX;
      X = nodes[i].position.x;
      console.log("NodeType", nodes[i].type)
      console.log("X", X, nodes[i].width)
      if (X > XMax) { XMax = X + nodes[i].width; }
      if (X < XMin) { XMin = X; }
      Y = nodes[i].position.y;
      console.log("Y", Y)
      if (Y > YMax) { YMax = Y + nodes[i].height; }
      if (Y < YMin) { YMin = Y; }
    }
  }
  console.log("XMin, YMin, XMax, YMax", XMin, YMin, XMax, YMax)
  return [XMin, YMin, XMax, YMax]
}

function mirrorNodes(nodes, parentNode, direction, Constraints) {
  console.log("parentNode", parentNode, direction);
  const parentWidth = parentNode.width;
  const parentHeight = parentNode.height;
  console.log("parentWidth", parentWidth, parentHeight);
  const ListNode = FindNodesChieldOneLevelID(nodes, parentNode.id);
  console.log("ListNode", ListNode);
  let NodeID;
  for (let i = 0; i < ListNode.length; i++) {
    if (direction === 'vertical') {
      NodeID = ListNode[i];
      nodes[NodeID].position.x = parentWidth - (nodes[NodeID].position.x + nodes[NodeID].width);
    } else {
      NodeID = ListNode[i];
      nodes[NodeID].position.y = parentHeight - (nodes[NodeID].position.y + nodes[NodeID].height);
    }
    let NodeType = nodes[NodeID].type;
    if (Constraints.ListBoxes.includes(NodeType)) {
      mirrorNodes(nodes, nodes[NodeID], direction, Constraints)
    }
  }
}
//Verifica restrições para uma cópia ou drop de recurso em um box. Exemplo, por-se ter apenas um Terrafron node por box.
function BoxRestrictions(Nodes, NodeType, ParentNodeID) {
  //1) Apenas 1 Terraform node por box
  let ListTerrraformType = ["TerraformN", "TerraformBackendS3N"];
  let Error = "";
  if (ListTerrraformType.includes(NodeType)) {
    let ListNodes = FindNodesChieldOneLevelID(Nodes, ParentNodeID);
    for (let i = 0; i < ListNodes.length; i++) {
      let ChildNodeType = Nodes[ListNodes[i]].type;
      if (ListTerrraformType.includes(ChildNodeType)) {
        Error = "There can only be one Terraform $$TerraformN$$ node per box.";
      }
    }
  }
  if (NodeType === "IGWN") {
    let ListNodes = FindNodesChieldOneLevelID(Nodes, ParentNodeID);
    for (let i = 0; i < ListNodes.length; i++) {
      let ChildNodeType = Nodes[ListNodes[i]].type;
      if (ChildNodeType === "IGWN") {
        Error = "There can only be one IGW $$IGWN$$ node per VPC.";
      }
    }
  }
  return Error;
}
function FindTerraformHead(Edges, Nodes, NodeID) {

}

//Descobre a rede terraform a partir de qualquer box. Usado em Stage Codepipeline
//Retorna a lista de estados Terraform por dependencia e o Terraform Backend, se existir e flag indicando loop, se houver
function DiscoveryTerraformNetwork(Edges, Nodes, NodeID) {
  let LoopList = [];
  let FirstTerraformIntID = 0;
  let BackEndTerraformIntID = 0;
  let ListNodes = FindNodesChieldID(Nodes, NodeID);//Todos os nodes do box referente ao stage
  //console.log("ListNodes", ListNodes)
  for (let i = 0; i < ListNodes.length; i++) {
    let ChildNodeType = Nodes[ListNodes[i]].type;
    if (ChildNodeType === "TerraformN") {
      FirstTerraformIntID = parseInt(ListNodes[i]);
      //console.log("FirstTerraformIntID", FirstTerraformIntID)
      break;
    }
  }
  if (FirstTerraformIntID === 0) { return [[], 0, ""] }
  if (detectLoop(Edges, Nodes, FirstTerraformIntID)) { return [[], 0, true] }
  //Procura Terrraform top da hierarquia
  while (true) {
    console.log(" Nodes[FirstTerraformIntID]", Nodes[FirstTerraformIntID])
    let ListFather = SearchNodesSource(Edges, Nodes, Nodes[FirstTerraformIntID], "all");
    //console.log("ListFather", ListFather)
    if (ListFather.length > 0) {
      FirstTerraformIntID = parseInt(ListFather[0]);
      if (LoopList.includes(FirstTerraformIntID)) { return [[], 0, true]; }
      LoopList.push(FirstTerraformIntID)
    } else {
      break;
    }
  }
  //Achou primeiro Terraform da cadeia
  let ListStates = [];
  LoopList = [FirstTerraformIntID.toString()];
  if (Nodes[FirstTerraformIntID].type === "TerraformBackendS3N") {
    BackEndTerraformIntID = FirstTerraformIntID;
  }
  let List = SearcheStateChild(Edges, Nodes, FirstTerraformIntID, ListNodes, [], 0, [], [FirstTerraformIntID.toString()])
  ListStates = ListStates.concat(List[0]);
  let ListLevel = List[2];
  let HasLoop = List[3];
  const combinado = ListStates.map((id, index) => ({ id, nivel: ListLevel[index] }));
  combinado.sort((a, b) => a.nivel - b.nivel);
  const lastOccurrenceMap = new Map();
  combinado.forEach((item, index) => {
    lastOccurrenceMap.set(item.id, index);
  });
  const uniqueCombinado = combinado.filter((item, index) => lastOccurrenceMap.get(item.id) === index);
  ListStates = uniqueCombinado.map(objeto => objeto.id);
  //console.log("ListStates", ListStates);
  ListStates.unshift(FirstTerraformIntID.toString());
  //console.log("ListStates", ListStates)
  let ListName = [];
  for (let i = 0; i < ListStates.length; i++) {
    ListName.push(Nodes[parseInt(ListStates[i])].data.Param[1][1]);
  }
  //console.log("ListName", ListName);
  return [ListStates, BackEndTerraformIntID, HasLoop];
}

function SearcheStateChild(Edges, Nodes, TerraformIntID, ListNodes, ListState, Level, ListLevel, LoopList) {
  Level += 1;
  let NextListState = [];
  //console.log("NodeName", Nodes[TerraformIntID].data.Param[1][1])
  let ListChild = SearchNodesTarget(Edges, Nodes, Nodes[TerraformIntID], "TerraformN");
  for (let i = 0; i < ListChild.length; i++) {
    let ChildIntID = ListChild[i];
    let ChildID = ChildIntID.toString();
    //console.log("ChildID", ChildID)
    if (LoopList.includes(ChildID)) {
      //console.log("LoopList lopp", LoopList);
      //return [[], [], [], true]
    }
    LoopList.push(ChildID);
    //console.log("LoopList", LoopList);
    NextListState = NextListState.concat(SearcheStateChild(Edges, Nodes, ChildIntID, ListNodes, ListState, Level, ListLevel, LoopList));
    if (NextListState[3]) { return [[], [], [], true] }
    if (!ListState.includes(ChildID)) {
      if (ListNodes.includes(ChildID)) {
        //console.log("push", Nodes[ChildIntID].data.Param[1][1], ChildID)
        ListState.push(ChildID);
        ListLevel.push(Level);
      }
    } else {
      //console.log("Ajuste das depend")
      ListState.push(ChildID);
      ListLevel.push(Level);
    }
  }
  //console.log("ListState", ListState)
  ListChild = NextListState[0] || [];
  //console.log("ListChildL2", ListChild)
  for (let i = 0; i < ListChild.length; i++) {
    let ChildIntID = ListChild[i];
    let ChildID = ChildIntID.toString();
    if ((!ListState.includes(ChildID)) && (ListNodes.includes(ChildID))) {
      ListState.push(ChildID);
      ListLevel.push(Level);
    }
  }
  //console.log("ListState", ListState, ListLevel)
  return [ListState, NextListState, ListLevel, false];
}

function detectLoop(Edges, Nodes, startNodeID) {
  // Conjunto para manter os nós no caminho atual
  let currentPath = new Set();
  // Conjunto para manter todos os nós visitados
  let visitedNodes = new Set();
  // Função recursiva de DFS para percorrer a árvore e detectar loops
  function dfs(nodeID) {
    // Se o nó já está no caminho atual, detectamos um loop
    if (currentPath.has(nodeID)) {
      //console.log("Loop detected at node:", nodeID);
      return true;
    }
    // Adicionar o nó ao caminho atual e ao conjunto de nós visitados
    currentPath.add(nodeID);
    visitedNodes.add(nodeID);
    // Obter a lista de nós filhos conectados ao nó atual
    let childNodes = SearchNodesTarget(Edges, Nodes, Nodes[nodeID], "TerraformN");
    // Percorrer cada nó filho e realizar a busca em profundidade (DFS)
    for (let childID of childNodes) {
      if (dfs(childID)) {
        return true; // Loop detectado em um dos filhos
      }
    }
    // Remover o nó do caminho atual após visitar todos os filhos
    currentPath.delete(nodeID);
    return false;
  }
  // Iniciar a busca em profundidade (DFS) a partir do nó inicial
  return dfs(startNodeID);
}

function FindBackendStorage(Edges, Nodes, TFID) {
  console.log("TFID", TFID)
  if (TFID !== 0) {
    const BoxParentID = parseInt(Nodes[TFID].parentNode);
    console.log("BoxParentID", BoxParentID)
    const ListNodes = FindNodesChieldID(Nodes, BoxParentID);
    console.log("ListNodes", ListNodes)
    let StorageID = 0, DBID = 0, RepoID = 0, BuildID = 0;
    let ErrorMSG = "";
    const Storagelist = ["S3N"];
    const DBList = ["DynamoDBN"];
    const RepoList = ["CodeCommitN", "GitHubN"];
    const BuildList = ["CodeBuildN"]
    for (let i = 0; i < ListNodes.length; i++) {
      let NodeID = parseInt(ListNodes[i]);
      let NodeType = Nodes[NodeID].type;
      console.log("NodeType", NodeType, NodeID)
      if (Storagelist.includes(NodeType)) {
        if (StorageID !== 0) { ErrorMSG = "It shoud have only one S3 in the backend box." }
        console.log("s3", StorageID)
        StorageID = NodeID;
      }
      if (DBList.includes(NodeType)) {
        if (DBID !== 0) { ErrorMSG = "It shoud have only one DynamoDB in the backend box." }
        DBID = NodeID;
      }
      if (RepoList.includes(NodeType)) {
        if (RepoID !== 0) { ErrorMSG = "It shoud have only one Repository in the backend box." }
        RepoID = NodeID;
      }
      if (BuildList.includes(NodeType)) {
        if (BuildID !== 0) { ErrorMSG = "It shoud have only one Compute icon to run Terraform in the backend box." }
        BuildID = NodeID;
      }
    }
    console.log("StorageID, DBID, ErrorMSG func", StorageID, DBID, ErrorMSG)
    return [StorageID, DBID, RepoID, BuildID, ErrorMSG];
  } else {
    return [0, 0, 0, 0, ""];
  }
}

function FindNodeHead(Edges, Nodes, NodeTypeHead, NodeType, NodeIntID, Level = -1) {
  console.log("NodeIntID", NodeIntID, NodeType)
  while (true) {
    console.log("Level", Level)
    let SourceList = SearchNodesSource(Edges, Nodes, Nodes[NodeIntID], NodeTypeHead);
    if (SourceList.length > 0) {
      return [parseInt(SourceList[0]), Level]
    } else {
      let SourceList = SearchNodesSource(Edges, Nodes, Nodes[NodeIntID], NodeType);
      console.log("SourceList", SourceList)
      if (SourceList.length > 0) {
        NodeIntID = parseInt(SourceList[0])
        Level += 1;
        if (Level > 50) {
          console.log("Break loop")
          return [0, 0];
        }
      } else {
        return [0, 0];
      }
    }
  }
}

function FindNodeNonGraphNHead(edges, nodes, currentNodeID) {
  // Verificar se o node atual é do tipo GraphN, se não for, retornar seu ID
  if (nodes[currentNodeID].type !== 'GraphN') {
    return currentNodeID;
  }
  // Encontrar os nodes fontes conectados ao node atual
  let sourceList = SearchNodesSource(edges, nodes, nodes[currentNodeID]);
  // Se a lista de fontes estiver vazia, retornar 0
  if (sourceList.length === 0) {
    return 0;
  }
  // Recursivamente verificar o primeiro node da lista de fontes
  return FindNodeNonGraphNHead(edges, nodes, parseInt(sourceList[0]));
}

function compareVersions(version1, version2) {
  // Divide as versões em partes usando split e converte em arrays de números inteiros
  let v1Parts = version1.split('.').map(Number);
  let v2Parts = version2.split('.').map(Number);
  // Verifica se cada parte contém apenas números
  if (!validateVersionParts(v1Parts) || !validateVersionParts(v2Parts)) {
    return -2;
  }
  // Completa os arrays com zeros para garantir que ambos tenham o mesmo tamanho
  let maxLength = Math.max(v1Parts.length, v2Parts.length);
  v1Parts = padArray(v1Parts, maxLength);
  v2Parts = padArray(v2Parts, maxLength);
  // Compara cada parte numericamente
  for (let i = 0; i < maxLength; i++) {
    if (v1Parts[i] > v2Parts[i]) {
      return 1;
    } else if (v1Parts[i] < v2Parts[i]) {
      return -1;
    }
  }
  return 0; // Se forem iguais
}
// Função auxiliar para validar se todas as partes são números
function validateVersionParts(parts) {
  for (let part of parts) {
    if (isNaN(part)) {
      return false;
    }
  }
  return true;
}
// Função auxiliar para preencher o array com zeros até o tamanho especificado
function padArray(array, length) {
  while (array.length < length) {
    array.push(0);
  }
  return array;
}

function AdjustSubnetCIDRAfterPaste(Edges, Nodes, NewNodes) {
  let ListVPC = [];
  for (let i = 0; i < NewNodes.length; i++) {
    if (TypeSubnet.includes(NewNodes[i].type)) {
      console.log("TypeSubnet", NewNodes[i].type, NewNodes[i], NewNodes[i].id)
      let VPCID = GetVPCParent(Nodes, parseInt(NewNodes[i].id))
      ListVPC.push(VPCID);
    }
  }
  ListVPC = [...new Set(ListVPC)];
  console.log("ListVPC", ListVPC)
  for (let i = 0; i < ListVPC.length; i++) {
    AdjustSubnetCIDR(Edges, Nodes, ListVPC[i]);
  }
}

//compara dois objetos se são iguais
function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
}

const reorderNodesAndEdges = (nodes, edges) => {
  const adjustedNodes = JSON.parse(JSON.stringify(nodes));
  //console.log("Adjusted Nodes (Deep Copy):", adjustedNodes);
  const orderedNodes = [];
  const orderedEdges = [];
  const nodeMap = new Map(adjustedNodes.map(node => [node.id, node]));
  const idMap = new Map(); // Map to keep track of new IDs
  //console.log("Node Map:", nodeMap);
  // Adiciona o nó de configuração fixo na posição 0
  const configNodeId = 'Config';
  const configNode = nodeMap.get(configNodeId);
  if (configNode) {
    orderedNodes.push(configNode);
    idMap.set(configNodeId, '0'); // O nó de configuração mantém o ID '0'
  } else {
    console.error(`Config node (id: ${configNodeId}) is missing.`);
    return { nodes: [], edges: [] };
  }
  //console.log("Ordered Nodes after adding config node:", orderedNodes);
  // Função recursiva para ordenar a árvore e atualizar os IDs
  const orderTree = (node, currentId) => {
    const newId = currentId.toString();
    idMap.set(node.id, newId); // Atualiza o ID do nó
    const newNode = { ...node, id: newId };
    if (node.parentNode === 0) {
      newNode.parentNode = 0; // Garante que os nós raiz tenham parentNode igual a 0
    } else if (newNode.parentNode !== null && newNode.parentNode !== undefined) {
      newNode.parentNode = idMap.get(newNode.parentNode); // Atualiza o parentNode para o novo ID
    }
    orderedNodes.push(newNode);
    //console.log(`Added node ${newNode.id} with parent ${newNode.parentNode} to orderedNodes. Current orderedNodes:`, orderedNodes);
    let nextId = currentId + 1;
    nodeMap.forEach(child => {
      if (child.parentNode === node.id) {
        //console.log(`Node ${child.id} is a child of node ${node.id}`);
        nextId = orderTree(child, nextId); // Recursivamente ordena os filhos
      }
    });
    return nextId;
  };
  // Iniciar a ordenação a partir dos nós raiz (parentNode igual a 0)
  nodeMap.forEach(node => {
    if (node.parentNode === 0) {
      //console.log(`Starting orderTree for root node ${node.id}`);
      orderTree(node, orderedNodes.length);
    }
  });
  //console.log("Final orderedNodes:", orderedNodes);
  // Reordenar as edges de acordo com os novos IDs dos nós
  edges.forEach(edge => {
    const newSource = idMap.get(edge.source);
    const newTarget = idMap.get(edge.target);
    if (newSource !== undefined && newTarget !== undefined) {
      orderedEdges.push({ ...edge, source: newSource, target: newTarget });
    } else {
      //console.error(`Edge with source ${edge.source} and target ${edge.target} has invalid mapping.`);
    }
  });
  //console.log("Final orderedEdges:", orderedEdges);
  return { nodes: orderedNodes, edges: orderedEdges };
};

function UpdateRoute53BlueGreen(R53ID, NewGreenPercent) {
  console.log("R53ID, NewGreenPercent", R53ID, NewGreenPercent)

}

function AdjustSufixName(GlobalNodes, NodeID, IsSingleNode = false) {
  let ListBoxesSufix = [];
  let NodesWithSufix = JSON.parse(JSON.stringify(GlobalNodes));
  let [CloudNode, Region] = FindRegionAndCloud(GlobalNodes, NodeID);
  for (let i = 1; i < NodesWithSufix.length; i++) {
    let [Cloud, Region] = FindRegionAndCloud(GlobalNodes, i);
    //console.log("CloudNode", CloudNode, Cloud)
    let NodeType = NodesWithSufix[i].type;
    if (Cloud !== CloudNode && !(TypeCloud.includes(NodeType))) {//apgaga dados de todos os nodes de outra cloud, menos a propria 
      NodesWithSufix[i].data.Param = "";
      NodesWithSufix[i].type = "Discard"
    }
    if (NodeType === "GraphN") { //discarta de for Graph
      NodesWithSufix[i].data.Param = ""
      NodesWithSufix[i].type = "Discard"
    }
    if (NodeType === "SBoxN" || TypeVPC.includes(NodeType)) {
      console.log("NodeType", NodeType)
      let ParentID = parseInt(GlobalNodes[i].parentNode);
      let ParentName = GlobalNodes[ParentID].data.Param[1][1].toLowerCase();
      //console.log("ParentID", ParentID, GlobalNodes[ParentID].data.Param[1][1])
      if (GlobalNodes[ParentID].type === "SBoxN") {
        let SufixFather = GlobalNodes[ParentID].data.Param[4][1].toString().toLowerCase();
        let SufixChild = GlobalNodes[i].data.Param[4][1].toString().toLowerCase();
        console.log("Sufix child", SufixChild, SufixFather)
        if (!ParentName.includes("version") && !SufixFather.includes(".")) {
          ListBoxesSufix.push([i, SufixFather])
        }
      }
    }
  }
  console.log("ListBoxesSufix", ListBoxesSufix)
  const ExcludedList = ["GraphN", "Discard", "CodePipelineStageN"];
  for (let i = 0; i < ListBoxesSufix.length; i++) {
    let BoxID = ListBoxesSufix[i][0];
    //console.log("Level", Level, NodesWithSufix[BoxID].type)
    let Sufix = ListBoxesSufix[i][1];
    //console.log("NodesWithSufix", NodesWithSufix)
    let ListNodesSufix = FindNodesChieldID(NodesWithSufix, BoxID.toString());
    //console.log("ListNodes", ListNodesSufix)
    for (let j = 0; j < ListNodesSufix.length; j++) {
      let Index = parseInt(ListNodesSufix[j]);
      let NodeTypeInsideBox = NodesWithSufix[Index].type;
      //console.log("Index", Index, NodeTypeInsideBox)
      if (!TypeAZ.includes(NodeTypeInsideBox) && !(ExcludedList.includes(NodeTypeInsideBox)) && Sufix !== "") {
        NodesWithSufix[Index].data.Param[1][1] = NodesWithSufix[Index].data.Param[1][1] + "-" + Sufix;
      }
    }
  }
  if (IsSingleNode) {
    return NodesWithSufix[NodeID]
  } else {
    return NodesWithSufix
  }
}

function updateTerraformDependencies(TFID, GlobalEdges, GlobalNodes, GlobalEdgesLocal) {
  function edgeExists(source, target, edges) {
    return edges.some(edge => edge.source === source && edge.target === target);
  }
  //Varre ancestrais
  let currentLevel = [TFID];
  let nextLevel = [];
  while (currentLevel.length > 0) {
    for (let i = 0; i < currentLevel.length; i++) {
      let RefID = currentLevel[i];
      let ListSourceTF = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[RefID], "TerraformN");
      for (let j = 0; j < ListSourceTF.length; j++) {
        let TFSourceID = ListSourceTF[j];
        if (!edgeExists(TFSourceID.toString(), RefID.toString(), GlobalEdgesLocal) && RefID === TFID) {
          GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": TFSourceID.toString(), "target": RefID.toString(), "label": "" });
        }
        // Adiciona TFSourceID ao próximo nível para processar na próxima iteração
        nextLevel.push(TFSourceID);
        // Adiciona edges para todos os ancestrais do TFSourceID
        let AncestorQueue = [TFSourceID];
        while (AncestorQueue.length > 0) {
          let AncestorID = AncestorQueue.shift();
          let ListAncestorTF = SearchNodesSource(GlobalEdges, GlobalNodes, GlobalNodes[AncestorID], "TerraformN");
          if (ListAncestorTF.length > 0) {
            for (let k = 0; k < ListAncestorTF.length; k++) {
              let TFA = ListAncestorTF[k];
              if (!edgeExists(TFA, RefID.toString(), GlobalEdgesLocal) && RefID === TFID) {
                GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": TFA.toString(), "target": RefID.toString(), "label": "" });
              }
              AncestorQueue.push(TFA);
            }
          }
        }
      }
    }
    // Passa para o próximo nível
    currentLevel = nextLevel;
    nextLevel = [];
  }
  //varre descendentes
  currentLevel = [TFID];
  nextLevel = [];
  while (currentLevel.length > 0) {
    for (let i = 0; i < currentLevel.length; i++) {
      let RefID = currentLevel[i];
      let ListTargetTF = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[RefID], "TerraformN");
      for (let j = 0; j < ListTargetTF.length; j++) {
        let TFTargetID = ListTargetTF[j];
        if (!edgeExists(RefID.toString(), TFTargetID.toString(), GlobalEdgesLocal) && RefID === TFID) {
          GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": RefID.toString(), "target": TFTargetID.toString(), "label": "" });
        }
        // Adiciona TFTargetID ao próximo nível para processar na próxima iteração
        nextLevel.push(TFTargetID);
        // Adiciona edges para todos os descendentes do TFTargetID
        let DescendantQueue = [TFTargetID];
        while (DescendantQueue.length > 0) {
          let DescendantID = DescendantQueue.shift();
          let ListDescendantTF = SearchNodesTarget(GlobalEdges, GlobalNodes, GlobalNodes[DescendantID], "TerraformN");
          if (ListDescendantTF.length > 0) {
            for (let k = 0; k < ListDescendantTF.length; k++) {
              let TFD = ListDescendantTF[k];
              if (!edgeExists(RefID.toString(), TFD.toString(), GlobalEdgesLocal) && RefID === TFID) {
                GlobalEdgesLocal.push({ "id": GlobalEdgesLocal.length.toString(), "source": RefID.toString(), "target": TFD.toString(), "label": "" });
              }
              DescendantQueue.push(TFD);
            }
          }
        }
      }
    }
    // Passa para o próximo nível
    currentLevel = nextLevel;
    nextLevel = [];
  }
}

// Função para formatar os números do eixo Y
const formatTick = value => {
  if (value < 100) {
    return value.toFixed(2);
  } else if (value < 1000) {
    return value.toFixed(1);
  }
  let suffix = '';
  let divisor = 1;
  if (value < 9999) {
    return value.toFixed(0);
  } else if (value >= 9999 && value < 9999000) {
    suffix = 'K';
    divisor = 1000;
  } else if (value >= 9999000 && value < 9999000000) {
    suffix = 'M';
    divisor = 1000000;
  } else if (value >= 9999000000) {
    suffix = 'G';
    divisor = 1000000000;
  }
  let formattedNumber = value / divisor;
  return `${Number(formattedNumber.toFixed(1)).toString().replace(/\.0$/, '')}${suffix}`;
};


async function createAndCaptureChartSequentially(metricData, Grid, Legends, intervalInSeconds, LastUpdate, width = 384, height = 288) { //Nivo
  return new Promise(async (resolve, reject) => {
    const container = document.createElement('div');
    container.style.width = `${384}px`;
    container.style.height = `${236}px`;
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    document.body.appendChild(container);

    const intervalToText = (interval) => {
      const intervals = {
        3600: "1 Hour",
        10800: "3 Hours",
        43200: "12 Hours",
        86400: "1 Day",
        259200: "3 Days",
        604800: "1 Week",
        1209600: "2 Weeks",
        2592000: "1 Month",
        5184000: "2 Months",
        10368000: "4 Months"
      };
      return intervals[interval] || '';
    };

    const intervalText = intervalToText(intervalInSeconds);

    // Calcular os valores de grade com base nos dados
    const xValues = metricData[0].data.map(d => d.x);
    const yValues = metricData.flatMap(series => series.data.map(d => d.y));
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);

    // Definir intervalos de grade
    const yInterval = Math.ceil((yMax - yMin) / 10);
    const gridYValues = Array.from({ length: 11 }, (_, i) => yMin + i * yInterval);

    // Definir valores de tick para o eixo X em um intervalo específico (por exemplo, a cada 5 pontos)
    const tickInterval = Math.ceil(xValues.length / 10);
    const gridXValues = xValues.filter((_, index) => index % tickInterval === 0);

    const lineChart = (
      <Line
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "black",
                strokeWidth: 0.5
              }
            },
            ticks: {
              line: {
                stroke: "black"
              },
              text: {
                fontSize: '9px', // Reduzido em 45%
              }
            },
            legend: {
              text: {
                fontSize: '11px', // Reduzido em 45%
              }
            }
          },
          legends: {
            text: {
              fontSize: '11px', // Reduzido em 45%
            }
          }
        }}
        gridXValues={Grid ? gridXValues : []}
        gridYValues={Grid ? gridYValues : []}
        animate={false}
        motionStiffness={0}
        data={metricData}
        width={384}
        height={280}
        margin={{ top: 30, right: 15, bottom: 116, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: gridXValues,
          tickColor: "black",
          domain: { color: "black" },
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 0,
          legendPosition: 'middle',
          tickValues: gridYValues,
          tickColor: 'black',
          domain: { color: 'black' },
          format: formatTick
        }}
        colors={['#f52030', '#1c28f0', '#f9d423', '#10b332']}
        pointSize={3}
        pointBorderWidth={1}
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={Legends ? [{
          anchor: 'bottom',
          direction: 'column',
          margin: { top: 30, right: 0, bottom: 10, left: 0 },
          justify: false,
          translateX: -45,
          translateY: 55 + (metricData.length - 1) * 4,
          itemsSpacing: 7,
          itemWidth: 300,
          itemHeight: 5,
          itemDirection: 'left-to-right',
          itemTextColor: '#000',
          symbolSize: 7,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }] : []}
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          // Custom layer for annotations
          ({ innerWidth, innerHeight }) => (
            <g transform={`translate(270, 178)`}>
              <text
                textAnchor="middle"
                style={{
                  fill: 'rgba(0, 0, 0)',
                  fontSize: 28,
                }}
              >
                {intervalText}
              </text>
            </g>
          ),
          ({ innerWidth, innerHeight }) => (
            <g transform={`translate(270, 194)`}>
              <text
                textAnchor="middle"
                style={{
                  fill: 'rgba(0, 0, 0)',
                  fontSize: 8,
                }}
              >
                Last Update: {LastUpdate}
              </text>
            </g>
          )
        ]
        }
      />
    );
    const root = createRoot(container);
    root.render(lineChart);
    // Usar MutationObserver para monitorar mudanças no DOM
    const observer = new MutationObserver(async (mutationsList, observer) => {
      // Se houver qualquer mutação nos nós filhos
      if (mutationsList.some(mutation => mutation.type === 'childList' || mutation.type === 'attributes')) {
        try {
          const canvas = await html2canvas(container, { logging: false });
          const imageSrc = canvas.toDataURL();
          if (container.parentNode) {
            document.body.removeChild(container);
          }
          resolve(imageSrc);
        } catch (error) {
          if (container.parentNode) {
            document.body.removeChild(container);
          }
          reject(error);
        } finally {
          observer.disconnect(); // Desconecta o observador após a captura
        }
      }
    });
    // Configurar o observador para monitorar mudanças no container
    observer.observe(container, { attributes: true, childList: true, subtree: true });
  });
}

/*function createAndCaptureChart(metricData, Grid, Legends) {
  console.time('createAndCaptureChart');
  return new Promise(async (resolve, reject) => {
    const container = document.createElement('div');
    container.style.width = '512px';
    container.style.height = '384px';
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    document.body.appendChild(container);
 
    // Calcular os valores de grade com base nos dados
    const xValues = metricData[0].data.map(d => d.x);
    const yValues = metricData.flatMap(series => series.data.map(d => d.y));
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);
 
    // Definir intervalos de grade
    const yInterval = Math.ceil((yMax - yMin) / 10);
    const gridYValues = Array.from({ length: 11 }, (_, i) => yMin + i * yInterval);
 
    // Definir valores de tick para o eixo X em um intervalo específico (por exemplo, a cada 5 pontos)
    const tickInterval = Math.ceil(xValues.length / 10); // Ajuste conforme necessário
    const gridXValues = xValues.filter((_, index) => index % tickInterval === 0);
 
    const lineChart = (
      <Line
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "black",
                strokeWidth: 1
              }
            },
            ticks: {
              line: {
                stroke: "black"
              },
              text: {
                fontSize: '15px',
              }
            },
            legend: {
              text: {
                fontSize: '20px',
              }
            }
          },
          legends: {
            text: {
              fontSize: '20px',
            }
          }
        }}
        gridXValues={Grid ? gridXValues : []}
        gridYValues={Grid ? gridYValues : []}
        animate={false}
        motionStiffness={0}
        data={metricData}
        width={512}
        height={384}
        margin={{ top: 30, right: 15, bottom: 116, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: gridXValues,
          tickColor: "black",
          domain: { color: "black" },
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 0,
          legendPosition: 'middle',
          tickValues: gridYValues,
          tickColor: "black",
          domain: { color: "black" },
          format: formatTick
        }}
        colors={['#f52030', '#1c28f0', '#f9d423', '#10b332']}
        pointSize={6}
        pointBorderWidth={2}
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={Legends ? [{
          anchor: 'bottom',
          direction: 'column',
          margin: { top: 30, right: 0, bottom: 30, left: 0 },
          justify: false,
          translateX: 0,
          translateY: 94 + (metricData.length - 1) * 5,
          itemsSpacing: 11,
          itemWidth: 300,
          itemHeight: 12,
          itemDirection: 'left-to-right',
          itemTextColor: '#000',
          symbolSize: 16,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }] : []}
      />
    );
 
    const root = createRoot(container);
    root.render(lineChart);
 
    // Adicionando um tempo de espera para garantir a renderização completa
    setTimeout(async () => {
      try {
        const canvas = await html2canvas(container);
        const imageSrc = canvas.toDataURL();
        document.body.removeChild(container);
        console.timeEnd('createAndCaptureChart');
        resolve(imageSrc);
      } catch (error) {
        document.body.removeChild(container);
        console.timeEnd('createAndCaptureChart');
        reject(error);
      }
    }, 20); // Espera 20ms para garantir a renderização completa
  });
}*/



/*function adjustTimestamps(metricsArray, intervalInSeconds, SamplePeriodInSeconds) {
  if (!Array.isArray(metricsArray) || metricsArray.length === 0) {
    console.error('Invalid metric data format:', metricsArray);
    return [];
  }
 
  // Define um limite para intervalos de 1 hora, 3 horas, 12 horas e 1 dia em segundos
  const oneDayInSeconds = 86400;
 
  // Verifica se o intervalo é 1 hora, 3 horas, 12 horas ou 1 dia
  const isShortInterval = intervalInSeconds <= oneDayInSeconds;
 
  // Define a granularidade dos dados
  const dataGranularityInMinutes = SamplePeriodInSeconds / 60;
  console.log("dataGranularityInMinutes", dataGranularityInMinutes)
  console.log("SamplePeriodInSeconds", SamplePeriodInSeconds)
 
  // Processa cada métrica individualmente
  return metricsArray.map(metric => {
    if (!metric.data || !Array.isArray(metric.data)) {
      console.error('Invalid or missing data in metric:', metric);
      return null; // Retorna null para métricas inválidas para fácil identificação.
    }
 
    // Converte os pontos de dados em um mapa para facilitar a inserção de pontos ausentes
    const dataMap = new Map();
    metric.data.forEach(point => {
      if (point.x && typeof point.x === 'string') {
        let dateTime = new Date(point.x);
        let formattedTime = dateTime.toISOString();
        dataMap.set(formattedTime, point.y);
      }
    });
 
    // Determina o intervalo inicial e final dos dados
    let startTime = new Date(metric.data[0].x);
    let endTime = new Date(metric.data[metric.data.length - 1].x);
 
    // Cria uma lista de pontos ajustados preenchendo intervalos ausentes com zeros
    const adjustedData = [];
    for (let time = startTime; time <= endTime; time.setMinutes(time.getMinutes() + dataGranularityInMinutes)) {
      let formattedTime = time.toISOString();
      let yValue = dataMap.has(formattedTime) ? dataMap.get(formattedTime) : 0;
 
      if (isShortInterval) {
        // Mostrar apenas as horas e minutos para intervalos de um dia ou menos
        let hours = String(time.getUTCHours()).padStart(2, '0');
        let minutes = String(time.getUTCMinutes()).padStart(2, '0');
        formattedTime = `${hours}:${minutes}`;
      } else {
        // Mostrar a data e hora para intervalos maiores que um dia
        let year = String(time.getUTCFullYear()).slice(2, 4); // Extrai os dois últimos dígitos do ano
        let month = String(time.getUTCMonth() + 1).padStart(2, '0'); // Extrai o mês
        let day = String(time.getUTCDate()).padStart(2, '0'); // Extrai o dia
        let hours = String(time.getUTCHours()).padStart(2, '0');
        formattedTime = `${year}-${month}-${day}T${hours}`;
      }
 
      adjustedData.push({ x: formattedTime, y: yValue });
    }
 
    // Retorna os dados ajustados com o id preservado
    return {
      id: metric.id, // Inclui o id no objeto retornado
      data: adjustedData
    };
  }).filter(metric => metric !== null); // Filtra qualquer métrica que tenha sido invalidada e retornada como null
}*/

function adjustTimestamps(metricsArray, intervalInSeconds, samplePeriodInSeconds, endTimeString) {
  if (!Array.isArray(metricsArray) || metricsArray.length === 0) {
    //console.error('Invalid metric data format:', metricsArray);
    return [];
  }

  const dataGranularityInMinutes = samplePeriodInSeconds / 60;
  const numberOfPoints = Math.floor(intervalInSeconds / samplePeriodInSeconds);
  const oneDayInSeconds = 86400;
  const isShortInterval = intervalInSeconds <= oneDayInSeconds;

  //console.log("dataGranularityInMinutes", dataGranularityInMinutes);
  //console.log("samplePeriodInSeconds", samplePeriodInSeconds);
  //console.log("numberOfPoints", numberOfPoints);

  const endTime = new Date(endTimeString);
  const startTime = new Date(endTime.getTime() - intervalInSeconds * 1000);

  //console.log("startTime:", startTime.toISOString());
  //console.log("endTime:", endTime.toISOString());

  return metricsArray.map(metric => {
    if (!metric.data || !Array.isArray(metric.data)) {
      console.error('Invalid or missing data in metric:', metric);
      return null;
    }

    //console.log("Processing metric:", metric.id);

    const adjustedData = new Array(numberOfPoints + 1).fill(0).map((_, i) => { // numberOfPoints + 1 to ensure we can remove extra points later
      const time = new Date(startTime.getTime() + i * samplePeriodInSeconds * 1000);
      let formattedTime = time.toISOString();

      if (isShortInterval) {
        const hours = String(time.getUTCHours()).padStart(2, '0');
        const minutes = String(time.getUTCMinutes()).padStart(2, '0');
        formattedTime = `${hours}:${minutes}`;
      }

      return { x: formattedTime, y: 0 };
    });

    //console.log("Initial adjustedData:", adjustedData);

    metric.data.forEach(point => {
      if (point.x && typeof point.x === 'string') {
        const pointTime = new Date(point.x);
        const index = Math.floor((pointTime - startTime) / (samplePeriodInSeconds * 1000));

        if (index >= 0 && index < adjustedData.length) {
          //console.log(`Replacing zero with actual value at index ${index}: x=${point.x}, y=${point.y}`);
          adjustedData[index].y = point.y;
        }
      }
    });

    // Verifica o intervalo e remove pontos adicionais conforme necessário
    let finalAdjustedData = adjustedData;

    if (intervalInSeconds <= 3600) {
      finalAdjustedData = adjustedData.slice(0, adjustedData.length - 5); // 1 hora
    } else if (intervalInSeconds <= 10800) {
      finalAdjustedData = adjustedData.slice(0, adjustedData.length - 3); // 3 horas
    } else if (intervalInSeconds <= 43200) {
      finalAdjustedData = adjustedData.slice(0, adjustedData.length - 2); // 12 horas
    } else if (intervalInSeconds <= 86400) {
      finalAdjustedData = adjustedData.slice(0, adjustedData.length - 2); // 1 dia
    }

    //console.log("Final adjustedData:", finalAdjustedData);

    return {
      id: metric.id,
      data: finalAdjustedData
    };
  }).filter(metric => metric !== null);
}

function MakeCWParams(Edges, Nodes, NodeID) {
  try {

    //console.log("Passou aqui");
    let SourceID = FindNodeNonGraphNHead(Edges, Nodes, NodeID);
    //console.log("SourceList", SourceList);
    const NodesWithSufix = AdjustSufixName(Nodes, SourceID, true);
    const SourceName = NodesWithSufix.data.Param[1][1];
    //console.log("SourceName", SourceName);
    const Time = {
      "0": 3600,           // 1 hora
      "1": 10800,          // 3 horas
      "2": 43200,          // 12 horas
      "3": 86400,          // 1 dia
      "4": 259200,         // 3 dias
      "5": 604800,         // 1 semana
      "6": 1209600,        // 2 semanas
      "7": 2592000,        // 1 mês (30 dias)
      "8": 5184000,        // 2 meses (60 dias)
      "9": 10368000        // 4 meses (120 dias)
    };
    let IntervalSource = Nodes[NodeID].data.Param[3][2][1][2][1][1];
    let IntervalTime = Time[IntervalSource] * 1000;
    const intervalInSeconds = Time[IntervalSource];
    // Ajustar o SamplePeriod para sempre retornar 20 pontos
    const SamplePeriods = [
      60,     // 1 min se 1 hora
      180,    // 3 min se 3 horas
      600,    // 10 min se 12 horas
      1200,   // 20 min se 1 dia
      3600,   // 1 hora se 3 dias
      7200,   // 2 horas se 1 semana
      14400,  // 4 horas se 2 semanas
      28800,  // 8 horas se 1 mês
      43200,  // 12 horas se 2 meses
      86400   // 24 horas se 4 meses
    ];
    let SamplePeriodInSeconds = SamplePeriods[IntervalSource];
    Nodes[NodeID].data.MaxCounter = SamplePeriodInSeconds / 60;
    //console.log("IntervalSource:", IntervalSource);
    //console.log("IntervalTime (ms):", IntervalTime);
    //console.log("Calculated SamplePeriod (s):", SamplePeriodInSeconds);
    let [Cloud, Region] = FindRegionAndCloud(Nodes, NodeID);
    let RegionName = Nodes[Region].data.Param[2][2];
    let RoleARN = Nodes[Cloud].data.Param[5][1];
    //console.log("IntervalTime", IntervalTime, "SamplePeriod", SamplePeriodInSeconds);
    let JSON = ListaStandard[ResourceLookUp["GraphN"]]["GeneralParam"]["ListMetrics"];
    //console.log("JSON api call", JSON);
    let MetricsArray = [];
    for (let i = 0; i < 4; i++) {
      let Metrics = Nodes[NodeID].data.Param[3][2][1][2][2 + i][2][1][1];
      let IsEnable = Nodes[NodeID].data.Param[3][2][1][2][2 + i][2][2][1];
      if (IsEnable) {
        let InputNodeType = Metrics.InputNodeType;
        //console.log("InputNodeType", InputNodeType);
        const subObject = JSON[InputNodeType];
        //console.log("subObject", subObject);
        const secondKey = Object.keys(subObject)[0];
        const secondSubObject = subObject[secondKey];
        let Metric = Metrics["Metric"];
        let JSONVars = secondSubObject[Metric];
        console.log("JSONVars", JSONVars);
        let JSONDimensions = JSONVars['Dimensions'];
        console.log("JSONDimensions", JSONDimensions);
        let ValidStatistics = Metrics["ValidStatistics"];
        if (ValidStatistics === "") {
          ValidStatistics = JSONVars['ValidStatistics'][0];
        }
        let metricItem = {
          Metric: Metric,
          ValidStatistics: ValidStatistics,
          NameSpace: Metrics["NameSpace"],
          Dimensions: JSONDimensions,
          isEnabled: IsEnable
        };
        delete JSONVars.MetricDescription;
        //console.log("Metrics", Metrics);
        let Dimension = Metrics['Dimensions'];
        //console.log("Dimension", Dimension);
        //console.log("JSONVars['Dimensions']", JSONVars['Dimensions']);
        MetricsArray.push(metricItem);
        let ListRemove = [];

        for (let j = 0; j < JSONDimensions.length; j++) {
          let Value = JSONDimensions[j].Value;
          let Key = JSONDimensions[j].Name;
          //console.log("Dimension", Key, Value);
          if (Value === "$Var") {
            let NewValue = Metrics[Key];
            //console.log("NewValue", NewValue);
            metricItem.Dimensions[j]["Value"] = NewValue;
            if (NewValue === "All") {
              ListRemove.push(j);
              //console.log("ListRemove", ListRemove);
            }
          }
        }
        //console.log("ListRemove new", ListRemove);
        for (let j = ListRemove.length; j > 0; j--) {
          metricItem.Dimensions.splice(ListRemove[j - 1], 1);
          //console.log("Removeu", ListRemove[j - 1], j);
        }
      }
    }
    //console.log("MetricsArray", MetricsArray);
    var EnabledMetrics = MetricsArray.filter(metric => metric.isEnabled);
    //console.log("EnabledMetrics", EnabledMetrics);
    var ParamMetrics = {
      StartTime: new Date(Date.now() - IntervalTime).toISOString(),
      EndTime: new Date().toISOString(),
      MetricDataQueries: EnabledMetrics.map((metric, index) => {
        //console.log("metric", metric);
        let query = {
          Id: "query_" + metric.Metric + (metric.ValidStatistics ? "_" + metric.ValidStatistics : ""),
          MetricStat: {
            Metric: {
              Namespace: metric["NameSpace"],
              MetricName: metric.Metric
            },
            Period: SamplePeriodInSeconds,
            Stat: metric.ValidStatistics
          }
        };
        if (metric.Dimensions) {
          query.MetricStat.Metric.Dimensions = metric.Dimensions;
        }
        return query;
      })
    };
    //console.log("ParamMetrics", ParamMetrics);
    return [RoleARN, RegionName, ParamMetrics, SourceName, intervalInSeconds, SamplePeriodInSeconds];
  } catch (error) {
    //pass
  }
}

async function UpdateMetrics(Edges, Nodes, GlobalToken, NodeID, NodeVisible, setNodes, ShoudIncrement) {
  //console.log("UpdateMetrics",UpdateMetrics)
  try {
    const Selected = Nodes[NodeID].selected;
    //const metricData = Nodes[i] && Nodes[i].data ? Nodes[i].data.metricData : false;
    //let Counter = Nodes[i] && Nodes[i].data ? Nodes[i].data.Counter : 0;
    let Counter = Nodes[NodeID].data.Counter || 0;
    let MaxCounter = Nodes[NodeID].data.MaxCounter || 0;
    console.log("MaxCounter", MaxCounter, Counter, Selected, NodeID)
    if ((Counter >= MaxCounter && MaxCounter > 3 || Counter >= 5 && MaxCounter <= 3) && NodeVisible || Selected || MaxCounter === 0) {
      //try {
      const imageSrc = await fetchAndProcessMetrics(Edges, Nodes, NodeID, GlobalToken, setNodes);
      Nodes[NodeID].data.Counter = 0;
      //} catch (error) {
      //console.error("Error capturing chart:", error);
      //}
    } else {
      ShoudIncrement && (Counter += 1);
      Nodes[NodeID].data.Counter = Counter;
    }
    // Preservando os atributos dos nodes
  } catch (error) {
    //pass
  }
}

async function fetchAndProcessMetrics(Edges, Nodes, NodeID, GlobalToken, setNodes) {
  try {
    const Params = MakeCWParams(Edges, Nodes, NodeID);
    const CrossAccountRole = Params[0];
    const Region = Params[1];
    const ParamMetrics = Params[2];
    const TagName = Params[3];
    const intervalInSeconds = Params[4];
    const SamplePeriodInSeconds = Params[5];
    const EndTime = ParamMetrics.EndTime;
    //console.log("intervalInSeconds", intervalInSeconds);
    const Legends = Nodes[NodeID].data.Param[4][1] ? undefined : [];
    const Grid = Nodes[NodeID].data.Param[5][1] ? undefined : [];
    var raw = JSON.stringify([2, ParamMetrics, CrossAccountRole, Region, TagName]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
    var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };
    try {
      const response = await fetch(APIPricing, requestOptions);
      const result = await response.text();
      console.log("result", result);

      if (response.status === 403) {
        //erro de formatação da role cross account
        console.log("Retorno 1")
        return [];
      }

      let metricData = JSON.parse(result).body;
      console.log("metricData", metricData);
      if (typeof metricData !== 'object') {
        console.log("Erro: Dados métricos inválidos");
        console.log("Retorno 2")
        return [];  // Encerra a execução se os dados métricos não forem válidos
      }
      metricData = adjustTimestamps(metricData, intervalInSeconds, SamplePeriodInSeconds, EndTime);
      Nodes[NodeID].data.metricData = metricData;
      const now = new Date();
      const LastUpDate = formatDateUTC(now);
      const Grid = true;
      const Legends = true;
      const imageSrc = await createAndCaptureChartSequentially(metricData, Grid, Legends, intervalInSeconds, LastUpDate);
      Nodes[NodeID].data.img = imageSrc;
      setNodes(prevNodes => {
        const newNodes = [...prevNodes];
        newNodes[NodeID] = {
          ...prevNodes[NodeID],  // Mantém os atributos existentes
          data: {
            ...prevNodes[NodeID].data,  // Mantém os dados existentes
            // Adicione aqui qualquer dado específico que precisa ser atualizado
          }
        };
        console.log("Retorno 3,", newNodes)
        return newNodes;
      });
    } catch (error) {
      console.log('error', error);
    }
  } catch (error) {
    //pass
  }
}


async function CallAPI(APIName, raw, isJSON = false) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(raw), // Convertendo o array para JSON
      redirect: 'follow'
    };
    console.log("Request Options:", requestOptions);
    const response = await fetch(APIName, requestOptions);
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
    }
    if (isJSON) {
      const resultJson = await response.json();
      console.log("Result (JSON):", resultJson);
      return resultJson;
    } else {
      const resultText = await response.text();
      console.log("Result (Text):", resultText);
      return resultText;
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}


// Função para adicionar zero à esquerda em números menores que 10
function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

// Função para formatar a data em UTC
function formatDateUTC(date) {
  const year = date.getUTCFullYear().toString().slice(-2); // Últimos 2 dígitos do ano
  const day = padTo2Digits(date.getUTCDate());
  const month = padTo2Digits(date.getUTCMonth() + 1); // Meses são baseados em zero
  const hour = padTo2Digits(date.getUTCHours());
  const minute = padTo2Digits(date.getUTCMinutes());

  return `${year}/${day}/${month} ${hour}:${minute}`;
}

const calculateAbsolutePosition = (nodes, node) => {
  let x = 0;
  let y = 0;

  let currentNode = node;
  while (true) {
    x += currentNode.position.x;
    y += currentNode.position.y;
    let ParentID = parseInt(currentNode.parentNode)
    if (ParentID === 0) {
      break;
    }
    currentNode = nodes[ParentID];
  }
  return { x, y };
};



const checkNodeVisibility = (nodes, transform) => {
  console.log("nodes", nodes);
  const { x: translateX, y: translateY, zoom } = transform;
  const viewportWidth = window.innerWidth * 2 / zoom;
  const viewportHeight = window.innerHeight * 2 / zoom;
  console.log("Viewport Dimensions:", viewportWidth, viewportHeight);
  console.log("Transform:", transform);
  let IsVisible = [];

  for (let i = 1; i < nodes.length; i++) {
    let node = nodes[i];
    const NodeType = nodes[i].type;
    if (NodeType === "GraphN") {
      if (!node.width || !node.height) {
        console.log(`Node ${node.id} is missing width or height. Full node:`, node);
        IsVisible[i] = false;
        continue;
      }
      const { x, y } = calculateAbsolutePosition(nodes, node);
      const nodeX = x + translateX / zoom;
      const nodeY = y + translateY / zoom;
      const nodeWidth = node.width;
      const nodeHeight = node.height;

      const LeftTop = nodeX;
      const RightTop = nodeX + nodeWidth;
      const LeftBottom = nodeY;
      const RightBottom = nodeY + nodeHeight;

      console.log("absolute", x, y, node.id);
      console.log(`LeftTop: ${LeftTop}`);
      console.log(`RightTop: ${RightTop}`, viewportWidth);
      console.log(`LeftBottom: ${LeftBottom}`);
      console.log(`RightBottom: ${RightBottom}`, viewportHeight);

      IsVisible[i] = LeftTop < viewportWidth && RightTop > 0 && LeftBottom < viewportHeight && RightBottom > 0;
      console.log(`Node ${node.id} isVisible: ${IsVisible[i]}`);
    } else {
      IsVisible[i] = false;
    }
  }
  return IsVisible;
};


async function refreshToken() {
  const RefreshToken = sessionStorage.getItem("RefreshToken");
  console.log("RefreshToken", RefreshToken)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${GlobalToken}`);
  const response = await fetch(APIAuth, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ "code": "RefreshToken", "refresh_token": RefreshToken })
  });
  const authData = await response.json();
  const parsedBody = JSON.parse(authData.body);
  console.log("parsedBody", parsedBody);
  const currentTime = new Date().getTime();
  const accessExpiresAt = currentTime + (parsedBody.access_expires_in - 90) * 1000;
  sessionStorage.setItem('AccessExpiresAt', accessExpiresAt);
  const globalToken = parsedBody.access_token;
  sessionStorage.setItem("GlobalToken", globalToken);
  console.log("new Token", globalToken);

}

let isAlertShown = false;
async function checkAndRefreshToken(Edges, GNodes, Stage, GlobalCognitoSub, GlobalToken) {
  const currentTime = new Date().getTime();
  const AccessExpiresAt = parseInt(sessionStorage.getItem('AccessExpiresAt'), 10);
  const RefreshExpiresAt = parseInt(sessionStorage.getItem('RefreshExpiresAt'), 10);
  console.log("currentTime", currentTime, RefreshExpiresAt, AccessExpiresAt);

  if (currentTime > RefreshExpiresAt) {
    // Refresh token has expired, user needs to log in again
    if (!isAlertShown) {
      isAlertShown = true;
      alert('Session expired. Please log in again.');
      await Save(Edges, GNodes, GlobalCognitoSub, GlobalToken);
      window.location.replace('https://cloudman.pro');
    }
    return false;
  }

  if (currentTime > AccessExpiresAt) {
    console.log("?Novo Token");
    await refreshToken();
  }

  return sessionStorage.getItem('GlobalToken');
}


function AdjustChildPosition(nodes, ID, Direction, DeltaX = 0, DeltaY = 0) {
}

export {
  GetVPCParent, SearchNodesSource, SearchNodesTarget, ResourceLabelAdjust, SearchNodesTargetTwoLevels,
  GetFatherPosition, RemoveSelected, SearchNodeType, SearchNameNodeType, compareAndAdd, FindNodesChieldOneLevelID,
  SearchNodeTypeReturnNameList, FindNodesEdgesChield, FindNodesEdgesChieldSelected, FindSelectedNodes,
  FindResourceFather, FindNodesChieldIDFromParent, FindNodesChieldID, FindRegionAndCloud, nextIPCIDR,
  checkIPCIDRConflict, PermitionToAddNode, HandlerGeneral, GetCIDRList, RemoveNodesFromList,
  RemoveEdgesFromList, VerifyNodesTypeTarget, findIntersectingNodesBox, AdjustFatherPosition, Save, AjustEdgesNodesToSave,
  CheckIdenticalName, AdjustID, arraysAreEqual, ListTerraformDomain, GetNodeId, UpDateGlobalNodeID, FindFiliationLevel,
  FindAllFathers, ToJSONObject, ExtractTerraformResourceBlock, MakeCWParams, ReadCloud, ExtremePositionBox, MinXYBox,
  getCurrentMonthDates, GetCostFromCostExporerResponse, FindNameIDRouting, Compress, Descompact, openConsole,
  compareMetricDataQueries, findInstancesByTags, TestDuplicateEdges, CalculateScale, FindIPv6BlockIndex, ValidateArn,
  findEdgeLabel, AdjustSubnetCIDR, MaskString, ProcessLoad, SearchSubnetHead, UpdateDataNodes, IsEC2AutoScale, GenerateRandomizedBucketName,
  mirrorNodes, BoxRestrictions, DiscoveryTerraformNetwork, FindBackendStorage, FindNodeHead, FindNodeNonGraphNHead,
  compareVersions, AdjustSubnetCIDRAfterPaste, deepEqual, reorderNodesAndEdges, UpdateRoute53BlueGreen, AdjustSufixName, updateTerraformDependencies,
  formatTick, createAndCaptureChartSequentially, UpdateMetrics, adjustTimestamps, fetchAndProcessMetrics, checkNodeVisibility, checkAndRefreshToken,
  CallAPI,
}