import { Handle, Position, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const RT = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'RT.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const IGW = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'IGW.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const NatGateway = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'NatGateway.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const EgressOnlyIG = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'EgressOnlyIG.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const VPCEndpointG = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'VPCEndpointG.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const VPCEndpointI = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'VPCEndpointI.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { IGW, RT, NatGateway, EgressOnlyIG, VPCEndpointG, VPCEndpointI };

