import React, { useState, useContext } from 'react';
import { Handle, NodeToolbar, Position } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import '../VPC.css';
import '@reactflow/node-resizer/dist/style.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import FileSaver from 'file-saver';
import { Constraints, GNodes, GEdges, DictGeneralParam } from '../NodesData';
import GenericNode from '../GenericNode';
import { CheckIdenticalName, AdjustID, FindAllFathers, FindNodesChieldID, FindRegionAndCloud, MaskString } from '../Functions';
//import { API } from "../Config.js"
import { useModal } from '../ModalMain.js';
import GenericBoxNode from '../GenericBoxNode';
import '../index.css';

var ListOuputOpenNodes = [];
var ListInputOpenNodes = [];
var ListError = [];
var ListWarning = [];
var GlobalNodes = GNodes;
var GlobalEdges = GEdges;
var Code = "No code"
var ShowStatus = "Show Status";
const ViewCode = (e, data, NodeState, setNodeState) => {
    data.Mode = "ViewCode";
    setNodeState("ViewCode");
}
const CloseCode = (e, data, NodeState, setNodeState) => {
    data.Mode = "Regular";
    setNodeState("Regular");
}
const CloseGenCode = (e, data, NodeState, setNodeState) => {
    data.Mode = "Regular";
    setNodeState("Regular");
}
/*const GenCode = async (e, data, id, parentNode, NodeState, setNodeState) => {
    let TFID = data.TFID;
    let ListTF = [];
    let ListPositionTFSatete = 0;
    let CountListTF = 0;
    //Monta a lista de Terraform Nodes
    for (let i = 1; i < GNodes.length; i++) {
        if (GNodes[i].type === "TerraformN") {
            let TFFather = parseInt(GNodes[i].parentNode);
            let List = FindNodesChieldID(GNodes, TFFather);
            ListTF.push([i, List]);
            if (i == TFID) { ListPositionTFSatete = CountListTF }
            CountListTF += 1;
        }
    }
    //Crai a lista de nodes do TF corrente: ListNodesID
    let UsedNodesList = FindAllFathers(GNodes, TFID);
    let ListTFState = ListTF[ListPositionTFSatete][1];
    UsedNodesList = UsedNodesList.concat(ListTFState);
    let ListNodesID = [];
    for (let i = 0; i < UsedNodesList.length; i++) {
        ListNodesID.push(UsedNodesList[i]);
    }
    //verifica outros TF nodes que são source ou target do TF currente, e inclui os nodes na lista UsedNodesList
    for (let i = 0; i < GEdges.length; i++) {
        let Source = parseInt(GEdges[i]["source"]);
        let Target = parseInt(GEdges[i]["target"]);
        if (ListTF[ListPositionTFSatete][1].includes(Target) || (ListTF[ListPositionTFSatete][1].includes(Source))) {
            console.log("Source Target", Source, Target)
            for (let j = 0; j < ListTF.length; j++) {
                if ((ListTF[j][1].includes(Source)) || (ListTF[j][1].includes(Target))) {
                    let CurrentTFID = ListTF[j][0];
                    console.log("TF", ListTF[j][0], GNodes[ListTF[j][0]].data.Param[1][1])
                    if (CurrentTFID != TFID) {
                        UsedNodesList = UsedNodesList.concat(ListTF[j][1]);
                        let ListFather = FindAllFathers(GNodes, CurrentTFID);
                        UsedNodesList = UsedNodesList.concat(ListFather);
                    }
                }
            }
        }
    }
    //********* insere TODOS os nodes ******************
    for (let i = 1; i < GNodes.length; i++) {
        UsedNodesList[i] = parseInt(GNodes[i].id);
    }
    //Convert UsedNodesList para Int
    for (let i = 0; i < UsedNodesList.length; i++) {
        UsedNodesList[i] = parseInt(UsedNodesList[i]);
    }
    // Cria lista dos novos nodes para enviar ao backend
    let NewNodes = [];
    let NewNode = [];
    let NewNodeToOldNode = {};
    let OldNodeToNewNode = {};
    UsedNodesList = [...new Set(UsedNodesList)];
    for (let i = 0; i < UsedNodesList.length; i++) {
        NewNode = JSON.parse(JSON.stringify(GNodes[UsedNodesList[i]]));
        NewNode.id = i.toString();
        NewNodeToOldNode[i] = UsedNodesList[i];
        OldNodeToNewNode[UsedNodesList[i]] = i;
        //console.log("type", GNodes[UsedNodesList[i]].id, GNodes[UsedNodesList[i]].type, GNodes[UsedNodesList[i]].data.Param[1][1]);
        NewNodes.push(NewNode);
    }
    console.log("UsedNodesList", UsedNodesList);
    console.log("OldNodeToNewNode", OldNodeToNewNode);
    // Atualiza o campo parentNode dos nodes
    for (let i = 0; i < NewNodes.length; i++) {
        //console.log("NewNodes[i].parentNode)", NewNodes[i].parentNode);
        try {
            NewNodes[i].parentNode = OldNodeToNewNode[parseInt(NewNodes[i].parentNode)].toString();
        } catch (error) { //pass }
            NewNodes[i].parentNode = "-1"
        }
    }
    //Atualiza os id de ListNodesID
    for (let i = 0; i < ListNodesID.length; i++) {
        ListNodesID[i] = OldNodeToNewNode[ListNodesID[i]].toString();
    }
    //Cria a lista das novas edges para enviar ao backend
    let NewEdges = [];
    let NewEdge = [];
    console.log("Teste edge", UsedNodesList);
    for (let i = 0; i < GEdges.length; i++) {
        if (UsedNodesList.includes(parseInt(GEdges[i].source))) {
            NewEdge = JSON.parse(JSON.stringify(GEdges[i]));
            let Source = parseInt(NewEdge.source);
            NewEdge.source = OldNodeToNewNode[Source].toString();
            let Target = parseInt(NewEdge.target);
            //console.log("Target", Target);
            try {
                NewEdge.target = OldNodeToNewNode[Target].toString();
                NewEdges.push(NewEdge);
            } catch (error) { //pass }
            }
        }
    }
    for (let i = 0; i < UsedNodesList.length; i++) {
        console.log("Used node ", GNodes[UsedNodesList[i]].id, GNodes[UsedNodesList[i]].type, GNodes[UsedNodesList[i]].data.Param[1][1]);
    }
    console.log("NewNodes", NewNodes);
    console.log("NewEdges", NewEdges);
    console.log("ListNodesID", ListNodesID);
    let UserName = sessionStorage.getItem("UserName");
    var TFFilter = data.Param[2][1];
    var TerraformNodeName = data.Param[1][1];
    console.log("TFFilter", TFFilter, ListNodesID);
    console.log("ListNodesID **********************************************", ListNodesID);
    var raw = JSON.stringify([TerraformNodeName, ListNodesID, UserName, NewEdges, NewNodes]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
    let Response = await fetch(API + "GenTerraformCode", requestOptions);
    Response = await Response.json();
    console.log("Responsy", Response)
    Code = Response.body;
    data.CodeGenerated = Code[0];
    ListOuputOpenNodes = Code[1];
    console.log("ListOuputOpenNodes", JSON.parse(JSON.stringify(ListOuputOpenNodes)))
    for (let i = 0; i < ListOuputOpenNodes.length; i++) {
        ListError[i] = [NewNodeToOldNode[ListOuputOpenNodes[i][0]], ListOuputOpenNodes[i][1]];
    }
    ListInputOpenNodes = Code[2];
    console.log("ListInputOpenNodes", JSON.parse(JSON.stringify(ListInputOpenNodes)))
    for (let i = 0; i < ListInputOpenNodes.length; i++) {
        ListError[i] = [NewNodeToOldNode[ListInputOpenNodes[i][0]], ListInputOpenNodes[i][1]];
    }
    ListError = Code[3];
    console.log("ListError", JSON.parse(JSON.stringify(ListError)))
    for (let i = 0; i < ListError.length; i++) {
        ListError[i] = [NewNodeToOldNode[ListError[i][0]], ListError[i][1]];
        console.log("ListError[i]", ListError[i]);
    }
    ListWarning = Code[4];
    console.log("ListWarning", JSON.parse(JSON.stringify(ListWarning)))
    for (let i = 0; i < ListWarning.length; i++) {
        ListWarning[i] = [NewNodeToOldNode[ListWarning[i][0]], ListWarning[i][1]];
        console.log("ListWarning[i]", ListWarning[i]);
    }
    data.Mode = "GenCode";
    console.log("Code", Code[0]);
    setNodeState("GenCode");
}*/

function FindNodesTFState(GNodes, TFID) {
    let RegionID = parseInt(FindRegionAndCloud(GNodes, TFID)[1]);
    console.log("RegionID", RegionID)
    let Region = GNodes[RegionID].data.Param[2][2];
    let Father = GNodes[parseInt(TFID)].parentNode;
    let ListNodes = FindNodesChieldID(GNodes, Father);
    console.log("ListNodes", ListNodes, Father)
    return [ListNodes, Region]
}
/*const Status = async (e, data) => {
    //const { refreshNodes, setRefreshNodes } = data.Refresh;
    let TFID = data.TFID;
    let [ListNodes, Region] = FindNodesTFState(GNodes, TFID);
    let ResourceList = [];
    let ListNodesStatus = [];
    for (let i = 0; i < ListNodes.length; i++) {
        let NodeType = GNodes[parseInt(ListNodes[i])].type;
        let ResourceName = GNodes[parseInt(ListNodes[i])].data.Param[1][1];
        try {
            let GeneralParam = DictGeneralParam[NodeType];
            let HasStatus = GeneralParam.HasStatus;
            let AWSName = GeneralParam.AWSName;
            if (HasStatus) {
                ResourceList.push([AWSName, ResourceName]);
                ListNodesStatus.push(parseInt(ListNodes[i]));
            }
            console.log("HasStatus,AWSName", NodeType, HasStatus, AWSName)
            console.log("ResourceList", ResourceList);
        } catch (error) {
            //pass
        }
    }
    var raw = JSON.stringify({ "Region": Region, "ResourceList": ResourceList });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
    let Response = await fetch(API + "AWSResourceStatus", requestOptions);
    Response = await Response.json();
    let ListID = Response.body;
    console.log("ListID", ListID)
    for (let i = 0; i < ListNodesStatus.length; i++) {
        if (ListID[i].length !== 0) {
            GNodes[ListNodesStatus[i]].data.EnableBorder = true;
            GNodes[ListNodesStatus[i]].data.Border = '2px solid #20FF20';
            GNodes[ListNodesStatus[i]].data.arn = ListID[i][1];
            console.log("Node Green", GNodes[ListNodesStatus[i]].type, ListID[i][1])
        } else {
            GNodes[ListNodesStatus[i]].data.EnableBorder = true;
            GNodes[ListNodesStatus[i]].data.Border = '2px solid #FF2020';
            GNodes[ListNodesStatus[i]].data.arn = "";
            console.log("Node Red", GNodes[ListNodesStatus[i]].type, ListID[i][1])
        }
    }
    //setRefreshNodes(!refreshNodes);
}*/
const dragHandleStyle = {
    display: 'inline-block',
    width: 300,
    height: 30,
    backgroundColor: 'teal',
    marginLeft: 5,
    borderRadius: 1,
};
const Terraform = ({ data, id, parentNode, selected, dragable }) => {
    GlobalNodes = GNodes;
    GlobalEdges = GEdges;
    const [NodeState, setNodeState] = useState("Regular");
    const [isMouseNear, setIsMouseNear] = useState(false);
    const { showCode } = useModal();

    if (NodeState == "Regular") {
        data.Mode = "Regular"
        let Label = "Terraform";
        let URLIcon = 'url("' + Constraints.IconsURL + 'Terraform.png")';
        let URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
        let Opacity = data.Opacity;
        return (
            <div
                onMouseEnter={() => setIsMouseNear(true)}
                onMouseLeave={() => setIsMouseNear(false)}
            >
                <div
                    className={`node-container ${isMouseNear ? 'enlarged' : ''}`}
                    style={{
                        boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
                        borderRadius: "7%",
                        opacity: Opacity,
                    }}
                >
                    <div
                        className="circleNode"
                        style={{
                            backgroundImage: URLIcon,
                            filter: selected ? "saturate(500%)" : "none",
                            borderRadius: (data.ShowLabel || data.ShowLabelName) ? "3px 3px 0 0" : "3px",
                        }}
                    ></div>
                    {data.ShowLabel && (
                        <div
                            className="BarNameFontSmaller"
                            style={{
                                background: selected ? "#C0C0E0" : "#FFFFFF", width: "40px", height: "auto",
                                borderRadius: data.ShowLabelName ? "0" : "0 0 3px 3px",
                            }}
                        >
                            {Label}
                        </div>
                    )}
                    {!data.AS && data.ShowLabelName && (
                        <div
                            className="BarNameFontSmaller"
                            style={{
                                background: selected ? "#C0C0E0" : "#FDFDFD", width: "40px", height: "auto",
                                borderRadius: "0 0 3px 3px",
                            }}
                        >
                            {data.Param[1][1]}
                        </div>
                    )}
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="right"
                        position={Position.Right}
                        type="source"
                        isConnectable={true}
                    />
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="left"
                        position={Position.Left}
                        type="source"
                        isConnectable={true}
                    />
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="top"
                        position={Position.Top}
                        type="source"
                        isConnectable={true}
                    />
                    <Handle
                        className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                        id="bottom"
                        position={Position.Bottom}
                        type="source"
                        isConnectable={true}
                    />
                </div>
            </div>
        );
    } else {
        if (NodeState == "ViewCode") {
            data.Mode = "ViewCode";
            dragable = false;
            const beautify = require('js-beautify').js_beautify;
            const beautifiedCode = beautify(data.CodeGenerated, { indent_size: 3 });
            return (
                <><div>
                    <button onClick={(e) => CloseCode(e.target.value, data, NodeState, setNodeState)}>Close</button>
                </div>
                    <div className="nowheel" style={{
                        height: "100%", overflow: "scroll", overflowY: 'auto',
                        width: '1200px', minWidth: '100%', minHeight: '100%', height: '900px', display: 'flex', flexWrap: 'wrap', zIndex: 25,
                    }}>
                        <SyntaxHighlighter showLineNumbers={true} language="terraform" style={dark}>
                            {beautifiedCode}
                        </SyntaxHighlighter >
                    </div>
                </>
            );
        } else { //Compile Result
            //console.log("GlobalNodes++++++++", GlobalNodes);
            data.Mode = "Compile";
            if (GlobalNodes.length > 0) {
                let NameError = CheckIdenticalName(GlobalNodes, Constraints);
                let WarningIcon = Constraints.IconsURL + "Warning.png";
                let ErrorIcon = Constraints.IconsURL + "Error.png";
                let WarningArray = [];
                let ErrorArray = [];
                for (let i = 0; i < ListOuputOpenNodes.length; i++) {
                    let ID = ListOuputOpenNodes[i];
                    //ID[0] = AdjustID(ID[0], GlobalNodes);
                    var IconOuput = Constraints.IconsURL + GlobalNodes[ID[0]].type.slice(0, -1) + ".png"
                    WarningArray.push([ID[0], ID[1], IconOuput]);
                }
                for (let i = 0; i < ListInputOpenNodes.length; i++) {
                    let ID = ListInputOpenNodes[i];
                    //ID[0] = AdjustID(ID[0], GlobalNodes);
                    var IconInput = Constraints.IconsURL + GlobalNodes[ID[0]].type.slice(0, -1) + ".png"
                    WarningArray.push([ID[0], ID[1], IconInput]);
                }
                for (let i = 0; i < ListWarning.length; i++) {
                    let ID = ListWarning[i][0];
                    ID = AdjustID(ID, GlobalNodes);
                    let Msg = ListWarning[i][1];
                    try {
                        var IconInput = Constraints.IconsURL + GlobalNodes[ID].type.slice(0, -1) + ".png";
                    } catch (error) {
                        console.log("Icon Input Error", ID);
                    }

                    WarningArray.push([ID, Msg, IconInput]);
                }
                console.log("ListError", ListError)
                for (let i = 0; i < ListError.length; i++) {
                    let ID = ListError[i][0];
                    console.log("ID", ID);
                    ID = AdjustID(ID, GlobalNodes);
                    let Msg = ListError[i][1];
                    try {
                        var IconInput = Constraints.IconsURL + GlobalNodes[ID].type.slice(0, -1) + ".png";
                        ErrorArray.push([ID, Msg, IconInput]);
                    } catch (error) {
                        console.log("Error GlobalNodes", GlobalNodes, ID)
                    }
                    //var IconInput = Constraints.IconsURL + GlobalNodes[ID].type.slice(0, -1) + ".png";
                    //ErrorArray.push([ID, Msg, IconInput]);

                }
                let Success = ((ErrorArray.length == 0) && (WarningArray.length == 0));
                let Fail = ErrorArray.length > 0;
                let Pass = !Fail && !Success;

                function HandleSelect(e, ID) {
                    if (data && typeof data.onValueChange === 'function') {
                        data.Select = true;
                        data.onValueChange([id, ID.toString()]);
                    } else {
                        console.error('data or data.onValueChange is not defined');
                    }
                }

                return (
                    <>
                        <div className="react-flow__node-custom">
                            <div className="custom-node__header">
                                <h2 >Result:</h2>
                            </div>
                            <div className="custom-node__header" style={{ fontSize: 9 }}>
                                {NameError.map((ID) =>
                                    <>
                                        <img src={ErrorIcon} alt="Descrição da imagem" style={{ height: "19px", width: "19px", verticalAlign: "middle" }}></img>
                                        <strong> Error: </strong>
                                        <img src={ID[2]} alt="Descrição da imagem" onClick={(e) => HandleSelect(e.target.value, ID[0])}
                                            style={{
                                                height: "15px", width: "15px", borderRadius: "2px", verticalAlign: "middle",
                                                border: "1px solid black", cursor: "pointer !important"
                                            }}></img> and <img src={ID[2]} alt="Descrição da imagem" onClick={(e) => HandleSelect(e.target.value, ID[0])}
                                                style={{ height: "15px", width: "15px", borderRadius: "2px", verticalAlign: "middle", border: "1px solid black", cursor: "pointer !important" }}></img>

                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>
                                            {GlobalNodes[ID[0]].data.Param[1][1]} {ID[3]}</span>
                                        <br></br>
                                    </>

                                )}
                                {WarningArray.map((ID) =>
                                    <>
                                        <img src={WarningIcon} alt="Descrição da imagem" style={{ height: "15px", width: "15px;", verticalAlign: "middle" }}></img>
                                        <strong> Warning: </strong>
                                        <img src={ID[2]} alt="Descrição da imagem" onClick={(e) => HandleSelect(e.target.value, ID[0])}
                                            style={{
                                                height: "15px", width: "15px;", borderRadius: "2px", verticalAlign: "middle",
                                                cursor: "pointer !important", border: "1px solid black",
                                            }}></img>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>
                                            {GlobalNodes[ID[0]].data.Param[1][1]} {ID[1]}</span>
                                        <br></br>
                                    </>
                                )}
                                {ErrorArray.map((ID) =>
                                    <>
                                        <img src={ErrorIcon} alt="Descrição da imagem" style={{ height: "19px", width: "19px;", verticalAlign: "middle" }}></img>
                                        <strong> Error: </strong>
                                        <img src={ID[2]} alt="Descrição da imagem" onClick={(e) => HandleSelect(e.target.value, ID[0])}
                                            style={{ height: "15px", width: "15px;", borderRadius: "2px", verticalAlign: "middle", border: "1px solid black" }}></img>
                                        <span style={{ lineHeight: "15px", margin: "0 0 0 3px" }}>
                                            {GlobalNodes[ID[0]].data.Param[1][1]} {ID[1]}</span>
                                        <br></br>
                                    </>
                                )}
                            </div>
                            {Success && <h3> Success! 0 Errors and 0 wranings.</h3>}
                            {Fail && <h3> Fail! With {ErrorArray.length} error(s). </h3>}
                            {Pass && <h3> Pass with {WarningArray.length} warning(s). </h3>}
                            <button style={{ display: "block", margin: "0 auto 2px" }}
                                onClick={(e) => CloseGenCode(e.target.value, data, NodeState, setNodeState)}>Close</button>
                            <br></br>

                        </div>
                    </>
                );
            } else {
                return (
                    <>Erro GlobalNodes
                        <button onClick={(e) => CloseGenCode(e.target.value, data, NodeState, setNodeState)}>Close</button >
                    </>
                )
            }
        };
    }
}
/*const SaveCodeBackend = async (e, data, id) => {
    let UserName = sessionStorage.getItem("UserName");
    var TFFilter = id;//data.Param[4][1];
    var TerraformNodeName = data.Param[1][1];
    var StartFromResource = data.ParentID;
    var CredentialFile = "";//data.Param[2][1];
    var ListNodesID = data.Param.ListNodesID;
    console.log("TFFilter", TFFilter, StartFromResource, CredentialFile);
    console.log("ListNodesID **********************************************", ListNodesID);
    var raw = JSON.stringify([TerraformNodeName, ListNodesID, CredentialFile, UserName]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = { method: 'Post', headers: myHeaders, body: raw, redirect: 'follow' };
    let Response = await fetch(API + "GenTerraformCode", requestOptions);
    Response = await Response.json();
    Code = Response.body[0];
    console.log("Code", Code);
    const blob = new Blob([Code], { type: 'text/plain;charset=utf-8' });
    var FileName = "BackendS3.tf"
    FileSaver.saveAs(blob, FileName);
}*/
const TerraformBackendS3 = ({ data, id, selected }) => {
    GlobalNodes = GNodes;
    GlobalEdges = GEdges;
    let Label = data.Label;
    let URLIcon = Constraints.IconsURL + 'TerraformBackendS3.png';
    let Opacity = data.Opacity;
    const [isMouseNear, setIsMouseNear] = useState(false);

    return (
        <div
            onMouseEnter={() => setIsMouseNear(true)}
            onMouseLeave={() => setIsMouseNear(false)}
        >
            <div
                className={`node-container ${isMouseNear ? 'enlarged' : ''}`}
                style={{
                    boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)',
                    borderRadius: "7%",
                    opacity: Opacity,
                }}
            >
                <div
                    className="circleNode"
                    style={{
                        backgroundImage: `url(${URLIcon})`,
                        filter: selected ? "saturate(500%)" : "none",
                        borderRadius: data.ShowLabel ? "3px 3px 0 0" : "3px",
                    }}
                ></div>
                {data.ShowLabel && (
                    <div
                        className="BarNameFontSmaller"
                        style={{
                            background: selected ? "#C0C0E0" : "#FFFFFF", width: "40px", height: "auto",
                            borderRadius: data.ShowLabelName ? "0" : "0 0 3px 3px",
                        }}
                    >
                        {Label}
                    </div>
                )}
                {!data.AS && data.ShowLabelName && (
                    <div
                        className="BarNameFontSmaller"
                        style={{
                            background: selected ? "#C0C0E0" : "#FDFDFD", width: "40px", height: "auto",
                            borderRadius: "0 0 3px 3px",
                        }}
                    >
                        {data.Param[1][1]}
                    </div>
                )}
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true}
                />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true}
                />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true}
                />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true}
                />
            </div>
        </div>
    );
};

const TerraformBackendFull = ({ data, id, selected }) => {
    GlobalNodes = GNodes;
    GlobalEdges = GEdges;
    let Label = data.Label;
    let URLIcon = 'url("' + Constraints.IconsURL + 'TerraformBackendFull.png")';
    let URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    let Opacity = data.Opacity;
    return (
        <>
            <div style={{
                boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                opacity: Opacity
            }}>

                <div className="circleNode" style={{
                    backgroundImage: URLIcon,
                    filter: selected ? "saturate(500%)" : "none", borderRadius: data.ShowLabel ? "3px 3px 0 0 " : "3px"
                }} ></div>
                {data.ShowLabel && (<div className="BarNameFontSmaller"
                    style={{
                        width: "40px", background: selected ? "#C0C0E0" : "#FFFFFF", height: "auto",
                        borderRadius: data.ShowLabelName ? "0" : "0 0 3px 3px"
                    }}>
                    {Label}
                </div>
                )}
                {!data.AS && data.ShowLabelName && (<div class="BarNameFontSmaller"
                    style={{ width: "40px", background: selected ? "#C0C0E0" : "#FDFDFD", height: "auto", borderRadius: "0 0 3px 3px" }}>
                    {data.Param[1][1]}
                </div>
                )}
                <Handle type="source" position="right" style={{
                    background: 'transparent', backgroundImage: `url(${URLArrow})`, backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat', backgroundPosition: 'center', border: 'none', afterBorderColor: 'none',
                    width: '8px', height: '8px', top: '7px', transform: 'translateX(-2px)', imageRendering: 'crisp-edges',
                }} isConnectable={true} />
                <Handle type="target" id="4" position="left" style={{
                    background: 'transparent', backgroundImage: `url(${URLArrow})`, backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat', backgroundPosition: 'center', border: 'none', afterBorderColor: 'none',
                    width: '8px', height: '8px', top: '7px', transform: 'translateX(-2px)', imageRendering: 'crisp-edges',
                }}
                    onConnect={(params) => console.log('handle onConnect', params)} isConnectable={true} />
            </div>
        </>
    );
}

const Cloud = ({ id, data, selected }) => {
    const URLIcon = Constraints.IconsURL + 'Cloud.png';
    const ProviderURLIcon = Constraints.IconsURL + 'AWS.png';
    let CloudPRovider = data.Param[2][2];
    let Name = data.Param[1][1];
    let Account;
    try {
        Account = MaskString(data.Param[3][1]);
    } catch (error) {
        Account = "";
    }
    //console.log("Account", Account, Name)
    let URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    return (
        <>
            <GenericBoxNode
                id={id}
                data={data}
                selected={selected}
                HasHandle={false}
                borderWidth={10}
            >
                <div style={{
                    position: "relative", boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.60)",
                    backgroundColor: selected ? "#A2A2A2" : "#f2f2f2", borderRadius: "15px 15px 0 0"
                }}>
                    <div className="custom-drag-handle" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                        <img src={URLIcon} alt="Icon" style={{ width: 80, height: 80, margin: "0px 11px" }} />
                        <div style={{ whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" }}></div>
                        <img src={ProviderURLIcon} alt="Icon" style={{ width: 60, height: 60, margin: "0px 11px" }} />
                        <div style={{ whiteSpace: "nowrap", fontSize: 25, fontWeight: "bold" }}>  </div>
                        <div><h1>&nbsp;Name:&nbsp;{Name}&nbsp;&nbsp;&nbsp;Account:&nbsp;{Account}</h1></div>
                    </div>
                    <div style={{ borderRadius: "10%" }}>
                    </div>
                </div>
                <Handle
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />
            </GenericBoxNode>
        </>
    );
};



const Null = ({ id, data, selected }) => {
    return (
        <>
        </>)
}

const Tag = ({ id, data, selected }) => {
    let URLIcon = 'url("' + Constraints.IconsURL + 'Tag.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};

const Teste = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'Teste.png")';
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} />
    );
};

const AWSARN = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'AWSARN.png")';
    let ARN = data.Param[1][1].split(":")[2]
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const Host = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'Host.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CanonicalID = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CanonicalID.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

export { Cloud, Tag, Terraform, Null, Teste, AWSARN, Host, TerraformBackendS3, TerraformBackendFull, CanonicalID };




