import { Handle, NodeToolbar } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'


const CodeCommit = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodeCommit.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CodeDeployGroup = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodeDeployGroup.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CodeBuild = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodeBuild.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CodeBuildSource = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodeBuildSource.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CodeBuildArtifact = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodeBuildArtifact.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const CodePipeline = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodePipeline.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    )
};
const CodePipelineAction = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodePipelineAction.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    )
};
const CodePipelineStage = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodePipelineStage.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    )
};
const CodeBuildNotification = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'CodeBuildNotification.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};


export {
    CodeDeployGroup, CodeBuild, CodeBuildSource, CodeBuildArtifact, CodeCommit, CodeBuildNotification, CodePipeline, CodePipelineAction,
    CodePipelineStage
};

