
var NodeData = {};
var ListParamCmd = [];
var ListResources = [];
var Constraints = [];
var NodesType = [];
var DictResourceEvent = [];
var ListaStandard = [];
var TagLookUp = [];
var GNodes = [];
var GEdges = [];
var EdgesAtributes = {};
var ListDocAWS = {};
var ListRegions = [];
var DictGeneralParam = [];
var ResourceLookUp = [];
var DictTarget = [];
var DictSource = [];
var ConstraintsAWS = [];
var ConstraintsAZ = [];
var TypeCloud = [];
var TypeVPC = [];
var TypeRegion = [];
var TypeAZ = [];
var TypeSubnet = [];
var GlobalToken = null;
var Code = null;
var ListNodeWithCost = [];
var ListTemplates = [];
var GlobalCognitoSub;
var GlobalUserName;
var setNodes, AccessExpiresAt, RefreshExpiresAt, RefreshToken, Stage, ShowGraph, TypeTerraform;


function NodesData(ListFull, PropListStandard, GlobalNodes, GlobalEdges, DictEdgesAtributes, PropListDoc, PropListRegions,
    PTypeCloud, PTypeRegion, PTypeVPC, PTypeAZ, PTypeSubnet, PGlobalToken, PCode, PListTemplates, PGlobalCognitoSub, PGlobalUserName, PGsetNodes,
    PAccessExpiresAt, PRefreshExpiresAt, PRefreshToken, PStage, PShowGraph, PTypeTerraform) {
    ListParamCmd = ListFull[1];
    ListResources = ListFull[0];
    Constraints = ListFull[4];
    NodeData = ListFull[6];
    DictGeneralParam = ListFull[16];
    ListaStandard = PropListStandard;
    TagLookUp = ListFull[5];
    GNodes = GlobalNodes;
    GEdges = GlobalEdges;
    EdgesAtributes = DictEdgesAtributes;
    ListDocAWS = PropListDoc;
    ListRegions = PropListRegions;
    ResourceLookUp = ListFull[7];
    DictTarget = ListFull[2][0];
    DictSource = ListFull[2][3];
    ConstraintsAWS = ListFull[4];
    TypeCloud = PTypeCloud;
    TypeVPC = PTypeVPC;
    TypeRegion = PTypeRegion;
    TypeAZ = PTypeAZ;
    TypeSubnet = PTypeSubnet;
    GlobalToken = PGlobalToken;
    Code = PCode;
    ListNodeWithCost = ListFull[18];
    ListTemplates = PListTemplates;
    GlobalCognitoSub = PGlobalCognitoSub;
    GlobalUserName = PGlobalUserName;
    setNodes = PGsetNodes;
    AccessExpiresAt = PAccessExpiresAt;
    RefreshExpiresAt = PRefreshExpiresAt;
    RefreshToken = PRefreshToken;
    Stage = PStage;
    ShowGraph = PShowGraph;
    TypeTerraform = PTypeTerraform;
    return NodeData;
}
export {
    NodesData, NodeData, Constraints, NodesType, DictResourceEvent, ListaStandard, TagLookUp, GNodes, GEdges, EdgesAtributes, ListDocAWS,
    ListRegions, DictGeneralParam, ResourceLookUp, DictTarget, DictSource, ConstraintsAWS, TypeCloud, TypeVPC, TypeRegion, TypeAZ, TypeSubnet,
    GlobalToken, ListNodeWithCost, Code, ListTemplates, GlobalCognitoSub, GlobalUserName, setNodes, AccessExpiresAt, RefreshExpiresAt, RefreshToken,
    Stage, ShowGraph, TypeTerraform,
}