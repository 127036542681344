import { Handle, Position, NodeProps } from "reactflow";
import { Constraints, GNodes } from './NodesData';
import './index.css';
import { useState } from 'react';

export default ({ data, URL, Border = "", EnableBorder = false, Selected, LabelName = "", Label = "", Color = "Gray" }) => {
    const URLArrow = Constraints.IconsURL + 'ArrowHandlerF.png';
    if (EnableBorder === null) { EnableBorder = false; }
    if (Border === null) { Border = ""; }
    const [isMouseNear, setIsMouseNear] = useState(false);
    if (Label == "") {
        Label = data.Label;
    }
    let ShowLabel;
    try {
        ShowLabel = data.ShowLabel;
    } catch (error) {
        ShowLabel = false;
    }
    let ShowLabelName;
    try {
        ShowLabelName = data.ShowLabelName;
    } catch (error) {
        ShowLabelName = false;
    }
    let Opacity;
    try {
        Opacity = data.Opacity;
    } catch (error) {
        Opacity = false;
    }
    try {
        Opacity = data.Opacity;
    } catch (error) {
        Opacity = false;
    }
    let Cost;
    let ShowCost;
    try {
        ShowCost = data.ShowCost;
        Cost = data.Cost;
    } catch (error) {
        ShowCost = false;
    }

    return (
        <div
            onMouseEnter={() => setIsMouseNear(true)}
            onMouseLeave={() => setIsMouseNear(false)}
        >
            <div className={`node-container ${isMouseNear ? 'enlarged' : ''}`} style={{
                boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%",
                border: EnableBorder ? Border : 'none', Color: "yellow",
                opacity: Opacity
            }}>
                <div
                    className="circleNode"
                    style={{
                        backgroundImage: URL,
                        filter: Selected ? "saturate(500%)" : "none",
                        borderRadius: (ShowLabel || ShowLabelName) ? "3px 3px 0 0" : "3px",
                        position: 'relative', // Assegurar que o posicionamento dos elementos internos seja relativo ao pai
                        display: 'flex', // Usar flex para centralizar o conteúdo
                        justifyContent: 'center', // Centralizar horizontalmente
                        alignItems: 'flex-end', // Alinhar verticalmente o conteúdo na parte de baixo
                        color: 'yellow', // Cor do texto ajustada para amarelo
                        fontSize: '8px' // Tamanho da fonte ajustado para 8px
                    }}

                >
                    {(ShowLabel || ShowLabelName) && (
                        <div style={{
                            padding: '10px',
                            background: ShowCost ? 'rgba(0, 0, 0, 0.3)' : 'none',
                            textAlign: 'center',
                            width: '100%', // Garantir que ocupe toda a largura do pai
                            height: '50%',
                            borderRadius: 'inherit' // Herdar o borderRadius do pai para manter a consistência visual
                        }}>
                            {ShowLabelName && <div>{ShowLabelName}</div>}
                            {ShowCost && <div>$<br></br><br></br>{Cost}</div>}
                        </div>
                    )}
                </div>
                {ShowLabel && (<div className="BarNameFontSmaller"
                    style={{
                        width: "40px", background: Selected ? "#C0C0E0" : "#FFFFFF", height: "auto", borderRadius: ShowLabelName ? "0" : "0 0 3px 3px"
                    }}>
                    {Label}
                </div>
                )}
                {ShowLabelName && (<div className="BarNameFontSmaller"
                    style={{ width: "40px", background: Selected ? "#C0C0E0" : "#FDFDFD", height: "auto", borderRadius: "0 0 3px 3px" }}>
                    {LabelName}
                </div>
                )}
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />

                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />

            </div>
        </div >
    );
};
