import { Handle, Position } from "reactflow";
import { ResourceLabelAdjust } from '../Functions'
import { Constraints } from '../NodesData';
import GenericNode from '../GenericNode'
import { useState } from 'react';

const LBTG = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'LBTG.png")';
    let LabelName = data.Param[1][1];
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const ALBListener = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ALBListener.png")';
    let Protocol = data.Param[3][3];
    let Port = data.Param[4][1]
    if (Protocol == "") { Protocol = "HTTP"; }
    let LabelName = data.Param[1][1] + " " + Protocol + " " + Port;
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ALBAction = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ALBAction.png")';
    let TypeIndex = data.Param[3][1];
    let Array = ["forward", "redirect", "fixed-response", "authenticate_cognito", "authenticate_oidc"];
    let Type = Array[TypeIndex];
    let LabelName = data.Param[1][1] + " " + Type;
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};
const ALBCondition = ({ data, selected }) => {
    const URLIcon = 'url("' + Constraints.IconsURL + 'ALBCondition.png")';
    let TypeIndex = data.Param[2][1];
    let Array = ["source_ip", "host_header", "http_header", "http_request_method", "path_pattern", "query_string"];
    let Type = Array[TypeIndex];
    let LabelName = data.Param[1][1] + " " + Type;
    return (
        <GenericNode URL={URLIcon} data={data} Selected={selected} LabelName={LabelName} />
    );
};

const ALoadBalancer = ({ data, selected }) => {
    let Label = data.Label;
    const URLIcon = 'url("' + Constraints.IconsURL + 'ALoadBalancer.png")';
    const [isMouseNear, setIsMouseNear] = useState(false);
    let Opacity = data.Opacity;
    let LabelName = data.Param[1][1];
    return (
        <div
            onMouseEnter={() => setIsMouseNear(true)}
            onMouseLeave={() => setIsMouseNear(false)}
        >
            <div style={{
                boxShadow: '2px 0px 4px 0px rgba(0,0,0,0.60)', borderRadius: "7%", opacity: Opacity
            }}>
                <div className="circleNode" style={{
                    backgroundImage: URLIcon,
                    filter: selected ? "saturate(500%)" : "none", borderRadius: data.ShowLabel ? "3px 3px 0 0 " : "3px"
                }} ></div>

                {data.ShowLabel && (<div className="BarNameFontSmaller"
                    style={{
                        width: "40px", background: selected ? "#C0C0E0" : "#FFFFFF", height: "auto",
                        borderRadius: data.ShowLabelName ? "0" : "0 0 3px 3px"
                    }}>
                    {Label} {data.Param[1][1]}
                </div>
                )}

                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="right"
                    position={Position.Right}
                    type="source"
                    isConnectable={true} />

                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Left}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="left"
                    position={Position.Top}
                    type="source"
                    isConnectable={true} />
                <Handle
                    className={`customHandler ${isMouseNear ? 'visible' : ''}`}
                    id="top"
                    position={Position.Bottom}
                    type="source"
                    isConnectable={true} />
            </div>
        </div>
    );
};
export { ALoadBalancer, LBTG, ALBListener, ALBAction, ALBCondition };