import React, { memo } from 'react';
import ReactFlow, { Handle, Position, NodeToolbar, useStoreApi } from "reactflow";
//import { useStoreApi } from '@reactflow/store-api';
import { RemoveSelected, SearchNameNodeType, PermitionToAddNode, } from './Functions'
import '@reactflow/node-resizer/dist/style.css';

var GlobalNodeID = 2;
var Category = "GeneralNodes";

const SelectMenu = ({ data }) => {
    //const { store, state, actions } = useStoreApi();

    //const AddNode = () => {
    //    const { actions } = useStoreApi();
    //}

    function GetNodeId() {
        let ID = `${GlobalNodeID++}`
        console.log("Novo ID", ID);
        return [ID, false];
    }

    function HandlerGeneralB(NodeType, nodes, NodeData, NewDataParams = "") {
        let Resource = NodeType.slice(0, -1);
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].selected == true) {
                let IDParent = PermitionToAddNode(NodeType, nodes[i], NodeData);
                console.log("IDParentRT", IDParent);
                if (IDParent != "0") {
                    let ID = NodeGeneral(IDParent, Resource, nodes, NodeData, NewDataParams);
                    return ID;
                }
            }
        }
    }

    const handleNodeAdd = (node) => {
        node.id = 'node-1';
        node.label = 'Node 1';
        node.x = 100;
        node.y = 100;
    };

    const URL = data.EspecificParams[0].IconsURL;
    const NetworkNodes = data.EspecificParams[0].NetworkNodes;
    const ComputingNodes = data.EspecificParams[0].ComputingNodes;


    console.log("data.EspecificParams[0]", URL, NetworkNodes);
    var imagePaths = [];
    var NodesType = [];
    var ListNodesType = data.EspecificParams[1];
    var SelectNodes = data.EspecificParams[0][Category];
    console.log("SelectNodes jkjkjkjkjkjk", SelectNodes);

    for (let i = 0; i < SelectNodes.length; i++) {
        let Files = SelectNodes[i].slice(0, -1) + '.png'
        imagePaths.push(URL + Files);
        NodesType.push(SelectNodes[i].slice(0, -1));
    }

    function AddNodeFunc(index) {
        console.log("NodesKK", index, NodesType[index])
        sessionStorage.setItem("NewNode", NodesType[index]);


        //actions.addNode({ id: "3", label: 'Novo nó' });

    }

    function NodeGeneral(IDParent, Resource, nodes, NodeData, NewDataParams) {
        let GetID = GetNodeId();
        const id = GetID[0];
        const Recycle = GetID[1];
        RemoveSelected(nodes);
        var NewData = "";
        if (Recycle) {
            NewData = NodeData[Resource];
            console.log("NewData", NewData)
        } else {
            NewData = JSON.parse(JSON.stringify(NodeData[Resource]));
        }
        console.log("NodeGerneral", Recycle, id, NewData);
        let newNode = { id, parentNode: `${IDParent}`, ...NewData }
        if (NewDataParams != "") {
            newNode.data.EspecificParams = NewDataParams;
        }
        console.log("newNode", newNode)
        let Name = SearchNameNodeType(nodes, Resource + "N", newNode.data.Param[1][1]);
        newNode.data.Param[1][1] = Name;
        AddNodeFunc(newNode, Recycle, id);
        return id;
    }

    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        transform: 'none !important',
    };

    const gridStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: 'repeat(5, 1fr)',
        gridGap: '8px',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        border: '2px solid black',
        transform: 'none !important',
    };

    const textStyle = {
        position: 'absolute',
        top: '3px',
        left: '3px',
        fontSize: '16px',
        color: 'black',
        zIndex: 1,
        transform: 'none !important',
    };

    const imageStyle = {
        width: '50px',
        height: '50px',
        margin: '3px',
        border: '2px solid black',
        display: 'inline-block',
        position: 'relative',
        transform: 'none !important',
    }

    const buttonContainer = {
        display: "flex",
        transform: 'none !important',
    }

    const handleClick = (index) => {
        console.log('image index:', index);
    }


    var buttons = document.querySelectorAll("button");
    buttons.forEach(function (button) {
        button.addEventListener("click", function () {
            buttons.forEach(function (btn) {
                btn.style.backgroundColor = "#aaa";
            });
            button.style.backgroundColor = "#eee";
            //if (button.id == "General"){
            Category = button.id;
            //}
        });
    });
    return (
        <>
            <div>Resource Selection</div>
            <div class="buttonContainer">
                <button id="GeneralNodes" style={{ backgroundColor: "#eee" }}>General</button>
                <button id="NetworkNodes" style={{ backgroundColor: "#aaa" }}>Network</button>
                <button id="ComputingNodes" style={{ backgroundColor: "#aaa" }}>Computing</button>
                <button id="btn4" style={{ backgroundColor: "#aaa" }}>Button 4</button>
                <button id="btn5" style={{ backgroundColor: "#aaa" }}>Button 5</button>
            </div>
            <div style={containerStyle}>

                <div style={gridStyle}>
                    {imagePaths.map((image, index) => (
                        <img key={index} src={image} onClick={() => AddNodeFunc(index)}
                            style={imageStyle} />

                    ))}
                </div>
            </div>

        </>
    );
};


export default SelectMenu;
